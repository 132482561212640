import React from "react";
import EventSource from "eventsource";
import { useDebounce } from "ahooks";
import { usePageVisibility } from "react-page-visibility";
import { noOp } from "libs/helpers/no-op";

export interface UseSSEOptions<MessageEventData> {
  headers?: EventSource.EventSourceInitDict["headers"];
  withCredentials?: boolean;
  onOpen?: (event: MessageEvent<MessageEventData>) => void;
  onMessage?: (event: MessageEvent<MessageEventData>) => void;
  onError?: (event: MessageEvent<MessageEventData>) => void;
  refreshDeps?: React.DependencyList;
}

const HEADERS_DEFAULT = {};
const REFRESH_DEPS_DEFAULT: React.DependencyList = [];

export function useSSE<MessageEventData = any>(
  url: string,
  options: UseSSEOptions<MessageEventData> = {}
) {
  const {
    headers = HEADERS_DEFAULT,
    withCredentials = false,
    onOpen = noOp,
    onMessage = noOp,
    onError = noOp,
    refreshDeps = REFRESH_DEPS_DEFAULT,
  } = options;

  const isVisible = usePageVisibility();
  const debouncedIsVisible = useDebounce(isVisible, { wait: 1500 });

  React.useEffect(() => {
    if (debouncedIsVisible) {
      const eventSource = new EventSource(url, {
        withCredentials,
        headers,
      });
      eventSource.onopen = onOpen;
      eventSource.onmessage = onMessage;
      eventSource.onerror = onError;

      return () => {
        eventSource.close();
      };
    }
    // eslint-disable-next-line
  }, [...refreshDeps, debouncedIsVisible]);
}
