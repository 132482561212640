import { ThunkAction } from "redux-thunk";
import { userService } from "services";
import { UserRolesAction, UserRolesActionType } from "store/reducers/user-roles";
import { RootState } from "store/store";

export function clearUserRoles() {
  return { type: UserRolesActionType.CLEAR_USER_ROLES };
}

export function loadUserRoles(): ThunkAction<Promise<void>, RootState, unknown, UserRolesAction> {
  return async function loadUserRolesThunk(dispatch) {
    try {
      dispatch({ type: UserRolesActionType.LOAD_USER_ROLES });
      const roles = await userService.getMyRoles();
      dispatch({ type: UserRolesActionType.LOADED_USER_ROLES, payload: { userRoles: roles } });
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: UserRolesActionType.ERRORED_USER_ROLES, payload: { error } });
      }
    }
  };
}
