import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm, message } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import BranchDescription from "components/descriptions/BranchDescription";
import { branchService } from "services";
import { Branch } from "services/branch/models/branch";
import BranchForm, { BranchFormDto } from "components/forms/BranchForm";
import { BranchService } from "services/branch/branch.service";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import BranchesSettingsPage from "../branches/branches.page";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import CheckAbilities from "store/components/CheckAbilities";

export interface BranchSettingsPageParams {
  id: Branch["id"];
}

const fetchBranch = (id: Branch["id"]) => branchService.findOne(id, { join: ["city"] });

const updateBranch: BranchService["updateOne"] = (id, dto) => branchService.updateOne(id, dto);

const deleteBranch = (id: Branch["id"]) => branchService.deleteOne(id);

const BranchSettingsPage: PageFCWithPathCreator = () => {
  const { id } = useParams<BranchSettingsPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchBranch,
    data: branch,
    error: fetchBranchError,
    loading: fetchBranchLoading,
    refresh: refreshBranch,
    mutate: mutateBranch,
  } = useRequest(fetchBranch, {
    manual: true,
  });

  const { runAsync: runUpdateBranch, loading: updateBranchLoading } = useRequest(updateBranch, {
    manual: true,

    onError: (e, params) => {
      const [, dto] = params;
      const { name } = dto;
      if (e instanceof ApiDriverHttpError) {
        if (e.status === 409) {
          message.error(`Филиал с названием "${name}" уже существует!`);
        }
      }
    },
  });

  const { run: runDeleteBranch, loading: deleteBranchLoading } = useRequest(deleteBranch, {
    manual: true,
    onSuccess: () => {
      history.push(`${SettingsPage.path}${BranchesSettingsPage.path}`);
    },
  });

  const [edit, setEdit] = React.useState<boolean>(false);

  useSetTitleEffect([SettingsPage.label, BranchSettingsPage.label, branch?.name || id]);

  useMount(() => {
    if (id) {
      runFetchBranch(id);
    }
  });

  const handleUpdate = React.useCallback(
    async (dto: BranchFormDto) => {
      if (!branch) {
        return;
      }
      const updated = await runUpdateBranch(branch.id, dto);
      mutateBranch(updated);
      setEdit(false);
    },
    [branch, runUpdateBranch, mutateBranch, setEdit]
  );

  const handleDelete = React.useCallback(async () => {
    if (!branch) {
      return;
    }
    await runDeleteBranch(branch.id);
    setEdit(false);
    await refreshBranch();
  }, [branch, runDeleteBranch, setEdit, refreshBranch]);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, BranchSettingsPage.label, branch?.name || id]} />
      <PageHeader
        title={BranchSettingsPage.label}
        subTitle={branch?.name}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.BRANCH,
                  action: subjectAction.BRANCH.UPDATE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={fetchBranchLoading || deleteBranchLoading || !branch}
                  loading={updateBranchLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.BRANCH,
                  action: subjectAction.BRANCH.DELETE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={fetchBranchLoading || updateBranchLoading || !branch}
                    loading={deleteBranchLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateBranchLoading || deleteBranchLoading}
                loading={fetchBranchLoading}
                onClick={refreshBranch}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchBranchLoading} delay={500}>
          <HttpErrorResult error={fetchBranchError} />
          {edit ? (
            <Spin spinning={updateBranchLoading}>
              <BranchForm entityToUpdate={branch} onFinish={handleUpdate} title={""} />
            </Spin>
          ) : (
            branch && <BranchDescription data={branch} title={""} />
          )}
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

BranchSettingsPage.path = "/branch/:id";
BranchSettingsPage.pathCreator = (id) => `/branch/${id}`;
BranchSettingsPage.label = "Филиал";

export default BranchSettingsPage;
