import React from "react";
import { PageHeader, Table } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { ServiceOrder } from "services/service-order.service";
import { useAppDispatch, useAppSelector, useSetTitleEffect } from "store/hooks";
import useAsyncActionState from "hooks/use-async-action-state";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import RefreshButton from "components/buttons/refresh-button";
import ResetButton from "components/buttons/reset-button";
import ServiceOrdersPage from "pages/service-orders/service-orders.page";
import { DEFAULT_TABLE_SORTER } from "pages/service-orders/service-orders.constants";
import getColumns from "./helpers/get-columns";
import fetchBranches from "pages/service-orders/pages/all/helpers/fetch-branches";
import { useRefreshServiceOrders } from "../../hooks/use-refresh-service-orders";
import { useTableStateCleaningMarkers } from "hooks/use-table-state-cleaning-markers";
import fetchServiceOrders from "./helpers/fetch-service-orders";
import { useTableState } from "hooks/use-table-state";
import { setAllServiceOrdersTableState } from "store/actions/latest-table-states";

const AllServiceOrdersPage: PageFunctionComponent = () => {
  useSetTitleEffect([ServiceOrdersPage.label, AllServiceOrdersPage.label]);
  const allServiceOrdersTableState = useAppSelector(
    (state) => state.latestTableStates.allServiceOrdersTableState
  );
  const dispatch = useAppDispatch();
  const [state, , setStateAsync] = useTableState<ServiceOrder>(
    allServiceOrdersTableState ? allServiceOrdersTableState : { sorter: DEFAULT_TABLE_SORTER }
  );
  const refreshServiceOrders = useRefreshServiceOrders(
    state.pagination,
    setStateAsync,
    fetchServiceOrders
  );
  const [findAllBranches, { loading: branchesLoading, result: branches = [] }] =
    useAsyncActionState(fetchBranches);
  const handleTableChange = React.useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ServiceOrder> | SorterResult<ServiceOrder>[]
    ) => {
      let validSorter = sorter;
      if (!Array.isArray(sorter)) {
        validSorter = sorter.order ? sorter : DEFAULT_TABLE_SORTER;
      }
      refreshServiceOrders(pagination, filters, validSorter);
    },
    [refreshServiceOrders]
  );
  const columns = React.useMemo(() => getColumns(branches, state), [branches, state]);
  const { canClearSorter, canClearFilters } = useTableStateCleaningMarkers(
    state,
    DEFAULT_TABLE_SORTER
  );
  const clearSorter = React.useCallback(() => {
    refreshServiceOrders(state.pagination, state.filters, DEFAULT_TABLE_SORTER);
  }, [refreshServiceOrders, state]);

  const clearFilters = React.useCallback(() => {
    refreshServiceOrders(state.pagination, {}, state.sorter);
  }, [refreshServiceOrders, state]);

  const loading = state.loading || branchesLoading;

  useComponentDidMountEffect(() => {
    refreshServiceOrders(state.pagination, state.filters, state.sorter);
    findAllBranches();
  });

  React.useEffect(() => {
    dispatch(setAllServiceOrdersTableState(state));
  }, [dispatch, state]);

  return (
    <PageHeader
      title={AllServiceOrdersPage.label}
      extra={
        <React.Fragment>
          <ResetButton onClick={clearFilters} disabled={!canClearFilters || loading}>
            Сбросить фильтры
          </ResetButton>
          <ResetButton onClick={clearSorter} disabled={!canClearSorter || loading}>
            Сбросить сортировки
          </ResetButton>
          <RefreshButton
            onClick={() => refreshServiceOrders(state.pagination, state.filters, state.sorter)}
            loading={loading}
          />
        </React.Fragment>
      }
    >
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={{ spinning: loading, delay: 500 }}
        onChange={handleTableChange}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </PageHeader>
  );
};

AllServiceOrdersPage.path = "/all";
AllServiceOrdersPage.label = "Все заказ-наряды";

export default AllServiceOrdersPage;
