export interface ReviewAttachment {
  id: string;
  name: string;
  reviewKey: string;
}

const reviewAttachments: ReviewAttachment[] = [
  { id: "SERVICE_ORDER", name: "Заказ-наряд", reviewKey: "reviewServiceOrders" },
  { id: "WEB_LINK", name: "Отзыв на сайте", reviewKey: "reviewWebLinks" },
  { id: "CLIENT_EMAIL", name: "Email клиента", reviewKey: "reviewClientEmails" },
  { id: "CLIENT_CALL", name: "Звонок клиента", reviewKey: "reviewClientCalls" },
];

function getReviewAttachments() {
  return reviewAttachments;
}

export default getReviewAttachments;
