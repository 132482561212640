import React from "react";
import { Link } from "react-router-dom";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import ReviewTagTypeSettingsPage from "pages/settings/pages/review-tag-type/review-tag-type.page";
import { ArrowRightOutlined } from "@ant-design/icons";
import SettingsPage from "pages/settings/settings.page";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";

export interface ReviewTagTypeTableProps {
  tableProps: TableProps<ReviewTagType>;
  sorter: SorterResult<ReviewTagType> | SorterResult<ReviewTagType>[];
  size?: SizeType;
}

const getColumns: (
  sorter: SorterResult<ReviewTagType> | SorterResult<ReviewTagType>[]
) => ColumnsType<ReviewTagType> = (sorter) => {
  let nameSorter: SortOrder = null;
  let createdAtSortOrder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "name" && sorter.order) {
      nameSorter = sorter.order;
    } else if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    } else if (sorter.field === "updatedAt" && sorter.order) {
      updatedAtSortOrder = sorter.order;
    }
  }

  return [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: nameSorter,
      ellipsis: true,
      render: (_: any, record) => record.name,
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortOrder: updatedAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "",
      dataIndex: "reviewTagType",
      key: "reviewTagType",
      render: (_: any, record) => (
        <Link
          to={{
            pathname: `${SettingsPage.path}${ReviewTagTypeSettingsPage.pathCreator(record.id)}`,
            state: { canGoBack: true },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      align: "center",
      fixed: "right",
      ellipsis: true,
    },
  ];
};

const ReviewTagTypeTable: React.FC<ReviewTagTypeTableProps> = (props) => {
  const { tableProps, sorter, size } = props;

  const columns = React.useMemo(() => getColumns(sorter), [sorter]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default ReviewTagTypeTable;
