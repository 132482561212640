import { plainToClass } from "class-transformer";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "../interfaces/paginated-response.interface";
import { City } from "./models/city";
import { CityUpsertDto } from "./dtos/city-upsert-dto.type";

export class CityService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<City> | City[]>("/city", {
      params: queryParams,
    });
    if (Array.isArray(response)) {
      response = plainToClass(City, response);
    } else {
      response.data = plainToClass(City, response.data);
    }
    return response;
  }

  async findOne(id: City["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<City>(`/city/${id}`, {
      params: queryParams,
    });
    return plainToClass(City, response);
  }

  async createOne(dto: CityUpsertDto) {
    const user = await this.apiDriver.post<City>("/city", dto);
    return plainToClass(City, user);
  }

  async updateOne(id: string, dto: CityUpsertDto) {
    const updated = await this.apiDriver.patch<City>(`/city/${id}`, dto);
    return plainToClass(City, updated);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<City>(`/city/${id}`, {
      params: queryParams,
    });
    return plainToClass(City, deleted);
  }
}
