import ApiDriverBaseError from "./api-driver-base.error";

class ApiDriverRequestSetupError extends ApiDriverBaseError {
  constructor(...args: any[]) {
    super(...args);

    if (ApiDriverBaseError.captureStackTrace) {
      ApiDriverBaseError.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new ApiDriverBaseError().stack;
    }

    const [message] = args;
    this.message = message || "ApiDriverRequestSetupError";
  }
}

export default ApiDriverRequestSetupError;
