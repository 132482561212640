import React from "react";
import { useHistory } from "react-router-dom";
import { useAntdTable } from "ahooks";
import { Form, PageHeader, Row, Col, Button } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import useGoBack from "hooks/use-go-back";
import ReviewDestinationTypeTableSimpleForm, {
  ReviewDestinationTypeTableSimpleFormDto,
} from "components/tables/ReviewDestinationTypeTable/ReviewDestinationTypeTableSimpleForm";
import { getReviewDestinationTypeTableData } from "./get-review-destination-type-table-data";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import ReviewDestinationTypeTable from "components/tables/ReviewDestinationTypeTable/ReviewDestinationTypeTable";
import AddReviewDestinationTypeSettingsPage from "../add-review-destination-type/add-review-destination-type.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

const ReviewDestinationTypesSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, ReviewDestinationTypesSettingsPage.label]);

  const goBack = useGoBack();
  const history = useHistory();
  const [form] = Form.useForm<ReviewDestinationTypeTableSimpleFormDto>();

  const { tableProps, loading, refresh, search, params } = useAntdTable(
    getReviewDestinationTypeTableData,
    {
      form,
      defaultType: "simple",
      cacheKey: "ReviewDestinationTypesPageTable",
      cacheTime: 10 * 1000,
      loadingDelay: 500,
      defaultParams: [
        {
          current: 1,
          pageSize: 10,
          sorter: { field: "name", order: "ascend" },
        },
      ],
    }
  );

  const { type, changeType, submit, reset } = search || {};
  const { sorter = {} } = params[0] || ({} as any);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, ReviewDestinationTypesSettingsPage.label]} />
      <PageHeader
        title={ReviewDestinationTypesSettingsPage.label}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.REVIEW_DESTINATION_TYPE,
                  action: subjectAction.REVIEW_DESTINATION_TYPE.CREATE,
                },
              ]}
              placeholder={null}
              loading={loading}
            >
              <Col>
                <AddButton
                  onClick={() =>
                    history.push({
                      pathname: `${SettingsPage.path}${AddReviewDestinationTypeSettingsPage.path}`,
                      state: { canGoBack: true },
                    })
                  }
                >
                  Добавить
                </AddButton>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton loading={loading} onClick={refresh} />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {type === "advance" ? null : (
            <ReviewDestinationTypeTableSimpleForm form={form} submit={submit} reset={reset} />
          )}
          <Button type="link" onClick={changeType} hidden>
            {type === "simple" ? "Расширенный поиск" : "Простой поиск"}
          </Button>
        </div>

        <ReviewDestinationTypeTable tableProps={tableProps} sorter={sorter} />
      </PageHeader>
    </ContentLayout>
  );
};

ReviewDestinationTypesSettingsPage.path = "/review-destination-types";
ReviewDestinationTypesSettingsPage.label = "Назначения отзывов";

export default ReviewDestinationTypesSettingsPage;
