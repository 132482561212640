import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { UnorderedListOutlined } from "@ant-design/icons";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { SideNavigationItem } from "libs/interfaces/side-navigation-item.interface";
import usePath from "hooks/use-path";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import SideNavigation from "components/common/side-navigation";
import NotFoundErrorPage from "pages/not-found-error/not-found-error.page";
import AddReminderPage from "./pages/add/add.page";
import AllRemindersPage from "./pages/all/all.page";
import ReminderCardPage from "./pages/card/card.page";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";
import CheckAbilities from "store/components/CheckAbilities";

const RemindersPage: PageFunctionComponent = () => {
  const path = usePath(2);

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);
  const pageAccessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        AddReminderPage: false,
        AllRemindersPage: false,
        ReminderCardPage: false,
      };
    }

    return {
      AddReminderPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REMINDER,
        action: subjectAction.REMINDER.CREATE,
      }),
      AllRemindersPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          { subject: Subject.REMINDER, action: subjectAction.REMINDER.READ_ALL },
          { subject: Subject.REMINDER, action: subjectAction.REMINDER.READ_OWN },
        ],
      }),
      ReminderCardPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          { subject: Subject.REMINDER, action: subjectAction.REMINDER.READ_ALL },
          { subject: Subject.REMINDER, action: subjectAction.REMINDER.READ_OWN },
        ],
      }),
    };
  }, [pageAccessControlState]);

  const sideNavigationItems = React.useMemo(() => {
    const items: SideNavigationItem[] = [];

    if (pageAccessControl.AllRemindersPage) {
      items.push({
        key: `${RemindersPage.path}${AllRemindersPage.path}`,
        pathname: `${RemindersPage.path}${AllRemindersPage.path}`,
        icon: <UnorderedListOutlined />,
        label: AllRemindersPage.label,
      });
    }

    return items;
  }, [pageAccessControl]);

  return (
    <React.Fragment>
      <SideNavigation selectedKey={path} items={sideNavigationItems} />
      <Switch>
        <Route exact path={RemindersPage.path}>
          <Redirect to={`${RemindersPage.path}${AllRemindersPage.path}`} />
        </Route>
        <Route path={`${RemindersPage.path}${ReminderCardPage.path}`}>
          <CheckAbilities manual={pageAccessControl.ReminderCardPage}>
            <ReminderCardPage />
          </CheckAbilities>
        </Route>
        <Route path={`${RemindersPage.path}${AddReminderPage.path}`}>
          <CheckAbilities manual={pageAccessControl.AddReminderPage}>
            <AddReminderPage />
          </CheckAbilities>
        </Route>
        <Route path={`${RemindersPage.path}${AllRemindersPage.path}`}>
          <CheckAbilities manual={pageAccessControl.AllRemindersPage}>
            <AllRemindersPage />
          </CheckAbilities>
        </Route>
        <Route path={`${RemindersPage.path}${NotFoundErrorPage.path}`}>
          <NotFoundErrorPage />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

RemindersPage.path = "/reminders";
RemindersPage.label = "Напоминания";

export default RemindersPage;
