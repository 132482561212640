import React from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "ahooks";
import { message, PageHeader, Spin } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import CityForm from "components/forms/CityForm";
import { cityService } from "services";
import { CityService } from "services/city/city.service";
import SettingsPage from "pages/settings/settings.page";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import CitySettingsPage from "../city/city.page";

const createCity: CityService["createOne"] = (dto) => cityService.createOne(dto);

const AddCitySettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, AddCitySettingsPage.label]);

  const goBack = useGoBack();

  const history = useHistory();

  const { run, loading } = useRequest(createCity, {
    manual: true,
    onSuccess: (data) => {
      if (data) {
        history.push(`${SettingsPage.path}${CitySettingsPage.pathCreator(data.id)}`, {
          canGoBack: true,
        });
      }
    },

    onError: (e, params) => {
      const [dto] = params;
      const { name } = dto;
      if (e instanceof ApiDriverHttpError) {
        if (e.status === 409) {
          message.error(`Город с названием "${name}" уже существует!`);
        }
      }
    },
  });

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, AddCitySettingsPage.label]} />
      <PageHeader title={AddCitySettingsPage.label} onBack={goBack}>
        <Spin spinning={loading}>
          <CityForm title="" onFinish={run} />
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

AddCitySettingsPage.path = "/add-city";
AddCitySettingsPage.label = "Добавить город";

export default AddCitySettingsPage;
