import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/lib/button";

interface AddButtonProps extends ButtonProps {
  children?: string;
}

const AddButton: React.FunctionComponent<AddButtonProps> = (props) => {
  const { children, loading, ...restProps } = props;
  return (
    <Button {...restProps} loading={loading}>
      <PlusOutlined />
      {children ? <span>{children}</span> : null}
    </Button>
  );
};

export default AddButton;
