import React from "react";
import { Link } from "react-router-dom";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import { UserRole } from "services/user-role/models/user-role";
import { Branch } from "services/branch/models/branch";
import { Role } from "services/role/models/role";

export interface UserRoleTableProps {
  tableProps: TableProps<UserRole>;
  sorter: SorterResult<UserRole> | SorterResult<UserRole>[];
  size?: SizeType;
  getRoleLink?: (roleId: Role["id"]) => string;
  getBranchLink?: (branchId: Branch["id"]) => string;
  rightExtra?: ColumnsType<UserRole>;
}

export interface UserRoleTableGetColumnsParams {
  sorter: UserRoleTableProps["sorter"];
  getRoleLink?: UserRoleTableProps["getRoleLink"];
  getBranchLink?: UserRoleTableProps["getBranchLink"];
  rightExtra?: UserRoleTableProps["rightExtra"];
}

const getColumns: (params: UserRoleTableGetColumnsParams) => ColumnsType<UserRole> = (params) => {
  const { sorter, getRoleLink, getBranchLink, rightExtra } = params;

  let createdAtSortOrder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    } else if (sorter.field === "updatedAt" && sorter.order) {
      updatedAtSortOrder = sorter.order;
    }
  }

  const columns: ColumnsType<UserRole> = [
    {
      title: "Пользователь",
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      render: (_: any, record) => (record.user ? record.user.fullName : record.userId),
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      ellipsis: true,
      render: (_: any, record) => {
        let displayName = "ПУСТО";
        if (!record.role) {
          displayName = record.roleId;
        } else {
          displayName = record.role.name;
        }

        if (getRoleLink === undefined) {
          return displayName;
        }

        return (
          <Link
            to={{
              pathname: getRoleLink(record.roleId),
              state: { canGoBack: true },
            }}
          >
            {displayName}
          </Link>
        );
      },
    },
    {
      title: "Филиал",
      dataIndex: "branch",
      key: "branch",
      ellipsis: true,
      render: (_: any, record) => {
        let displayName = "ПУСТО";
        if (record.branchId === undefined || record.branchId === null) {
          displayName = "Все филиалы";
        } else if (!record.branch) {
          displayName = record.branchId;
        } else {
          displayName = record.branch.name;
        }

        if (
          getBranchLink === undefined ||
          record.branchId === undefined ||
          record.branchId === null
        ) {
          return displayName;
        }

        return (
          <Link
            to={{
              pathname: getBranchLink(record.branchId),
              state: { canGoBack: true },
            }}
          >
            {displayName}
          </Link>
        );
      },
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortOrder: updatedAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
  ];

  if (rightExtra) {
    columns.push(...rightExtra);
  }

  return columns;
};

const UserRoleTable: React.FC<UserRoleTableProps> = (props) => {
  const { tableProps, sorter, size, getBranchLink, getRoleLink, rightExtra } = props;

  const columns = React.useMemo(
    () => getColumns({ sorter, getBranchLink, getRoleLink, rightExtra }),
    [sorter, getBranchLink, getRoleLink, rightExtra]
  );

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default UserRoleTable;
