import { TokenPair } from "libs/common/api-driver/api-driver";

export interface TokenPairState {
  accessToken: TokenPair["accessToken"] | null;
  refreshToken: TokenPair["refreshToken"] | null;
}

export enum TokenPairActionType {
  SET_TOKEN_PAIR = "SET_TOKEN_PAIR",
  CLEAR_TOKEN_PAIR = "CLEAR_TOKEN_PAIR",
}

export interface TokenPairAction {
  type: TokenPairActionType;
  payload?: TokenPairState;
}

const initialState: TokenPairState = {
  accessToken: null,
  refreshToken: null,
};

export default function tokenPairReducer(
  state = initialState,
  action: TokenPairAction
): TokenPairState {
  switch (action.type) {
    case TokenPairActionType.SET_TOKEN_PAIR:
      return {
        accessToken: action?.payload?.accessToken || null,
        refreshToken: action?.payload?.refreshToken || null,
      };
    case TokenPairActionType.CLEAR_TOKEN_PAIR:
      return initialState;
    default:
      return state;
  }
}

// @TODO: remove in next version
localStorage.removeItem("token-pair");
