import React from "react";
import ReactQuill from "react-quill";
import { noOp } from "libs/helpers/no-op";

export interface AntdFormReactQuillInputProps {
  value?: any;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  placeholder?: string;
  readOnly?: boolean;
}

const AntdFormReactQuillInput: React.FC<AntdFormReactQuillInputProps> = (props) => {
  const { value = "", onChange = noOp, onBlur, placeholder, readOnly } = props;
  const ref = React.useRef<ReactQuill>(null);

  const handleChange = React.useCallback(
    (content: string) => {
      if (content === "<p><br></p>") {
        onChange("");
      } else {
        onChange(content);
      }
    },
    [onChange]
  );

  return (
    <ReactQuill
      ref={ref}
      theme="snow"
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      placeholder={placeholder}
      readOnly={readOnly}
    />
  );
};

export default AntdFormReactQuillInput;
