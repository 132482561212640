import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PageHeader, Form, Input, Space, Button, Spin, message } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import ClientsPage from "pages/clients/clients.page";
import { useSetTitleEffect } from "store/hooks";
import { Store } from "antd/lib/form/interface";
import { BlacklistClient } from "services/blacklist-client.service";
import useAsyncActionState from "hooks/use-async-action-state";
import BlacklistPage from "../blacklist/blacklist.page";
import useGoBack from "hooks/use-go-back";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { blacklistClientService } from "services";

const createBlacklistClient = (dto: BlacklistClient) => blacklistClientService.createOne(dto);

interface AddToBlacklistPageLocationState {
  clientGuid1c?: string;
  clientPhone?: string;
}

const AddToBlacklistPage: PageFunctionComponent = () => {
  useSetTitleEffect([ClientsPage.label, AddToBlacklistPage.label]);
  const history = useHistory();
  const [form] = Form.useForm();
  const goBack = useGoBack();
  const { state: { clientGuid1c, clientPhone } = {} } =
    useLocation<AddToBlacklistPageLocationState>();

  const [createBlacklistClientHelper, { loading, error }] =
    useAsyncActionState(createBlacklistClient);

  const handleFinish = React.useCallback(
    (values: Store) => {
      const blacklistClient = new BlacklistClient();
      blacklistClient.clientGuid1c = values.clientGuid1c;
      blacklistClient.clientPhone = values.clientPhone;
      blacklistClient.reason = values.reason;

      createBlacklistClientHelper(blacklistClient).then(({ error }) => {
        if (!error) {
          if (goBack) {
            goBack();
          } else {
            history.push({
              pathname: `${ClientsPage.path}${BlacklistPage.path}`,
            });
          }
        }
      });
    },
    [createBlacklistClientHelper, history, goBack]
  );

  React.useEffect(() => {
    const err = error as ApiDriverHttpError;
    if (err) {
      let content = err.message;
      if (err.status === 409) {
        content = "Клиент с таким номером телефона уже в чёрном списке!";
      }
      message.error(content);
    }
  }, [error]);

  return (
    <PageHeader title={AddToBlacklistPage.label} onBack={goBack}>
      <Spin spinning={loading}>
        <Form
          form={form}
          initialValues={{ clientGuid1c, clientPhone }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          onFinish={handleFinish}
          size="large"
        >
          <Form.Item
            label="ГУИД клиента в 1С"
            name="clientGuid1c"
            rules={[
              {
                pattern:
                  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/,
                message: "Это поле должно содержать валидный ГУИД",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Телефон клиента"
            name="clientPhone"
            rules={[
              {
                required: true,
                message: "Это поле должно быть заполнено",
              },
              {
                type: "integer",
                transform: (value) => Number(value),
                message: "Это поле должно содержать только цифры",
              },
              {
                type: "string",
                transform: (value) => String(value),
                max: 11,
                min: 3,
                message: "Номер телефона - 3-11 цифр",
              },
            ]}
            validateTrigger={"onBlur"}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="reason"
            label={"Причина добавления"}
            rules={[
              {
                type: "string",
                transform: (value) => String(value).trim(),
                max: 1024,
                message: "Максимальная длина - 1024 символов",
              },
            ]}
          >
            <Input placeholder={"Сотрудник, отказ от обзвона и т.п."} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  );
};

AddToBlacklistPage.path = "/add-to-blacklist";
AddToBlacklistPage.label = "Добавить в черный список";

export default AddToBlacklistPage;
