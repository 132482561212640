import React from "react";
import { Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/lib/button";

const RefreshButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { loading, ...restProps } = props;
  return (
    <Button {...restProps} type="primary" loading={loading}>
      <ReloadOutlined />
      <span>Обновить</span>
    </Button>
  );
};

export default RefreshButton;
