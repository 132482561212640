import React from "react";
import { Modal, Table, Form, Input } from "antd";
import { noOp } from "libs/helpers/no-op";
import { ColumnsType } from "antd/lib/table";
import { uniqBy } from "lodash";

export interface BlacklistItemToAdd {
  clientGuid1c?: string;
  clientPhone: string;
  reason?: string;
}

export interface AddToBlacklistModalProps {
  visible?: boolean;
  confirmLoading?: boolean;
  serviceOrderClients: BlacklistItemToAdd[];
  onCancel?: () => any;
  onOk?: (blacklistItemToAdd: BlacklistItemToAdd[]) => any;
}

const columns: ColumnsType<BlacklistItemToAdd> = [
  {
    title: "Гуид клиента в 1С",
    dataIndex: "clientGuid1c",
    key: "clientGuid1c",
  },
  {
    title: "Телефон клиента",
    dataIndex: "clientPhone",
    key: "clientPhone",
  },
];

const AddToBlacklistModal: React.FC<AddToBlacklistModalProps> = (props) => {
  const {
    visible = false,
    confirmLoading = false,
    serviceOrderClients,
    onCancel = noOp,
    onOk = noOp,
  } = props;

  const serviceOrderClientsCopy = React.useMemo(() => {
    const items: BlacklistItemToAdd[] = [];
    uniqBy(serviceOrderClients, "clientPhone").forEach((serviceOrderClient) => {
      items.push({
        clientGuid1c: serviceOrderClient.clientGuid1c,
        clientPhone: serviceOrderClient.clientPhone,
      });
    });
    return items;
  }, [serviceOrderClients]);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState(
    serviceOrderClientsCopy.map((item) => item.clientPhone)
  );

  const [form] = Form.useForm();

  const handleOk = React.useCallback(() => {
    const reason = form.getFieldValue("reason");
    const blacklistItemsToAdd = serviceOrderClientsCopy.filter((item) =>
      selectedRowKeys.includes(item.clientPhone)
    );
    if (reason) {
      blacklistItemsToAdd.forEach((item) => {
        item.reason = reason;
      });
    }
    onOk(blacklistItemsToAdd);
  }, [onOk, serviceOrderClientsCopy, selectedRowKeys, form]);

  const handleCancel = React.useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleCheckboxChange = React.useCallback(
    (selectedRowKeysParam, selectedRowsParam) => {
      setSelectedRowKeys(selectedRowKeysParam);
    },
    [setSelectedRowKeys]
  );

  return (
    <Modal
      title="Добавить клиента в чёрный список"
      visible={visible}
      onOk={handleOk}
      okButtonProps={{ disabled: selectedRowKeys.length === 0 }}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      width={780}
    >
      <Table
        columns={columns}
        rowKey={(record) => record.clientPhone}
        rowSelection={{ type: "checkbox", onChange: handleCheckboxChange, selectedRowKeys }}
        dataSource={serviceOrderClientsCopy}
        pagination={false}
        tableLayout="auto"
      />
      <Form style={{ marginTop: 24 }} form={form} size="large">
        <Form.Item name="reason" label="Причина добавления">
          <Input
            disabled={selectedRowKeys.length === 0}
            placeholder={"Сотрудник, отказ от обзвона и т.п."}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddToBlacklistModal;
