import React from "react";
import { Descriptions } from "antd";
import { displayDateTime } from "libs/helpers/display-date-time";
import { ReviewServiceOrderPartialById } from "services/models/review-service-order.model";

export interface ReviewServiceOrderDescriptionsProps {
  reviewServiceOrder: ReviewServiceOrderPartialById;
}

function ReviewServiceOrderDescriptions(props: ReviewServiceOrderDescriptionsProps) {
  const { reviewServiceOrder } = props;

  return (
    <Descriptions
      column={{ xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      bordered
      style={{ wordBreak: "normal" }}
    >
      <Descriptions.Item label="Номер">{reviewServiceOrder.serviceOrderNumber}</Descriptions.Item>
      <Descriptions.Item label="Дата открытия">
        {reviewServiceOrder.serviceOrderOpenedAt &&
          displayDateTime(reviewServiceOrder.serviceOrderOpenedAt)}
      </Descriptions.Item>
      {reviewServiceOrder.serviceOrderGuid1c && (
        <Descriptions.Item label="ГУИД в 1С ">
          {reviewServiceOrder.serviceOrderGuid1c}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}

export default ReviewServiceOrderDescriptions;
