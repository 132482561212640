/* example: forms = ["заявка", "заявки", "заявок"] (index - plural form) */
export function getPluralForm(count: number, forms: string[]) {
  const n = count;
  const plural =
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2;
  return forms[plural];
}
