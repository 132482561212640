import { plainToClass } from "class-transformer";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "../interfaces/paginated-response.interface";
import { IntegrationKey } from "./models/integration-key";
import { IntegrationKeyUpsertDto } from "./dtos/integration-key-upsert-dto.type";

export class IntegrationKeyService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async getMyIntegrationKeys() {
    const integrationKeys = await this.apiDriver.get<IntegrationKey[]>(
      "/integration-key/my-integration-keys"
    );

    return plainToClass(IntegrationKey, integrationKeys);
  }

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<IntegrationKey> | IntegrationKey[]>(
      "/integration-key",
      {
        params: queryParams,
      }
    );
    if (Array.isArray(response)) {
      response = plainToClass(IntegrationKey, response);
    } else {
      response.data = plainToClass(IntegrationKey, response.data);
    }
    return response;
  }

  async findOne(id: IntegrationKey["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<IntegrationKey>(`/integration-key/${id}`, {
      params: queryParams,
    });
    return plainToClass(IntegrationKey, response);
  }

  async createOne(dto: IntegrationKeyUpsertDto) {
    const reviewSite = await this.apiDriver.post<IntegrationKey>("/integration-key", dto);
    return plainToClass(IntegrationKey, reviewSite);
  }

  async updateOne(id: string, dto: IntegrationKeyUpsertDto) {
    const updated = await this.apiDriver.patch<IntegrationKey>(`/integration-key/${id}`, dto);
    return plainToClass(IntegrationKey, updated);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<IntegrationKey>(`/integration-key/${id}`, {
      params: queryParams,
    });
    return plainToClass(IntegrationKey, deleted);
  }
}
