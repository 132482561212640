import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ExclamationOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { SideNavigationItem } from "libs/interfaces/side-navigation-item.interface";
import usePath from "hooks/use-path";
import SideNavigation from "components/common/side-navigation";
import NotFoundErrorPage from "pages/not-found-error/not-found-error.page";
import MyUnreadNotificationsPage from "./pages/my-unread/my-unread.page";
import AllNotificationsPage from "./pages/all/all.page";

const NotificationsPage: PageFunctionComponent = () => {
  const path = usePath(2);

  const sideNavigationItems: SideNavigationItem[] = React.useMemo(
    () => [
      {
        key: `${NotificationsPage.path}${MyUnreadNotificationsPage.path}`,
        pathname: `${NotificationsPage.path}${MyUnreadNotificationsPage.path}`,
        icon: <ExclamationOutlined />,
        label: MyUnreadNotificationsPage.label,
      },
      {
        key: `${NotificationsPage.path}${AllNotificationsPage.path}`,
        pathname: `${NotificationsPage.path}${AllNotificationsPage.path}`,
        icon: <UnorderedListOutlined />,
        label: AllNotificationsPage.label,
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <SideNavigation selectedKey={path} items={sideNavigationItems} />
      <Switch>
        <Route exact path={NotificationsPage.path}>
          <Redirect to={`${NotificationsPage.path}${MyUnreadNotificationsPage.path}`} />
        </Route>
        <Route path={`${NotificationsPage.path}${MyUnreadNotificationsPage.path}`}>
          <MyUnreadNotificationsPage />
        </Route>
        <Route path={`${NotificationsPage.path}${AllNotificationsPage.path}`}>
          <AllNotificationsPage />
        </Route>
        <Route path={`${NotificationsPage.path}${NotFoundErrorPage.path}`}>
          <NotFoundErrorPage />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

NotificationsPage.path = "/notifications";
NotificationsPage.label = "Уведомления";

export default NotificationsPage;
