import React from "react";
import { Select } from "antd";
import { useMount, useRequest } from "ahooks";
import { roleAbilityService } from "services";
import { Subject } from "services/role-ability/models/subject";

const fetchSubjects = () => roleAbilityService.getSubjects();

export interface SubjectSelectProps {
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  disabled?: boolean;
}

const SubjectSelect: React.FC<SubjectSelectProps> = React.forwardRef<
  any, // :(
  SubjectSelectProps
>((props, ref) => {
  const { run, data, loading } = useRequest(fetchSubjects, {
    manual: true,
  });

  useMount(() => {
    run();
  });

  if (!data) {
    return (
      <Select
        ref={ref}
        {...props}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children"
      />
    );
  }

  const mapData = (item: Subject) => (
    <Select.Option key={`SubjectSelect_${item.id}`} value={item.id}>
      {item.name}
    </Select.Option>
  );

  return (
    <Select
      ref={ref}
      {...props}
      loading={loading}
      allowClear
      showSearch
      optionFilterProp="children"
    >
      {data.map(mapData)}
    </Select>
  );
});

export default SubjectSelect;
