import { Type } from "class-transformer";

export class ReviewTagType {
  id: string;
  name: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;
}
