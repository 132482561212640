import React from "react";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import ServiceOrdersWithoutReviewPage from "pages/service-orders/pages/without-review/without-review.page";

const ServiceOrderQueueReviewPage: PageFunctionComponent = () => {
  return <ServiceOrdersWithoutReviewPage title={ServiceOrderQueueReviewPage.label} />;
};

ServiceOrderQueueReviewPage.label = "Очередь заказ-нарядов";
ServiceOrderQueueReviewPage.path = "/queue";

export default ServiceOrderQueueReviewPage;
