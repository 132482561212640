import React from "react";
import { Link } from "react-router-dom";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { User } from "services/user/models/user";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import UserSettingsPage from "pages/settings/pages/user/user.page";
import { ArrowRightOutlined } from "@ant-design/icons";
import SettingsPage from "pages/settings/settings.page";

export interface UserTableProps {
  tableProps: TableProps<User>;
  sorter: SorterResult<User> | SorterResult<User>[];
  size?: SizeType;
}

const getColumns: (sorter: SorterResult<User> | SorterResult<User>[]) => ColumnsType<User> = (
  sorter
) => {
  let fullNameSorter: SortOrder = null;
  let emailStorter: SortOrder = null;
  let createdAtSortOrder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;
  let isActiveSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "fullName" && sorter.order) {
      fullNameSorter = sorter.order;
    } else if (sorter.field === "email" && sorter.order) {
      emailStorter = sorter.order;
    } else if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    } else if (sorter.field === "updatedAt" && sorter.order) {
      updatedAtSortOrder = sorter.order;
    } else if (sorter.field === "isActive" && sorter.order) {
      isActiveSortOrder = sorter.order;
    }
  }

  return [
    {
      title: "Полное имя",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      sortOrder: fullNameSorter,
      ellipsis: true,
      render: (_: any, record) => record.fullName,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      sortOrder: emailStorter,
      ellipsis: true,
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
      render: (_: any, record) => record.phoneWithDescription,
    },
    {
      title: "Профиль активен",
      dataIndex: "isActive",
      key: "isActive",
      sorter: true,
      sortOrder: isActiveSortOrder,
      ellipsis: true,
      render: (_: any, record) => (record.isActive ? "Да" : "Нет"),
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortOrder: updatedAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "",
      dataIndex: "user",
      key: "user",
      render: (_: any, record) => (
        <Link
          to={{
            pathname: `${SettingsPage.path}${UserSettingsPage.pathCreator(record.id)}`,
            state: { canGoBack: true },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      align: "center",
      fixed: "right",
      ellipsis: true,
    },
  ];
};

const UserTable: React.FC<UserTableProps> = (props) => {
  const { tableProps, sorter, size } = props;

  const columns = React.useMemo(() => getColumns(sorter), [sorter]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default UserTable;
