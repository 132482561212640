import React from "react";
import { Button } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/lib/button";

interface ResetButtonProps extends ButtonProps {
  children?: string;
}

const ResetButton: React.FunctionComponent<ResetButtonProps> = (props) => {
  const { children, loading, ...restProps } = props;
  return (
    <Button {...restProps} loading={loading}>
      <ClearOutlined />
      {children ? <span>{children}</span> : null}
    </Button>
  );
};

export default ResetButton;
