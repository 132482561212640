import { Type } from "class-transformer";
import { Branch } from "services/branch/models/branch";
import { Role } from "services/role/models/role";
import { User } from "services/user/models/user";

export class UserRole {
  id!: string;
  userId!: string;
  roleId!: string;
  branchId?: string | null;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => User)
  user?: User;

  @Type(() => Role)
  role?: Role;

  @Type(() => Branch)
  branch?: Branch;
}
