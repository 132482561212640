import { Type } from "class-transformer";
import { Review } from "./review.model";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import { PartialBy } from "libs/types/partial-by.type";

export class ReviewDestination {
  id: string;
  reviewId: string;
  reviewDestinationTypeId: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Review)
  review?: Review;

  @Type(() => ReviewDestinationType)
  reviewDestinationType?: ReviewDestinationType;
}

export type ReviewDestinationPartialById = PartialBy<ReviewDestination, "id">;
