import ApiDriver from "libs/common/api-driver/api-driver";

export class BackendCacheService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async clear() {
    const response = await this.apiDriver.post<{ success: boolean }>("/backend-cache/clear");
    return response;
  }
}
