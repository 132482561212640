import React from "react";
import { Button } from "antd";

const RefreshPageMessage: React.FC = () => {
  const reloadPage = React.useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <React.Fragment>
      Приложение было обновлено. Требуется перезагрузка страницы!{" "}
      <Button type="primary" onClick={reloadPage}>
        Перезагрузить страницу
      </Button>
    </React.Fragment>
  );
};

export default RefreshPageMessage;
