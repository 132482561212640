import React from "react";
import { Select } from "antd";
import { useMount, useRequest } from "ahooks";
import { branchService } from "services";
import { Branch } from "services/branch/models/branch";

const fetchBranchs = () =>
  branchService.findAll({
    fields: ["id", "name"],
    sort: [
      { field: "city.name", order: "ASC" },
      { field: "name", order: "ASC" },
      { field: "id", order: "ASC" },
    ],
    join: ["city", ["id", "name"]],
    limit: 1000,
  });

export interface BranchSelectProps {
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  disabled?: boolean;
  allBranchesOption?: boolean;
}

export const BRANCH_SELECT_ALL_BRANCHES_OPTION_VALUE = "BRANCH_SELECT_ALL_BRANCHES_OPTION_VALUE";

const allBranches = new Branch();
allBranches.id = BRANCH_SELECT_ALL_BRANCHES_OPTION_VALUE;
allBranches.name = "Все филиалы";

const BranchSelect: React.FC<BranchSelectProps> = React.forwardRef<
  any, // :(
  BranchSelectProps
>((props, ref) => {
  const { allBranchesOption = false, ...restProps } = props;

  const {
    run,
    data: branches,
    loading,
  } = useRequest(fetchBranchs, {
    manual: true,
  });

  useMount(() => {
    run();
  });

  const data = React.useMemo(() => {
    if (!branches) {
      return [];
    }
    if (!allBranchesOption) {
      return branches;
    }
    if (Array.isArray(branches)) {
      return [allBranches, ...branches];
    }
    branches.data = [allBranches, ...branches.data];
    return branches;
  }, [branches, allBranchesOption]);

  // if (!data) {
  //   return (
  //     <Select
  //       ref={ref}
  //       {...restProps}
  //       loading={loading}
  //       allowClear
  //       showSearch
  //       optionFilterProp="children"
  //     />
  //   );
  // }

  const mapData = (item: Branch) => (
    <Select.Option key={`BranchSelect_${item.id}`} value={item.id}>
      {item.city ? `${item.name} (${item.city.name})` : item.name}
    </Select.Option>
  );

  return (
    <Select
      ref={ref}
      {...restProps}
      loading={loading}
      allowClear
      showSearch
      optionFilterProp="children"
    >
      {Array.isArray(data) ? data.map(mapData) : data.data.map(mapData)}
    </Select>
  );
});

export default BranchSelect;
