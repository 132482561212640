import { Subject } from "abilities/subject.enum";
import { MyAbility } from "services/user/models/my-ability";

export interface CheckAbilitiesAbilityToCheckParamItem {
  subject: Subject;
  action: string;
  branchId?: string;
  reviewDestinationTypeIds?: string[];
}

export interface CheckAbilitiesAbilityToCheckParamOR {
  $and?: never;
  $or?: Array<CheckAbilitiesAbilityToCheckParamItem | CheckAbilitiesAbilityToCheckParamOperators>;
}

export interface CheckAbilitiesAbilityToCheckParamAND {
  $and?: Array<CheckAbilitiesAbilityToCheckParamItem | CheckAbilitiesAbilityToCheckParamOperators>;
  $or?: never;
}

export type CheckAbilitiesAbilityToCheckParamOperators =
  | CheckAbilitiesAbilityToCheckParamOR
  | CheckAbilitiesAbilityToCheckParamAND;

export type CheckAbilitiesAbilityToCheckParam =
  | CheckAbilitiesAbilityToCheckParamItem
  | CheckAbilitiesAbilityToCheckParamItem[]
  | CheckAbilitiesAbilityToCheckParamOperators;

export default function checkAbilities(
  myAbilities: MyAbility[],
  abilityToCheck: CheckAbilitiesAbilityToCheckParam
): boolean {
  if (Array.isArray(abilityToCheck)) {
    return abilityToCheck.every((item) => checkAbilities(myAbilities, item));
  } else if ("subject" in abilityToCheck) {
    const ability = myAbilities.find((a) => {
      let predicate = a.subject === abilityToCheck.subject && a.action === abilityToCheck.action;
      if (abilityToCheck.reviewDestinationTypeIds) {
        if (abilityToCheck.reviewDestinationTypeIds.length > 0) {
          predicate =
            predicate &&
            abilityToCheck.reviewDestinationTypeIds.some((reviewDestinationTypeId) =>
              a.reviewDestinationTypeIds.includes(reviewDestinationTypeId)
            );
        }
      }
      if (abilityToCheck.branchId) {
        predicate = predicate && a.branchIds.includes(abilityToCheck.branchId);
      }
      return predicate;
    });
    if (!ability) {
      return false;
    }
    return true;
  } else if (abilityToCheck.$and) {
    return abilityToCheck.$and.every((item) => checkAbilities(myAbilities, item));
  } else if (abilityToCheck.$or) {
    return abilityToCheck.$or.some((item) => checkAbilities(myAbilities, item));
  }

  return false;
}
