import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { UnorderedListOutlined } from "@ant-design/icons";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import usePath from "hooks/use-path";
import SideNavigation from "components/common/side-navigation";
import Breadcrumbs from "components/common/breadcrumbs";
import ContentLayout from "components/layouts/content.layout";
import NotFoundErrorPage from "pages/not-found-error/not-found-error.page";
import AllBranchesPage from "./pages/all/all.page";

const BranchesPage: PageFunctionComponent = () => {
  const path = usePath(2);

  return (
    <React.Fragment>
      <SideNavigation
        selectedKey={path}
        items={[
          {
            key: `${BranchesPage.path}${AllBranchesPage.path}`,
            pathname: `${BranchesPage.path}${AllBranchesPage.path}`,
            icon: <UnorderedListOutlined />,
            label: AllBranchesPage.label,
          },
        ]}
      />
      <Switch>
        <Route exact path={BranchesPage.path}>
          <Redirect to={`${BranchesPage.path}${AllBranchesPage.path}`} />
        </Route>
        <Route path={`${BranchesPage.path}${AllBranchesPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[BranchesPage.label, AllBranchesPage.label]} />
            <AllBranchesPage />
          </ContentLayout>
        </Route>
        <Route path={`${BranchesPage.path}${NotFoundErrorPage.path}`}>
          <NotFoundErrorPage />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

BranchesPage.path = "/branches";
BranchesPage.label = "Филиалы";

export default BranchesPage;
