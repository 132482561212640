import React from "react";
import { message } from "antd";
import { BACKEND_BASE_URL } from "configs/configs";
import ApiDriver from "libs/common/api-driver/api-driver";
import RefreshPageMessage from "components/common/refresh-page-message";

export const apiDriver = new ApiDriver({
  baseUrl: BACKEND_BASE_URL,
  onApiVersionChange: (version) => message.info(<RefreshPageMessage />, 10 * 1000),
});
