import React from "react";
import { PageHeader, Table, Checkbox } from "antd";
import { BranchWithHeads } from "services/branch/branch.service";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import BranchesPage from "pages/branches/branches.page";
import useAsyncActionState from "hooks/use-async-action-state";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import RefreshButton from "components/buttons/refresh-button";
import { ColumnsType } from "antd/lib/table";
import { branchService } from "services";

const branchFetcher = async () => branchService.findAllWithHeads();

const compareCities = (a: BranchWithHeads, b: BranchWithHeads) => {
  if (!a.city) {
    return 1;
  }

  if (!b.city) {
    return -1;
  }

  if (a.city && b.city) {
    return a.city.name.localeCompare(b.city.name);
  }

  return 0;
};

const compareBranches = (a: BranchWithHeads, b: BranchWithHeads) => b.name.localeCompare(a.name);

const columns: ColumnsType<BranchWithHeads> = [
  {
    title: "Город",
    dataIndex: "city",
    key: "city",
    render: (city: any, record: BranchWithHeads) => (record.city ? record.city.name : "Не указан"),
    sorter: {
      multiple: 2,
      compare: compareCities,
    },
    defaultSortOrder: "descend",
    ellipsis: true,
  },
  {
    title: "Филиал",
    dataIndex: "branch",
    key: "branch",
    render: (branch: any, record: BranchWithHeads) => record.name,
    sorter: {
      multiple: 1,
      compare: compareBranches,
    },
    defaultSortOrder: "descend",
    ellipsis: true,
  },
  {
    title: "ГУИД 1С",
    dataIndex: "guid1c",
    key: "guid1c",
    align: "center",
    render: (guid1c: any, record: BranchWithHeads) => (
      <Checkbox checked={record.guid1c ? true : false} disabled />
    ),
    ellipsis: true,
  },
  {
    title: "",
    dataIndex: "headRole",
    key: "headRole",
    render: (headRole: any, record: BranchWithHeads) => (
      <div>
        {record.heads.map((head) => (
          <div key={`record_${record.id}_headRole_${head.id}`}>{head.roleName}</div>
        ))}
      </div>
    ),
    ellipsis: true,
  },
  {
    title: "Руководитель",
    dataIndex: "head",
    key: "head",
    render: (heads: any, record: BranchWithHeads) => (
      <div>
        {record.heads.map((head) => (
          <div key={`record_${record.id}_head_${head.id}`}>
            {[head.headSurname, head.headFirstName, head.headPatronymic]
              .map((v) => (v === null ? "" : v))
              .join(" ")
              .replace("  ", " ")}
          </div>
        ))}
      </div>
    ),
    ellipsis: true,
  },
  {
    title: "Контакты руководителя",
    dataIndex: "headContacts",
    key: "headContacts",
    render: (heads: any, record: BranchWithHeads) => (
      <div>
        {record.heads.map((head) => (
          <div key={`record_${record.id}_headContact_${head.id}`}>
            тел. {head.headPhone}{" "}
            {head.headPhoneDescription ? `(${head.headPhoneDescription})` : ""}, {head.headEmail}
          </div>
        ))}
      </div>
    ),
    ellipsis: true,
  },
];

const AllBranchesPage: PageFunctionComponent = () => {
  useSetTitleEffect([BranchesPage.label, AllBranchesPage.label]);

  const [fetchBranches, { loading: fetchBranchesLoading, result: branches = [] }] =
    useAsyncActionState(branchFetcher);

  const refreshBranches = React.useCallback(() => {
    fetchBranches();
  }, [fetchBranches]);

  useComponentDidMountEffect(() => {
    refreshBranches();
  });

  return (
    <PageHeader
      title={AllBranchesPage.label}
      extra={
        <React.Fragment>
          <RefreshButton onClick={refreshBranches} loading={fetchBranchesLoading} />
        </React.Fragment>
      }
    >
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={branches}
        pagination={false}
        loading={{ spinning: fetchBranchesLoading, delay: 500 }}
        size="small"
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </PageHeader>
  );
};

AllBranchesPage.path = "/all";
AllBranchesPage.label = "Все филиалы";

export default AllBranchesPage;
