import React from "react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/lib/button";

interface EditButtonProps extends ButtonProps {
  children?: string;
}

const EditButton = React.forwardRef<any, EditButtonProps>((props, ref) => {
  const { children, loading, ...restProps } = props;
  return (
    <span ref={ref}>
      <Button {...restProps} loading={loading}>
        <EditOutlined />
        {children ? <span>{children}</span> : null}
      </Button>
    </span>
  );
});

export default EditButton;
