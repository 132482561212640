import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAntdTable } from "ahooks";
import { Form, PageHeader, Row, Col, Button } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import RemindersPage from "../../reminders.page";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import useGoBack from "hooks/use-go-back";
import ReminderTableSimpleForm, {
  ReminderTableSimpleFormDto,
} from "components/tables/ReminderTable/ReminderTableSimpleForm";
import { getReminderTableData } from "./get-reminder-table-data";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import ReminderTable from "components/tables/ReminderTable/ReminderTable";
import AddRemindersPage from "../../pages/add/add.page";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import ReminderTableAdvancedForm from "components/tables/ReminderTable/ReminderTableAdvancedForm";
import { ArrowRightOutlined } from "@ant-design/icons";
import ReminderCardPage from "../card/card.page";
import CheckAbilities from "store/components/CheckAbilities";

const AllRemindersPage: PageFunctionComponent = () => {
  useSetTitleEffect([RemindersPage.label, AllRemindersPage.label]);

  const goBack = useGoBack();

  const history = useHistory();

  const [form] = Form.useForm<ReminderTableSimpleFormDto>();

  const { tableProps, loading, refresh, search, params } = useAntdTable(getReminderTableData, {
    form,
    defaultType: "simple",
    cacheKey: "RemindersPageTable",
    cacheTime: 60 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: { field: "remindAt", order: "descend" },
      },
    ],
  });

  const { type, changeType, submit, reset } = search || {};
  const { sorter = {} } = params[0] || ({} as any);

  return (
    <ContentLayout>
      <Breadcrumbs items={[RemindersPage.label, AllRemindersPage.label]} />
      <PageHeader
        title={AllRemindersPage.label}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[{ subject: Subject.REMINDER, action: subjectAction.REMINDER.CREATE }]}
              placeholder={null}
              loading={loading}
            >
              <Col>
                <AddButton
                  onClick={() =>
                    history.push({
                      pathname: `${RemindersPage.path}${AddRemindersPage.path}`,
                      state: { canGoBack: true },
                    })
                  }
                >
                  Добавить
                </AddButton>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton loading={loading} onClick={refresh} />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "12px",
          }}
        >
          {type === "advance" ? (
            <ReminderTableAdvancedForm form={form} submit={submit} reset={reset} />
          ) : (
            <ReminderTableSimpleForm form={form} submit={submit} reset={reset} />
          )}
          <Button type="link" onClick={changeType}>
            {type === "simple" ? "Расширенный поиск" : "Простой поиск"}
          </Button>
        </div>

        <ReminderTable
          tableProps={tableProps}
          sorter={sorter}
          rightExtra={[
            {
              title: "",
              dataIndex: "reminder",
              key: "reminder",
              render: (_: any, record) => (
                <Link
                  to={{
                    pathname: `${RemindersPage.path}${ReminderCardPage.pathCreator(record.id)}`,
                    state: { canGoBack: true },
                  }}
                >
                  <ArrowRightOutlined />
                </Link>
              ),
              align: "center",
              fixed: "right",
              ellipsis: true,
            },
          ]}
        />
      </PageHeader>
    </ContentLayout>
  );
};

AllRemindersPage.path = "/all";
AllRemindersPage.label = "Все напоминания";

export default AllRemindersPage;
