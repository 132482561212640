import React from "react";
import { Form, Space, Button } from "antd";
import { Store } from "antd/lib/form/interface";
import { SendOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import AntdFormReactQuillInput from "components/common/antd-form-react-quill-input";
import { ReviewChatMessage } from "services/review/models/review-chat-message.model";
import { noOp } from "libs/helpers/no-op";

export interface ReviewChatMessageFormInitialValues {
  message: string;
}

export interface ReviewChatMessageFormProps {
  reviewId: string;
  reviewChatMessageId?: string;
  loading?: boolean;
  initialValues?: ReviewChatMessageFormInitialValues;
  onSubmit?: (reviewChatMessage: ReviewChatMessage) => void;
  size?: SizeType;
  extra?: React.ReactNode;
}

const ReviewChatMessageForm: React.FC<ReviewChatMessageFormProps> = (props) => {
  const {
    loading = false,
    reviewId,
    reviewChatMessageId,
    initialValues,
    onSubmit = noOp,
    size = "large",
    extra,
  } = props;
  const [form] = Form.useForm();

  const handleFinish = React.useCallback(
    (values: Store) => {
      const reviewChatMessage = new ReviewChatMessage();
      if (reviewChatMessageId) {
        reviewChatMessage.id = reviewChatMessageId;
      }
      reviewChatMessage.message = values.message;
      reviewChatMessage.reviewId = reviewId;
      onSubmit(reviewChatMessage);
      form.resetFields();
    },
    [reviewId, reviewChatMessageId, onSubmit, form]
  );

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={handleFinish}
      size={size}
    >
      <Form.Item
        name="message"
        rules={[{ required: true, message: "Это поле должно быть заполнено" }]}
      >
        <AntdFormReactQuillInput placeholder="Введите сообщение... (Что выявлено, что предпринято и т.д.)" />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            <SendOutlined />
            <span>Отправить</span>
          </Button>
          {extra}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ReviewChatMessageForm;
