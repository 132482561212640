import React from "react";
import { Drawer, Button, Steps, Radio, Space, Form } from "antd";
import { noOp } from "libs/helpers/no-op";
import { Store } from "antd/lib/form/interface";
import { ReviewAttachment } from "services/review/review.service";
import AddButton from "components/buttons/add-button";
import {
  ReviewServiceOrder,
  ReviewServiceOrderPartialById,
} from "services/models/review-service-order.model";
import { ReviewWebLink } from "services/review/models/review-web-link.model";
import { ReviewClientEmail } from "services/review/models/review-client-email";
import { ReviewClientCall } from "services/review/models/review-client-call.model";
import ReviewWebLinkForm from "./review-web-link-form/review-web-link-form";
import ReviewClientCallForm from "./review-client-call-form";
import ReviewClientEmailForm from "./review-client-email-form";
import ReviewServiceOrderForm from "./review-service-order-form/review-service-order-form";

const { Step } = Steps;

export interface AddReviewAttachmentDrawerProps {
  currentReviewServiceOrders?: ReviewServiceOrderPartialById[];
  visible?: boolean;
  onClose?: () => void;
  onFinish?: (application: ReviewAttachment) => void;
}

export interface AddReviewAttachmentDrawerState {
  application?: ReviewAttachment;
}

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

const applicationTypeMap: { [key: string]: any } = {
  ReviewServiceOrder: ReviewServiceOrder,
  ReviewWebLink: ReviewWebLink,
  ReviewClientEmail: ReviewClientEmail,
  ReviewClientCall: ReviewClientCall,
};

const AddReviewAttachmentDrawer: React.FC<AddReviewAttachmentDrawerProps> = (props) => {
  const {
    currentReviewServiceOrders = [],
    visible = false,
    onClose = noOp,
    onFinish = noOp,
  } = props;
  const [state, setState] = React.useState<AddReviewAttachmentDrawerState>({});
  const [form] = Form.useForm();

  const currentStep = React.useMemo(() => {
    if (!state.application) {
      return 0;
    }
    return 1;
  }, [state.application]);

  const handleClose = React.useCallback(() => {
    onClose();
    setState({});
    form.resetFields();
  }, [onClose, setState, form]);

  const handleSubmit = React.useCallback(() => {
    form.submit();
  }, [form]);

  const handleFinish = React.useCallback(
    (values: Store) => {
      if (state.application) {
        onFinish(Object.assign(state.application, values));
        setState({});
        form.resetFields();
      }
    },
    [onFinish, setState, state, form]
  );

  return (
    <Drawer
      title="Добавить заказ-наряд, сайт, и т.д."
      onClose={handleClose}
      visible={visible}
      width={720}
      maskClosable={false}
      destroyOnClose={true}
      bodyStyle={{ paddingBottom: 80, maxWidth: "100%" }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            <Button onClick={handleClose}>Отмена</Button>
            <AddButton onClick={handleSubmit} type="primary" disabled={currentStep !== 1}>
              Добавить
            </AddButton>
          </Space>
        </div>
      }
    >
      <Steps current={currentStep}>
        <Step title={"Выбор типа"} />
        <Step title={"Заполнение"} />
      </Steps>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {currentStep === 0 && (
          <React.Fragment>
            <Radio.Group
              onChange={(event) => {
                const applicationType = applicationTypeMap[event.target.value];
                setState((s) => ({ ...s, application: new applicationType() }));
              }}
            >
              <Radio style={radioStyle} value={"ReviewServiceOrder"}>
                Заказ-наряд
              </Radio>
              <Radio style={radioStyle} value={"ReviewWebLink"}>
                Отзыв на сайте
              </Radio>
              <Radio style={radioStyle} value={"ReviewClientEmail"}>
                Email клиента
              </Radio>
              <Radio style={radioStyle} value={"ReviewClientCall"}>
                Звонок клиента
              </Radio>
            </Radio.Group>
          </React.Fragment>
        )}
        {currentStep === 1 && state.application instanceof ReviewServiceOrder && (
          <ReviewServiceOrderForm
            form={form}
            onFinish={handleFinish}
            currentReviewServiceOrders={currentReviewServiceOrders}
          />
        )}
        {currentStep === 1 && state.application instanceof ReviewWebLink && (
          <ReviewWebLinkForm form={form} onFinish={handleFinish} />
        )}
        {currentStep === 1 && state.application instanceof ReviewClientCall && (
          <ReviewClientCallForm form={form} onFinish={handleFinish} />
        )}
        {currentStep === 1 && state.application instanceof ReviewClientEmail && (
          <ReviewClientEmailForm form={form} onFinish={handleFinish} />
        )}
      </div>
      <Space>{currentStep > 0 && <Button onClick={() => setState({})}>Назад</Button>}</Space>
    </Drawer>
  );
};

export default AddReviewAttachmentDrawer;
