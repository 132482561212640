import React from "react";
import { Rate, Tag, DatePicker, Button } from "antd";
import {
  ArrowRightOutlined,
  PhoneOutlined,
  MailOutlined,
  SearchOutlined,
  CalendarFilled,
} from "@ant-design/icons";
import { gold, blue } from "@ant-design/colors";
import { TableState } from "libs/interfaces/table-state.interface";
import { Review } from "services/review/review.service";
import { SortOrder, ColumnsType } from "antd/es/table/interface";
import { getColumnSearchProps } from "components/common/table-column-search-filter/table-column-search-filter";
import Highlighter from "react-highlight-words";
import { displayDateTime } from "libs/helpers/display-date-time";
import ReviewsPage from "pages/reviews/reviews.page";
import ReviewCardPage from "../../card/card.page";
import { Link } from "react-router-dom";
import { Branch } from "services/branch/models/branch";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import { ReviewStatusView } from "services/review-status-view.service";
import ReviewStatus from "components/common/review-status";
import { ReviewAttachment } from "./get-review-attachments";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";
import { User } from "services/user/models/user";

const { RangePicker } = DatePicker;

function getColumns(
  tableState: TableState<Review>,
  branches: Branch[],
  reviewDestinationTypes: ReviewDestinationType[],
  reviewAttachments: ReviewAttachment[],
  reviewTagTypes: ReviewTagType[],
  reviewStatusViews: ReviewStatusView[],
  operators: User[]
): ColumnsType<Review> {
  let createdAtSortorder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;

  if (Array.isArray(tableState.sorter)) {
    //...
  } else {
    createdAtSortorder =
      tableState.sorter.field === "createdAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
    updatedAtSortOrder =
      tableState.sorter.field === "updatedAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
  }

  return [
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (_: any, record) => <ReviewStatus status={record.status} />,
      filters: reviewStatusViews.map((reviewStatusView) => ({
        text: <ReviewStatus status={reviewStatusView.id} />,
        value: reviewStatusView.id,
      })),
      filteredValue: tableState.filters.status || null,
      ellipsis: true,
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
      render: (_: any, record) => record?.branch?.city?.name,
      ellipsis: true,
    },
    {
      title: "Филиал",
      dataIndex: "branchId",
      key: "branchId",
      render: (_: any, record) => record?.branch?.name,
      filters: branches.map((branch) => ({
        text: branch.name,
        value: branch.id,
      })),
      filteredValue: tableState.filters.branchId || null,
      ellipsis: true,
    },
    {
      title: "Направления",
      dataIndex: "reviewDestinations",
      key: "reviewDestinations",
      render: (_: any, record) => (
        <div>
          {record?.reviewDestinations?.map((reviewDestination) => (
            <div key={`review_${record.id}_reviewDestination_${reviewDestination.id}`}>
              {reviewDestination.reviewDestinationType?.name}
            </div>
          ))}
        </div>
      ),
      filters: reviewDestinationTypes.map((reviewDestinationType) => ({
        text: reviewDestinationType.name,
        value: reviewDestinationType.id,
      })),
      filteredValue: tableState.filters.reviewDestinations || null,
      ellipsis: true,
    },
    {
      title: "Оценка",
      dataIndex: "rate",
      key: "rate",
      render: (rate: number, record) =>
        rate || rate === 0 ? <Rate disabled value={rate} /> : "Без оценки",
      filters: [0, 1, 2, 3, 4, 5].map((rate) => {
        return {
          text: rate === 0 ? "Без оценки" : <Rate disabled value={rate} />,
          value: rate,
        };
      }),
      filteredValue: tableState.filters.rate || null,
      ellipsis: true,
    },
    {
      title: "Приложения",
      dataIndex: "reviewAttachments",
      key: "reviewAttachments",
      render: (_: any, record) => {
        const attachments: ReviewAttachment[] = [];

        if (record.reviewServiceOrders?.length) {
          const attachment = reviewAttachments.find((rA) => rA.reviewKey === "reviewServiceOrders");
          if (attachment) {
            attachments.push(attachment);
          }
        }

        if (record.reviewWebLinks?.length) {
          const attachment = reviewAttachments.find((rA) => rA.reviewKey === "reviewWebLinks");
          if (attachment) {
            attachments.push(attachment);
          }
        }

        if (record.reviewClientEmails?.length) {
          const attachment = reviewAttachments.find((rA) => rA.reviewKey === "reviewClientEmails");
          if (attachment) {
            attachments.push(attachment);
          }
        }

        if (record.reviewClientCalls?.length) {
          const attachment = reviewAttachments.find((rA) => rA.reviewKey === "reviewClientCalls");
          if (attachment) {
            attachments.push(attachment);
          }
        }

        return (
          <div>
            {attachments.map((attachment) => (
              <div key={`review_${record.id}_reviewAttachment_${attachment.id}`}>
                {attachment.name}
              </div>
            ))}
          </div>
        );
      },
      filters: reviewAttachments.map((reviewAttachment) => {
        return {
          text: reviewAttachment.name,
          value: reviewAttachment.reviewKey,
        };
      }),
      filteredValue: tableState.filters.reviewAttachments || null,
      ellipsis: true,
    },
    {
      title: "Теги",
      dataIndex: "reviewTags",
      key: "reviewTags",
      render: (_: any, record) =>
        record.reviewTags?.map((reviewTag) =>
          reviewTag.reviewTagType ? (
            <div key={`review_${record.id}_reviewTag_${reviewTag.id}`}>
              <Tag>{reviewTag.reviewTagType.name}</Tag>
            </div>
          ) : (
            ""
          )
        ),
      filters: reviewTagTypes.map((reviewTagType) => {
        return {
          text: <Tag>{reviewTagType.name}</Tag>,
          value: reviewTagType.id,
        };
      }),
      filteredValue: tableState.filters.reviewTags || null,
      ellipsis: true,
    },
    {
      title: "Клиент",
      dataIndex: "clientDisplayName",
      key: "clientDisplayName",
      ...getColumnSearchProps("clientDisplayName"),
      filteredValue: tableState.filters.clientDisplayName || null,
      render: (_: any, record) =>
        tableState.filters.clientDisplayName ? (
          <Highlighter
            highlightStyle={{ backgroundColor: gold[3], padding: 0 }}
            searchWords={tableState.filters.clientDisplayName.map((rStr) => rStr.toString().trim())}
            autoEscape
            textToHighlight={record.clientDisplayName}
          />
        ) : (
          record.clientDisplayName
        ),
      ellipsis: true,
    },
    {
      title: "Контакты клиента",
      dataIndex: "clientContacts",
      key: "clientContacts",
      ...getColumnSearchProps("clientContacts", "Телефон или email клиента"),
      filteredValue: tableState.filters.clientContacts || null,
      render: (_: any, record) => {
        return (
          <div>
            {record.clientPhone ? (
              <div>
                <PhoneOutlined />{" "}
                <span>
                  {tableState.filters.clientContacts ? (
                    <Highlighter
                      highlightStyle={{ backgroundColor: gold[4], padding: 0 }}
                      searchWords={tableState.filters.clientContacts.map((rStr) =>
                        rStr.toString().trim()
                      )}
                      autoEscape
                      textToHighlight={record.clientPhone}
                    />
                  ) : (
                    record.clientPhone
                  )}
                </span>
              </div>
            ) : null}
            {record.clientEmail ? (
              <div>
                <MailOutlined />{" "}
                <span>
                  {tableState.filters.clientContacts ? (
                    <Highlighter
                      highlightStyle={{ backgroundColor: gold[4], padding: 0 }}
                      searchWords={tableState.filters.clientContacts.map((rStr) =>
                        rStr.toString().trim()
                      )}
                      autoEscape
                      textToHighlight={record.clientEmail}
                    />
                  ) : (
                    record.clientEmail
                  )}
                </span>
              </div>
            ) : null}
          </div>
        );
      },
      ellipsis: true,
    },
    {
      title: "Оператор",
      dataIndex: "operator",
      key: "operator",
      render: (_: any, record) => (
        <React.Fragment>
          {record.operatorUser?.surname} {record.operatorUser?.firstName}
        </React.Fragment>
      ),
      filters: operators.map((operator) => ({
        text: `${operator.surname} ${operator.firstName}`,
        value: operator.id,
      })),
      filteredValue: tableState.filters.operator || null,
      ellipsis: true,
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: Date) => displayDateTime(createdAt),
      sorter: true,
      sortOrder: createdAtSortorder,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <RangePicker showTime style={{ marginBottom: 8 }} allowClear={false} />
          <br />
          <Button type="link" onClick={() => {}} icon={<SearchOutlined />} size="small">
            Найти
          </Button>
          <Button type="link" onClick={() => {}} size="small" style={{ float: "right" }}>
            Сбросить
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarFilled style={{ color: filtered ? blue.primary : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {},
      ellipsis: true,
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt: Date) => displayDateTime(updatedAt),
      sorter: true,
      sortOrder: updatedAtSortOrder,
      sortDirections: ["descend"],
      ellipsis: true,
    },
    {
      title: "",
      key: "action",
      render: (text: any, record) => (
        <Link
          to={{
            pathname: `${ReviewsPage.path}${ReviewCardPage.pathCreator(record.id)}`,
            state: {
              canGoBack: true,
            },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      fixed: "right",
      align: "center",
    },
  ];
}

export default getColumns;
