import { TableState } from "libs/interfaces/table-state.interface";
import { Review } from "services/review/review.service";
import { ServiceOrder } from "services/service-order.service";
import {
  LatestTableStatesAction,
  LatestTableStatesActionType,
} from "store/reducers/latest-table-states";

export function setAllReviewsTableState(tableState: TableState<Review>): LatestTableStatesAction {
  return { type: LatestTableStatesActionType.SET_ALL_REVIEWS_TABLE_STATE, payload: tableState };
}

export function setAllServiceOrdersTableState(
  tableState: TableState<ServiceOrder>
): LatestTableStatesAction {
  return {
    type: LatestTableStatesActionType.SET_ALL_SERVICE_ORDERS_TABLE_STATE,
    payload: tableState,
  };
}

export function setCreatingReviewsTableState(
  tableState: TableState<Review>
): LatestTableStatesAction {
  return {
    type: LatestTableStatesActionType.SET_CREATING_REVIEWS_TABLE_STATE,
    payload: tableState,
  };
}

export function setProcessingReviewsTableState(
  tableState: TableState<Review>
): LatestTableStatesAction {
  return {
    type: LatestTableStatesActionType.SET_PROCESSING_REVIEWS_TABLE_STATE,
    payload: tableState,
  };
}

export function setReviewsFromSitesTableState(
  tableState: TableState<Review>
): LatestTableStatesAction {
  return {
    type: LatestTableStatesActionType.SET_REVIEWS_FROM_SITES_TABLE_STATE,
    payload: tableState,
  };
}

export function setServiceOrdersWithoutReview(
  tableState: TableState<ServiceOrder>
): LatestTableStatesAction {
  return {
    type: LatestTableStatesActionType.SET_SERVICE_ORDERS_WITHOUT_REVIEW,
    payload: tableState,
  };
}

export function clearLatestTableStates(): LatestTableStatesAction {
  return { type: LatestTableStatesActionType.CLEAR_LATEST_TABLE_STATES };
}
