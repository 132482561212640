import React from "react";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import ReviewsPage from "pages/reviews/reviews.page";
import { useAppDispatch, useAppSelector, useSetTitleEffect } from "store/hooks";
import { PageHeader, Table } from "antd";
import ResetButton from "components/buttons/reset-button";
import RefreshButton from "components/buttons/refresh-button";
import ReviewStatus from "components/common/review-status";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { DEFAULT_TABLE_SORTER } from "pages/reviews/reviews.constants";
import { Review } from "services/review/review.service";
import { useTableState } from "hooks/use-table-state";
import { useTableChangeHandler } from "hooks/use-table-change-handler";
import fetchReviews from "./helpers/fetch-reviews";
import convertTableParamsToQueryParams from "./helpers/convert-table-params-to-query-params";
import getColumns from "./helpers/get-columns";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import { useTableSorterCleaner } from "hooks/use-table-sorter-cleaner";
import { useTableFilterCleaner } from "hooks/use-table-filter-cleaner";
import useAsyncActionState from "hooks/use-async-action-state";
import fetchOperators from "pages/reviews/helpers/fetch-operators";
import { setCreatingReviewsTableState } from "store/actions/latest-table-states";

const CreatingReviewsPage: PageFunctionComponent = () => {
  useSetTitleEffect([ReviewsPage.label, CreatingReviewsPage.label]);
  const creatingReviewsTableState = useAppSelector(
    (state) => state.latestTableStates.creatingReviewsTableState
  );
  const dispatch = useAppDispatch();
  const [state, , setStateAsync] = useTableState<Review>(
    creatingReviewsTableState ? creatingReviewsTableState : { sorter: DEFAULT_TABLE_SORTER }
  );
  const handleTableChange = useTableChangeHandler(
    state,
    setStateAsync,
    convertTableParamsToQueryParams,
    fetchReviews,
    DEFAULT_TABLE_SORTER
  );
  const [canClearSorter, clearSorter] = useTableSorterCleaner(
    state,
    handleTableChange,
    DEFAULT_TABLE_SORTER
  );
  const [canClearFilter, clearFilter] = useTableFilterCleaner(state, handleTableChange, {});
  const [operatorsFetcher, { loading: fetchOperatorsLoading, result: operators = [] }] =
    useAsyncActionState(fetchOperators);
  const columns = React.useMemo(() => getColumns(state, operators), [state, operators]);

  useComponentDidMountEffect(() => {
    handleTableChange(state.pagination, state.filters, state.sorter, {
      currentDataSource: state.data,
      action: "paginate",
    });
    operatorsFetcher();
  });

  React.useEffect(() => {
    dispatch(setCreatingReviewsTableState(state));
  }, [dispatch, state]);

  const loading = state.loading || fetchOperatorsLoading;

  return (
    <PageHeader
      title="Отзывы"
      subTitle={
        <React.Fragment>
          <ReviewStatus status={ReviewStatusEnum.DRAFT} />
          <span style={{ marginRight: 8 }}>/</span>
          <ReviewStatus status={ReviewStatusEnum.REQUIRE_CHECK} />
        </React.Fragment>
      }
      extra={
        <React.Fragment>
          <ResetButton onClick={clearFilter} disabled={!canClearFilter || loading}>
            Сбросить фильтры
          </ResetButton>
          <ResetButton onClick={clearSorter} disabled={!canClearSorter || loading}>
            Сбросить сортировки
          </ResetButton>
          <RefreshButton
            onClick={() =>
              handleTableChange(state.pagination, state.filters, state.sorter, {
                currentDataSource: state.data,
                action: "paginate",
              })
            }
            loading={loading}
          />
        </React.Fragment>
      }
    >
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={{ spinning: loading, delay: 500 }}
        onChange={handleTableChange}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </PageHeader>
  );
};

CreatingReviewsPage.path = "/creating";
CreatingReviewsPage.label = "Черновики и проверка";

export default CreatingReviewsPage;
