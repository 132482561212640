import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { PageHeader, Divider, Table, message, Popconfirm, Row, Col } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import ContentLayout from "components/layouts/content.layout";
import RefreshButton from "components/buttons/refresh-button";
import { useAppSelector, useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import ServiceOrdersPage from "pages/service-orders/service-orders.page";
import useAsyncActionState, { AsyncActionState } from "hooks/use-async-action-state";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import AddButton from "components/buttons/add-button";
import HttpErrorResult from "components/common/http-error-result";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import fetchServiceOrderHelper from "./helpers/fetch-service-order";
import getReviewTableColumns from "./helpers/get-review-table-columns";
import createReviewWithServiceOrder from "../../helpers/create-review-with-service-order";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import ReviewsPage from "pages/reviews/reviews.page";
import EditReviewCardPage from "pages/reviews/pages/edit-card/edit-card.page";
import ServiceOrderDescriptions from "components/descriptions/service-order-descriptions";
import ClientsPage from "pages/clients/clients.page";
import AddToBlacklistPage from "pages/clients/pages/add-to-blacklist/add-to-blacklist.page";
import fetchBlacklistClient from "./helpers/fetch-blacklist-client";
import deleteBlacklistClientHelper from "./helpers/delete-blacklist-client";
import RemoveButton from "components/buttons/remove-button";
import { ServiceOrder } from "services/service-order.service";
import { noOp } from "libs/helpers/no-op";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import CheckAbilities from "store/components/CheckAbilities";
import checkAbilities from "store/helpers/check-abilities";

const reviewTableColumns = getReviewTableColumns();

const ServiceOrderCardPage: PageFCWithPathCreator = () => {
  const { id = "" } = useParams<{ id?: string }>();
  useSetTitleEffect([ServiceOrdersPage.label, `Заказ-наряд № ${id}`]);
  const history = useHistory();
  const goBack = useGoBack();

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const fetchServiceOrder = React.useCallback(() => fetchServiceOrderHelper(id), [id]);

  const [
    serviceOrderFetcher,
    { result: serviceOrder, loading: fetchServiceOrderLoading, error: fetchServiceOrderError },
  ] = useAsyncActionState(fetchServiceOrder);

  const [
    blacklistClientFetcher,
    {
      result: blacklistClient,
      loading: fetchBlacklistClientLoading,
      // error: fetchBlacklistClientError,
    },
  ] = useAsyncActionState(fetchBlacklistClient);

  const [
    deleteBlacklistClient,
    { loading: deleteBlacklistClientLoading, error: deleteBlacklistClientError },
  ] = useAsyncActionState(deleteBlacklistClientHelper);

  const [createReview, { loading: createReviewLoading, error: createReviewError }] =
    useAsyncActionState(createReviewWithServiceOrder);

  const refresh = React.useCallback(() => {
    let doThen = ({ error, result }: AsyncActionState<ServiceOrder>) => {
      if (!error && result) {
        blacklistClientFetcher({
          clientGuid1c: result.clientGuid1c,
          clientPhone: result.clientPhone,
        });
      }
    };
    serviceOrderFetcher().then(doThen);
    return () => {
      doThen = noOp;
    };
  }, [blacklistClientFetcher, serviceOrderFetcher]);

  const handleAddReview = React.useCallback(
    (serviceOrderId: string) => {
      createReview({ serviceOrderId, useLimits: false }).then(({ result: id, error }) => {
        if (error) {
          refresh();
        } else if (id) {
          history.push({
            pathname: `${ReviewsPage.path}${EditReviewCardPage.pathCreator(id)}`,
            state: {
              canGoBack: true,
            },
          });
        }
      });
    },
    [createReview, history, refresh]
  );

  useComponentDidMountEffect(() => {
    refresh();
  });

  React.useEffect(() => {
    if (createReviewError instanceof ApiDriverHttpError) {
      let content = "Ошибка!";
      if (createReviewError.status === 409) {
        content = "Отзыв на этот заказ-наряд уже создан.";
      } else if (createReviewError.status >= 500) {
        content = "Ошибка сервера.";
      }

      message.error(content);
    }
  }, [createReviewError]);

  React.useEffect(() => {
    if (deleteBlacklistClientError instanceof ApiDriverHttpError) {
      refresh();
    }
  }, [deleteBlacklistClientError, refresh]);

  const loading =
    fetchServiceOrderLoading ||
    createReviewLoading ||
    fetchBlacklistClientLoading ||
    deleteBlacklistClientLoading;

  const pageAccessControl = React.useMemo(() => {
    if ((pageAccessControlState.loading || loading) && !blacklistClient) {
      return {
        userCanCreateReview: false,
        userCanAddClientToBlacklist: false,
        userCanRemoveClientFromBlacklist: false,
      };
    }

    const userCanDeleteAllBlacklistClients = checkAbilities(pageAccessControlState.abilities, {
      subject: Subject.BLACKLIST_CLIENT,
      action: subjectAction.BLACKLIST_CLIENT.DELETE_ALL,
    });
    const userCanDeleteOwnBlacklistClients = checkAbilities(pageAccessControlState.abilities, {
      subject: Subject.BLACKLIST_CLIENT,
      action: subjectAction.BLACKLIST_CLIENT.DELETE_OWN,
    });

    return {
      userCanCreateReview: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.CREATE,
      }),
      userCanAddClientToBlacklist: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.BLACKLIST_CLIENT,
        action: subjectAction.BLACKLIST_CLIENT.CREATE,
      }),
      userCanRemoveClientFromBlacklist:
        userCanDeleteAllBlacklistClients ||
        (userCanDeleteOwnBlacklistClients &&
          blacklistClient?.createdByUserId === pageAccessControlState.user?.id),
    };
  }, [pageAccessControlState, blacklistClient, loading]);

  return (
    <ContentLayout>
      <Breadcrumbs items={[ServiceOrdersPage.label, `Заказ-наряд № ${id}`]} />
      <PageHeader
        title="Заказ-наряд"
        subTitle={id}
        onBack={goBack}
        extra={
          <React.Fragment>
            <Row gutter={[8, 8]} justify="end" style={{ marginBottom: "16px" }}>
              {blacklistClient ? (
                <CheckAbilities
                  manual={pageAccessControl.userCanRemoveClientFromBlacklist}
                  placeholder={null}
                >
                  <Col>
                    <Popconfirm
                      placement="bottom"
                      title="Вы уверены?"
                      okText="Убрать"
                      okButtonProps={{ danger: true }}
                      onConfirm={() => {
                        if (blacklistClient) {
                          deleteBlacklistClient(blacklistClient.id).then(({ error: err }) => {
                            if (!err) {
                              refresh();
                            }
                          });
                        }
                      }}
                    >
                      <RemoveButton loading={loading}>
                        Убрать клиента из черного списка
                      </RemoveButton>
                    </Popconfirm>
                  </Col>
                </CheckAbilities>
              ) : (
                <CheckAbilities
                  manual={pageAccessControl.userCanAddClientToBlacklist}
                  placeholder={null}
                >
                  <Col>
                    <AddButton
                      loading={loading}
                      danger={true}
                      onClick={() => {
                        history.push({
                          pathname: `${ClientsPage.path}${AddToBlacklistPage.path}`,
                          state: {
                            canGoBack: true,
                            clientGuid1c: serviceOrder?.clientGuid1c,
                            clientPhone: serviceOrder?.clientPhone,
                          },
                        });
                      }}
                    >
                      Добавить клиента в чёрный список
                    </AddButton>
                  </Col>
                </CheckAbilities>
              )}
              <Col>
                <RefreshButton loading={loading} onClick={refresh} />
              </Col>
            </Row>
            <Row gutter={[8, 8]} justify="end">
              {serviceOrder &&
                serviceOrder.reviewServiceOrders &&
                serviceOrder.reviewServiceOrders.length === 0 &&
                !blacklistClient && (
                  <CheckAbilities manual={pageAccessControl.userCanCreateReview} placeholder={null}>
                    <Col>
                      <AddButton
                        loading={loading}
                        type="primary"
                        onClick={() => handleAddReview(serviceOrder.id)}
                        size="large"
                      >
                        Добавить отзыв
                      </AddButton>
                    </Col>
                  </CheckAbilities>
                )}
            </Row>
          </React.Fragment>
        }
      >
        {serviceOrder && (
          <React.Fragment>
            <ServiceOrderDescriptions
              serviceOrder={serviceOrder}
              blacklistClient={blacklistClient}
            />
            <Divider orientation="left">Отзывы</Divider>
            {serviceOrder.reviewServiceOrders && serviceOrder.reviewServiceOrders.length !== 0 && (
              <Table
                columns={reviewTableColumns}
                dataSource={serviceOrder.reviewServiceOrders}
                rowKey={(record) => record.id}
                pagination={false}
              />
            )}
          </React.Fragment>
        )}
        {fetchServiceOrderError && (
          <HttpErrorResult
            error={fetchServiceOrderError}
            subtitlesMap={{
              403: "Для просмотра этого заказ-наряда недостаточно прав.",
              404: "Заказ-наряд не найден.",
            }}
          />
        )}
      </PageHeader>
    </ContentLayout>
  );
};

ServiceOrderCardPage.label = "";
ServiceOrderCardPage.path = "/card/:id";
ServiceOrderCardPage.pathCreator = (id: string) => `/card/${id}`;

export default ServiceOrderCardPage;
