import { ServiceOrder } from "services/service-order.service";
import { ConvertTableParamsToQueryParams } from "hooks/use-table-change-handler";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { QuerySort } from "@nestjsx/crud-request";
import { TABLE_DEFAULT_PAGE, TABLE_DEFAULT_PAGE_SIZE } from "configs/configs";

const convertTableParamsToQueryParams: ConvertTableParamsToQueryParams<ServiceOrder> = (
  pagination,
  filters,
  sorter
) => {
  const search: { $and: any[] } = { $and: [] };

  if (Array.isArray(filters.number)) {
    if (filters.number.length > 0) {
      search.$and.push({
        number: { $contL: filters.number[0].toString().trim() },
      });
    }
  }

  if (Array.isArray(filters.clientDisplayName)) {
    if (filters.clientDisplayName.length > 0) {
      search.$and.push({
        clientDisplayName: { $contL: filters.clientDisplayName[0].toString().trim() },
      });
    }
  }

  if (Array.isArray(filters.clientPhone)) {
    if (filters.clientPhone.length > 0) {
      search.$and.push({
        clientPhone: { $contL: filters.clientPhone[0].toString().trim() },
      });
    }
  }

  const fetchSorter = Array.isArray(sorter)
    ? sorter
        .map((s) => convertTableSorterToQuerySorter<ServiceOrder>(s))
        .reduce((acc, curr) => {
          if (curr) {
            acc.push(curr);
          }
          return acc;
        }, [] as QuerySort[])
    : convertTableSorterToQuerySorter<ServiceOrder>(sorter);

  return {
    page: pagination.current || TABLE_DEFAULT_PAGE,
    limit: pagination.pageSize || TABLE_DEFAULT_PAGE_SIZE,
    sort: fetchSorter,
    search,
  };
};

export default convertTableParamsToQueryParams;
