export interface AppSettingsState {
  sideBarCollapsed: boolean;
}

export enum AppSettingsActionType {
  TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR",
}

export interface AppSettingsAction {
  type: AppSettingsActionType;
  payload?: Partial<AppSettingsState>;
}

const initialState: AppSettingsState = {
  sideBarCollapsed: false,
};

export default function appSettingsReducer(
  state = initialState,
  action: AppSettingsAction
): AppSettingsState {
  switch (action.type) {
    case AppSettingsActionType.TOGGLE_SIDE_BAR:
      return { ...state, sideBarCollapsed: !state.sideBarCollapsed };
    default:
      return state;
  }
}

// @TODO: remove in next version
localStorage.removeItem("app-settings");
