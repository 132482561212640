import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm, message } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import ReviewSiteDescription from "components/descriptions/ReviewSiteDescription";
import { reviewSiteService } from "services";
import { ReviewSite } from "services/review-site/models/review-site";
import ReviewSiteForm, { ReviewSiteFormDto } from "components/forms/ReviewSiteForm";
import { ReviewSiteService } from "services/review-site/review-site.service";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import ReviewSitesSettingsPage from "../review-sites/review-sites.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

export interface ReviewSiteSettingsPageParams {
  id: ReviewSite["id"];
}

const fetchReviewSite = (id: ReviewSite["id"]) => reviewSiteService.findOne(id);

const updateReviewSite: ReviewSiteService["updateOne"] = (id, dto) =>
  reviewSiteService.updateOne(id, dto);

const deleteReviewSite = (id: ReviewSite["id"]) => reviewSiteService.deleteOne(id);

const ReviewSiteSettingsPage: PageFCWithPathCreator = () => {
  const { id } = useParams<ReviewSiteSettingsPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchReviewSite,
    data: reviewSite,
    error: fetchReviewSiteError,
    loading: fetchReviewSiteLoading,
    refresh: refreshReviewSite,
    mutate: mutateReviewSite,
  } = useRequest(fetchReviewSite, {
    manual: true,
  });

  const { runAsync: runUpdateReviewSite, loading: updateReviewSiteLoading } = useRequest(
    updateReviewSite,
    {
      manual: true,

      onError: (e, params) => {
        const [, dto] = params;
        const { name } = dto;
        if (e instanceof ApiDriverHttpError) {
          if (e.status === 409) {
            message.error(`Сайт отзывов с названием "${name}" уже существует!`);
          }
        }
      },
    }
  );

  const { run: runDeleteReviewSite, loading: deleteReviewSiteLoading } = useRequest(
    deleteReviewSite,
    {
      manual: true,
      onSuccess: () => {
        history.push(`${SettingsPage.path}${ReviewSitesSettingsPage.path}`);
      },
    }
  );

  const [edit, setEdit] = React.useState<boolean>(false);

  useSetTitleEffect([SettingsPage.label, ReviewSiteSettingsPage.label, reviewSite?.name || id]);

  useMount(() => {
    if (id) {
      runFetchReviewSite(id);
    }
  });

  const handleUpdate = React.useCallback(
    async (dto: ReviewSiteFormDto) => {
      if (!reviewSite) {
        return;
      }
      const updated = await runUpdateReviewSite(reviewSite.id, dto);
      mutateReviewSite(updated);
      setEdit(false);
    },
    [reviewSite, runUpdateReviewSite, mutateReviewSite, setEdit]
  );

  const handleDelete = React.useCallback(async () => {
    if (!reviewSite) {
      return;
    }
    await runDeleteReviewSite(reviewSite.id);
    setEdit(false);
    await refreshReviewSite();
  }, [reviewSite, runDeleteReviewSite, setEdit, refreshReviewSite]);

  return (
    <ContentLayout>
      <Breadcrumbs
        items={[SettingsPage.label, ReviewSiteSettingsPage.label, reviewSite?.name || id]}
      />
      <PageHeader
        title={ReviewSiteSettingsPage.label}
        subTitle={reviewSite?.name}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.REVIEW_SITE,
                  action: subjectAction.REVIEW_SITE.UPDATE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={fetchReviewSiteLoading || deleteReviewSiteLoading || !reviewSite}
                  loading={updateReviewSiteLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.REVIEW_SITE,
                  action: subjectAction.REVIEW_SITE.UPDATE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={fetchReviewSiteLoading || updateReviewSiteLoading || !reviewSite}
                    loading={deleteReviewSiteLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateReviewSiteLoading || deleteReviewSiteLoading}
                loading={fetchReviewSiteLoading}
                onClick={refreshReviewSite}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchReviewSiteLoading} delay={500}>
          <HttpErrorResult error={fetchReviewSiteError} />
          {edit ? (
            <Spin spinning={updateReviewSiteLoading}>
              <ReviewSiteForm entityToUpdate={reviewSite} onFinish={handleUpdate} title={""} />
            </Spin>
          ) : (
            reviewSite && <ReviewSiteDescription data={reviewSite} title={""} />
          )}
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

ReviewSiteSettingsPage.path = "/review-site/:id";
ReviewSiteSettingsPage.pathCreator = (id) => `/review-site/${id}`;
ReviewSiteSettingsPage.label = "Сайт отзывов";

export default ReviewSiteSettingsPage;
