import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  PageHeader,
  Divider,
  Button,
  Descriptions,
  Tag,
  Rate,
  Typography,
  Row,
  Col,
  Popconfirm,
  message,
  Space,
  Alert,
} from "antd";
import { PhoneOutlined, MailOutlined, MoreOutlined, ArrowRightOutlined } from "@ant-design/icons";
import Breadcrumbs from "components/common/breadcrumbs";
import ContentLayout from "components/layouts/content.layout";
import RefreshButton from "components/buttons/refresh-button";
import HttpErrorResult from "components/common/http-error-result";
import ReviewStatus from "components/common/review-status";
import RemoveButton from "components/buttons/remove-button";
import EditButton from "components/buttons/edit-button";
import ReviewAttachmentCollapse from "components/common/review-attachments-collapse";
import InfoTooltip from "components/common/into-tooltip";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { useAppSelector, useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import useAsyncActionState from "hooks/use-async-action-state";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import ReviewsPage from "pages/reviews/reviews.page";
import deleteReviewHelper from "pages/reviews/helpers/delete-review";
import EditReviewCardPage from "../edit-card/edit-card.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { ReviewChatMessage } from "services/review/models/review-chat-message.model";
import { ReviewStatus as ReviewStatusModel } from "services/review/models/review-status.model";
import fetchReviewHelper from "./helpers/fetch-review.helper";
import createReviewChatMessageHelper from "./helpers/create-review-chat-message.helper";
import fetchReviewChatMessagesHelper from "./helpers/fetch-review-chat-messages.helper";
import updateReviewChatMessageHelper from "./helpers/update-review-chat-message.helper";
import setReadyToCloseStatusToReviewHelper from "./helpers/set-ready-to-close-status-to-review.helper";
import setClosedStatusToReviewHelper from "./helpers/set-closed-status-to-review.helper";
import ReviewChatMessageForm from "./components/review-chat-message-form/review-chat-message-form";
import ReviewChat from "./components/review-chat/review-chat";
import sendNewNegativeReviewEmail from "./helpers/send-new-negative-review-email.helper";
import { useHttpErrorMessage } from "hooks/use-http-error-message";
import AddReminderButton from "components/buttons/AddReminderButton";
import { ServiceOrder } from "services/service-order.service";
import { ReviewClientCall } from "services/review/models/review-client-call.model";
import { ReviewClientEmail } from "services/review/models/review-client-email";
import { ReviewWebLink } from "services/review/models/review-web-link.model";
import checkAbilities from "store/helpers/check-abilities";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import CheckAbilities from "store/components/CheckAbilities";

const { Paragraph } = Typography;

function compareChatItemsByCreatedAtDate(
  a: ReviewChatMessage | ReviewStatusModel,
  b: ReviewChatMessage | ReviewStatusModel
) {
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  return 0;
}

const ReviewCardPage: PageFCWithPathCreator = () => {
  const { id = "" } = useParams<{ id?: string }>();
  useSetTitleEffect([ReviewsPage.label, `Отзыв № ${id}`]);

  const user = useAppSelector((state) => state.user.user);
  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const history = useHistory();
  const goBack = useGoBack();

  const [chatItems, setChatItems] = React.useState<(ReviewChatMessage | ReviewStatusModel)[]>([]);

  const reviewFetcher = React.useCallback(() => fetchReviewHelper(id), [id]);

  const [fetchReview, { result: review, loading: fetchReviewLoading, error: fetchReviewError }] =
    useAsyncActionState(reviewFetcher);

  React.useEffect(() => {
    if (review) {
      const newChatItems = [
        ...(review.reviewStatuses || []),
        ...(review.reviewChatMessages || []),
      ].sort(compareChatItemsByCreatedAtDate);
      setChatItems(newChatItems);
    }
  }, [setChatItems, review]);

  const [deleteReview, { loading: deleteReviewLoading, error: deleteReviewError }] =
    useAsyncActionState(deleteReviewHelper);

  const [
    createReviewChatMessage,
    { loading: createReviewChatMessageLoaidng, error: createReviewChatMessageError },
  ] = useAsyncActionState(createReviewChatMessageHelper);

  const [
    updateReviewChatMessage,
    { loading: updateReviewChatMessageLoaidng, error: updateReviewChatMessageError },
  ] = useAsyncActionState(updateReviewChatMessageHelper);

  const [
    fetchReviewChatMessages,
    {
      result: reviewChatMessages,
      loading: fetchReviewChatMessagesLoading,
      error: fetchReviewChatMessagesError,
    },
  ] = useAsyncActionState(fetchReviewChatMessagesHelper);

  React.useEffect(() => {
    if (Array.isArray(reviewChatMessages)) {
      const newReviewChatMessagesIds = reviewChatMessages.map(
        (reviewChatMessage) => reviewChatMessage.id
      );
      setChatItems((currentChatItems) => {
        let newChatItems = currentChatItems.filter(
          (item) => !newReviewChatMessagesIds.includes(item.id)
        );
        newChatItems.push(...reviewChatMessages);

        return newChatItems.sort(compareChatItemsByCreatedAtDate);
      });
    }
  }, [setChatItems, reviewChatMessages]);

  const [
    setReadyToCloseStatusToReview,
    { loading: setReadyToCloseStatusToReviewLoading, error: setReadyToCloseStatusToReviewError },
  ] = useAsyncActionState(setReadyToCloseStatusToReviewHelper);

  React.useEffect(() => {
    if (setReadyToCloseStatusToReviewError) {
      if (setReadyToCloseStatusToReviewError instanceof ApiDriverHttpError) {
        if (setReadyToCloseStatusToReviewError.status === 404) {
          message.error("Отзыв не найден! Обновите страницу.");
        } else if (setReadyToCloseStatusToReviewError.status === 422) {
          message.error("Статус уже был изменен! Обновите страницу.");
        } else {
          message.error(setReadyToCloseStatusToReviewError.message);
        }
      } else {
        message.error(setReadyToCloseStatusToReviewError.message);
      }
    }
  }, [setReadyToCloseStatusToReviewError]);

  const handleSetReadyToCloseStatusToReviewClick = React.useCallback(() => {
    if (review) {
      setReadyToCloseStatusToReview(review.id).then(({ error }) => {
        if (!error) {
          fetchReview();
        }
      });
    }
  }, [review, setReadyToCloseStatusToReview, fetchReview]);

  const [
    setClosedStatusToReview,
    { loading: setClosedStatusToReviewLoading, error: setClosedStatusToReviewError },
  ] = useAsyncActionState(setClosedStatusToReviewHelper);

  React.useEffect(() => {
    if (setClosedStatusToReviewError) {
      if (setClosedStatusToReviewError instanceof ApiDriverHttpError) {
        if (setClosedStatusToReviewError.status === 404) {
          message.error("Отзыв не найден! Обновите страницу.");
        } else if (setClosedStatusToReviewError.status === 422) {
          message.error("Статус уже был изменен! Обновите страницу.");
        } else {
          message.error(setClosedStatusToReviewError.message);
        }
      } else {
        message.error(setClosedStatusToReviewError.message);
      }
    }
  }, [setClosedStatusToReviewError]);

  const handleSetClosedStatusToReview = React.useCallback(() => {
    if (review) {
      setClosedStatusToReview(review.id).then(({ error }) => {
        if (!error) {
          fetchReview();
        }
      });
    }
  }, [review, setClosedStatusToReview, fetchReview]);

  const handleReviewChatMessageFormSubmit = React.useCallback(
    (reviewChatMessage: ReviewChatMessage) => {
      if (review) {
        const latestItem = chatItems[chatItems.length - 1];
        createReviewChatMessage(reviewChatMessage).then(() =>
          fetchReviewChatMessages({ reviewId: review.id, after: latestItem?.createdAt })
        );
      }
    },
    [createReviewChatMessage, fetchReviewChatMessages, review, chatItems]
  );

  const handleReviewChatMessageUpdate = React.useCallback(
    async (reviewChatMessageToUpdate: ReviewChatMessage) => {
      if (review) {
        const latestItem = chatItems[chatItems.length - 1];
        await updateReviewChatMessage(reviewChatMessageToUpdate).then(() =>
          fetchReviewChatMessages({ reviewId: review.id, after: latestItem?.createdAt })
        );
      }
    },
    [updateReviewChatMessage, fetchReviewChatMessages, review, chatItems]
  );

  const [
    sendNewNegativeReviewEmailHelper,
    { loading: sendNewNegativeReviewEmailLoading, error: sendNewNegativeReviewEmailError },
  ] = useAsyncActionState(sendNewNegativeReviewEmail);

  useHttpErrorMessage(sendNewNegativeReviewEmailError);

  React.useEffect(() => {
    if (createReviewChatMessageError) {
      message.error(createReviewChatMessageError.message);
    }
  }, [createReviewChatMessageError]);

  React.useEffect(() => {
    if (fetchReviewChatMessagesError) {
      message.error(fetchReviewChatMessagesError.message);
    }
  }, [fetchReviewChatMessagesError]);

  React.useEffect(() => {
    if (updateReviewChatMessageError) {
      message.error(updateReviewChatMessageError.message);
    }
  }, [updateReviewChatMessageError]);

  const loading =
    fetchReviewLoading ||
    deleteReviewLoading ||
    setReadyToCloseStatusToReviewLoading ||
    setClosedStatusToReviewLoading ||
    sendNewNegativeReviewEmailLoading;
  const chatLoading =
    createReviewChatMessageLoaidng ||
    fetchReviewChatMessagesLoading ||
    updateReviewChatMessageLoaidng ||
    setReadyToCloseStatusToReviewLoading ||
    setClosedStatusToReviewLoading ||
    sendNewNegativeReviewEmailLoading;

  useComponentDidMountEffect(() => {
    fetchReview();
  });

  const pageAccessControl = React.useMemo(() => {
    if (pageAccessControlState.loading || fetchReviewLoading) {
      return {
        userCanCreateReview: false,
        userCanPostComment: false,
        userCanDeleteReview: false,
        userCanUpdateReview: false,
        userCanSetReadyToCloseStatusToReview: false,
        userCanSetClosedStatusToReview: false,
        userCanCreateReminder: false,
      };
    }

    if (!review) {
      return {
        userCanCreateReview: false,
        userCanPostComment: false,
        userCanDeleteReview: false,
        userCanUpdateReview: false,
        userCanSetReadyToCloseStatusToReview: false,
        userCanSetClosedStatusToReview: false,
        userCanCreateReminder: false,
      };
    }

    if (fetchReviewError) {
      return {
        userCanCreateReview: false,
        userCanPostComment: false,
        userCanDeleteReview: false,
        userCanUpdateReview: false,
        userCanSetReadyToCloseStatusToReview: false,
        userCanSetClosedStatusToReview: false,
        userCanCreateReminder: false,
      };
    }

    const branchId = review?.branchId;
    const reviewDestinationTypeIds = review?.reviewDestinations?.map(
      (reviewDestination) => reviewDestination?.reviewDestinationType?.id
    ) as string[] | undefined;

    const userCanReadAllReviews = checkAbilities(pageAccessControlState.abilities, {
      subject: Subject.REVIEW,
      action: subjectAction.REVIEW.READ_ALL,
      branchId,
      reviewDestinationTypeIds,
    });
    const userCanReadOwnReviews =
      checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.READ_OWN,
        branchId,
        reviewDestinationTypeIds,
      }) && pageAccessControlState.user?.id === review.operatorUserId;
    const userCanUpdateAllReviews = checkAbilities(pageAccessControlState.abilities, {
      subject: Subject.REVIEW,
      action: subjectAction.REVIEW.UPDATE_ALL,
      branchId,
      reviewDestinationTypeIds,
    });
    const userCanUpdateOwnReviews =
      checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.UPDATE_OWN,
        branchId,
        reviewDestinationTypeIds,
      }) && pageAccessControlState.user?.id === review.operatorUserId;
    const userCanDeleteAllReviews = checkAbilities(pageAccessControlState.abilities, {
      subject: Subject.REVIEW,
      action: subjectAction.REVIEW.DELETE_ALL,
      branchId,
      reviewDestinationTypeIds,
    });
    const userCanDeleteOwnReviews =
      checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.DELETE_OWN,
        branchId,
        reviewDestinationTypeIds,
      }) && pageAccessControlState.user?.id === review.operatorUserId;

    const userCanCreateReminder = checkAbilities(pageAccessControlState.abilities, {
      subject: Subject.REMINDER,
      action: subjectAction.REMINDER.CREATE,
    });

    return {
      userCanCreateReview: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.CREATE,
      }),
      userCanPostComment:
        userCanReadAllReviews ||
        userCanReadOwnReviews ||
        [ReviewStatusEnum.READY_TO_CLOSE, ReviewStatusEnum.PROCESSING].includes(review.status),
      userCanDeleteReview: userCanDeleteAllReviews || userCanDeleteOwnReviews,
      userCanUpdateReview: userCanUpdateAllReviews || userCanUpdateOwnReviews,
      userCanSetReadyToCloseStatusToReview: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.SET_READY_TO_CLOSE_STATUS,
        branchId,
        reviewDestinationTypeIds,
      }),
      userCanSetClosedStatusToReview: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.SET_CLOSED_STATUS,
        branchId,
        reviewDestinationTypeIds,
      }),
      userCanCreateReminder,
    };
  }, [pageAccessControlState, review, fetchReviewLoading, fetchReviewError]);

  return (
    <ContentLayout>
      <Breadcrumbs items={[ReviewsPage.label, `Отзыв № ${id}`]} />
      <PageHeader
        title="Отзыв"
        subTitle={
          <span>
            {id} {review && <ReviewStatus status={review.status} />}
          </span>
        }
        onBack={goBack}
        extra={
          <React.Fragment>
            <Row gutter={[8, 8]} justify="end" style={{ marginBottom: "16px" }}>
              {review && (
                <React.Fragment>
                  <CheckAbilities placeholder={null} manual={pageAccessControl.userCanDeleteReview}>
                    <Col>
                      <Popconfirm
                        title="Вы уверены?"
                        okText="Да"
                        okButtonProps={{ danger: true }}
                        onConfirm={() => {
                          deleteReview(review.id).then(() =>
                            history.push({
                              pathname: `${ReviewsPage.path}`,
                              state: {
                                canGoBack: true,
                              },
                            })
                          );
                        }}
                        disabled={loading}
                      >
                        <RemoveButton disabled={loading} danger type="primary">
                          Удалить
                        </RemoveButton>
                      </Popconfirm>
                    </Col>
                  </CheckAbilities>
                  <CheckAbilities
                    placeholder={false}
                    manual={pageAccessControl.userCanUpdateReview}
                  >
                    <Col>
                      <EditButton
                        onClick={() =>
                          history.push({
                            pathname: `${ReviewsPage.path}${EditReviewCardPage.pathCreator(id)}`,
                            state: {
                              canGoBack: true,
                            },
                          })
                        }
                        disabled={loading}
                      >
                        Редактировать
                      </EditButton>
                    </Col>
                  </CheckAbilities>
                </React.Fragment>
              )}
              <Col>
                <RefreshButton loading={loading} onClick={() => fetchReview()} />
              </Col>
            </Row>
            {review ? (
              <CheckAbilities
                manual={pageAccessControl.userCanCreateReminder}
                placeholder={null}
                loading={loading}
              >
                <Row gutter={[8, 8]} justify="end">
                  <Col>
                    <AddReminderButton
                      reviews={[review]}
                      serviceOrders={
                        review.reviewServiceOrders
                          ? (review.reviewServiceOrders
                              .map((el) => el.serviceOrder)
                              .filter((el) => el !== undefined) as ServiceOrder[])
                          : undefined
                      }
                      reviewClientCalls={
                        review.reviewClientCalls
                          ? (review.reviewClientCalls.filter(
                              (el) => el !== undefined
                            ) as ReviewClientCall[])
                          : undefined
                      }
                      reviewClientEmails={
                        review.reviewClientEmails
                          ? (review.reviewClientEmails.filter(
                              (el) => el !== undefined
                            ) as ReviewClientEmail[])
                          : undefined
                      }
                      reviewWebLinks={
                        review.reviewWebLinks
                          ? (review.reviewWebLinks.filter(
                              (el) => el !== undefined
                            ) as ReviewWebLink[])
                          : undefined
                      }
                    />
                  </Col>
                </Row>
              </CheckAbilities>
            ) : null}
          </React.Fragment>
        }
      >
        {review && (
          <React.Fragment>
            <Descriptions
              bordered
              column={{ xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              style={{ wordBreak: "normal" }}
            >
              <Descriptions.Item label="Оценка">
                {review.rate ? <Rate value={review.rate} disabled /> : "Без оценки"}
              </Descriptions.Item>
              <Descriptions.Item label="Теги">
                {review.reviewTags?.map((reviewTag) => (
                  <Tag key={`review_${review.id}_reviewTag_${reviewTag.id}`}>
                    {reviewTag.reviewTagType?.name}
                  </Tag>
                ))}
              </Descriptions.Item>
              <Descriptions.Item
                label="Отзыв клиента"
                span={2}
                style={{ wordBreak: "unset", whiteSpace: "pre-wrap" }}
              >
                <div dangerouslySetInnerHTML={{ __html: review.content }} />
              </Descriptions.Item>
              {review.comment && (
                <Descriptions.Item
                  label="Комментарий оператора СКК"
                  span={2}
                  style={{ wordBreak: "unset", whiteSpace: "pre-wrap" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: review.comment }} />
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Клиент" style={{ fontSize: 18 }}>
                {review.clientDisplayName}
              </Descriptions.Item>
              <Descriptions.Item label="Контакты клиента" style={{ fontSize: 18 }}>
                {review.clientPhone && (
                  <div>
                    <PhoneOutlined />
                    <Paragraph
                      style={{
                        display: "inline-block",
                        marginLeft: 8,
                        marginBottom: review.clientEmail ? "1em" : 0,
                      }}
                      copyable
                    >
                      {review.clientPhone}
                    </Paragraph>
                  </div>
                )}
                {review.clientEmail && (
                  <div>
                    <MailOutlined />
                    <Paragraph
                      style={{ display: "inline-block", marginLeft: 8, marginBottom: 0 }}
                      copyable
                    >
                      {review.clientEmail}
                    </Paragraph>
                  </div>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Филиал">{review.branch?.name}</Descriptions.Item>
              <Descriptions.Item label="Направления">
                {review.reviewDestinations
                  ?.map((reviewDestination) => reviewDestination.reviewDestinationType?.name)
                  .join(", ")}
              </Descriptions.Item>
              <Descriptions.Item
                label={<div style={{ padding: "16px 24px" }}>Приложения</div>}
                style={{ margin: 0, padding: 0, wordBreak: "unset" }}
                span={2}
              >
                <ReviewAttachmentCollapse
                  reviewServiceOrders={review?.reviewServiceOrders}
                  reviewWebLinks={review?.reviewWebLinks}
                  reviewClientCalls={review?.reviewClientCalls}
                  reviewClientEmails={review?.reviewClientEmails}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Оператор">
                <div>
                  <div>
                    {review.operatorUser?.surname} {review.operatorUser?.firstName}
                  </div>
                  <div>
                    {review.operatorUser?.phone}{" "}
                    {review.operatorUser?.phoneDescription
                      ? `(${review.operatorUser?.phoneDescription})`
                      : null}
                  </div>
                </div>
              </Descriptions.Item>
            </Descriptions>

            {review && (
              <React.Fragment>
                <Divider orientation="left">
                  <h3>
                    Действия{" "}
                    <InfoTooltip
                      title={
                        <ul>
                          <li>
                            Для отзыва в статусе{" "}
                            <ReviewStatus status={ReviewStatusEnum.PROCESSING} /> можно установить
                            статус <ReviewStatus status={ReviewStatusEnum.READY_TO_CLOSE} />;
                          </li>
                          <li>
                            Для отзыва в статусе{" "}
                            <ReviewStatus status={ReviewStatusEnum.PROCESSING} /> или{" "}
                            <ReviewStatus status={ReviewStatusEnum.READY_TO_CLOSE} /> можно
                            установить статус <ReviewStatus status={ReviewStatusEnum.CLOSED} />.
                          </li>
                        </ul>
                      }
                    />
                  </h3>
                </Divider>
                <Space>
                  <CheckAbilities
                    placeholder={null}
                    manual={
                      pageAccessControl.userCanCreateReview &&
                      review.status === ReviewStatusEnum.PROCESSING
                    }
                  >
                    <Popconfirm
                      title={<React.Fragment>Отправить повторно?</React.Fragment>}
                      okText="Да"
                      onConfirm={() => sendNewNegativeReviewEmailHelper(review.id)}
                      disabled={loading}
                    >
                      <Button disabled={loading} size="large">
                        Отправить email-оповещение ещё раз
                      </Button>
                    </Popconfirm>
                  </CheckAbilities>
                  <CheckAbilities
                    placeholder={null}
                    manual={
                      pageAccessControl.userCanSetReadyToCloseStatusToReview &&
                      review.status === ReviewStatusEnum.PROCESSING
                    }
                  >
                    <Popconfirm
                      title={
                        <React.Fragment>
                          Установить статус{" "}
                          <ReviewStatus status={ReviewStatusEnum.READY_TO_CLOSE} />?
                        </React.Fragment>
                      }
                      okText="Да"
                      onConfirm={handleSetReadyToCloseStatusToReviewClick}
                      disabled={loading}
                    >
                      <Button disabled={loading} size="large">
                        Отзыв готов к закрытию
                      </Button>
                    </Popconfirm>
                  </CheckAbilities>
                  <CheckAbilities
                    placeholder={null}
                    manual={
                      pageAccessControl.userCanSetClosedStatusToReview &&
                      [ReviewStatusEnum.PROCESSING, ReviewStatusEnum.READY_TO_CLOSE].includes(
                        review.status
                      )
                    }
                  >
                    <Popconfirm
                      title={
                        <React.Fragment>
                          Установить статус <ReviewStatus status={ReviewStatusEnum.CLOSED} />?
                        </React.Fragment>
                      }
                      okText="Да"
                      onConfirm={handleSetClosedStatusToReview}
                      disabled={loading}
                    >
                      <Button disabled={loading} size="large">
                        Закрыть отзыв
                      </Button>
                    </Popconfirm>
                  </CheckAbilities>
                </Space>
              </React.Fragment>
            )}

            <Divider orientation="left">
              <h3>
                Комментарии / Изменения статуса{" "}
                <InfoTooltip
                  title={
                    <div>
                      Изменить свой комментарий можно: <MoreOutlined /> {"->"} "Редактировать".
                    </div>
                  }
                />
              </h3>
            </Divider>

            <ReviewChat
              reviewId={review.id}
              items={chatItems}
              userId={user?.id}
              onReviewChatMessageUpdate={handleReviewChatMessageUpdate}
              loading={chatLoading}
              disableReviewChatMessageUpdate={!pageAccessControl.userCanPostComment}
            />

            <RefreshButton loading={loading} onClick={() => fetchReview()} />

            <CheckAbilities placeholder={null} manual={pageAccessControl.userCanPostComment}>
              {review.status !== ReviewStatusEnum.CLOSED && (
                <React.Fragment>
                  <Divider orientation="left">Добавить комментарий</Divider>
                  <Alert
                    type="info"
                    showIcon
                    message={
                      <React.Fragment>
                        <div>
                          Для оповещения оператора о закрытии отзыва необходимо нажать кнопку "Отзыв
                          готов к закрытию".
                        </div>
                        <div>
                          (Эта страница <ArrowRightOutlined /> Действия <ArrowRightOutlined />{" "}
                          Кнопка "Отзыв готов к закрытию")
                        </div>
                      </React.Fragment>
                    }
                    style={{ marginBottom: 16 }}
                  />
                  <ReviewChatMessageForm
                    reviewId={review.id}
                    onSubmit={handleReviewChatMessageFormSubmit}
                    loading={chatLoading}
                  />
                </React.Fragment>
              )}
            </CheckAbilities>
          </React.Fragment>
        )}
        {fetchReviewError && (
          <HttpErrorResult
            error={fetchReviewError}
            subtitlesMap={{
              403: "Для просмотра этого отзыва недостаточно прав.",
              404: "Отзыв не найден.",
            }}
          />
        )}
        {deleteReviewError && <HttpErrorResult error={deleteReviewError} />}
      </PageHeader>
    </ContentLayout>
  );
};

ReviewCardPage.label = "";
ReviewCardPage.path = "/card/:id";
ReviewCardPage.pathCreator = (id: string) => `/card/${id}`;

export default ReviewCardPage;
