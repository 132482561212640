import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";
import { plainToClass } from "class-transformer";
import ApiDriver from "libs/common/api-driver/api-driver";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { RoleMailingUpsertDto } from "./dtos/role-mailing-upsert-dto.type";
import { EmailType } from "./models/email-type";
import { RoleMailing } from "./models/role-mailing";

export class RoleMailingService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(roleId: RoleMailing["roleId"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<RoleMailing> | RoleMailing[]>(
      `/role/${roleId}/role-mailing`,
      { params: queryParams }
    );
    if (Array.isArray(response)) {
      response = plainToClass(RoleMailing, response);
    } else {
      response.data = plainToClass(RoleMailing, response.data);
    }
    return response;
  }

  async findOne(
    roleId: RoleMailing["roleId"],
    id: RoleMailing["id"],
    params: CreateQueryParams = {}
  ) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<RoleMailing>(`/role/${roleId}/role-mailing/${id}`, {
      params: queryParams,
    });
    return plainToClass(RoleMailing, response);
  }

  async createOne(roleId: RoleMailing["roleId"], dto: RoleMailingUpsertDto) {
    const role = await this.apiDriver.post<RoleMailing>(`/role/${roleId}/role-mailing`, dto);
    return plainToClass(RoleMailing, role);
  }

  async updateOne(roleId: RoleMailing["roleId"], id: RoleMailing["id"], dto: RoleMailingUpsertDto) {
    const updated = await this.apiDriver.patch<RoleMailing>(
      `/role/${roleId}/role-mailing/${id}`,
      dto
    );
    return plainToClass(RoleMailing, updated);
  }

  async deleteOne(
    roleId: RoleMailing["roleId"],
    id: RoleMailing["id"],
    params: CreateQueryParams = {}
  ) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<RoleMailing>(`/role/${roleId}/role-mailing/${id}`, {
      params: queryParams,
    });
    return plainToClass(RoleMailing, deleted);
  }

  async getEmailTypes() {
    const response = await this.apiDriver.get<EmailType[]>("/role-mailing/email-types");
    return plainToClass(EmailType, response);
  }

  getRoleMailingTableDataGetter(roleId: RoleMailing["roleId"]): Service<Data, Params> {
    return async (paginatedParams, formData: Record<string, unknown>) => {
      const tableQuerySorter = convertTableSorterToQuerySorter<RoleMailing>(paginatedParams.sorter);

      const roleAbilities = await this.findAll(roleId, {
        page: paginatedParams.current,
        limit: paginatedParams.pageSize,
        sort: tableQuerySorter
          ? [tableQuerySorter, { field: "id", order: "ASC" }]
          : { field: "id", order: "ASC" },
        join: [["role"]],
      });

      if (Array.isArray(roleAbilities)) {
        return {
          list: roleAbilities,
          total: roleAbilities.length,
        };
      } else {
        return {
          list: roleAbilities.data,
          total: roleAbilities.total,
        };
      }
    };
  }
}
