import { Type } from "class-transformer";
import { Review } from "../review.service";
import { PartialBy } from "libs/types/partial-by.type";

export class ReviewClientCall {
  id: string;
  reviewId: string;
  clientPhone: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Review)
  review?: Review;
}

export type ReviewClientCallPartialById = PartialBy<ReviewClientCall, "id">;
