import React from "react";
import classNames from "classnames";
import { Card, Tag } from "antd";
import { Notification } from "services/notification/models/notification";
import { displayDateTime } from "libs/helpers/display-date-time";
import "./NotificationCard.css";

export interface NotificationCardProps {
  data: Notification;
  extra?: React.ReactNode;
}

const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  const { data, extra } = props;

  return (
    <Card
      className={classNames("notification-card", {
        "notification-card__is-not-delievered": !data.isDelivered,
      })}
      id={data.id}
      title={
        <span>
          <span className="notification-card--title-timestamp">
            {displayDateTime(data.notifyAt)}
          </span>
          : {data.title}
          {!data.isDelivered ? (
            <React.Fragment>
              {" "}
              <Tag color="warning">Новое!</Tag>
            </React.Fragment>
          ) : null}
        </span>
      }
      extra={extra}
      bordered
      hoverable
    >
      <div className="notification-card--description">
        <div dangerouslySetInnerHTML={{ __html: data.description || "" }} />
      </div>
    </Card>
  );
};

export default NotificationCard;
