export interface NotificationsState {
  myUnreadNotificationCount: number;
  loading: boolean;
  error?: Error;
}

export enum NotificationsActionType {
  LOAD_MY_UNREAD_NOTIFICATION_COUNT = "LOAD_MY_UNREAD_NOTIFICATION_COUNT",
  LOADED_MY_UNREAD_NOTIFICATION_COUNT = "LOADED_MY_UNREAD_NOTIFICATION_COUNT",
  ERRORED_MY_UNREAD_NOTIFICATION_COUNT = "ERRORED_MY_UNREAD_NOTIFICATION_COUNT",
  CLEAR_NOTIFICATIONS_STATE = "CLEAR_NOTIFICATIONS_STATE",
  SET_MY_UNREAD_NOTIFICATION_COUNT = "SET_MY_UNREAD_NOTIFICATION_COUNT",
}

export interface LoadMyUnreadNotificationCountAction {
  type: NotificationsActionType.LOAD_MY_UNREAD_NOTIFICATION_COUNT;
}

export interface LoadedMyUnreadNotificationCountAction {
  type: NotificationsActionType.LOADED_MY_UNREAD_NOTIFICATION_COUNT;
  payload: number;
}

export interface ErroredMyUnreadNotificationCountAction {
  type: NotificationsActionType.ERRORED_MY_UNREAD_NOTIFICATION_COUNT;
  payload: Error;
}

export interface ClearNotificationsStateAction {
  type: NotificationsActionType.CLEAR_NOTIFICATIONS_STATE;
}

export interface SetMyUnreadNotificationCountAction {
  type: NotificationsActionType.SET_MY_UNREAD_NOTIFICATION_COUNT;
  payload: number;
}

export type NotificationsAction =
  | LoadMyUnreadNotificationCountAction
  | LoadedMyUnreadNotificationCountAction
  | ErroredMyUnreadNotificationCountAction
  | ClearNotificationsStateAction
  | SetMyUnreadNotificationCountAction;

const initialState: NotificationsState = {
  myUnreadNotificationCount: 0,
  loading: false,
};

export default function notificationsReducer(
  state = initialState,
  action: NotificationsAction
): NotificationsState {
  switch (action.type) {
    case NotificationsActionType.LOAD_MY_UNREAD_NOTIFICATION_COUNT:
      return { ...state, loading: true, error: undefined };
    case NotificationsActionType.LOADED_MY_UNREAD_NOTIFICATION_COUNT:
      return {
        ...state,
        myUnreadNotificationCount: action.payload,
        loading: false,
        error: undefined,
      };
    case NotificationsActionType.ERRORED_MY_UNREAD_NOTIFICATION_COUNT:
      return { ...state, error: action.payload, loading: false };
    case NotificationsActionType.CLEAR_NOTIFICATIONS_STATE:
      return initialState;
    case NotificationsActionType.SET_MY_UNREAD_NOTIFICATION_COUNT:
      return {
        ...state,
        myUnreadNotificationCount: action.payload,
        loading: false,
        error: undefined,
      };
    default:
      return state;
  }
}
