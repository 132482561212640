import { reviewTagTypeService } from "services";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";

const fetchReviewTagTypes = async () => {
  const reviewTagTypes = await reviewTagTypeService.findAll({
    sort: [{ field: "name", order: "ASC" }],
  });
  return reviewTagTypes as ReviewTagType[];
};

export default fetchReviewTagTypes;
