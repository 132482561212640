import { plainToClass } from "class-transformer";
import ApiDriver from "libs/common/api-driver/api-driver";
import { Notification } from "./models/notification";

export class NotificationService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async readMyAll(ids: Notification["id"][] = []) {
    const response = await this.apiDriver.post<{ success: boolean }>("/notification/my/read", {
      ids,
    });
    return response.success;
  }

  async findMyOne(id: Notification["id"]) {
    const response = await this.apiDriver.get<Notification>(`/notification/my/${id}`);
    return plainToClass(Notification, response);
  }

  async readMyOne(id: Notification["id"]) {
    const response = await this.apiDriver.post<{ success: boolean }>(`/notification/my/${id}/read`);
    return response.success;
  }

  async findMyAll(pagination: { page?: number; pageSize?: number } = {}) {
    const response = await this.apiDriver.get<{
      items: Notification[];
      page: number;
      pageSize: number;
      count: number;
    }>("/notification/my", { params: pagination });
    response.items = plainToClass(Notification, response.items);
    return response;
  }

  async findMyAllUnread(pagination: { page?: number; pageSize?: number } = {}) {
    const response = await this.apiDriver.get<{
      items: Notification[];
      page: number;
      pageSize: number;
      count: number;
    }>("/notification/my/unread", { params: pagination });
    response.items = plainToClass(Notification, response.items);
    return response;
  }

  async countMyAllUnread() {
    const response = await this.apiDriver.get<{
      count: number;
    }>("/notification/my/unread/count");
    return response.count;
  }
}
