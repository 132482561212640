import React from "react";
import { useAntdTable, useRequest } from "ahooks";
import { Row, Col, PageHeader, Spin, Tag, Popconfirm, message } from "antd";
import { RoleReviewDestination } from "services/role-review-destination/models/role-review-destination";
import { RoleReviewDestinationUpsertDto } from "services/role-review-destination/dtos/role-review-destination-upsert-dto.type";
import { RoleReviewDestinationService } from "services/role-review-destination/role-review-destination.service";
import { roleReviewDestinationService } from "services";
import RoleReviewDestinationTable from "./RoleReviewDestinationTable";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import RoleReviewDestinationForm, {
  RoleReviewDestinationFormProps,
} from "./RoleReviewDestinationForm";
import { ColumnsType } from "antd/es/table";
import RemoveButton from "components/buttons/remove-button";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";

const createRoleReviewDestination: RoleReviewDestinationService["createOne"] = (roleId, dto) => {
  const dtoCopy: RoleReviewDestinationUpsertDto = { ...dto, roleId };
  return roleReviewDestinationService.createOne(roleId, dtoCopy);
};

const deleteRoleReviewDestination: RoleReviewDestinationService["deleteOne"] = (roleId, id) =>
  roleReviewDestinationService.deleteOne(roleId, id);

export interface RoleReviewDestinationRelationProps {
  roleId?: RoleReviewDestination["roleId"];
  loading?: boolean;
  buttonsDisabled?: boolean;
  title?: string;
}

const RoleReviewDestinationRelation: React.FC<RoleReviewDestinationRelationProps> = (props) => {
  const {
    roleId,
    loading: loadingProp = false,
    buttonsDisabled = false,
    title = "Назначение отзывов роли",
  } = props;

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const accessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        update: false,
        delete: false,
      };
    }
    return {
      update: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.ROLE,
        action: subjectAction.ROLE.UPDATE_ALL,
      }),
      delete: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.ROLE,
        action: subjectAction.ROLE.DELETE_ALL,
      }),
    };
  }, [pageAccessControlState]);

  const [addRoleReviewDestination, setAddRoleReviewDestination] = React.useState<boolean>(false);

  const getTableData = React.useMemo(() => {
    if (!roleId) {
      return async () => {
        return {
          list: [],
          total: 0,
        };
      };
    }

    return roleReviewDestinationService.getRoleReviewDestinationTableDataGetter(roleId);
  }, [roleId]);

  const {
    tableProps,
    loading: getTableDataLoading,
    refresh,
    params,
  } = useAntdTable(getTableData, {
    defaultType: "simple",
    cacheKey: `RoleReviewDestinationTable-${roleId}`,
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: {},
      },
    ],
    refreshDeps: [roleId],
  });

  const { sorter = {} } = params[0] || ({} as any);

  const { run: runCreateRoleReviewDestination, loading: createRoleReviewDestinationLoading } =
    useRequest(createRoleReviewDestination, {
      manual: true,
      onSuccess: () => {
        setAddRoleReviewDestination(false);
        refresh();
      },
      onError: (e) => {
        if (e instanceof ApiDriverHttpError) {
          if (e.status === 409) {
            message.error("Такое назначение отзыва роли уже существует!");
          }
        }
      },
    });

  const { run: runDeleteRoleReviewDestination, loading: deleteRoleReviewDestinationLoading } =
    useRequest(deleteRoleReviewDestination, {
      manual: true,
      onSuccess: () => {
        setAddRoleReviewDestination(false);
        refresh();
      },
      onError: () => refresh(),
    });

  const handleRoleReviewDestinationFormFinish: Required<RoleReviewDestinationFormProps>["onFinish"] =
    React.useCallback(
      (dto) => {
        if (roleId) {
          runCreateRoleReviewDestination(roleId, dto);
        }
      },
      [roleId, runCreateRoleReviewDestination]
    );

  const handleDelete = React.useCallback(
    async (roleReviewDestinationId: RoleReviewDestination["id"]) => {
      if (!roleReviewDestinationId || !roleId) {
        return;
      }
      await runDeleteRoleReviewDestination(roleId, roleReviewDestinationId);
    },
    [runDeleteRoleReviewDestination, roleId]
  );

  const roleReviewDestinationTableRightExtra: ColumnsType<RoleReviewDestination> =
    React.useMemo(() => {
      if (!accessControl.delete) {
        return [];
      }

      return [
        {
          title: "",
          dataIndex: "_",
          key: "_",
          ellipsis: true,
          align: "center",
          fixed: "right",
          render: (_: any, record) => (
            <Popconfirm
              title="Вы уверены? Это действие будет нельзя отменить."
              okText="Удалить"
              okButtonProps={{ danger: true }}
              onConfirm={() => handleDelete(record.id)}
            >
              <RemoveButton
                disabled={getTableDataLoading || createRoleReviewDestinationLoading}
                loading={deleteRoleReviewDestinationLoading}
              />
            </Popconfirm>
          ),
        },
      ];
    }, [
      getTableDataLoading,
      createRoleReviewDestinationLoading,
      deleteRoleReviewDestinationLoading,
      handleDelete,
      accessControl,
    ]);

  if (!roleId) {
    return null;
  }

  return (
    <PageHeader
      title={title}
      subTitle={<Tag color="warning">Вносимые изменения вступят в силу в течение 10 минут</Tag>}
      extra={
        <Row gutter={[8, 8]}>
          {accessControl.update ? (
            <Col>
              <AddButton
                disabled={buttonsDisabled}
                danger={addRoleReviewDestination}
                onClick={() => setAddRoleReviewDestination(!addRoleReviewDestination)}
              >
                {addRoleReviewDestination ? "Отмена" : "Добавить"}
              </AddButton>
            </Col>
          ) : null}
          <Col>
            <RefreshButton
              disabled={buttonsDisabled}
              loading={getTableDataLoading}
              onClick={refresh}
            />
          </Col>
        </Row>
      }
    >
      <Spin spinning={loadingProp} delay={500}>
        {addRoleReviewDestination && (
          <div style={{ marginBottom: "24px" }}>
            <Spin
              spinning={createRoleReviewDestinationLoading || deleteRoleReviewDestinationLoading}
            >
              <RoleReviewDestinationForm
                title="Добавить назначение отзывов роли"
                initialValues={{ roleId }}
                disabledFields={{ roleId: true }}
                onFinish={handleRoleReviewDestinationFormFinish}
              />
            </Spin>
          </div>
        )}

        <RoleReviewDestinationTable
          tableProps={tableProps}
          sorter={sorter}
          size="small"
          rightExtra={roleReviewDestinationTableRightExtra}
        />
      </Spin>
    </PageHeader>
  );
};

export default RoleReviewDestinationRelation;
