import React from "react";
import { Select } from "antd";
import { useRequest } from "ahooks";
import { roleAbilityService } from "services";
import { Subject } from "services/role-ability/models/subject";
import { SubjectAction } from "services/role-ability/models/subject-action";

export interface SubjectActionSelectProps {
  subjectId?: Subject["id"];
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  disabled?: boolean;
  placeholder?: string;
}

const fetchSubjectActions = async (subjectId?: Subject["id"]) => {
  if (!subjectId) {
    return [] as SubjectAction[];
  }
  return roleAbilityService.getSubjectActions(subjectId);
};
const SubjectActionSelect: React.FC<SubjectActionSelectProps> = React.forwardRef<
  any,
  SubjectActionSelectProps
>((props, ref) => {
  const { subjectId, ...restProps } = props;

  const { run, data, loading, refresh } = useRequest(fetchSubjectActions, {
    manual: true,
  });

  React.useEffect(() => {
    if (subjectId) {
      run(subjectId);
    } else {
      refresh();
    }
  }, [run, subjectId, refresh]);

  if (!data) {
    return (
      <Select
        ref={ref}
        {...restProps}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children"
      />
    );
  }

  const mapData = (item: SubjectAction) => (
    <Select.Option key={`SubjectActionSelect_${item.id}`} value={item.id}>
      {item.name}
    </Select.Option>
  );

  return (
    <Select
      ref={ref}
      {...restProps}
      loading={loading}
      allowClear
      showSearch
      optionFilterProp="children"
    >
      {data.map(mapData)}
    </Select>
  );
});

export default SubjectActionSelect;
