import React from "react";
import { LinkOutlined } from "@ant-design/icons";

export interface ExternalLinkProps {
  href?: string;
  children?: string;
}

function ExternalLink(props: ExternalLinkProps) {
  const { href, children } = props;

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ wordBreak: "keep-all" }}>
      <LinkOutlined /> <span>{children}</span>
    </a>
  );
}

export default ExternalLink;
