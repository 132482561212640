import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";
import { plainToClass } from "class-transformer";
import ApiDriver from "libs/common/api-driver/api-driver";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { RoleAbilityUpsertDto } from "./dtos/role-ability-upsert-dto.type";
import { RoleAbility } from "./models/role-ability";
import { Subject } from "./models/subject";
import { SubjectAction } from "./models/subject-action";

export class RoleAbilityService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(roleId: RoleAbility["roleId"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<RoleAbility> | RoleAbility[]>(
      `/role/${roleId}/role-ability`,
      { params: queryParams }
    );
    if (Array.isArray(response)) {
      response = plainToClass(RoleAbility, response);
    } else {
      response.data = plainToClass(RoleAbility, response.data);
    }
    return response;
  }

  async findOne(
    roleId: RoleAbility["roleId"],
    id: RoleAbility["id"],
    params: CreateQueryParams = {}
  ) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<RoleAbility>(`/role/${roleId}/role-ability/${id}`, {
      params: queryParams,
    });
    return plainToClass(RoleAbility, response);
  }

  async createOne(roleId: RoleAbility["roleId"], dto: RoleAbilityUpsertDto) {
    const role = await this.apiDriver.post<RoleAbility>(`/role/${roleId}/role-ability`, dto);
    return plainToClass(RoleAbility, role);
  }

  async updateOne(roleId: RoleAbility["roleId"], id: RoleAbility["id"], dto: RoleAbilityUpsertDto) {
    const updated = await this.apiDriver.patch<RoleAbility>(
      `/role/${roleId}/role-ability/${id}`,
      dto
    );
    return plainToClass(RoleAbility, updated);
  }

  async deleteOne(
    roleId: RoleAbility["roleId"],
    id: RoleAbility["id"],
    params: CreateQueryParams = {}
  ) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<RoleAbility>(`/role/${roleId}/role-ability/${id}`, {
      params: queryParams,
    });
    return plainToClass(RoleAbility, deleted);
  }

  async getSubjects() {
    const response = await this.apiDriver.get<Subject[]>("/role-ability/subjects");
    return plainToClass(Subject, response);
  }

  async getSubjectActions(subjectId: string) {
    const response = await this.apiDriver.get<SubjectAction[]>(
      `/role-ability/subject/${subjectId}/actions`
    );
    return plainToClass(Subject, response);
  }

  getRoleAbilityTableDataGetter(roleId: RoleAbility["roleId"]): Service<Data, Params> {
    return async (paginatedParams, formData: Record<string, unknown>) => {
      const tableQuerySorter = convertTableSorterToQuerySorter<RoleAbility>(paginatedParams.sorter);

      const roleAbilities = await this.findAll(roleId, {
        page: paginatedParams.current,
        limit: paginatedParams.pageSize,
        sort: tableQuerySorter
          ? [tableQuerySorter, { field: "id", order: "ASC" }]
          : { field: "id", order: "ASC" },
        join: [["role"]],
      });

      if (Array.isArray(roleAbilities)) {
        return {
          list: roleAbilities,
          total: roleAbilities.length,
        };
      } else {
        return {
          list: roleAbilities.data,
          total: roleAbilities.total,
        };
      }
    };
  }
}
