import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Tag } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { ENV, SITE_LABEL } from "configs/configs";
import usePath from "hooks/use-path";
import AuthPage from "pages/auth/auth.page";
import SignOutPage from "pages/auth/pages/sign-out/sign-out.page";
import IndexPage from "pages/index/index.page";
import ReviewsPage from "pages/reviews/reviews.page";
import ServiceOrdersPage from "pages/service-orders/service-orders.page";
import ClientsPage from "pages/clients/clients.page";
import BranchesPage from "pages/branches/branches.page";
import SettingsPage from "pages/settings/settings.page";
import ProfilePage from "pages/profile/profile.page";
import "./dashboard.layout.css";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { useSettingsPageAccessControl } from "pages/settings/hooks/use-settings-page-access-control";
import RemindersPage from "pages/reminders/reminders.page";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { toggleSideBar } from "store/actions/app-settings";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";

const { SubMenu } = Menu;
const { Header, Footer } = Layout;

interface DashboardLayoutProps {
  content?: React.ReactNode;
}

interface DashboardMenuItem {
  key: string;
  to: { pathname: string };
  label: string;
}

const LOGO_LINK_CLASS =
  ENV === "development"
    ? "dashboard-layout--logo-link dashboard-layout--logo-link__dev"
    : "dashboard-layout--logo-link";

const DashboardLayout: React.FunctionComponent<DashboardLayoutProps> = (props) => {
  const { children, content: contentProp } = props;
  const content = contentProp ? contentProp : children;
  const firstLevelPath = usePath(1);
  const sideBarCollapsed = useAppSelector((state) => state.appSettings.sideBarCollapsed);
  const user = useAppSelector((state) => state.user.user);
  const pageAccessControlState = useAppSelector(pageAccessControlSelector);
  const myUnreadNotificationCount = useAppSelector(
    (state) => state.notifications.myUnreadNotificationCount
  );
  const dispatch = useAppDispatch();

  const pageAccessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        review: false,
        serviceOrder: false,
        client: true,
        branch: true,
        statistics: false,
        reminder: false,
      };
    }

    return {
      review: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.REVIEW,
            action: subjectAction.REVIEW.READ_ALL,
          },
          {
            subject: Subject.REVIEW,
            action: subjectAction.REVIEW.READ_OWN,
          },
        ],
      }),
      serviceOrder: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.SERVICE_ORDER,
        action: subjectAction.SERVICE_ORDER.READ_ALL,
      }),
      client: true,
      branch: true,
      statistics: false,
      reminder: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.REMINDER,
            action: subjectAction.REMINDER.READ_ALL,
          },
          {
            subject: Subject.REMINDER,
            action: subjectAction.REMINDER.READ_OWN,
          },
        ],
      }),
    };
  }, [pageAccessControlState]);

  const settingsPageAccessControl = useSettingsPageAccessControl();

  const showSettings = React.useMemo(() => {
    return Object.values(settingsPageAccessControl).some((e) => e);
  }, [settingsPageAccessControl]);

  const menuItems = React.useMemo(() => {
    const items: DashboardMenuItem[] = [];

    if (pageAccessControl.review) {
      items.push({
        key: ReviewsPage.path,
        to: { pathname: ReviewsPage.path },
        label: ReviewsPage.label,
      });
    }

    if (pageAccessControl.serviceOrder) {
      items.push({
        key: ServiceOrdersPage.path,
        to: { pathname: ServiceOrdersPage.path },
        label: ServiceOrdersPage.label,
      });
    }

    if (pageAccessControl.reminder) {
      items.push({
        key: RemindersPage.path,
        to: { pathname: RemindersPage.path },
        label: RemindersPage.label,
      });
    }

    if (pageAccessControl.client) {
      items.push({
        key: ClientsPage.path,
        to: { pathname: ClientsPage.path },
        label: ClientsPage.label,
      });
    }

    if (pageAccessControl.branch) {
      items.push({
        key: BranchesPage.path,
        to: { pathname: BranchesPage.path },
        label: BranchesPage.label,
      });
    }

    if (pageAccessControl.statistics) {
      //@TODO:
    }

    if (showSettings) {
      items.push({
        key: SettingsPage.path,
        to: { pathname: SettingsPage.path },
        label: SettingsPage.label,
      });
    }

    return items;
  }, [pageAccessControl, showSettings]);

  return (
    <Layout>
      <Header>
        <div className="dashboard-layout--logo">
          <Link className={LOGO_LINK_CLASS} to={{ pathname: IndexPage.path }}>
            {SITE_LABEL}
          </Link>
        </div>
        <Menu
          className="dashboard-layout__header-menu"
          theme="dark"
          mode="horizontal"
          selectedKeys={[firstLevelPath]}
        >
          <Menu.Item key={"toggle-sidebar"} onClick={() => dispatch(toggleSideBar())}>
            {sideBarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Menu.Item>
          <Menu.Item key={"/notifications"}>
            <Link to={"/notifications"}>
              <NotificationOutlined />
              {myUnreadNotificationCount > 0 ? (
                <React.Fragment>
                  {" "}
                  <Tag color="darkred" style={{ borderColor: "red", borderRadius: "10px" }}>
                    {myUnreadNotificationCount}
                  </Tag>
                </React.Fragment>
              ) : null}
            </Link>
          </Menu.Item>
          {menuItems.map((menuItem) => (
            <Menu.Item key={menuItem.key}>
              <Link to={menuItem.to}>{menuItem.label}</Link>
            </Menu.Item>
          ))}
          <SubMenu key={"user"} title={<span>{user?.email}</span>} style={{ marginLeft: "auto" }}>
            <Menu.Item key={ProfilePage.path}>
              <Link to={{ pathname: ProfilePage.path }}>
                <UserOutlined />
                <span>{ProfilePage.label}</span>
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key={`${AuthPage.path}`}>
              <Link to={{ pathname: `${AuthPage.path}${SignOutPage.path}` }}>
                <LogoutOutlined />
                <span>{SignOutPage.label}</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
      <Layout>{content}</Layout>
      <Footer id="dashboard-layout--footer" style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()}, Автокомплекс REAKTOR - Служба контроля качества
      </Footer>
    </Layout>
  );
};

export default DashboardLayout;
