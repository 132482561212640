import { Type, plainToClass } from "class-transformer";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "./interfaces/paginated-response.interface";
import { ReviewServiceOrder } from "./models/review-service-order.model";
import { User } from "./user/models/user";

export class ServiceOrderWork {
  id: string;
  serviceOrderId: string;
  name: string;
  nomenclatureGuid1c: string;
  quantity: number;

  @Type(() => Date)
  createdAt: Date;
}

export class ServiceOrder {
  id: string;
  guid1c: string;
  number: string;
  carGuid1c?: string | null;
  carManufacturer?: string | null;
  carModel?: string | null;
  carManufactureYear?: number | null;
  carMileage?: number | null;
  carVin?: string | null;
  carLicensePlate?: string | null;
  clientGuid1c: string;
  clientDisplayName: string;
  clientPhone: string;
  createdByUserId?: string;

  @Type(() => Date)
  openedAt: Date;

  @Type(() => Date)
  closedAt: Date;

  recommendations?: string;
  branchGuid1c: string;
  branchName: string;

  linkedToReviewTimes: number;
  inBlacklist: boolean;

  @Type(() => Date)
  latestCallAt?: Date;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => ServiceOrderWork)
  serviceOrderWorks?: ServiceOrderWork[];

  @Type(() => ReviewServiceOrder)
  reviewServiceOrders?: ReviewServiceOrder[];

  @Type(() => User)
  createdByUser?: User;
}

export class ServiceOrderService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let serviceOrders = await this.apiDriver.get<PagiantedResponse<ServiceOrder> | ServiceOrder[]>(
      "/service-order",
      { params: queryParams }
    );

    if (Array.isArray(serviceOrders)) {
      serviceOrders = plainToClass(ServiceOrder, serviceOrders);
    } else {
      serviceOrders.data = plainToClass(ServiceOrder, serviceOrders.data);
    }

    return serviceOrders;
  }

  async findOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const serviceOrder = await this.apiDriver.get<ServiceOrder>(`/service-order/${id}`, {
      params: queryParams,
    });

    return plainToClass(ServiceOrder, serviceOrder);
  }
}
