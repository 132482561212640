import { ThunkAction } from "redux-thunk";
import { userService } from "services";
import { UserAbilitiesAction, UserAbilitiesActionType } from "store/reducers/user-abilities";
import { RootState } from "store/store";

export function clearUserAbilities() {
  return { type: UserAbilitiesActionType.CLEAR_USER_ABILITIES };
}

export function loadUserAbilities(): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  UserAbilitiesAction
> {
  return async function loadUserAbilitiesThunk(dispatch) {
    try {
      dispatch({ type: UserAbilitiesActionType.LOAD_USER_ABILITIES });
      const abilities = await userService.getMyAbilities();
      dispatch({
        type: UserAbilitiesActionType.LOADED_USER_ABILITIES,
        payload: { userAbilities: abilities },
      });
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: UserAbilitiesActionType.ERRORED_USER_ABILITIES, payload: { error } });
      }
    }
  };
}
