import useState, { SetState, SetStateAsync } from "hooks/use-state";
import {
  TABLE_DEFAULT_PAGE,
  TABLE_DEFAULT_PAGE_SIZE,
  TABLE_PAGE_SIZE_OPTIONS,
} from "configs/configs";
import { TableState } from "libs/interfaces/table-state.interface";
import { displayTotalRows } from "libs/helpers/display-total-rows";

export function useTableState<RecordType>(
  defaultState: Partial<TableState<RecordType>> = {}
): [
  TableState<RecordType>,
  SetState<TableState<RecordType>>,
  SetStateAsync<TableState<RecordType>>
] {
  const [state, setState, setStateAsync] = useState<TableState<RecordType>>(
    Object.assign(
      {
        data: [],
        loading: false,
        pagination: {
          current: TABLE_DEFAULT_PAGE,
          pageSize: TABLE_DEFAULT_PAGE_SIZE,
          pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        },
        filters: {},
        sorter: [],
      },
      defaultState
    )
  );
  return [state, setState, setStateAsync];
}
