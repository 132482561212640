import React from "react";
import { Select } from "antd";
import { useMount, useRequest } from "ahooks";
import { reviewDestinationTypeService } from "services";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";

const fetchReviewDestinationTypes = () =>
  reviewDestinationTypeService.findAll({
    fields: ["id", "name"],
    sort: [
      { field: "name", order: "ASC" },
      { field: "id", order: "ASC" },
    ],
    limit: 1000,
  });

export interface ReviewDestinationTypeSelectProps {
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string | string[]) => void;
  onClear?: () => void;
  disabled?: boolean;
  mode?: "multiple" | "tags";
}

const ReviewDestinationTypeSelect: React.FC<ReviewDestinationTypeSelectProps> = React.forwardRef<
  any, // :(
  ReviewDestinationTypeSelectProps
>((props, ref) => {
  const { run, data, loading } = useRequest(fetchReviewDestinationTypes, {
    manual: true,
  });

  useMount(() => {
    run();
  });

  if (!data) {
    return (
      <Select
        ref={ref}
        {...props}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children"
      />
    );
  }

  const mapData = (item: ReviewDestinationType) => (
    <Select.Option key={`ReviewDestinationTypeSelect_${item.id}`} value={item.id}>
      {item.name}
    </Select.Option>
  );

  return (
    <Select
      ref={ref}
      {...props}
      loading={loading}
      allowClear
      showSearch
      optionFilterProp="children"
    >
      {Array.isArray(data) ? data.map(mapData) : data.data.map(mapData)}
    </Select>
  );
});

export default ReviewDestinationTypeSelect;
