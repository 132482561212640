import { QueryFilter, QueryFilterArr } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { integrationKeyService } from "services";
import { IntegrationKey } from "services/integration-key/models/integration-key";

export type GetIntegrationKeyTableData = Service<Data, Params>;

export const getIntegrationKeyTableData: GetIntegrationKeyTableData = async (
  paginatedParams,
  formData: Record<string, any>
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<IntegrationKey>(paginatedParams.sorter);

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  const integrationKeyTypes = await integrationKeyService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort: tableQuerySorter
      ? [tableQuerySorter, { field: "id", order: "ASC" }]
      : { field: "id", order: "ASC" },
    filter,
    join: [["createdByUser"], ["belongsToUser"]],
  });

  if (Array.isArray(integrationKeyTypes)) {
    return {
      list: integrationKeyTypes,
      total: integrationKeyTypes.length,
    };
  } else {
    return {
      list: integrationKeyTypes.data,
      total: integrationKeyTypes.total,
    };
  }
};
