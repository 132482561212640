import { reviewService } from "services";

export interface FetchReviewChatMessagesPayload {
  reviewId: string;
  after?: Date;
}

const fetchReviewChatMessages = (payload: FetchReviewChatMessagesPayload) =>
  reviewService.findAllReviewChatMessages(payload.reviewId, {
    search: payload.after
      ? {
          updatedAt: { $gte: payload.after.toISOString() },
        }
      : undefined,
    join: [["user"]],
  });

export default fetchReviewChatMessages;
