import { Type, plainToClass } from "class-transformer";
import { PartialDeep } from "type-fest";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "./interfaces/paginated-response.interface";
import { User } from "./user/models/user";

export class BlacklistClient {
  id: string;
  createdByUserId?: string;
  clientGuid1c?: string;
  clientPhone: string;
  reason?: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => User)
  createdByUser?: User;
}

export class BlacklistClientService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;

    let blacklistClients = await this.apiDriver.get<
      PagiantedResponse<BlacklistClient> | BlacklistClient[]
    >("/blacklist-client", {
      params: queryParams,
    });

    if (Array.isArray(blacklistClients)) {
      blacklistClients = plainToClass(BlacklistClient, blacklistClients);
    } else {
      blacklistClients.data = plainToClass(BlacklistClient, blacklistClients.data);
    }

    return blacklistClients;
  }

  async findOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const review = await this.apiDriver.get<BlacklistClient>(`/blacklist-client/${id}`, {
      params: queryParams,
    });

    return plainToClass(BlacklistClient, review);
  }

  async createMany(dto: PartialDeep<BlacklistClient>[]) {
    const created = await this.apiDriver.post<BlacklistClient[]>("/blacklist-client/bulk", {
      bulk: dto,
    });
    return plainToClass(BlacklistClient, created);
  }

  async createOne(dto: PartialDeep<BlacklistClient>) {
    return this.apiDriver.post<BlacklistClient>("/blacklist-client", dto);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    await this.apiDriver.delete<BlacklistClient>(`/blacklist-client/${id}`, {
      params: queryParams,
    });
  }

  async updateOne(id: string, reviewFields: PartialDeep<BlacklistClient>) {
    const updatedReview = await this.apiDriver.patch<BlacklistClient>(
      `/blacklist-client/${id}`,
      reviewFields
    );
    return updatedReview;
  }
}
