import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";
import { plainToClass } from "class-transformer";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { MyAbility } from "services/user/models/my-ability";
import { MyRole } from "services/user/models/my-role";
import { UserUpsertDto } from "./dtos/user-upsert-dto.type";
import { User } from "./models/user";

export class UserService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<User> | User[]>("/user", {
      params: queryParams,
    });
    if (Array.isArray(response)) {
      response = plainToClass(User, response);
    } else {
      response.data = plainToClass(User, response.data);
    }
    return response;
  }

  async findOne(id: User["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<User>(`/user/${id}`, {
      params: queryParams,
    });
    return plainToClass(User, response);
  }

  async createOne(dto: UserUpsertDto) {
    const user = await this.apiDriver.post<User>("/user", dto);
    return plainToClass(User, user);
  }

  async updateOne(id: string, dto: UserUpsertDto) {
    const updated = await this.apiDriver.patch<User>(`/user/${id}`, dto);
    return plainToClass(User, updated);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<User>(`/user/${id}`, {
      params: queryParams,
    });
    return plainToClass(User, deleted);
  }

  async signIn(username: string, password: string) {
    return this.apiDriver.signIn(username, password);
  }

  async signOut() {
    return this.apiDriver.signOut();
  }

  async restorePassword(email: string) {
    return this.apiDriver.post("/auth/restore-password", { email });
  }

  async resetPassword(restoreToken: string, newPassword: string) {
    return this.apiDriver.post("/auth/reset-password", { restoreToken, newPassword });
  }

  async verifyRestoreToken(restoreToken: string) {
    return this.apiDriver.post<{ maskedEmail: string }>("/auth/verify-restore-token", {
      restoreToken,
    });
  }

  async getMyProfile() {
    const user = await this.apiDriver.get<User>("/user/my-profile");
    return plainToClass(User, user);
  }

  async updateMyPhone() {}

  async changeMyPassword() {}

  async getOperators() {
    const operators = await this.apiDriver.get<User[]>("user/operators");
    return plainToClass(User, operators);
  }

  async getMyRoles() {
    let myRoles = await this.apiDriver.get<MyRole[]>("/user/my-roles");
    return plainToClass(MyRole, myRoles);
  }

  async getMyAbilities() {
    const myAbilities = await this.apiDriver.get<MyAbility[]>("/user/my-abilities");
    return plainToClass(MyAbility, myAbilities);
  }
}
