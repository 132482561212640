import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  PlusOutlined,
  ExclamationOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
  BuildOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { SideNavigationItem } from "libs/interfaces/side-navigation-item.interface";
import usePath from "hooks/use-path";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import SideNavigation from "components/common/side-navigation";
import Breadcrumbs from "components/common/breadcrumbs";
import ContentLayout from "components/layouts/content.layout";
import NotFoundErrorPage from "pages/not-found-error/not-found-error.page";
import AddReviewPage from "./pages/add/add.page";
import AllReviewsPage from "./pages/all/all.page";
import ReviewCardPage from "./pages/card/card.page";
import CreatingReviewsPage from "./pages/creating/creating";
import EditReviewCardPage from "./pages/edit-card/edit-card.page";
import ReviewsFromSitesPage from "./pages/from-sites/from-sites.page";
import ProcessingReviewsPage from "./pages/processing/processing.page";
import ServiceOrderQueueReviewPage from "./pages/queue/queue.page";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";
import CheckAbilities from "store/components/CheckAbilities";

const ReviewsPage: PageFunctionComponent = () => {
  const path = usePath(2);

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);
  const pageAccessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        AddReviewPage: false,
        AllReviewsPage: false,
        ReviewCardPage: false,
        CreatingReviewsPage: false,
        EditReviewCardPage: false,
        ReviewsFromSitesPage: false,
        ProcessingReviewsPage: false,
        ServiceOrderQueueReviewPage: false,
      };
    }

    return {
      AddReviewPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.CREATE,
      }),
      AllReviewsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.READ_ALL },
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.READ_OWN },
        ],
      }),
      ReviewCardPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.READ_ALL },
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.READ_OWN },
        ],
      }),
      CreatingReviewsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.CREATE,
      }),
      EditReviewCardPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.UPDATE_ALL },
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.UPDATE_OWN },
        ],
      }),
      ReviewsFromSitesPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.READ_ALL },
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.READ_OWN },
        ],
      }),
      ProcessingReviewsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.READ_ALL },
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.READ_OWN },
        ],
      }),
      ServiceOrderQueueReviewPage: checkAbilities(pageAccessControlState.abilities, {
        $and: [
          {
            subject: Subject.SERVICE_ORDER,
            action: subjectAction.SERVICE_ORDER.READ_ALL,
          },
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.CREATE },
        ],
      }),
    };
  }, [pageAccessControlState]);

  const sideNavigationItems = React.useMemo(() => {
    const items: SideNavigationItem[] = [];

    if (pageAccessControl.ServiceOrderQueueReviewPage) {
      items.push({
        key: `${ReviewsPage.path}${ServiceOrderQueueReviewPage.path}`,
        pathname: `${ReviewsPage.path}${ServiceOrderQueueReviewPage.path}`,
        icon: <OrderedListOutlined />,
        label: ServiceOrderQueueReviewPage.label,
      });
    }

    if (pageAccessControl.AllReviewsPage) {
      items.push({
        key: `${ReviewsPage.path}${AllReviewsPage.path}`,
        pathname: `${ReviewsPage.path}${AllReviewsPage.path}`,
        icon: <UnorderedListOutlined />,
        label: AllReviewsPage.label,
      });
    }

    if (pageAccessControl.ProcessingReviewsPage) {
      items.push({
        key: `${ReviewsPage.path}${ProcessingReviewsPage.path}`,
        pathname: `${ReviewsPage.path}${ProcessingReviewsPage.path}`,
        icon: <ExclamationOutlined />,
        label: ProcessingReviewsPage.label,
      });
    }

    if (pageAccessControl.ReviewsFromSitesPage) {
      items.push({
        key: `${ReviewsPage.path}${ReviewsFromSitesPage.path}`,
        pathname: `${ReviewsPage.path}${ReviewsFromSitesPage.path}`,
        icon: <LinkOutlined />,
        label: ReviewsFromSitesPage.label,
      });
    }

    if (pageAccessControl.CreatingReviewsPage) {
      items.push({
        key: `${ReviewsPage.path}${CreatingReviewsPage.path}`,
        pathname: `${ReviewsPage.path}${CreatingReviewsPage.path}`,
        icon: <BuildOutlined />,
        label: CreatingReviewsPage.label,
      });
    }

    if (pageAccessControl.AddReviewPage) {
      items.push({
        key: `${ReviewsPage.path}${AddReviewPage.path}`,
        pathname: `${ReviewsPage.path}${AddReviewPage.path}`,
        icon: <PlusOutlined />,
        label: AddReviewPage.label,
      });
    }

    return items;
  }, [pageAccessControl]);

  return (
    <React.Fragment>
      <SideNavigation selectedKey={path} items={sideNavigationItems} />
      <Switch>
        <Route exact path={ReviewsPage.path}>
          <Redirect to={`${ReviewsPage.path}${AllReviewsPage.path}`} />
        </Route>
        <Route path={`${ReviewsPage.path}${EditReviewCardPage.path}`}>
          <CheckAbilities manual={pageAccessControl.EditReviewCardPage}>
            <EditReviewCardPage />
          </CheckAbilities>
        </Route>
        <Route path={`${ReviewsPage.path}${ReviewCardPage.path}`}>
          <CheckAbilities manual={pageAccessControl.ReviewCardPage}>
            <ReviewCardPage />
          </CheckAbilities>
        </Route>
        <Route path={`${ReviewsPage.path}${AddReviewPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ReviewsPage.label, AddReviewPage.label]} />
            <CheckAbilities manual={pageAccessControl.AddReviewPage}>
              <AddReviewPage />
            </CheckAbilities>
          </ContentLayout>
        </Route>
        <Route path={`${ReviewsPage.path}${AllReviewsPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ReviewsPage.label, AllReviewsPage.label]} />
            <CheckAbilities manual={pageAccessControl.AllReviewsPage}>
              <AllReviewsPage />
            </CheckAbilities>
          </ContentLayout>
        </Route>
        <Route path={`${ReviewsPage.path}${CreatingReviewsPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ReviewsPage.label, CreatingReviewsPage.label]} />
            <CheckAbilities manual={pageAccessControl.CreatingReviewsPage}>
              <CreatingReviewsPage />
            </CheckAbilities>
          </ContentLayout>
        </Route>
        <Route path={`${ReviewsPage.path}${ReviewsFromSitesPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ReviewsPage.label, ReviewsFromSitesPage.label]} />
            <CheckAbilities manual={pageAccessControl.ReviewsFromSitesPage}>
              <ReviewsFromSitesPage />
            </CheckAbilities>
          </ContentLayout>
        </Route>
        <Route path={`${ReviewsPage.path}${ProcessingReviewsPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ReviewsPage.label, ProcessingReviewsPage.label]} />
            <CheckAbilities manual={pageAccessControl.ProcessingReviewsPage}>
              <ProcessingReviewsPage />
            </CheckAbilities>
          </ContentLayout>
        </Route>
        <Route path={`${ReviewsPage.path}${ServiceOrderQueueReviewPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ReviewsPage.label, ServiceOrderQueueReviewPage.label]} />
            <CheckAbilities manual={pageAccessControl.ServiceOrderQueueReviewPage}>
              <ServiceOrderQueueReviewPage />
            </CheckAbilities>
          </ContentLayout>
        </Route>
        <Route path={`${ReviewsPage.path}${NotFoundErrorPage.path}`}>
          <NotFoundErrorPage />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

ReviewsPage.path = "/reviews";
ReviewsPage.label = "Отзывы";

export default ReviewsPage;
