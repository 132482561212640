import { QuerySort, QuerySortArr, SCondition } from "@nestjsx/crud-request";
import { Review } from "services/review/review.service";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { reviewService } from "services";

const fetchReviewsWithWebLinks = async (
  page: number,
  limit: number,
  sort?: QuerySort | Array<QuerySort>,
  search: SCondition = {}
) => {
  const validSort: Array<QuerySort | QuerySortArr> = [];

  if (Array.isArray(sort)) {
    validSort.push(...sort);
  } else if (sort) {
    validSort.push(sort);
  }

  validSort.push({ field: "id", order: "DESC" });

  let validSearch;
  if (search.$and) {
    search.$and.push(
      {
        status: { $notin: [ReviewStatusEnum.DRAFT, ReviewStatusEnum.REQUIRE_CHECK] },
      },
      {
        "reviewWebLinks.id": { $notnull: true },
      }
    );
    validSearch = search;
  } else {
    validSearch = Object.assign({}, search, {
      status: { $notin: [ReviewStatusEnum.DRAFT, ReviewStatusEnum.REQUIRE_CHECK] },
      "reviewWebLinks.id": { $notnull: true },
    });
  }

  const reviews = await reviewService.findAll({
    fields: [
      "id",
      "branchId",
      "rate",
      "status",
      "statusUpdatedAt",
      "clientDisplayName",
      "operatorUserId",
      "createdAt",
      "updatedAt",
    ],
    join: [
      ["branch", ["id", "name", "cityId"]],
      ["branch.city", ["id", "name"]],
      ["operatorUser", ["id", "firstName", "surname"]],
      ["reviewDestinations", ["id", "reviewId", "reviewDestinationTypeId"]],
      ["reviewDestinations.reviewDestinationType", ["id", "name"]],
      ["reviewWebLinks", ["id", "link", "reviewSiteId", "reviewId"]],
      ["reviewWebLinks.reviewSite", ["id", "name"]],
    ],
    page,
    limit,
    sort: validSort,
    search: validSearch,
  });

  return reviews as PagiantedResponse<Review>;
};

export default fetchReviewsWithWebLinks;
