import React from "react";
import { PageHeader, Spin, Button, Popconfirm, Row, Col, message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import Breadcrumbs from "components/common/breadcrumbs";
import ContentLayout from "components/layouts/content.layout";
import RefreshButton from "components/buttons/refresh-button";
import { useAppSelector, useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import ReviewsPage from "pages/reviews/reviews.page";
import useAsyncActionState from "hooks/use-async-action-state";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import HttpErrorResult from "components/common/http-error-result";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import fetchReviewHelper from "./helpers/fetch-review";
import fetchReviewDestinationTypes from "../../helpers/fetch-review-destination-types";
import ReviewStatus from "components/common/review-status";
import fetchReviewTagTypes from "pages/reviews/helpers/fetch-review-tag-types";
import fetchBranches from "pages/reviews/helpers/fetch-branches";
import { Branch } from "services/branch/models/branch";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";
import ResetButton from "components/buttons/reset-button";
import ReviewForm, { ReviewFormInstance } from "components/forms/review-form/review-form";
import { Review } from "services/review/review.service";
import updateReview from "./helpers/update-review";
import ReviewCardPage from "../card/card.page";
import { noOp } from "libs/helpers/no-op";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import deleteReviewHelper from "pages/reviews/helpers/delete-review";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { useHttpErrorMessage } from "hooks/use-http-error-message";
import AddToBlacklistModal, { BlacklistItemToAdd } from "./components/add-to-blacklist-modal";
import { ServiceOrder } from "services/service-order.service";
import addToBlacklistHelper from "./helpers/add-to-blacklist";
import { BlacklistClient } from "services/blacklist-client.service";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import AddReminderButton from "components/buttons/AddReminderButton";
import { ReviewClientCall } from "services/review/models/review-client-call.model";
import { ReviewClientEmail } from "services/review/models/review-client-email";
import { ReviewWebLink } from "services/review/models/review-web-link.model";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";
import CheckAbilities from "store/components/CheckAbilities";

const fetchSelectorsData = () =>
  Promise.all([fetchBranches(), fetchReviewDestinationTypes(), fetchReviewTagTypes()]) as Promise<
    [Branch[], ReviewDestinationType[], ReviewTagType[]]
  >;

const EditReviewCardPage: PageFCWithPathCreator = () => {
  const { id = "" } = useParams<{ id?: string }>();
  useSetTitleEffect([ReviewsPage.label, `Редактировать отзыв № ${id}`]);
  const history = useHistory();
  const goBack = useGoBack();

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const [addToBlacklistModalIsVisible, setAddToBlacklistModalVisibility] =
    React.useState<boolean>(false);

  const formRef = React.useRef<ReviewFormInstance>(null);

  const fetchReview = React.useCallback(() => fetchReviewHelper(id), [id]);
  const [
    reviewFetcher,
    { result: review, loading: fetchReviewLoading, error: fetchReviewError },
    resetReviewFetcherState,
  ] = useAsyncActionState(fetchReview);

  const [
    reviewUpdater,
    { loading: updateReviewLoading, error: updateReviewError },
    resetReviewUpdaterState,
  ] = useAsyncActionState(updateReview);

  useHttpErrorMessage(updateReviewError);

  const [deleteReview, { loading: deleteReviewLoading, error: deleteReviewError }] =
    useAsyncActionState(deleteReviewHelper);

  useHttpErrorMessage(deleteReviewError);

  const [
    selectorsDataFetcher,
    {
      result: selectorsData = [
        [] as Branch[],
        [] as ReviewDestinationType[],
        [] as ReviewTagType[],
      ],
      loading: fetchSelectorsDataLoading,
    },
    resetSelectorsDataFetcherState,
  ] = useAsyncActionState(fetchSelectorsData);

  const [addToBlacklist, { loading: addToBlacklistLoading, error: addToBlacklistError }] =
    useAsyncActionState(addToBlacklistHelper);

  React.useEffect(() => {
    if (addToBlacklistError) {
      if (addToBlacklistError instanceof ApiDriverHttpError) {
        if (addToBlacklistError.status === 409) {
          message.error("Один из телефонов клиента уже в чёрном списке.");
        } else {
          message.error(addToBlacklistError.message, 3);
        }
      }
    }
  }, [addToBlacklistError]);

  const loading =
    fetchReviewLoading ||
    fetchSelectorsDataLoading ||
    updateReviewLoading ||
    deleteReviewLoading ||
    addToBlacklistLoading;

  useComponentDidMountEffect(() => {
    reviewFetcher();
    selectorsDataFetcher();
  });

  const handleDidNotGetThroughClick = React.useCallback(() => {
    if (review) {
      deleteReview(review.id).then(({ error }) => {
        if (!error) {
          if (goBack) {
            goBack();
          } else {
            history.push({
              pathname: `${ReviewsPage.path}`,
            });
          }
        }
      });
    }
  }, [deleteReview, review, goBack, history]);

  const handleAddToBlacklist = React.useCallback(
    (items: BlacklistItemToAdd[]) => {
      if (review) {
        addToBlacklist(
          items.map((item) => {
            const blacklistClient = new BlacklistClient();
            blacklistClient.clientPhone = item.clientPhone;
            blacklistClient.clientGuid1c = item.clientGuid1c;
            blacklistClient.reason = item.reason;
            return blacklistClient;
          })
        ).then(({ error }) => {
          if (!error) {
            setAddToBlacklistModalVisibility(false);
            deleteReview(review.id).then(({ error }) => {
              if (!error) {
                history.push({
                  pathname: `${ReviewsPage.path}`,
                });
              }
            });
          }
        });
      }
    },
    [setAddToBlacklistModalVisibility, addToBlacklist, review, deleteReview, history]
  );

  const handleSubmit = React.useCallback(
    (updatedReview: Review) => {
      if (review) {
        const relationsToSave = {
          reviewClientCalls: updatedReview.reviewClientCalls
            ?.filter((item) => !item.id)
            .map((item) => {
              item.reviewId = review.id;
              return item;
            }),
          reviewClientEmails: updatedReview.reviewClientEmails
            ?.filter((item) => !item.id)
            .map((item) => {
              item.reviewId = review.id;
              return item;
            }),
          reviewServiceOrders: updatedReview.reviewServiceOrders
            ?.filter((item) => !item.id)
            .map((item) => {
              item.reviewId = review.id;
              return item;
            }),
          reviewWebLinks: updatedReview.reviewWebLinks
            ?.filter((item) => !item.id)
            .map((item) => {
              item.reviewId = review.id;
              return item;
            }),
        };
        const relationsToDelete = {
          reviewClientCalls: review?.reviewClientCalls?.filter(
            (item) => !updatedReview.reviewClientCalls?.map((rCC) => rCC.id).includes(item.id)
          ),
          reviewClientEmails: review?.reviewClientEmails?.filter(
            (item) => !updatedReview.reviewClientEmails?.map((rCE) => rCE.id).includes(item.id)
          ),
          reviewServiceOrders: review?.reviewServiceOrders?.filter(
            (item) => !updatedReview.reviewServiceOrders?.map((rCE) => rCE.id).includes(item.id)
          ),
          reviewWebLinks: review?.reviewWebLinks?.filter(
            (item) => !updatedReview.reviewWebLinks?.map((rCE) => rCE.id).includes(item.id)
          ),
        };
        reviewUpdater({
          id: review.id,
          review: updatedReview,
          relationsToSave,
          relationsToDelete,
        })
          .then(({ result: updated, error }) => {
            if (!error && updated) {
              if (goBack) {
                goBack();
              } else {
                history.push({
                  pathname: `${ReviewsPage.path}${ReviewCardPage.pathCreator(updated.id)}`,
                });
              }
            }
          })
          .catch(noOp);
      }
    },
    [review, reviewUpdater, history, goBack]
  );

  const serviceOrderClients = React.useMemo(() => {
    if (!review) {
      return [];
    }

    if (!review.reviewServiceOrders) {
      return [];
    }

    return review.reviewServiceOrders
      .map((reviewServiceOrder) => reviewServiceOrder.serviceOrder)
      .reduce((acc, curr) => {
        if (curr) {
          acc.push(curr);
        }
        return acc;
      }, [] as ServiceOrder[]);
  }, [review]);

  const pageAccessControl = React.useMemo(() => {
    if (pageAccessControlState.loading || fetchReviewLoading) {
      return {
        userCanUpdateReview: false,
        userCanCreateReminder: false,
      };
    }

    if (!review) {
      return {
        userCanUpdateReview: false,
        userCanCreateReminder: false,
      };
    }

    if (fetchReviewError) {
      return {
        userCanUpdateReview: false,
        userCanCreateReminder: false,
      };
    }

    const branchId = review?.branchId;
    const reviewDestinationTypeIds = review?.reviewDestinations?.map(
      (reviewDestination) => reviewDestination?.reviewDestinationType?.id
    ) as string[] | undefined;

    const userCanUpdateAllReviews = checkAbilities(pageAccessControlState.abilities, {
      subject: Subject.REVIEW,
      action: subjectAction.REVIEW.UPDATE_ALL,
      branchId,
      reviewDestinationTypeIds,
    });
    const userCanUpdateOwnReviews =
      checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW,
        action: subjectAction.REVIEW.UPDATE_OWN,
        branchId,
        reviewDestinationTypeIds,
      }) && pageAccessControlState.user?.id === review.operatorUserId;

    const userCanCreateReminder = checkAbilities(pageAccessControlState.abilities, {
      subject: Subject.REMINDER,
      action: subjectAction.REMINDER.CREATE,
    });

    return {
      userCanUpdateReview: userCanUpdateAllReviews || userCanUpdateOwnReviews,
      userCanCreateReminder,
    };
  }, [pageAccessControlState, review, fetchReviewLoading, fetchReviewError]);

  if (fetchReviewError) {
    return (
      <ContentLayout>
        <Breadcrumbs items={[ReviewsPage.label, `Редактировать отзыв № ${id}`]} />
        <HttpErrorResult
          error={fetchReviewError}
          subtitlesMap={{
            403: "Для просмотра этого отзыва недостаточно прав.",
            404: "Отзыв не найден.",
          }}
        />
      </ContentLayout>
    );
  }

  if (!pageAccessControl.userCanUpdateReview) {
    return (
      <ContentLayout>
        <Breadcrumbs items={[ReviewsPage.label, `Редактировать отзыв № ${id}`]} />
        <CheckAbilities
          manual={pageAccessControl.userCanUpdateReview}
          loading={loading}
        ></CheckAbilities>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout>
      <Breadcrumbs items={[ReviewsPage.label, `Редактировать отзыв № ${id}`]} />
      <PageHeader
        title="Редактировать отзыв"
        subTitle={
          <span>
            {id} {review && <ReviewStatus status={review.status} />}
          </span>
        }
        onBack={goBack}
        extra={
          <React.Fragment>
            <Row gutter={[8, 8]} justify="end" style={{ marginBottom: "16px" }}>
              {review && (
                <React.Fragment>
                  {review.status === ReviewStatusEnum.DRAFT && (
                    <React.Fragment>
                      <Col>
                        <Button
                          disabled={loading || serviceOrderClients.length === 0}
                          danger
                          type="primary"
                          onClick={() => setAddToBlacklistModalVisibility(true)}
                        >
                          Добавить в черный список
                        </Button>
                      </Col>
                      <Col>
                        <Popconfirm
                          title="Вы уверены?"
                          okText="Удалить"
                          okButtonProps={{ danger: true }}
                          onConfirm={handleDidNotGetThroughClick}
                        >
                          <Button disabled={loading} danger type="primary">
                            Не дозвонились
                          </Button>
                        </Popconfirm>
                      </Col>
                    </React.Fragment>
                  )}
                  <Col>
                    <ResetButton
                      disabled={loading}
                      onClick={() => {
                        resetReviewUpdaterState();
                        formRef.current?.resetForm();
                      }}
                    >
                      Сбросить все изменения
                    </ResetButton>
                  </Col>
                </React.Fragment>
              )}
              <Col>
                <RefreshButton
                  loading={loading}
                  onClick={() => {
                    resetReviewFetcherState();
                    resetReviewUpdaterState();
                    resetSelectorsDataFetcherState();
                    selectorsDataFetcher();
                    reviewFetcher();
                  }}
                />
              </Col>
            </Row>
            {review ? (
              <CheckAbilities
                manual={pageAccessControl.userCanCreateReminder}
                placeholder={null}
                loading={loading}
              >
                <Row gutter={[8, 8]} justify="end">
                  <Col>
                    <AddReminderButton
                      reviews={[review]}
                      serviceOrders={
                        review.reviewServiceOrders
                          ? (review.reviewServiceOrders
                              .map((el) => el.serviceOrder)
                              .filter((el) => el !== undefined) as ServiceOrder[])
                          : undefined
                      }
                      reviewClientCalls={
                        review.reviewClientCalls
                          ? (review.reviewClientCalls.filter(
                              (el) => el !== undefined
                            ) as ReviewClientCall[])
                          : undefined
                      }
                      reviewClientEmails={
                        review.reviewClientEmails
                          ? (review.reviewClientEmails.filter(
                              (el) => el !== undefined
                            ) as ReviewClientEmail[])
                          : undefined
                      }
                      reviewWebLinks={
                        review.reviewWebLinks
                          ? (review.reviewWebLinks.filter(
                              (el) => el !== undefined
                            ) as ReviewWebLink[])
                          : undefined
                      }
                    />
                  </Col>
                </Row>
              </CheckAbilities>
            ) : null}
          </React.Fragment>
        }
      >
        {review && (
          <Spin spinning={loading}>
            <ReviewForm
              ref={formRef}
              review={review}
              branches={selectorsData[0] as Branch[]}
              reviewDestinationTypes={selectorsData[1]}
              reviewTagTypes={selectorsData[2] as ReviewTagType[]}
              onSubmit={handleSubmit}
            />
          </Spin>
        )}
      </PageHeader>
      <AddToBlacklistModal
        visible={addToBlacklistModalIsVisible}
        confirmLoading={addToBlacklistLoading}
        serviceOrderClients={serviceOrderClients}
        onCancel={() => setAddToBlacklistModalVisibility(false)}
        onOk={handleAddToBlacklist}
      />
    </ContentLayout>
  );
};

EditReviewCardPage.label = "";
EditReviewCardPage.path = "/card/:id/edit";
EditReviewCardPage.pathCreator = (id: string) => `/card/${id}/edit`;

export default EditReviewCardPage;
