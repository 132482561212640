import React from "react";
import { useRequest } from "ahooks";
import { message } from "antd";
import ReminderDrawer from "components/drawers/ReminderDrawer";
import { reminderService } from "services";
import { ReminderService } from "services/reminder/reminder.service";
import AddButton from "./add-button";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { Review } from "services/review/review.service";
import { ServiceOrder } from "services/service-order.service";
import { ReviewClientCall } from "services/review/models/review-client-call.model";
import { ReviewClientEmail } from "services/review/models/review-client-email";
import { ReviewWebLink } from "services/review/models/review-web-link.model";
import { ReminderUpsertDto } from "services/reminder/dtos/reminder-upsert-dto.type";

const createReminder: ReminderService["createOne"] = (dto) => reminderService.createOne(dto);

export interface AddReminderButtonProps {
  reviews?: Review[];
  serviceOrders?: ServiceOrder[];
  reviewClientCalls?: ReviewClientCall[];
  reviewClientEmails?: ReviewClientEmail[];
  reviewWebLinks?: ReviewWebLink[];
}

const AddReminderButton: React.FC<AddReminderButtonProps> = (props) => {
  const [isDrawerVisible, setDrawerVisibility] = React.useState(false);

  const { run, loading } = useRequest(createReminder, {
    manual: true,
    onSuccess: (data) => {
      if (data) {
        message.success("Напоминание добавлено!");
      }
    },
    onError: (error) => {
      if (error instanceof ApiDriverHttpError) {
        message.error(`Ошибка, напоминание не добавлено! Статус ${error.status}`);
      } else {
        message.error("Ошибка, напоминание не добавлено!");
      }
    },
  });

  const handleFormFinish = React.useCallback(
    (dto: ReminderUpsertDto) => {
      setDrawerVisibility(false);
      run(dto);
    },
    [run, setDrawerVisibility]
  );

  return (
    <React.Fragment>
      <AddButton onClick={() => setDrawerVisibility(true)} loading={loading}>
        Добавить напоминание
      </AddButton>
      <ReminderDrawer
        {...props}
        visible={isDrawerVisible}
        onClose={() => setDrawerVisibility(false)}
        onFormFinish={handleFormFinish}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default AddReminderButton;
