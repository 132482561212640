import ApiDriver from "libs/common/api-driver/api-driver";
import { SuccessResponse } from "./interfaces/success-response.interface";

export class ManualEmailSendingService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async sendNewNegativeReviewEmail(reviewId: string) {
    const response = await this.apiDriver.post<SuccessResponse>(
      `/manual-email-sending/new-negative-review/${reviewId}`
    );
    return response;
  }
}
