import React from "react";
import { Form, Input, Descriptions, Button, Space, Switch } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import { User } from "services/user/models/user";
import { UserUpsertDto } from "services/user/dtos/user-upsert-dto.type";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";

export type UserFormDto = UserUpsertDto;

export interface UserFormProps {
  form?: FormInstance<UserFormDto>;
  onFinish?: (dto: UserUpsertDto, entityToUpdate?: User) => void;
  entityToUpdate?: User;
  initialValues?: Partial<UserFormDto>;
  disabledFields?: { [key in keyof UserFormDto]?: boolean };
  title?: string;
}

const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };

const UserForm: React.FC<UserFormProps> = (props) => {
  const {
    form: formProp,
    onFinish = noOp,
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Пользователь",
    disabledFields,
  } = props;

  const [form] = Form.useForm(formProp);

  const reset = React.useCallback(() => {
    form.resetFields();
  }, [form]);

  const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = { isActive: true };

    if (entityToUpdate) {
      Object.assign(iV, entityToUpdate);
    }

    if (initialValuesProp) {
      Object.assign(iV, initialValuesProp);
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const handleFinish = React.useCallback(
    (dto: UserFormDto) => {
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="surname"
            name="surname"
            label={<strong>Фамилия</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              { required: true, message: "Это поле должно быть заполнено." },
              {
                type: "string",
                max: 256,
                message: "Максимальный размер строки - 256 символов.",
              },
            ]}
          >
            <Input maxLength={256} disabled={disabledFields?.surname} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="firstName"
            name="firstName"
            label={<strong>Имя</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              { required: true, message: "Это поле должно быть заполнено." },
              {
                type: "string",
                max: 256,
                message: "Максимальный размер строки - 256 символов.",
              },
            ]}
          >
            <Input maxLength={256} disabled={disabledFields?.firstName} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="patronymic"
            name="patronymic"
            label={<strong>Отчество</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              {
                type: "string",
                max: 256,
                message: "Максимальный размер строки - 256 символов.",
              },
            ]}
          >
            <Input maxLength={256} disabled={disabledFields?.patronymic} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="email"
            name="email"
            label={<strong>Email</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              { required: true, message: "Это поле должно быть заполнено." },
              {
                type: "email",
                max: 256,
                message: "Введите валидный email.",
              },
            ]}
          >
            <Input maxLength={256} disabled={disabledFields?.email} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="phone"
            name="phone"
            label={<strong>Телефон</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              { required: true, message: "Это поле должно быть заполнено." },
              {
                type: "string",
                max: 256,
                message: "Максимальный размер строки - 256 символов.",
              },
            ]}
          >
            <Input maxLength={256} disabled={disabledFields?.phone} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="phoneDescription"
            name="phoneDescription"
            label={<strong>Описание телефона</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              {
                type: "string",
                max: 256,
                message: "Максимальный размер строки - 256 символов.",
              },
            ]}
          >
            <Input maxLength={256} disabled={disabledFields?.phoneDescription} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="isActive"
            name="isActive"
            label={<strong>Активен</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Да"
              unCheckedChildren="Нет"
              disabled={disabledFields?.isActive}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата обновления</strong>}>
          {displayDateTime(entityToUpdate?.updatedAt)}
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата создания</strong>}>
          {displayDateTime(entityToUpdate?.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Сохранить
          </Button>
          <Button type="text" size="large" onClick={reset}>
            Сбросить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
