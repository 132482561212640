import React from "react";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import { RoleMailing } from "services/role-mailing/models/role-mailing";

export interface RoleMailingTableProps {
  tableProps: TableProps<RoleMailing>;
  sorter: SorterResult<RoleMailing> | SorterResult<RoleMailing>[];
  size?: SizeType;
  rightExtra?: ColumnsType<RoleMailing>;
}

export interface RoleMailingTableGetColumnsParams {
  sorter: RoleMailingTableProps["sorter"];
  rightExtra?: RoleMailingTableProps["rightExtra"];
}

const getColumns: (params: RoleMailingTableGetColumnsParams) => ColumnsType<RoleMailing> = (
  params
) => {
  const { sorter, rightExtra } = params;

  let createdAtSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    }
  }

  const columns: ColumnsType<RoleMailing> = [
    {
      title: "Тип рассылки",
      dataIndex: "emailType",
      key: "emailType",
      ellipsis: true,
      render: (_: any, record) => {
        if (record.emailTypeLabel) {
          return record.emailTypeLabel;
        }
        return record.emailType;
      },
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
  ];

  if (rightExtra) {
    columns.push(...rightExtra);
  }

  return columns;
};

const RoleMailingTable: React.FC<RoleMailingTableProps> = (props) => {
  const { tableProps, sorter, size, rightExtra } = props;

  const columns = React.useMemo(() => getColumns({ sorter, rightExtra }), [sorter, rightExtra]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default RoleMailingTable;
