import { apiDriver } from "api-driver.instance";
import { BackendCacheService } from "./backend-cache/backend-cache.service";
import { BlacklistClientService } from "services/blacklist-client.service";
import { BranchService } from "services/branch/branch.service";
import { CityService } from "services/city/city.service";
import { IntegrationKeyService } from "services/integration-key/integration-key.service";
import { ManualEmailSendingService } from "services/manual-email-sending-service";
import { ReviewAttachmentService } from "services/review-attachment.service";
import { ReviewDestinationTypeService } from "services/review-destination-type/review-destination-type.service";
import { ReviewService } from "services/review/review.service";
import { ReviewSiteService } from "services/review-site/review-site.service";
import { ReviewStatusViewService } from "services/review-status-view.service";
import { ReviewTagTypeService } from "services/review-tag-type/review-tag-type.service";
import { RoleAbilityService } from "./role-ability/role-ability.service";
import { RoleMailingService } from "./role-mailing/role-mailing.service";
import { RoleReviewDestinationService } from "./role-review-destination/role-review-destination.service";
import { RoleService } from "services/role/role.service";
import { ServiceOrderService } from "services/service-order.service";
import { UserRoleService } from "services/user-role/user-role.service";
import { UserService } from "services/user/user.service";
import { ReminderService } from "./reminder/reminder.service";
import { NotificationService } from "./notification/notification.service";

export const backendCacheService = new BackendCacheService(apiDriver);
export const blacklistClientService = new BlacklistClientService(apiDriver);
export const branchService = new BranchService(apiDriver);
export const cityService = new CityService(apiDriver);
export const integrationKeyService = new IntegrationKeyService(apiDriver);
export const manualEmailSendingService = new ManualEmailSendingService(apiDriver);
export const reviewAttachmentService = new ReviewAttachmentService();
export const reviewDestinationTypeService = new ReviewDestinationTypeService(apiDriver);
export const reviewService = new ReviewService(apiDriver);
export const reviewSiteService = new ReviewSiteService(apiDriver);
export const reviewStatusViewService = new ReviewStatusViewService();
export const reviewTagTypeService = new ReviewTagTypeService(apiDriver);
export const roleAbilityService = new RoleAbilityService(apiDriver);
export const roleMailingService = new RoleMailingService(apiDriver);
export const roleReviewDestinationService = new RoleReviewDestinationService(apiDriver);
export const roleService = new RoleService(apiDriver);
export const serviceOrderService = new ServiceOrderService(apiDriver);
export const userRoleService = new UserRoleService(apiDriver);
export const userService = new UserService(apiDriver);
export const reminderService = new ReminderService(apiDriver);
export const notificationService = new NotificationService(apiDriver);
