import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm, message } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import ReviewDestinationTypeDescription from "components/descriptions/ReviewDestinationTypeDescription";
import { reviewDestinationTypeService } from "services";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import ReviewDestinationTypeForm, {
  ReviewDestinationTypeFormDto,
} from "components/forms/ReviewDestinationTypeForm";
import { ReviewDestinationTypeService } from "services/review-destination-type/review-destination-type.service";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import ReviewDestinationTypesSettingsPage from "../review-destination-types/review-destination-types.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

export interface ReviewDestinationTypeSettingsPageParams {
  id: ReviewDestinationType["id"];
}

const fetchReviewDestinationType = (id: ReviewDestinationType["id"]) =>
  reviewDestinationTypeService.findOne(id);

const updateReviewDestinationType: ReviewDestinationTypeService["updateOne"] = (id, dto) =>
  reviewDestinationTypeService.updateOne(id, dto);

const deleteReviewDestinationType = (id: ReviewDestinationType["id"]) =>
  reviewDestinationTypeService.deleteOne(id);

const ReviewDestinationTypeSettingsPage: PageFCWithPathCreator = () => {
  const { id } = useParams<ReviewDestinationTypeSettingsPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchReviewDestinationType,
    data: reviewDestinationType,
    error: fetchReviewDestinationTypeError,
    loading: fetchReviewDestinationTypeLoading,
    refresh: refreshReviewDestinationType,
    mutate: mutateReviewDestinationType,
  } = useRequest(fetchReviewDestinationType, {
    manual: true,
  });

  const { runAsync: runUpdateReviewDestinationType, loading: updateReviewDestinationTypeLoading } =
    useRequest(updateReviewDestinationType, {
      manual: true,

      onError: (e, params) => {
        const [, dto] = params;
        const { name } = dto;
        if (e instanceof ApiDriverHttpError) {
          if (e.status === 409) {
            message.error(`Назначение отзывов с названием "${name}" уже существует!`);
          }
        }
      },
    });

  const { run: runDeleteReviewDestinationType, loading: deleteReviewDestinationTypeLoading } =
    useRequest(deleteReviewDestinationType, {
      manual: true,
      onSuccess: () => {
        history.push(`${SettingsPage.path}${ReviewDestinationTypesSettingsPage.path}`);
      },
    });

  const [edit, setEdit] = React.useState<boolean>(false);

  useSetTitleEffect([
    SettingsPage.label,
    ReviewDestinationTypeSettingsPage.label,
    reviewDestinationType?.name || id,
  ]);

  useMount(() => {
    if (id) {
      runFetchReviewDestinationType(id);
    }
  });

  const handleUpdate = React.useCallback(
    async (dto: ReviewDestinationTypeFormDto) => {
      if (!reviewDestinationType) {
        return;
      }
      const updated = await runUpdateReviewDestinationType(reviewDestinationType.id, dto);
      mutateReviewDestinationType(updated);
      setEdit(false);
    },
    [reviewDestinationType, runUpdateReviewDestinationType, mutateReviewDestinationType, setEdit]
  );

  const handleDelete = React.useCallback(async () => {
    if (!reviewDestinationType) {
      return;
    }
    await runDeleteReviewDestinationType(reviewDestinationType.id);
    setEdit(false);
    await refreshReviewDestinationType();
  }, [
    reviewDestinationType,
    runDeleteReviewDestinationType,
    setEdit,
    refreshReviewDestinationType,
  ]);

  return (
    <ContentLayout>
      <Breadcrumbs
        items={[
          SettingsPage.label,
          ReviewDestinationTypeSettingsPage.label,
          reviewDestinationType?.name || id,
        ]}
      />
      <PageHeader
        title={ReviewDestinationTypeSettingsPage.label}
        subTitle={reviewDestinationType?.name}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.REVIEW_DESTINATION_TYPE,
                  action: subjectAction.REVIEW_DESTINATION_TYPE.UPDATE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={
                    fetchReviewDestinationTypeLoading ||
                    deleteReviewDestinationTypeLoading ||
                    !reviewDestinationType
                  }
                  loading={updateReviewDestinationTypeLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.REVIEW_DESTINATION_TYPE,
                  action: subjectAction.REVIEW_DESTINATION_TYPE.DELETE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={
                      fetchReviewDestinationTypeLoading ||
                      updateReviewDestinationTypeLoading ||
                      !reviewDestinationType
                    }
                    loading={deleteReviewDestinationTypeLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateReviewDestinationTypeLoading || deleteReviewDestinationTypeLoading}
                loading={fetchReviewDestinationTypeLoading}
                onClick={refreshReviewDestinationType}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchReviewDestinationTypeLoading} delay={500}>
          <HttpErrorResult error={fetchReviewDestinationTypeError} />
          {edit ? (
            <Spin spinning={updateReviewDestinationTypeLoading}>
              <ReviewDestinationTypeForm
                entityToUpdate={reviewDestinationType}
                onFinish={handleUpdate}
                title={""}
              />
            </Spin>
          ) : (
            reviewDestinationType && (
              <ReviewDestinationTypeDescription data={reviewDestinationType} title={""} />
            )
          )}
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

ReviewDestinationTypeSettingsPage.path = "/review-destination-type/:id";
ReviewDestinationTypeSettingsPage.pathCreator = (id) => `/review-destination-type/${id}`;
ReviewDestinationTypeSettingsPage.label = "Назначение отзывов";

export default ReviewDestinationTypeSettingsPage;
