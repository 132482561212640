import { createSelector } from "reselect";
import { RootState } from "../store";

export const pageAccessControlSelector = createSelector(
  (state: RootState) => state.user.user,
  (state: RootState) => state.user.loading,
  (state: RootState) => state.userAbilities.userAbilities,
  (state: RootState) => state.userAbilities.loading,
  (user, userLoading, abilities, abilitiesLoading) => {
    return {
      loading: userLoading || abilitiesLoading,
      abilities,
      user,
    };
  }
);
