import React from "react";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { Reminder } from "services/reminder/models/reminder";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";

export interface ReminderTableProps {
  tableProps: TableProps<Reminder>;
  sorter: SorterResult<Reminder> | SorterResult<Reminder>[];
  size?: SizeType;
  rightExtra?: ColumnsType<Reminder>;
}

export interface ReminderTableGetColumnsParams {
  sorter: ReminderTableProps["sorter"];
  rightExtra?: ReminderTableProps["rightExtra"];
}

const getColumns: (params: ReminderTableGetColumnsParams) => ColumnsType<Reminder> = (params) => {
  const { sorter, rightExtra } = params;
  let titleSortOrder: SortOrder = null;
  let remindAtSortOrder: SortOrder = null;
  let createdAtSortOrder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "title" && sorter.order) {
      titleSortOrder = sorter.order;
    } else if (sorter.field === "remindAt" && sorter.order) {
      remindAtSortOrder = sorter.order;
    } else if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    } else if (sorter.field === "updatedAt" && sorter.order) {
      updatedAtSortOrder = sorter.order;
    }
  }

  const columns: ColumnsType<Reminder> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      align: "center",
      fixed: "left",
    },
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      sorter: true,
      sortOrder: titleSortOrder,
      render: (value: string, record) => record.title,
    },
    {
      title: "Пользователь",
      dataIndex: "userId",
      key: "userId",
      ellipsis: true,
      render: (_: any, record) => record.user?.fullName || record.userId,
    },
    {
      title: "Дата напоминания",
      dataIndex: "remindAt",
      key: "remindAt",
      ellipsis: true,
      sorter: true,
      sortOrder: remindAtSortOrder,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      sorter: true,
      sortOrder: createdAtSortOrder,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      ellipsis: true,
      sorter: true,
      sortOrder: updatedAtSortOrder,
      render: (value: Date) => displayDateTime(value),
    },
  ];

  if (rightExtra) {
    columns.push(...rightExtra);
  }

  return columns;
};

const ReminderTable: React.FC<ReminderTableProps> = (props) => {
  const { tableProps, sorter, size, rightExtra } = props;

  const columns = React.useMemo(() => getColumns({ sorter, rightExtra }), [sorter, rightExtra]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default ReminderTable;
