export function displayDateTime(dateTime?: Date, placeholder: string = ""): string {
  if (dateTime instanceof Date) {
    const day = dateTime.getDate();
    const dayDisplay = day < 10 ? `0${day}` : day;
    const month = dateTime.getMonth() + 1;
    const monthDisplay = month < 10 ? `0${month}` : month;
    const year = dateTime.getFullYear();
    const hour = dateTime.getHours();
    const hourDisplay = hour < 10 ? `0${hour}` : hour;
    const minute = dateTime.getMinutes();
    const minuteDisplay = minute < 10 ? `0${minute}` : minute;
    return `${dayDisplay}.${monthDisplay}.${year} ${hourDisplay}:${minuteDisplay}`;
  }
  return placeholder;
}
