import { CondOperator, QueryFilter, QueryFilterArr } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { ReviewTagTypeTableSimpleFormDto } from "components/tables/ReviewTagTypeTable/ReviewTagTypeTableSimpleForm";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { reviewTagTypeService } from "services";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";

export type GetReviewTagTypeTableData = Service<Data, Params>;

export const getReviewTagTypeTableData: GetReviewTagTypeTableData = async (
  paginatedParams,
  formData: ReviewTagTypeTableSimpleFormDto
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<ReviewTagType>(paginatedParams.sorter);

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  if (formData.name) {
    filter.push({
      field: "name",
      operator: CondOperator.CONTAINS_LOW,
      value: formData.name.trim(),
    });
  }

  const reviewTagTypes = await reviewTagTypeService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort: tableQuerySorter
      ? [tableQuerySorter, { field: "id", order: "ASC" }]
      : { field: "id", order: "ASC" },
    filter,
  });

  if (Array.isArray(reviewTagTypes)) {
    return {
      list: reviewTagTypes,
      total: reviewTagTypes.length,
    };
  } else {
    return {
      list: reviewTagTypes.data,
      total: reviewTagTypes.total,
    };
  }
};
