import { reviewStatusViewService } from "services";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";

const getReviewStatusViews = () => {
  const reviewStatusViews = reviewStatusViewService
    .findAll()
    .filter(
      (reviewStatusView) =>
        reviewStatusView.id !== ReviewStatusEnum.DRAFT &&
        reviewStatusView.id !== ReviewStatusEnum.REQUIRE_CHECK
    );
  return reviewStatusViews;
};

export default getReviewStatusViews;
