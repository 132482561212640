import React from "react";
import { Form, Input, Button } from "antd";
import { Store } from "antd/lib/form/interface";
import { FormInstance } from "antd/lib/form";
import { ReviewClientCall } from "services/review/models/review-client-call.model";
import { noOp } from "libs/helpers/no-op";

export interface ReviewClientCallFormProps {
  form?: FormInstance;
  onFinish?: (reviewClientCall: ReviewClientCall) => void;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const ReviewClientCallForm: React.FC<ReviewClientCallFormProps> = (props) => {
  const { form, onFinish = noOp } = props;

  const handleFinish = React.useCallback(
    (values: Store) => {
      const reviewClientCall = new ReviewClientCall();
      reviewClientCall.clientPhone = values.clientPhone;
      onFinish(reviewClientCall);
    },
    [onFinish]
  );

  return (
    <Form form={form} {...layout} onFinish={handleFinish}>
      <Form.Item
        label="Телефон клиента"
        name="clientPhone"
        rules={[
          {
            required: true,
            message: "Это поле должно быть заполнено (или заполните email-адрес клиента)",
          },
          {
            type: "integer",
            transform: (value) => Number(value),
            message: "Это поле должно содержать только цифры",
          },
          {
            type: "string",
            transform: (value) => String(value),
            max: 11,
            min: 3,
            message: "Номер телефона - 3-11 цифр",
          },
        ]}
        validateTrigger={"onBlur"}
      >
        <Input />
      </Form.Item>
      {!form && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default ReviewClientCallForm;
