import { Button, notification as antdNotification } from "antd";
import { deserialize } from "class-transformer";
import { BACKEND_BASE_URL } from "configs/configs";
import { notificationService } from "services";
import { Notification } from "services/notification/models/notification";
import { loadMyUnreadNotificationCount } from "store/actions/notifications";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useSSE } from "./use-sse";

export function useNotificationsSSE() {
  const accessToken = useAppSelector((state) => state.tokenPair.accessToken);
  const dispatch = useAppDispatch();

  useSSE(`${BACKEND_BASE_URL}/notification/my/sse/subscribe`, {
    onOpen: () => {
      dispatch(loadMyUnreadNotificationCount());
    },
    onMessage: (event) => {
      dispatch(loadMyUnreadNotificationCount());

      const { data } = event;
      const notification = deserialize(Notification, data);

      function handleBtnClick() {
        antdNotification.close(notification.id);
        notificationService.readMyOne(notification.id).then((success) => {
          if (success) {
            return dispatch(loadMyUnreadNotificationCount());
          }
        });
      }

      antdNotification.open({
        key: notification.id,
        message: notification.title,
        description: <div dangerouslySetInnerHTML={{ __html: notification.description || "" }} />,
        duration: 10,
        btn: (
          <Button type="primary" onClick={handleBtnClick}>
            Прочитано
          </Button>
        ),
      });
    },
    onError: (event) => {
      console.log(event);
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    refreshDeps: [accessToken],
  });
}
