import React from "react";
import { Form, Input } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { noOp } from "libs/helpers/no-op";

export interface ReviewDestinationTypeTableSimpleFormDto {
  name?: string;
}

export interface ReviewDestinationTypeTableSimpleFormProps {
  form?: FormInstance<ReviewDestinationTypeTableSimpleFormDto>;
  submit?: () => void;
  reset?: () => void;
}

const ReviewDestinationTypeTableSimpleForm: React.FC<ReviewDestinationTypeTableSimpleFormProps> = (
  props
) => {
  const { form: formProp, submit = noOp /*, reset = noOp */ } = props;

  const [form] = Form.useForm(formProp);

  return (
    <Form layout="inline" form={form}>
      <Form.Item name="name" label="Название">
        <Input.Search onSearch={submit} allowClear />
      </Form.Item>
    </Form>
  );
};

export default ReviewDestinationTypeTableSimpleForm;
