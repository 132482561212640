import React from "react";
import { Select } from "antd";
import { useMount, useRequest } from "ahooks";
import { QueryFilter } from "@nestjsx/crud-request";
import { userService } from "services";
import { User } from "services/user/models/user";

const fetchUsers = (onlyActiveUsers: boolean) => {
  const filter: QueryFilter[] = [];

  if (onlyActiveUsers) {
    filter.push({ field: "isActive", operator: "$eq", value: true });
  }

  return userService.findAll({
    filter,
    fields: ["id", "firstName", "patronymic", "surname"],
    sort: [
      { field: "surname", order: "ASC" },
      { field: "firstName", order: "ASC" },
      { field: "patronymic", order: "ASC" },
      { field: "id", order: "ASC" },
    ],
    limit: 1000,
  });
};

export interface UserSelectProps {
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  disabled?: boolean;
  onlyActiveUsers?: boolean;
}

const UserSelect: React.FC<UserSelectProps> = React.forwardRef<
  any, // :(
  UserSelectProps
>((props, ref) => {
  const { onlyActiveUsers = false, ...restProps } = props;

  const { run, data, loading } = useRequest(fetchUsers, {
    manual: true,
  });

  useMount(() => {
    run(onlyActiveUsers);
  });

  if (!data) {
    return (
      <Select
        ref={ref}
        {...restProps}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children"
      />
    );
  }

  const mapData = (item: User) => (
    <Select.Option key={`UserSelect_${item.id}`} value={item.id}>
      {item.fullName}
    </Select.Option>
  );

  return (
    <Select
      ref={ref}
      {...restProps}
      loading={loading}
      allowClear
      showSearch
      optionFilterProp="children"
    >
      {Array.isArray(data) ? data.map(mapData) : data.data.map(mapData)}
    </Select>
  );
});

export default UserSelect;
