import React from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "ahooks";
import { message, PageHeader, Spin } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import BranchForm from "components/forms/BranchForm";
import { branchService } from "services";
import { BranchService } from "services/branch/branch.service";
import SettingsPage from "pages/settings/settings.page";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import BranchSettingsPage from "../branch/branch.page";

const createBranch: BranchService["createOne"] = (dto) => branchService.createOne(dto);

const AddBranchSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, AddBranchSettingsPage.label]);

  const goBack = useGoBack();

  const history = useHistory();

  const { run, loading } = useRequest(createBranch, {
    manual: true,
    onSuccess: (data) => {
      if (data) {
        history.push(`${SettingsPage.path}${BranchSettingsPage.pathCreator(data.id)}`, {
          canGoBack: true,
        });
      }
    },

    onError: (e, params) => {
      const [dto] = params;
      const { name } = dto;
      if (e instanceof ApiDriverHttpError) {
        if (e.status === 409) {
          message.error(`Филиал с названием "${name}" уже существует!`);
        }
      }
    },
  });

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, AddBranchSettingsPage.label]} />
      <PageHeader title={AddBranchSettingsPage.label} onBack={goBack}>
        <Spin spinning={loading}>
          <BranchForm title="" onFinish={run} />
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

AddBranchSettingsPage.path = "/add-branch";
AddBranchSettingsPage.label = "Добавить филиал";

export default AddBranchSettingsPage;
