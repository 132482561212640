import { QuerySort, QuerySortArr, SCondition } from "@nestjsx/crud-request";
import { Review } from "services/review/review.service";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { reviewService } from "services";

const fetchProcessingReviews = async (
  page: number,
  limit: number,
  sort?: QuerySort | Array<QuerySort>,
  search: SCondition = {}
) => {
  const validSort: Array<QuerySort | QuerySortArr> = [];

  if (Array.isArray(sort)) {
    validSort.push(...sort);
  } else if (sort) {
    validSort.push(sort);
  }

  validSort.push({ field: "id", order: "DESC" });

  let validSearch;
  if (search.$and) {
    search.$and.push({
      status: { $in: [ReviewStatusEnum.PROCESSING, ReviewStatusEnum.READY_TO_CLOSE] },
    });
    validSearch = search;
  } else {
    validSearch = Object.assign({}, search, {
      status: { $in: [ReviewStatusEnum.PROCESSING, ReviewStatusEnum.READY_TO_CLOSE] },
    });
  }

  const serviceOrders = await reviewService.findAll({
    fields: [
      "id",
      "branchId",
      "rate",
      "status",
      "operatorUserId",
      "clientDisplayName",
      "clientPhone",
      "clientEmail",
      "createdAt",
      "updatedAt",
    ],
    join: [
      ["branch", ["id", "name", "cityId"]],
      ["branch.city", ["id", "name"]],
      ["operatorUser", ["id", "firstName", "surname"]],
      ["reviewDestinations", ["id", "reviewId", "reviewDestinationTypeId"]],
      ["reviewDestinations.reviewDestinationType", ["id", "name"]],
    ],
    page,
    limit,
    sort: validSort,
    search: validSearch,
  });

  return serviceOrders as PagiantedResponse<Review>;
};

export default fetchProcessingReviews;
