import React from "react";
import moment from "moment";
import { Form, Input, Descriptions, Button, Space, DatePicker } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import { Reminder } from "services/reminder/models/reminder";
import { ReminderUpsertDto } from "services/reminder/dtos/reminder-upsert-dto.type";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";
import AntdFormReactQuillInput from "components/common/antd-form-react-quill-input";

export type ReminderFormDto = ReminderUpsertDto;

export interface ReminderFormProps {
  form?: FormInstance<ReminderFormDto>;
  onFinish?: (dto: ReminderUpsertDto, entityToUpdate?: Reminder) => void;
  entityToUpdate?: Reminder;
  initialValues?: Partial<ReminderFormDto>;
  disabledFields?: { [key in keyof ReminderFormDto]?: boolean };
  title?: string;
}

const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };

const ReminderForm: React.FC<ReminderFormProps> = (props) => {
  const {
    form: formProp,
    onFinish = noOp,
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Напоминание",
    disabledFields,
  } = props;

  const [form] = Form.useForm(formProp);

  const reset = React.useCallback(() => {
    form.resetFields();
  }, [form]);

  const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = {};

    if (entityToUpdate) {
      const { remindAt, ...rest } = entityToUpdate;
      Object.assign(iV, { ...rest });
      if (remindAt instanceof Date) {
        Object.assign(iV, { remindAt: moment(remindAt) });
      }
    }

    if (initialValuesProp) {
      const { remindAt, ...rest } = initialValuesProp;
      Object.assign(iV, { ...rest });
      if (remindAt instanceof Date) {
        Object.assign(iV, { remindAt: moment(remindAt) });
      }
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const handleFinish = React.useCallback(
    (dto: ReminderFormDto) => {
      dto.remindAt = moment(dto.remindAt).toDate();
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="title"
            name="title"
            label={<strong>Заголовок</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              { required: true, message: "Это поле должно быть заполнено." },
              {
                type: "string",
                max: 1024,
                message: "Максимальный размер строки - 1024 символа.",
              },
            ]}
          >
            <Input maxLength={1024} disabled={disabledFields?.title} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="description"
            name="description"
            label={<strong>Описание</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              {
                type: "string",
                max: 12288,
                message: "Максимальный размер строки - 12288 символов.",
              },
            ]}
          >
            <AntdFormReactQuillInput readOnly={disabledFields?.description} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="remindAt"
            name="remindAt"
            label={<strong>Дата напоминания</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <DatePicker showTime disabled={disabledFields?.remindAt} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата обновления</strong>}>
          {displayDateTime(entityToUpdate?.updatedAt)}
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата создания</strong>}>
          {displayDateTime(entityToUpdate?.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Сохранить
          </Button>
          <Button type="text" size="large" onClick={reset}>
            Сбросить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ReminderForm;
