import React from "react";
import { PageHeader, Table, message, Alert } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { ServiceOrder } from "services/service-order.service";
import { useAppDispatch, useAppSelector, useSetTitleEffect } from "store/hooks";
import useAsyncActionState from "hooks/use-async-action-state";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import RefreshButton from "components/buttons/refresh-button";
import ResetButton from "components/buttons/reset-button";
import ServiceOrdersPage from "pages/service-orders/service-orders.page";
import { DEFAULT_TABLE_SORTER } from "pages/service-orders/service-orders.constants";
import getColumns from "./helpers/get-columns";
import fetchBranches from "./helpers/fetch-branches";
import { useRefreshServiceOrders } from "../../hooks/use-refresh-service-orders";
import { useTableStateCleaningMarkers } from "hooks/use-table-state-cleaning-markers";
import fetchServiceOrders from "./helpers/fetch-service-orders";
import createReviewWithServiceOrder from "../../helpers/create-review-with-service-order";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { useHistory } from "react-router-dom";
import ReviewsPage from "pages/reviews/reviews.page";
import EditReviewCardPage from "pages/reviews/pages/edit-card/edit-card.page";
import { useTableState } from "hooks/use-table-state";
import { setServiceOrdersWithoutReview } from "store/actions/latest-table-states";

export interface ServiceOrdersWithoutReviewPageProps {
  title?: string;
}

const ServiceOrdersWithoutReviewPage: PageFunctionComponent<ServiceOrdersWithoutReviewPageProps> = (
  props
) => {
  const { title = ServiceOrdersWithoutReviewPage.label } = props;
  useSetTitleEffect([ServiceOrdersPage.label, title]);
  const history = useHistory();

  const serviceOrdersWithoutReviewTableState = useAppSelector(
    (state) => state.latestTableStates.serviceOrdersWithoutReviewTableState
  );
  const dispatch = useAppDispatch();

  const [state, , setStateAsync] = useTableState<ServiceOrder>(
    serviceOrdersWithoutReviewTableState
      ? serviceOrdersWithoutReviewTableState
      : { sorter: DEFAULT_TABLE_SORTER }
  );
  const refreshServiceOrders = useRefreshServiceOrders(
    state.pagination,
    setStateAsync,
    fetchServiceOrders
  );

  useComponentDidMountEffect(() => {
    refreshServiceOrders(state.pagination, state.filters, state.sorter);
    findAllBranches();
  });

  const [findAllBranches, { loading: branchesLoading, result: branches = [] }] =
    useAsyncActionState(fetchBranches);

  const [createReview, { loading: createReviewLoading, error: createReviewError }] =
    useAsyncActionState(createReviewWithServiceOrder);

  const loading = state.loading || branchesLoading || createReviewLoading;

  React.useEffect(() => {
    if (createReviewError instanceof ApiDriverHttpError) {
      let content = "Ошибка!";
      if (createReviewError.status === 409) {
        content =
          "Отзыв на этот заказ-наряд уже создан или клиент был добавлен в чёрный список. Попробуйте выбрать другой заказ-наряд.";
      } else if (createReviewError.status >= 500) {
        content = "Ошибка сервера.";
      }

      message.error(content);
    }
  }, [createReviewError]);

  React.useEffect(() => {
    dispatch(setServiceOrdersWithoutReview(state));
  }, [dispatch, state]);

  const handleTableChange = React.useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ServiceOrder> | SorterResult<ServiceOrder>[]
    ) => {
      let validSorter = sorter;
      if (!Array.isArray(sorter)) {
        validSorter = sorter.order ? sorter : DEFAULT_TABLE_SORTER;
      }
      refreshServiceOrders(pagination, filters, validSorter);
    },
    [refreshServiceOrders]
  );

  const handleAddReview = React.useCallback(
    (serviceOrderId: string) => {
      createReview({ serviceOrderId }).then(({ result: id, error }) => {
        if (error) {
          refreshServiceOrders(state.pagination, state.filters, state.sorter);
        } else if (id) {
          history.push({
            pathname: `${ReviewsPage.path}${EditReviewCardPage.pathCreator(id)}`,
            state: {
              canGoBack: true,
            },
          });
        }
      });
    },
    [createReview, history, refreshServiceOrders, state.pagination, state.filters, state.sorter]
  );

  const columns = React.useMemo(
    () => getColumns(branches, state, handleAddReview),
    [branches, state, handleAddReview]
  );

  const { canClearSorter, canClearFilters } = useTableStateCleaningMarkers(
    state,
    DEFAULT_TABLE_SORTER
  );

  const clearSorter = React.useCallback(() => {
    refreshServiceOrders(state.pagination, state.filters, DEFAULT_TABLE_SORTER);
  }, [refreshServiceOrders, state]);

  const clearFilters = React.useCallback(() => {
    refreshServiceOrders(state.pagination, {}, state.sorter);
  }, [refreshServiceOrders, state]);

  return (
    <PageHeader
      title={title}
      extra={
        <React.Fragment>
          <ResetButton onClick={clearFilters} disabled={!canClearFilters || loading}>
            Сбросить фильтры
          </ResetButton>
          <ResetButton onClick={clearSorter} disabled={!canClearSorter || loading}>
            Сбросить сортировки
          </ResetButton>
          <RefreshButton
            onClick={() => refreshServiceOrders(state.pagination, state.filters, state.sorter)}
            loading={loading}
          />
        </React.Fragment>
      }
    >
      <Alert
        message={
          <div>
            <span>Список заказ-нарядов:</span>
            <ul>
              <li>у которых нет отзывов;</li>
              <li>клиенты которых не находятся в чёрном списке;</li>
              <li>клиентам которых сегодня не звонили.</li>
            </ul>
          </div>
        }
        type="info"
        showIcon
      />
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={{ spinning: loading, delay: 500 }}
        onChange={handleTableChange}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </PageHeader>
  );
};

ServiceOrdersWithoutReviewPage.path = "/without-review";
ServiceOrdersWithoutReviewPage.label = "Заказ-наряды без отзыва";

export default ServiceOrdersWithoutReviewPage;
