import React from "react";
import { useAntdTable, useRequest } from "ahooks";
import { Row, Col, PageHeader, Spin, Tag, Popconfirm, message } from "antd";
import { RoleAbility } from "services/role-ability/models/role-ability";
import { RoleAbilityUpsertDto } from "services/role-ability/dtos/role-ability-upsert-dto.type";
import { RoleAbilityService } from "services/role-ability/role-ability.service";
import { roleAbilityService } from "services";
import RoleAbilityTable from "./RoleAbilityTable";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import RoleAbilityForm, { RoleAbilityFormProps } from "./RoleAbilityForm";
import { ColumnsType } from "antd/es/table";
import RemoveButton from "components/buttons/remove-button";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";

const createRoleAbility: RoleAbilityService["createOne"] = (roleId, dto) => {
  const dtoCopy: RoleAbilityUpsertDto = { ...dto, roleId };
  return roleAbilityService.createOne(roleId, dtoCopy);
};

const deleteRoleAbility: RoleAbilityService["deleteOne"] = (roleId, id) =>
  roleAbilityService.deleteOne(roleId, id);

export interface RoleAbilityRelationProps {
  roleId?: RoleAbility["roleId"];
  loading?: boolean;
  buttonsDisabled?: boolean;
  title?: string;
}

const RoleAbilityRelation: React.FC<RoleAbilityRelationProps> = (props) => {
  const {
    roleId,
    loading: loadingProp = false,
    buttonsDisabled = false,
    title = "Права роли",
  } = props;

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const accessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        update: false,
        delete: false,
      };
    }
    return {
      update: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.ROLE,
        action: subjectAction.ROLE.UPDATE_ALL,
      }),
      delete: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.ROLE,
        action: subjectAction.ROLE.DELETE_ALL,
      }),
    };
  }, [pageAccessControlState]);

  const [addRoleAbility, setAddRoleAbility] = React.useState<boolean>(false);

  const getTableData = React.useMemo(() => {
    if (!roleId) {
      return async () => {
        return {
          list: [],
          total: 0,
        };
      };
    }

    return roleAbilityService.getRoleAbilityTableDataGetter(roleId);
  }, [roleId]);

  const {
    tableProps,
    loading: getTableDataLoading,
    refresh,
    params,
  } = useAntdTable(getTableData, {
    defaultType: "simple",
    cacheKey: `RoleAbilityTable-${roleId}`,
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: {},
      },
    ],
    refreshDeps: [roleId],
  });

  const { sorter = {} } = params[0] || ({} as any);

  const { run: runCreateRoleAbility, loading: createRoleAbilityLoading } = useRequest(
    createRoleAbility,
    {
      manual: true,
      onSuccess: () => {
        setAddRoleAbility(false);
        refresh();
      },
      onError: (e) => {
        if (e instanceof ApiDriverHttpError) {
          if (e.status === 409) {
            message.error("Такое право роли уже существует!");
          }
        }
      },
    }
  );

  const { run: runDeleteRoleAbility, loading: deleteRoleAbilityLoading } = useRequest(
    deleteRoleAbility,
    {
      manual: true,
      onSuccess: () => {
        setAddRoleAbility(false);
        refresh();
      },
      onError: () => refresh(),
    }
  );

  const handleRoleAbilityFormFinish: Required<RoleAbilityFormProps>["onFinish"] = React.useCallback(
    (dto) => {
      if (roleId) {
        runCreateRoleAbility(roleId, dto);
      }
    },
    [roleId, runCreateRoleAbility]
  );

  const handleDelete = React.useCallback(
    async (roleAbilityId: RoleAbility["id"]) => {
      if (!roleAbilityId || !roleId) {
        return;
      }
      await runDeleteRoleAbility(roleId, roleAbilityId);
    },
    [runDeleteRoleAbility, roleId]
  );

  const roleAbilityTableRightExtra: ColumnsType<RoleAbility> = React.useMemo(() => {
    if (!accessControl.delete) {
      return [];
    }

    return [
      {
        title: "",
        dataIndex: "_",
        key: "_",
        ellipsis: true,
        align: "center",
        fixed: "right",
        render: (_: any, record) => (
          <Popconfirm
            title="Вы уверены? Это действие будет нельзя отменить."
            okText="Удалить"
            okButtonProps={{ danger: true }}
            onConfirm={() => handleDelete(record.id)}
          >
            <RemoveButton
              disabled={getTableDataLoading || createRoleAbilityLoading}
              loading={deleteRoleAbilityLoading}
            />
          </Popconfirm>
        ),
      },
    ];
  }, [
    getTableDataLoading,
    createRoleAbilityLoading,
    deleteRoleAbilityLoading,
    handleDelete,
    accessControl,
  ]);

  if (!roleId) {
    return null;
  }

  return (
    <PageHeader
      title={title}
      subTitle={<Tag color="warning">Вносимые изменения вступят в силу в течение 10 минут</Tag>}
      extra={
        <Row gutter={[8, 8]}>
          {accessControl.update ? (
            <Col>
              <AddButton
                disabled={buttonsDisabled}
                danger={addRoleAbility}
                onClick={() => setAddRoleAbility(!addRoleAbility)}
              >
                {addRoleAbility ? "Отмена" : "Добавить"}
              </AddButton>
            </Col>
          ) : null}
          <Col>
            <RefreshButton
              disabled={buttonsDisabled}
              loading={getTableDataLoading}
              onClick={refresh}
            />
          </Col>
        </Row>
      }
    >
      <Spin spinning={loadingProp} delay={500}>
        {addRoleAbility && (
          <div style={{ marginBottom: "24px" }}>
            <Spin spinning={createRoleAbilityLoading || deleteRoleAbilityLoading}>
              <RoleAbilityForm
                title="Добавить право роли"
                initialValues={{ roleId }}
                disabledFields={{ roleId: true }}
                onFinish={handleRoleAbilityFormFinish}
              />
            </Spin>
          </div>
        )}

        <RoleAbilityTable
          tableProps={tableProps}
          sorter={sorter}
          size="small"
          rightExtra={roleAbilityTableRightExtra}
        />
      </Spin>
    </PageHeader>
  );
};

export default RoleAbilityRelation;
