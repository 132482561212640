import React from "react";
import { PageHeader, Spin, message } from "antd";
import { useHistory } from "react-router-dom";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import ReviewsPage from "pages/reviews/reviews.page";
import { useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import fetchReviewDestinationTypes from "../../helpers/fetch-review-destination-types";
import fetchReviewTagTypes from "pages/reviews/helpers/fetch-review-tag-types";
import fetchBranches from "pages/reviews/helpers/fetch-branches";
import { Branch } from "services/branch/models/branch";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";
import useAsyncActionState from "hooks/use-async-action-state";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import ReviewForm, { ReviewFormInstance } from "components/forms/review-form/review-form";
import addReviewHelper from "./add-review.helper";
import { Review } from "services/review/review.service";
import { noOp } from "libs/helpers/no-op";
import ReviewCardPage from "../card/card.page";

const fetchSelectorsData = () =>
  Promise.all([fetchBranches(), fetchReviewDestinationTypes(), fetchReviewTagTypes()]) as Promise<
    [Branch[], ReviewDestinationType[], ReviewTagType[]]
  >;

const AddReviewPage: PageFunctionComponent = () => {
  useSetTitleEffect([ReviewsPage.label, AddReviewPage.label]);
  const history = useHistory();
  const goBack = useGoBack();

  const formRef = React.useRef<ReviewFormInstance>(null);

  const [
    selectorsDataFetcher,
    {
      result: selectorsData = [
        [] as Branch[],
        [] as ReviewDestinationType[],
        [] as ReviewTagType[],
      ],
      loading: fetchSelectorsDataLoading,
    },
  ] = useAsyncActionState(fetchSelectorsData);

  const [addReview, { loading: addReviewLoading, error: addReviewError }] =
    useAsyncActionState(addReviewHelper);

  React.useEffect(() => {
    if (addReviewError) {
      message.error(addReviewError.message);
    }
  }, [addReviewError]);

  useComponentDidMountEffect(() => {
    selectorsDataFetcher();
  });

  const handleSubmit = React.useCallback(
    (review: Review) => {
      if (review.reviewDestinations) {
        if (review.reviewDestinations.length === selectorsData[1].length) {
          review.reviewDestinations = [];
        }
      }
      addReview(review)
        .then(({ result: inserted }) => {
          if (inserted) {
            history.push({
              pathname: `${ReviewsPage.path}${ReviewCardPage.pathCreator(inserted.id)}`,
            });
          }
        })
        .catch(noOp);
    },
    [addReview, selectorsData, history]
  );

  const loading = fetchSelectorsDataLoading || addReviewLoading;

  return (
    <PageHeader title={AddReviewPage.label} onBack={goBack}>
      <Spin spinning={loading}>
        <ReviewForm
          ref={formRef}
          branches={selectorsData[0] as Branch[]}
          reviewDestinationTypes={selectorsData[1]}
          reviewTagTypes={selectorsData[2] as ReviewTagType[]}
          onSubmit={handleSubmit}
          contentIsRequired={true}
        />
      </Spin>
    </PageHeader>
  );
};

AddReviewPage.path = "/add";
AddReviewPage.label = "Добавить отзыв";

export default AddReviewPage;
