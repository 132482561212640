import React from "react";
import { Descriptions } from "antd";
import { User } from "services/user/models/user";
import { displayDateTime } from "libs/helpers/display-date-time";

export interface UserDescriptionProps {
  data: User;
  title?: string;
}

const COLUMN = { xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 };

const UserDescription: React.FC<UserDescriptionProps> = (props) => {
  const { data, title = "Пользователь" } = props;

  return (
    <Descriptions title={title} column={COLUMN}>
      <Descriptions.Item label={<strong>Id</strong>}>{data.id}</Descriptions.Item>
      <Descriptions.Item label={<strong>Полное имя</strong>}>{data.fullName}</Descriptions.Item>
      <Descriptions.Item label={<strong>Email</strong>}>{data.email}</Descriptions.Item>
      <Descriptions.Item label={<strong>Телефон</strong>}>
        {data.phoneWithDescription}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Активен</strong>}>
        {data.isActive ? "Да" : "Нет"}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Дата обновления</strong>}>
        {displayDateTime(data.updatedAt)}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Дата создания</strong>}>
        {displayDateTime(data.createdAt)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default UserDescription;
