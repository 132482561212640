import React from "react";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import { PageHeader, Spin } from "antd";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import SideNavigation from "components/common/side-navigation";
import { signOut } from "store/actions/user";
import { useAppDispatch } from "store/hooks";

const SignOutPage: PageFunctionComponent = () => {
  useSetTitleEffect([SignOutPage.label]);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(signOut());
  }, [dispatch]);

  return (
    <React.Fragment>
      <SideNavigation items={[]} />
      <ContentLayout>
        <Breadcrumbs items={[SignOutPage.label]} />
        <PageHeader
          title={
            <span>
              Выходим... <Spin />
            </span>
          }
        ></PageHeader>
      </ContentLayout>
    </React.Fragment>
  );
};

SignOutPage.path = "/sign-out";
SignOutPage.label = "Выйти";

export default SignOutPage;
