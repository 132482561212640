import React from "react";
import { PageHeader, Popconfirm, Table, message } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import ClientsPage from "pages/clients/clients.page";
import { useAppSelector, useSetTitleEffect } from "store/hooks";
import RefreshButton from "components/buttons/refresh-button";
import { BlacklistClient } from "services/blacklist-client.service";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import { displayDateTime } from "libs/helpers/display-date-time";
import RemoveButton from "components/buttons/remove-button";
import { ColumnsType } from "antd/lib/table";
import { useTableState } from "hooks/use-table-state";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { blacklistClientService } from "services";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";

function getColumns(
  deleteBlacklistClient: (id: string) => Promise<void>,
  allowedToDelete: boolean,
  userId: string | null
): ColumnsType<BlacklistClient> {
  const columns: ColumnsType<BlacklistClient> = [
    {
      title: "ГУИД клиента в 1С",
      dataIndex: "clientGuid1c",
      key: "clientGuid1c",
      render: (clientGuid1c: any, record) => (clientGuid1c ? clientGuid1c : ""),
      ellipsis: true,
    },
    {
      title: "Телефона клиента",
      dataIndex: "clientPhone",
      key: "clientPhone",
      ellipsis: true,
    },
    {
      title: "Причина добавления",
      dataIndex: "reason",
      key: "reason",
      render: (reason: any, record) => (reason ? reason : ""),
      ellipsis: true,
    },
    {
      title: "Кто добавил",
      dataIndex: "user",
      key: "user",
      render: (_: any, record) =>
        record.createdByUser
          ? [
              record.createdByUser.surname,
              record.createdByUser.firstName,
              record.createdByUser.patronymic,
            ]
              .map((v) => (v === null ? "" : v))
              .join(" ")
              .replace("  ", " ")
          : "",
      ellipsis: true,
    },
    {
      title: "Дата добавления",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_: any, record) => displayDateTime(record.createdAt),
      sorter: true,
      sortOrder: "descend",
      sortDirections: ["descend"],
      ellipsis: true,
    },
  ];

  if (allowedToDelete) {
    columns.push({
      title: "",
      dataIndex: "deleteButton",
      key: "deleteButton",
      render: (_: any, record) => {
        if (userId) {
          if (userId === record.createdByUserId) {
            return (
              <Popconfirm
                title="Вы уверены?"
                okText="Удалить"
                okButtonProps={{ danger: true }}
                onConfirm={() => deleteBlacklistClient(record.id)}
              >
                <RemoveButton />
              </Popconfirm>
            );
          }

          return null;
        }

        return (
          <Popconfirm
            title="Вы уверены?"
            okText="Удалить"
            okButtonProps={{ danger: true }}
            onConfirm={() => deleteBlacklistClient(record.id)}
          >
            <RemoveButton />
          </Popconfirm>
        );
      },
      fixed: "right",
      align: "center",
    });
  }

  return columns;
}

const BlacklistPage: PageFunctionComponent = () => {
  useSetTitleEffect([ClientsPage.label, BlacklistPage.label]);

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);
  const [state, , setStateAsync] = useTableState<BlacklistClient>();

  const pageAccessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        userCanDeleteAll: false,
        userCanDeleteOwn: false,
      };
    }

    return {
      userCanDeleteAll: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.BLACKLIST_CLIENT,
        action: subjectAction.BLACKLIST_CLIENT.DELETE_ALL,
      }),
      userCanDeleteOwn: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.BLACKLIST_CLIENT,
        action: subjectAction.BLACKLIST_CLIENT.DELETE_OWN,
      }),
    };
  }, [pageAccessControlState]);

  const userId = React.useMemo(() => {
    if (!pageAccessControlState.user) {
      return null;
    }
    return pageAccessControlState.user.id;
  }, [pageAccessControlState.user]);

  const refreshBlacklistClients = React.useCallback(
    async (page = state.pagination.current, pageSize = state.pagination.pageSize) => {
      await setStateAsync((s: any) => ({ ...s, loading: true }));
      const blacklistClients = await blacklistClientService.findAll({
        search: {},
        join: ["createdByUser"],
        sort: [{ field: "createdAt", order: "DESC" }],
        page,
        limit: pageSize,
      });
      if (!Array.isArray(blacklistClients)) {
        const pagination = { ...state.pagination };
        pagination.current = blacklistClients.page;
        pagination.total = blacklistClients.total;
        pagination.pageSize = pageSize;
        await setStateAsync((s) => ({
          ...s,
          data: blacklistClients.data,
          loading: false,
          pagination,
        }));
      }
    },
    [state.pagination, setStateAsync]
  );

  const deleteBlacklistClient = React.useCallback(
    async (id: string) => {
      try {
        await setStateAsync((s: any) => ({ ...s, loading: true }));
        await blacklistClientService.deleteOne(id);
        await refreshBlacklistClients();
        await setStateAsync((s: any) => ({ ...s, loading: false }));
      } catch (error) {
        if (error instanceof Error) {
          message.error(error.message);
        }
        await setStateAsync((s: any) => ({ ...s, loading: false }));
      }
    },
    [setStateAsync, refreshBlacklistClients]
  );

  useComponentDidMountEffect(() => {
    refreshBlacklistClients();
  });

  const handleTableChange = React.useCallback(
    (pagination /*, filters, sorter*/) => {
      if (
        state.pagination.current !== pagination.current ||
        state.pagination.pageSize !== pagination.pageSize
      ) {
        refreshBlacklistClients(pagination.current, pagination.pageSize);
      }
    },
    [refreshBlacklistClients, state.pagination]
  );

  const columns = React.useMemo(() => {
    if (pageAccessControl.userCanDeleteAll) {
      return getColumns(deleteBlacklistClient, true, null);
    } else if (pageAccessControl.userCanDeleteOwn) {
      return getColumns(deleteBlacklistClient, true, userId);
    }
    return getColumns(deleteBlacklistClient, false, null);
  }, [deleteBlacklistClient, pageAccessControl, userId]);

  return (
    <PageHeader
      title={BlacklistPage.label}
      extra={
        <React.Fragment>
          <RefreshButton loading={state.loading} onClick={() => refreshBlacklistClients()} />
        </React.Fragment>
      }
    >
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={{ spinning: state.loading, delay: 500 }}
        onChange={handleTableChange}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </PageHeader>
  );
};

BlacklistPage.path = "/blacklist";
BlacklistPage.label = "Чёрный список";

export default BlacklistPage;
