import React from "react";
import { Descriptions } from "antd";
import { City } from "services/city/models/city";
import { displayDateTime } from "libs/helpers/display-date-time";

export interface CityDescriptionProps {
  data: City;
  title?: string;
}

const COLUMN = { xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 };

const CityDescription: React.FC<CityDescriptionProps> = (props) => {
  const { data, title = "Город" } = props;

  return (
    <Descriptions title={title} column={COLUMN}>
      <Descriptions.Item label={<strong>Id</strong>}>{data.id}</Descriptions.Item>
      <Descriptions.Item label={<strong>Название</strong>}>{data.name}</Descriptions.Item>
      <Descriptions.Item label={<strong>Дата обновления</strong>}>
        {displayDateTime(data.updatedAt)}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Дата создания</strong>}>
        {displayDateTime(data.createdAt)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CityDescription;
