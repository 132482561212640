import React from "react";
import { Form, Space, Button, Input } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Reminder } from "services/reminder/models/reminder";
import { noOp } from "libs/helpers/no-op";

export interface ReminderTableAdvancedFormDto {
  id?: Reminder["id"];
  title?: Reminder["title"];
}

export interface ReminderTableAdvancedFormProps {
  form?: FormInstance<ReminderTableAdvancedFormDto>;
  submit?: () => void;
  reset?: () => void;
  initialValues?: {};
  disabledFields?: { [key in keyof ReminderTableAdvancedFormDto]?: boolean };
}

const ReminderTableAdvancedForm: React.FC<ReminderTableAdvancedFormProps> = (props) => {
  const { form: formProp, submit = noOp, reset = noOp, initialValues, disabledFields } = props;

  const [form] = Form.useForm(formProp);

  const handleSubmit = React.useCallback(() => {
    if (submit !== noOp) {
      return submit();
    }
    form.submit();
  }, [form, submit]);

  const handleReset = React.useCallback(() => {
    if (reset !== noOp) {
      return reset();
    }
    form.resetFields();
  }, [form, reset]);

  return (
    <Form layout="inline" form={form} initialValues={initialValues} style={{ rowGap: "1em" }}>
      <Form.Item name="id" label="Id">
        <Input allowClear type="number" step="1" disabled={disabledFields?.id} />
      </Form.Item>
      <Form.Item name="title" label="Заголовок">
        <Input allowClear disabled={disabledFields?.title} />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" onClick={handleSubmit}>
            Поиск
          </Button>
          <Button onClick={handleReset}>Сброс</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ReminderTableAdvancedForm;
