import { Type, plainToClass } from "class-transformer";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "../interfaces/paginated-response.interface";
import { City } from "services/city/models/city";
import { Branch } from "./models/branch";
import { BranchUpsertDto } from "./dtos/branch-upsert-dto.type";

export class BranchHeads {
  id: string;
  headFirstName: string;
  headPatronymic?: string;
  headSurname: string;
  headPhone: string;
  headPhoneDescription?: string;
  headEmail: string;
  roleName: string;
  roleDescription?: string;
}

export class BranchWithHeads {
  id: string;
  guid1c?: string;
  name: string;

  @Type(() => City)
  city?: City;

  heads: BranchHeads[];
}

export class BranchService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<Branch> | Branch[]>("/branch", {
      params: queryParams,
    });
    if (Array.isArray(response)) {
      response = plainToClass(Branch, response);
    } else {
      response.data = plainToClass(Branch, response.data);
    }
    return response;
  }

  async findOne(id: Branch["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<Branch>(`/branch/${id}`, {
      params: queryParams,
    });
    return plainToClass(Branch, response);
  }

  async createOne(dto: BranchUpsertDto) {
    const user = await this.apiDriver.post<Branch>("/branch", dto);
    return plainToClass(Branch, user);
  }

  async updateOne(id: string, dto: BranchUpsertDto) {
    const updated = await this.apiDriver.patch<Branch>(`/branch/${id}`, dto);
    return plainToClass(Branch, updated);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<Branch>(`/branch/${id}`, {
      params: queryParams,
    });
    return plainToClass(Branch, deleted);
  }

  async findAllWithHeads() {
    const branches = await this.apiDriver.get<BranchWithHeads[]>("/branch/with-heads");
    return plainToClass(BranchWithHeads, branches);
  }
}
