import { reviewService } from "services";

const fetchReview = (id: string) =>
  reviewService.findOne(id, {
    join: [
      ["branch", ["id", "cityId", "name", "isActive"]],
      ["branch.city", ["id", "name"]],
      ["reviewClientCalls"],
      ["reviewClientEmails"],
      ["reviewDestinations"],
      ["reviewDestinations.reviewDestinationType"],
      ["reviewServiceOrders"],
      ["reviewServiceOrders.serviceOrder"],
      ["reviewServiceOrders.serviceOrder.serviceOrderWorks"],
      ["reviewWebLinks"],
      ["reviewWebLinks.reviewSite"],
      ["reviewStatuses"],
      [
        "reviewStatuses.user",
        ["id", "firstName", "surname", "patronymic", "phone", "phoneDescription"],
      ],
      ["reviewTags"],
      ["reviewTags.reviewTagType"],
      ["operatorUser", ["id", "firstName", "surname", "patronymic", "phone", "phoneDescription"]],
      ["reviewChatMessages"],
      ["reviewChatMessages.user"],
    ],
  });

export default fetchReview;
