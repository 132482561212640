import React from "react";
import { FilterDropdownProps } from "antd/es/table/interface";
import SearchFilterDropdown from "../components/search-filter-dropdown";
import SearchFilterIcon from "../components/search-filter-icon";
import { Input } from "antd";

export function getColumnSearchProps(dataIndex: string, inputPlaceholder: string = "") {
  const inputRef: React.RefObject<Input> = { current: null };

  return {
    filterDropdown: (props: FilterDropdownProps) => (
      <SearchFilterDropdown
        {...props}
        dataIndex={dataIndex}
        inputPlaceholder={inputPlaceholder}
        ref={inputRef}
      />
    ),
    filterIcon: SearchFilterIcon,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => (inputRef.current ? inputRef.current.select() : null));
      }
    },
  };
}
