import React from "react";
import { Link } from "react-router-dom";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { Role } from "services/role/models/role";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import RoleSettingsPage from "pages/settings/pages/role/role.page";
import { ArrowRightOutlined } from "@ant-design/icons";
import SettingsPage from "pages/settings/settings.page";

export interface RoleTableProps {
  tableProps: TableProps<Role>;
  sorter: SorterResult<Role> | SorterResult<Role>[];
  size?: SizeType;
}

const getColumns: (sorter: SorterResult<Role> | SorterResult<Role>[]) => ColumnsType<Role> = (
  sorter
) => {
  let nameSorter: SortOrder = null;
  let createdAtSortOrder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;
  let isHeadSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "name" && sorter.order) {
      nameSorter = sorter.order;
    } else if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    } else if (sorter.field === "updatedAt" && sorter.order) {
      updatedAtSortOrder = sorter.order;
    } else if (sorter.field === "isHead" && sorter.order) {
      isHeadSortOrder = sorter.order;
    }
  }

  return [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: nameSorter,
      ellipsis: true,
      render: (_: any, record) => record.name,
    },
    {
      title: "Руководитель",
      dataIndex: "isHead",
      key: "isHead",
      sorter: true,
      sortOrder: isHeadSortOrder,
      ellipsis: true,
      render: (_: any, record) => (record.isHead ? "Да" : "Нет"),
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortOrder: updatedAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "",
      dataIndex: "role",
      key: "role",
      render: (_: any, record) => (
        <Link
          to={{
            pathname: `${SettingsPage.path}${RoleSettingsPage.pathCreator(record.id)}`,
            state: { canGoBack: true },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      align: "center",
      fixed: "right",
      ellipsis: true,
    },
  ];
};

const RoleTable: React.FC<RoleTableProps> = (props) => {
  const { tableProps, sorter, size } = props;

  const columns = React.useMemo(() => getColumns(sorter), [sorter]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default RoleTable;
