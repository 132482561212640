import { ThunkAction } from "redux-thunk";
import { notificationService } from "services";
import { NotificationsAction, NotificationsActionType } from "store/reducers/notifications";
import { RootState } from "store/store";

export function clearNotifications(): NotificationsAction {
  return { type: NotificationsActionType.CLEAR_NOTIFICATIONS_STATE };
}

export function loadMyUnreadNotificationCount(): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  NotificationsAction
> {
  return async function loadMyUnreadNotificationCountThunk(dispatch) {
    try {
      dispatch({ type: NotificationsActionType.LOAD_MY_UNREAD_NOTIFICATION_COUNT });
      const count = await notificationService.countMyAllUnread();
      dispatch({
        type: NotificationsActionType.LOADED_MY_UNREAD_NOTIFICATION_COUNT,
        payload: count,
      });
    } catch (error) {
      if (error instanceof Error) {
        dispatch({
          type: NotificationsActionType.ERRORED_MY_UNREAD_NOTIFICATION_COUNT,
          payload: error,
        });
      }
    }
  };
}

export function setMyUnreadNotificationCount(count: number): NotificationsAction {
  return { type: NotificationsActionType.SET_MY_UNREAD_NOTIFICATION_COUNT, payload: count };
}
