import { CondOperator, QueryFilter, QueryFilterArr } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { ReviewSiteTableSimpleFormDto } from "components/tables/ReviewSiteTable/ReviewSiteTableSimpleForm";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { reviewSiteService } from "services";
import { ReviewSite } from "services/review-site/models/review-site";

export type GetReviewSiteTableData = Service<Data, Params>;

export const getReviewSiteTableData: GetReviewSiteTableData = async (
  paginatedParams,
  formData: ReviewSiteTableSimpleFormDto
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<ReviewSite>(paginatedParams.sorter);

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  if (formData.name) {
    filter.push({
      field: "name",
      operator: CondOperator.CONTAINS_LOW,
      value: formData.name.trim(),
    });
  }

  const reviewSites = await reviewSiteService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort: tableQuerySorter
      ? [tableQuerySorter, { field: "id", order: "ASC" }]
      : { field: "id", order: "ASC" },
    filter,
  });

  if (Array.isArray(reviewSites)) {
    return {
      list: reviewSites,
      total: reviewSites.length,
    };
  } else {
    return {
      list: reviewSites.data,
      total: reviewSites.total,
    };
  }
};
