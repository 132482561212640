import React from "react";
import { Drawer, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import ReminderForm, { ReminderFormProps } from "components/forms/ReminderForm";
import { ReviewClientCall } from "services/review/models/review-client-call.model";
import { ReviewClientEmail } from "services/review/models/review-client-email";
import { ReviewWebLink } from "services/review/models/review-web-link.model";
import { Review } from "services/review/models/review.model";
import { ServiceOrder } from "services/service-order.service";
import { noOp } from "libs/helpers/no-op";
import ReviewsPage from "pages/reviews/reviews.page";
import ReviewCardPage from "pages/reviews/pages/card/card.page";
import ServiceOrdersPage from "pages/service-orders/service-orders.page";
import ServiceOrderCardPage from "pages/service-orders/pages/card/card.page";

function createTitle(
  source: Review | ServiceOrder | ReviewClientCall | ReviewClientEmail | ReviewWebLink
): string | undefined {
  if (source instanceof Review) {
    return `Перезвонить клиенту (${source.clientDisplayName})`;
  } else if (source instanceof ServiceOrder) {
    return `Перезвонить клиенту (${source.clientDisplayName})`;
  } else if (source instanceof ReviewClientCall) {
    return `Перезвонить клиенту (${source.clientPhone})`;
  } else if (source instanceof ReviewClientEmail) {
    return `Перезвонить клиенту (${source.clientEmail})`;
  } else if (source instanceof ReviewWebLink) {
    return `Перезвонить клиенту (${source.clientNickname})`;
  }
  return;
}

function createDescription(
  source: Review | ServiceOrder | ReviewClientCall | ReviewClientEmail | ReviewWebLink
): string | undefined {
  if (source instanceof Review) {
    const reviewLink = `${ReviewsPage.path}${ReviewCardPage.pathCreator(source.id)}`;
    return `
      <p>Клиент: ${source.clientDisplayName}</p>
      <p>Контакты клиента: ${[source.clientPhone, source.clientEmail]
        .filter((el) => !!el)
        .join(", ")}</p>
      <p><a href="${reviewLink}" rel="noopener noreferrer" target="_blank">Ссылка на отзыв (${
      source.id
    })</a></p>
    `.trim();
  } else if (source instanceof ServiceOrder) {
    const serviceOrderLink = `${ServiceOrdersPage.path}${ServiceOrderCardPage.pathCreator(
      source.id
    )}`;
    return `
      <p>Клиент: ${source.clientDisplayName}</p>
      <p>Контакты клиента: ${source.clientPhone}</p>
      <p><a href="${serviceOrderLink}" rel="noopener noreferrer" target="_blank">Ссылка на заказ-наряд (${source.number})</a></p>
    `.trim();
  } else if (source instanceof ReviewClientCall) {
    const reviewLink = `${ReviewsPage.path}${ReviewCardPage.pathCreator(source.reviewId)}`;
    return `
      <p>Контакты клиента: ${source.clientPhone}</p>
      <p><a href="${reviewLink}" rel="noopener noreferrer" target="_blank">Ссылка на отзыв</a></p>
    `.trim();
  } else if (source instanceof ReviewClientEmail) {
    const reviewLink = `${ReviewsPage.path}${ReviewCardPage.pathCreator(source.reviewId)}`;
    return `
      <p>Контакты клиента: ${source.clientEmail}</p>
      <p><a href="${reviewLink}" rel="noopener noreferrer" target="_blank">Ссылка на отзыв</a></p>
    `.trim();
  } else if (source instanceof ReviewWebLink) {
    const reviewLink = `${ReviewsPage.path}${ReviewCardPage.pathCreator(source.reviewId)}`;
    return `
      <p>Клиент: ${source.clientNickname}</p>
      <p><a href="${reviewLink}" rel="noopener noreferrer" target="_blank">Ссылка на отзыв</a></p>
    `.trim();
  }
  return;
}

export interface ReminderDrawerProps {
  visible?: boolean;
  loading?: boolean;
  onClose?: () => void;
  onFormFinish?: ReminderFormProps["onFinish"];
  reviews?: Review[];
  serviceOrders?: ServiceOrder[];
  reviewClientCalls?: ReviewClientCall[];
  reviewClientEmails?: ReviewClientEmail[];
  reviewWebLinks?: ReviewWebLink[];
}

const DEFAULT_REVIEWS: Review[] = [];
const DEFAULT_SERVICE_ORDERS: ServiceOrder[] = [];
const DEFAULT_REVIEW_CLIENT_CALLS: ReviewClientCall[] = [];
const DEFAULT_REVIEW_CLIENT_EMAILS: ReviewClientEmail[] = [];
const DEFAULT_REVIEW_WEB_LINKS: ReviewWebLink[] = [];

const ReminderDrawer: React.FC<ReminderDrawerProps> = (props) => {
  const {
    visible = false,
    onClose = noOp,
    onFormFinish = noOp,
    reviews = DEFAULT_REVIEWS,
    serviceOrders = DEFAULT_SERVICE_ORDERS,
    reviewClientCalls = DEFAULT_REVIEW_CLIENT_CALLS,
    reviewClientEmails = DEFAULT_REVIEW_CLIENT_EMAILS,
    reviewWebLinks = DEFAULT_REVIEW_WEB_LINKS,
  } = props;

  const [initialValues, setInitialValues] = React.useState<ReminderFormProps["initialValues"]>();

  const handleClose = React.useCallback(() => {
    onClose();
    setInitialValues(undefined);
  }, [onClose, setInitialValues]);

  const handleSelectChange = React.useCallback(
    (value: SelectValue) => {
      if (value === undefined) {
        setInitialValues(undefined);
        return;
      }

      const review = reviews.find((el) => el.id === value);
      if (review) {
        setInitialValues({
          title: createTitle(review),
          description: createDescription(review),
        });
        return;
      }

      const serviceOrder = serviceOrders.find((el) => el.id === value);
      if (serviceOrder) {
        setInitialValues({
          title: createTitle(serviceOrder),
          description: createDescription(serviceOrder),
        });
        return;
      }

      const reviewClientCall = reviewClientCalls.find((el) => el.id === value);
      if (reviewClientCall) {
        setInitialValues({
          title: createTitle(reviewClientCall),
          description: createDescription(reviewClientCall),
        });
        return;
      }

      const reviewClientEmail = reviewClientEmails.find((el) => el.id === value);
      if (reviewClientEmail) {
        setInitialValues({
          title: createTitle(reviewClientEmail),
          description: createDescription(reviewClientEmail),
        });
        return;
      }

      const reviewWebLink = reviewWebLinks.find((el) => el.id === value);
      if (reviewWebLink) {
        setInitialValues({
          title: createTitle(reviewWebLink),
          description: createDescription(reviewWebLink),
        });
        return;
      }
    },
    [
      setInitialValues,
      reviews,
      serviceOrders,
      reviewClientCalls,
      reviewClientEmails,
      reviewWebLinks,
    ]
  );

  const handleSelectClear = React.useCallback(() => {
    setInitialValues(undefined);
  }, [setInitialValues]);

  return (
    <Drawer
      title="Добавить напоминание"
      onClose={handleClose}
      visible={visible}
      width={720}
      maskClosable={false}
      destroyOnClose={true}
      bodyStyle={{ paddingBottom: 80, maxWidth: "100%" }}
    >
      <Select
        onChange={handleSelectChange}
        onClear={handleSelectClear}
        allowClear
        loading={false}
        disabled={false}
        showSearch
        optionFilterProp="children"
        style={{ width: "100%", marginBottom: "16px" }}
        placeholder="Выбрать источник"
      >
        {reviews.map((review) => (
          <Select.Option key={`ReminderDrawer_review_${review.id}`} value={review.id}>
            Отзыв (id={review.id})
          </Select.Option>
        ))}
        {serviceOrders.map((serviceOrder) => (
          <Select.Option
            key={`ReminderDrawer_serviceOrders_${serviceOrder.id}`}
            value={serviceOrder.id}
          >
            Заказ-наряд №{serviceOrder.number}
          </Select.Option>
        ))}
        {reviewClientEmails.map((reviewClientEmail) => (
          <Select.Option
            key={`ReminderDrawer_reviewClientEmail_${reviewClientEmail.id}`}
            value={reviewClientEmail.id}
          >
            Письмо клиента от {reviewClientEmail.clientEmail} (id={reviewClientEmail.id})
          </Select.Option>
        ))}
        {reviewClientCalls.map((reviewClientCall) => (
          <Select.Option
            key={`ReminderDrawer_reviewClientCall_${reviewClientCall.id}`}
            value={reviewClientCall.id}
          >
            Звонок клиента от {reviewClientCall.clientPhone} (id={reviewClientCall.id})
          </Select.Option>
        ))}
        {reviewWebLinks.map((reviewWebLink) => (
          <Select.Option
            key={`ReminderDrawer_reviewWebLink_${reviewWebLink.id}`}
            value={reviewWebLink.id}
          >
            Отзыв на сайте {reviewWebLink.reviewSite?.name} от {reviewWebLink.clientNickname} (id=
            {reviewWebLink.id})
          </Select.Option>
        ))}
      </Select>
      <ReminderForm
        title=""
        onFinish={onFormFinish}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
      />
    </Drawer>
  );
};

export default ReminderDrawer;
