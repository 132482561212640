import React from "react";
import { Tooltip } from "antd";
import { InfoCircleFilled, InfoCircleOutlined, InfoCircleTwoTone } from "@ant-design/icons";

export interface InfoTooltipProps {
  title: React.ReactNode;
  iconType?: "outlined" | "filled" | "two-tone";
  size?: number;
  style?: React.CSSProperties;
}

function InfoTooltip(props: InfoTooltipProps) {
  const { title, iconType = "outlined", size, style } = props;

  const icon = React.useMemo(() => {
    let iconNode: React.ReactNode = <InfoCircleFilled />;
    if (iconType === "outlined") {
      iconNode = <InfoCircleOutlined size={size} />;
    } else if (iconType === "filled") {
      iconNode = <InfoCircleFilled size={size} />;
    } else if (iconType === "two-tone") {
      iconNode = <InfoCircleTwoTone size={size} />;
    }

    return iconNode;
  }, [iconType, size]);

  return (
    <Tooltip title={title}>
      <span style={style}>{icon}</span>
    </Tooltip>
  );
}

export default InfoTooltip;
