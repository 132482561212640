import { CondOperator, QueryFilter, QueryFilterArr } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { BranchTableSimpleFormDto } from "components/tables/BranchTable/BranchTableSimpleForm";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { branchService } from "services";
import { Branch } from "services/branch/models/branch";

export type GetBranchTableData = Service<Data, Params>;

export const getBranchTableData: GetBranchTableData = async (
  paginatedParams,
  formData: BranchTableSimpleFormDto
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<Branch>(paginatedParams.sorter);

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  if (formData.name) {
    filter.push({
      field: "name",
      operator: CondOperator.CONTAINS_LOW,
      value: formData.name.trim(),
    });
  }

  const cities = await branchService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort: tableQuerySorter
      ? [tableQuerySorter, { field: "id", order: "ASC" }]
      : { field: "id", order: "ASC" },
    filter,
    join: ["city"],
  });

  if (Array.isArray(cities)) {
    return {
      list: cities,
      total: cities.length,
    };
  } else {
    return {
      list: cities.data,
      total: cities.total,
    };
  }
};
