import React from "react";
import { Form, Descriptions, Button, Space } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import { RoleMailing } from "services/role-mailing/models/role-mailing";
import { RoleMailingUpsertDto } from "services/role-mailing/dtos/role-mailing-upsert-dto.type";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";
import RoleSelect from "components/selects/RoleSelect";
import EmailTypeSelect from "components/selects/EmailTypeSelect";

export type RoleMailingFormDto = RoleMailingUpsertDto;

export interface RoleMailingFormProps {
  form?: FormInstance<RoleMailingFormDto>;
  onFinish?: (dto: RoleMailingUpsertDto, entityToUpdate?: RoleMailing) => void;
  entityToUpdate?: RoleMailing;
  initialValues?: Partial<RoleMailingFormDto>;
  disabledFields?: { [key in keyof RoleMailingFormDto]?: boolean };
  title?: string;
}

const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };

const RoleMailingForm: React.FC<RoleMailingFormProps> = (props) => {
  const {
    form: formProp,
    onFinish = noOp,
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Рассылка роли",
    disabledFields,
  } = props;

  const [form] = Form.useForm(formProp);

  const reset = React.useCallback(() => {
    form.resetFields();
  }, [form]);

  const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = {};

    if (entityToUpdate) {
      Object.assign(iV, entityToUpdate);
    }

    if (initialValuesProp) {
      Object.assign(iV, initialValuesProp);
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const handleFinish = React.useCallback(
    (dto: RoleMailingFormDto) => {
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="roleId"
            name="roleId"
            label={<strong>Роль</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <RoleSelect disabled={disabledFields?.roleId} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="emailType"
            name="emailType"
            label={<strong>Тип рассылки</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <EmailTypeSelect disabled={disabledFields?.emailType} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата создания</strong>}>
          {displayDateTime(entityToUpdate?.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Сохранить
          </Button>
          <Button type="text" size="large" onClick={reset}>
            Сбросить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default RoleMailingForm;
