import { Type } from "class-transformer";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import { Role } from "services/role/models/role";

export class RoleReviewDestination {
  id!: string;
  roleId!: string;
  reviewDestinationTypeId!: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Role)
  role?: Role;

  @Type(() => ReviewDestinationType)
  reviewDestinationType?: ReviewDestinationType;
}
