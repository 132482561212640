import React from "react";
import { useHistory } from "react-router-dom";
import { useAntdTable } from "ahooks";
import { PageHeader, Row, Col } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import useGoBack from "hooks/use-go-back";
import { getIntegrationKeyTableData } from "./get-integration-key-table-data";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import IntegrationKeyTable from "components/tables/IntegrationKeyTable/IntegrationKeyTable";
import AddIntegrationKeySettingsPage from "../add-integration-key/add-integration-key.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

const IntegrationKeysSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, IntegrationKeysSettingsPage.label]);

  const goBack = useGoBack();
  const history = useHistory();

  const { tableProps, loading, refresh, params } = useAntdTable(getIntegrationKeyTableData, {
    defaultType: "simple",
    cacheKey: "IntegrationKeysPageTable",
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: { field: "id", order: "ascend" },
      },
    ],
  });

  const { sorter = {} } = params[0] || ({} as any);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, IntegrationKeysSettingsPage.label]} />
      <PageHeader
        title={IntegrationKeysSettingsPage.label}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.INTEGRATION_KEY,
                  action: subjectAction.INTEGRATION_KEY.CREATE,
                },
              ]}
              placeholder={null}
              loading={loading}
            >
              <Col>
                <AddButton
                  onClick={() =>
                    history.push({
                      pathname: `${SettingsPage.path}${AddIntegrationKeySettingsPage.path}`,
                      state: { canGoBack: true },
                    })
                  }
                >
                  Добавить
                </AddButton>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton loading={loading} onClick={refresh} />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        {" "}
        <IntegrationKeyTable tableProps={tableProps} sorter={sorter} />
      </PageHeader>
    </ContentLayout>
  );
};

IntegrationKeysSettingsPage.path = "/integration-keys";
IntegrationKeysSettingsPage.label = "Интеграционные ключи";

export default IntegrationKeysSettingsPage;
