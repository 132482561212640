import React from "react";
import { Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/es/table/interface";

export interface SearchFilterDropdownProps extends FilterDropdownProps {
  dataIndex: string;
  inputPlaceholder?: string;
}

const SearchFilterDropdown = React.forwardRef<any, SearchFilterDropdownProps>((props, ref) => {
  const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
  const { inputPlaceholder = "" } = props;

  const handleSearch = React.useCallback(() => {
    confirm();
  }, [confirm]);

  const handleReset = React.useCallback(() => {
    if (clearFilters) {
      clearFilters();
    }
  }, [clearFilters]);

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={ref}
        placeholder={inputPlaceholder}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch()}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Button
        type="link"
        onClick={() => handleReset()}
        size="small"
        style={{ width: 90, marginRight: 8 }}
        disabled={selectedKeys.length === 0}
      >
        Сбросить
      </Button>
      <Button
        type="primary"
        onClick={() => handleSearch()}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90 }}
      >
        Найти
      </Button>
    </div>
  );
});

export default SearchFilterDropdown;
