import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function usePath(level = 1) {
  const location = useLocation();
  const path = useMemo(() => {
    const paths = location.pathname.split("/");

    if (paths.length === 1) {
      return "/";
    }

    return paths
      .filter((p) => p !== "")
      .slice(0, level)
      .map((p) => `/${p}`)
      .join("");
  }, [location, level]);
  return path;
}

export default usePath;
