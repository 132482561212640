import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { SITE_LABEL } from "configs/configs";
import AuthPage from "pages/auth/auth.page";

const { Header, Footer } = Layout;

interface AuthLayoutProps {
  content?: React.ReactNode;
}

const AuthLayout: React.FunctionComponent<AuthLayoutProps> = (props) => {
  const { children, content } = props;

  return (
    <Layout>
      <Header>
        <div className="dashboard-layout__logo">
          <Link to={{ pathname: AuthPage.path }}>{SITE_LABEL}</Link>
        </div>
      </Header>
      <Layout>{content ? content : children}</Layout>
      <Footer id="auth-layout--footer" style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()}, Автокомплекс REAKTOR - Служба контроля качества
      </Footer>
    </Layout>
  );
};

export default AuthLayout;
