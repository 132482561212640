import React from "react";
import { message } from "antd";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";

export function useHttpErrorMessage(error: Error | undefined) {
  React.useEffect(() => {
    if (error) {
      if (error instanceof ApiDriverHttpError) {
        message.error(error.message, 3);
      }
    }
  }, [error]);
}
