import { serviceOrderService } from "services";

const fetchServiceOrder = (id: string) =>
  serviceOrderService.findOne(id, {
    join: [
      ["serviceOrderWorks"],
      ["reviewServiceOrders", ["id", "reviewId"]],
      [
        "reviewServiceOrders.review",
        ["id", "operatorUserId", "createdAt", "status", "statusUpdatedAt"],
      ],
      [
        "reviewServiceOrders.review.operatorUser",
        ["id", "isActive", "firstName", "patronymic", "surname"],
      ],
    ],
  });

export default fetchServiceOrder;
