import React from "react";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { ServiceOrder } from "services/service-order.service";
import { TableState } from "libs/interfaces/table-state.interface";
import { SetStateAsync } from "hooks/use-state";
import { QuerySort } from "@nestjsx/crud-request";
import { TABLE_DEFAULT_PAGE, TABLE_DEFAULT_PAGE_SIZE } from "configs/configs";
import fetchAllServiceOrders from "../pages/all/helpers/fetch-service-orders";
import fetchServiceOrdersWithoutReview from "../pages/without-review/helpers/fetch-service-orders";

export function useRefreshServiceOrders(
  currentPagination: TablePaginationConfig,
  setStateAsync: SetStateAsync<TableState<ServiceOrder>>,
  fetchServiceOrders: typeof fetchAllServiceOrders | typeof fetchServiceOrdersWithoutReview
) {
  return React.useCallback(
    async (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null> = {},
      sorter: SorterResult<ServiceOrder> | SorterResult<ServiceOrder>[]
    ) => {
      await setStateAsync((s) => ({ ...s, loading: true }));

      const search: { $and: any[] } = { $and: [] };

      if (Array.isArray(filters.branchGuid1c)) {
        search.$and.push({ branchGuid1c: { $in: filters.branchGuid1c } });
      }

      if (Array.isArray(filters.number)) {
        if (filters.number.length > 0) {
          search.$and.push({ number: { $contL: filters.number[0].toString().trim() } });
        }
      }

      if (Array.isArray(filters.clientPhone)) {
        if (filters.clientPhone.length > 0) {
          search.$and.push({ clientPhone: { $contL: filters.clientPhone[0].toString().trim() } });
        }
      }

      if (Array.isArray(filters.clientDisplayName)) {
        if (filters.clientDisplayName.length > 0) {
          search.$and.push({
            clientDisplayName: { $contL: filters.clientDisplayName[0].toString().trim() },
          });
        }
      }

      if (Array.isArray(filters.clientDisplayNameOrClientPhone)) {
        if (filters.clientDisplayNameOrClientPhone.length > 0) {
          search.$and.push({
            $or: [
              {
                clientPhone: {
                  $contL: filters.clientDisplayNameOrClientPhone[0].toString().trim(),
                },
              },
              {
                clientDisplayName: {
                  $contL: filters.clientDisplayNameOrClientPhone[0].toString().trim(),
                },
              },
            ],
          });
        }
      }

      const fetchSorter = Array.isArray(sorter)
        ? sorter
            .map((s) => convertTableSorterToQuerySorter<ServiceOrder>(s))
            .reduce((acc, curr) => {
              if (curr) {
                acc.push(curr);
              }
              return acc;
            }, [] as QuerySort[])
        : convertTableSorterToQuerySorter<ServiceOrder>(sorter);

      const serviceOrders = await fetchServiceOrders(
        pagination.current || TABLE_DEFAULT_PAGE,
        pagination.pageSize || TABLE_DEFAULT_PAGE_SIZE,
        fetchSorter,
        search
      );

      const nextPagination = { ...currentPagination };

      nextPagination.current = serviceOrders.page;
      nextPagination.total = serviceOrders.total;
      nextPagination.pageSize = pagination.pageSize || TABLE_DEFAULT_PAGE_SIZE;

      await setStateAsync({
        data: serviceOrders.data,
        loading: false,
        pagination: nextPagination,
        filters,
        sorter,
      });
    },
    [currentPagination, setStateAsync, fetchServiceOrders]
  );
}
