import { combineReducers } from "redux";
import appSettingsReducer from "./app-settings";
import latestTableStatesReducer from "./latest-table-states";
import titleReducer from "./title";
import tokenPairReducer from "./token-pair";
import userAbilitiesReducer from "./user-abilities";
import userReducer from "./user";
import userRolesReducer from "./user-roles";
import notificationsReducer from "./notifications";

const rootReducer = combineReducers({
  appSettings: appSettingsReducer,
  latestTableStates: latestTableStatesReducer,
  title: titleReducer,
  tokenPair: tokenPairReducer,
  user: userReducer,
  userAbilities: userAbilitiesReducer,
  userRoles: userRolesReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
