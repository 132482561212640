import React from "react";
import { Select } from "antd";
import { useMount, useRequest } from "ahooks";
import { cityService } from "services";
import { City } from "services/city/models/city";

const fetchCities = () =>
  cityService.findAll({
    fields: ["id", "name"],
    sort: [
      { field: "name", order: "ASC" },
      { field: "id", order: "ASC" },
    ],
    limit: 1000,
  });

export interface CitySelectProps {
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  disabled?: boolean;
}

const CitySelect: React.FC<CitySelectProps> = React.forwardRef<
  any, // :(
  CitySelectProps
>((props, ref) => {
  const { run, data, loading } = useRequest(fetchCities, {
    manual: true,
  });

  useMount(() => {
    run();
  });

  if (!data) {
    return (
      <Select
        ref={ref}
        {...props}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children"
      />
    );
  }

  const mapData = (item: City) => (
    <Select.Option key={`CitySelect_${item.id}`} value={item.id}>
      {item.name}
    </Select.Option>
  );

  return (
    <Select
      ref={ref}
      {...props}
      loading={loading}
      allowClear
      showSearch
      optionFilterProp="children"
    >
      {Array.isArray(data) ? data.map(mapData) : data.data.map(mapData)}
    </Select>
  );
});

export default CitySelect;
