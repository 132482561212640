import React from "react";
import { Link } from "react-router-dom";
import { PageHeader, Button, Descriptions, Divider, Table } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useAppDispatch, useAppSelector, useSetTitleEffect } from "store/hooks";
import SideNavigation from "components/common/side-navigation";
import Breadcrumbs from "components/common/breadcrumbs";
import ContentLayout from "components/layouts/content.layout";
import AuthPage from "pages/auth/auth.page";
import SignOutPage from "pages/auth/pages/sign-out/sign-out.page";
import { displayDateTime } from "libs/helpers/display-date-time";
import ResetButton from "components/buttons/reset-button";
import { clearLatestTableStates } from "store/actions/latest-table-states";

const rolesTableColumns = [
  {
    title: "Роль",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Филиал",
    dataIndex: "branch",
    key: "branch",
  },
];

const ProfilePage: PageFunctionComponent = () => {
  useSetTitleEffect([ProfilePage.label]);

  const user = useAppSelector((state) => state.user.user);
  const userRolesState = useAppSelector((state) => state.userRoles);
  const disableClearLatestTableStatesButton = useAppSelector(
    (state) => Object.keys(state.latestTableStates).length === 0
  );
  const dispatch = useAppDispatch();

  const rolesTableData = React.useMemo(() => {
    if (!userRolesState.userRoles) {
      return [];
    }

    return userRolesState.userRoles.map((userRole) => ({
      key: userRole.id,
      role: userRole.role.name,
      branch: userRole.branch ? userRole.branch.name : "Все филиалы",
    }));
  }, [userRolesState]);

  return (
    <React.Fragment>
      <SideNavigation items={[]} />
      <ContentLayout>
        <Breadcrumbs items={[ProfilePage.label]} />
        <PageHeader
          title={ProfilePage.label}
          extra={
            <React.Fragment>
              <ResetButton
                onClick={() => dispatch(clearLatestTableStates)}
                disabled={disableClearLatestTableStatesButton}
              >
                Сбросить фильтры всех списков
              </ResetButton>
              <Link to={`${AuthPage.path}${SignOutPage.path}`}>
                <Button danger>
                  <LogoutOutlined />
                  <span>Выйти</span>
                </Button>
              </Link>
            </React.Fragment>
          }
        >
          <Descriptions column={1}>
            <Descriptions.Item label="ФИО">
              {user?.surname} {user?.firstName} {user?.patronymic}
            </Descriptions.Item>
            <Descriptions.Item label="email">{user?.email}</Descriptions.Item>
            <Descriptions.Item label="Контактный телефон">
              {user?.phone} {user?.phoneDescription ? `(${user?.phoneDescription})` : null}
            </Descriptions.Item>
            <Descriptions.Item label="Дата регистрации">
              {user?.createdAt ? displayDateTime(user?.createdAt) : null}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Table
            title={() => <strong>Роли на сайте</strong>}
            columns={rolesTableColumns}
            dataSource={rolesTableData}
            pagination={false}
          />
        </PageHeader>
      </ContentLayout>
    </React.Fragment>
  );
};

ProfilePage.path = "/profile";
ProfilePage.label = "Профиль";

export default ProfilePage;
