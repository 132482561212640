import { QuerySort, QuerySortArr, CreateQueryParams } from "@nestjsx/crud-request";
import { Review } from "services/review/review.service";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { reviewService } from "services";

const fetchReviews = async (params: CreateQueryParams) => {
  const validSort: Array<QuerySort | QuerySortArr> = [];

  if (Array.isArray(params.sort)) {
    for (const sortUnit of params.sort) {
      if (Array.isArray(sortUnit) || typeof sortUnit !== "string") {
        validSort.push(sortUnit);
      } else {
        break;
      }
    }

    if (validSort.length === 0) {
      validSort.push(params.sort as QuerySortArr);
    }
  } else if (params.sort) {
    validSort.push(params.sort);
  }

  validSort.push({ field: "id", order: "DESC" });

  const reviews = await reviewService.findAll({
    fields: [
      "id",
      "branchId",
      "rate",
      "status",
      "statusUpdatedAt",
      "clientDisplayName",
      "clientPhone",
      "clientEmail",
      "operatorUserId",
      "createdAt",
      "updatedAt",
    ],
    join: [
      ["branch", ["id", "name", "cityId"]],
      ["branch.city", ["id", "name"]],
      ["operatorUser", ["id", "firstName", "surname"]],
      ["reviewDestinations", ["id", "reviewId", "reviewDestinationTypeId"]],
      ["reviewDestinations.reviewDestinationType", ["id", "name"]],
      ["reviewTags", ["id", "reviewId", "reviewTagTypeId"]],
      ["reviewTags.reviewTagType", ["id", "name"]],
      ["reviewWebLinks", ["id"]],
      ["reviewServiceOrders", ["id"]],
      ["reviewClientEmails", ["id"]],
      ["reviewClientCalls", ["id"]],
    ],
    page: params.page,
    limit: params.limit,
    sort: validSort,
    search: params.search,
  });

  return reviews as PagiantedResponse<Review>;
};

export default fetchReviews;
