import { CondOperator, QueryFilter, QueryFilterArr } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { CityTableSimpleFormDto } from "components/tables/CityTable/CityTableSimpleForm";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { cityService } from "services";
import { City } from "services/city/models/city";

export type GetCityTableData = Service<Data, Params>;

export const getCityTableData: GetCityTableData = async (
  paginatedParams,
  formData: CityTableSimpleFormDto
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<City>(paginatedParams.sorter);

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  if (formData.name) {
    filter.push({
      field: "name",
      operator: CondOperator.CONTAINS_LOW,
      value: formData.name.trim(),
    });
  }

  const cities = await cityService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort: tableQuerySorter
      ? [tableQuerySorter, { field: "id", order: "ASC" }]
      : { field: "id", order: "ASC" },
    filter,
  });

  if (Array.isArray(cities)) {
    return {
      list: cities,
      total: cities.length,
    };
  } else {
    return {
      list: cities.data,
      total: cities.total,
    };
  }
};
