import { Type } from "class-transformer";
import { UserRole } from "../../user-role/models/user-role";

export class User {
  id: string;
  firstName: string;
  patronymic?: string | null;
  surname: string;
  email: string;
  phone: string;
  phoneDescription?: string | null;
  isActive: boolean;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => UserRole)
  userRoles?: UserRole[];

  get fullName() {
    if (this.patronymic) {
      return `${this.surname} ${this.firstName} ${this.patronymic}`.trim();
    }
    return `${this.surname} ${this.firstName}`.trim();
  }

  get phoneWithDescription() {
    if (this.phoneDescription) {
      return `${this.phone} (${this.phoneDescription})`;
    }
    return this.phone;
  }
}
