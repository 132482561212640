import { reviewDestinationTypeService } from "services";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";

const fetchReviewDestinationTypes = async () => {
  const reviewDestinationTypes = await reviewDestinationTypeService.findAll({
    sort: [{ field: "name", order: "ASC" }],
  });
  return reviewDestinationTypes as ReviewDestinationType[];
};

export default fetchReviewDestinationTypes;
