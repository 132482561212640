import React from "react";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import { RoleAbility } from "services/role-ability/models/role-ability";

export interface RoleAbilityTableProps {
  tableProps: TableProps<RoleAbility>;
  sorter: SorterResult<RoleAbility> | SorterResult<RoleAbility>[];
  size?: SizeType;
  rightExtra?: ColumnsType<RoleAbility>;
}

export interface RoleAbilityTableGetColumnsParams {
  sorter: RoleAbilityTableProps["sorter"];
  rightExtra?: RoleAbilityTableProps["rightExtra"];
}

const getColumns: (params: RoleAbilityTableGetColumnsParams) => ColumnsType<RoleAbility> = (
  params
) => {
  const { sorter, rightExtra } = params;

  let subjectSortOrder: SortOrder = null;
  let createdAtSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "subject" && sorter.order) {
      subjectSortOrder = sorter.order;
    } else if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    }
  }

  const columns: ColumnsType<RoleAbility> = [
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      ellipsis: true,
      render: (_: any, record) => {
        if (record.role) {
          return record.role.name;
        }
        return record.roleId;
      },
    },
    {
      title: "Субъект",
      dataIndex: "subject",
      key: "subject",
      sorter: true,
      sortOrder: subjectSortOrder,
      ellipsis: true,
      render: (_: any, record) => {
        if (record.subjectLabel) {
          return record.subjectLabel;
        }
        return record.subject;
      },
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
      render: (_: any, record) => {
        if (record.actionLabel) {
          return record.actionLabel;
        }
        return record.action;
      },
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
  ];

  if (rightExtra) {
    columns.push(...rightExtra);
  }

  return columns;
};

const RoleAbilityTable: React.FC<RoleAbilityTableProps> = (props) => {
  const { tableProps, sorter, size, rightExtra } = props;

  const columns = React.useMemo(() => getColumns({ sorter, rightExtra }), [sorter, rightExtra]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default RoleAbilityTable;
