import { Type } from "class-transformer";

export class MyRole {
  id: string;
  userId: string;
  branch?: { id: string; name: string; isActive: boolean };
  role: { id: string; name: string };

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;
}
