import React from "react";
import { TableState } from "libs/interfaces/table-state.interface";
import { FilterValue } from "antd/es/table/interface";
import { HandleTableChange } from "./use-table-change-handler";

export function useTableFilterCleaner<RecordType>(
  currentState: TableState<RecordType>,
  handleTableChange: HandleTableChange<RecordType>,
  defaultTableFilter: Record<string, FilterValue | null> = {}
): [boolean, () => void] {
  const canClearFilter = React.useMemo(
    () =>
      Object.keys(currentState.filters).reduce((prev, curr) => {
        if (currentState.filters[curr] !== null) {
          prev.push(curr);
        }
        return prev;
      }, [] as string[]).length > 0,
    [currentState.filters]
  );

  const clearFilters = React.useCallback(() => {
    handleTableChange(currentState.pagination, defaultTableFilter, currentState.sorter, {
      currentDataSource: currentState.data,
      action: "filter",
    });
  }, [handleTableChange, currentState, defaultTableFilter]);

  return [canClearFilter, clearFilters];
}
