import React from "react";
import { Table, Form, Input } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import { Store } from "antd/lib/form/interface";
import { FormInstance } from "antd/lib/form";
import { useTableState } from "hooks/use-table-state";
import { ServiceOrder } from "services/service-order.service";
import useAsyncActionState from "hooks/use-async-action-state";
import fetchServiceOrders from "./helpers/fetch-service-orders";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import HttpErrorResult from "components/common/http-error-result";
import getSearchTableColumns from "./helpers/get-search-table-columns";
import { useTableChangeHandler } from "hooks/use-table-change-handler";
import convertTableParamsToQueryParams from "./helpers/convert-table-params-to-query-params";
import { noOp } from "libs/helpers/no-op";

export interface ServiceOrderFormSearchProps {
  serviceOrderIdsToIgnore?: string[];
  form?: FormInstance;
  onFinish?: (values: Store) => void;
}

const ServiceOrderFormSearch: React.FC<ServiceOrderFormSearchProps> = (props) => {
  const { serviceOrderIdsToIgnore = [], form, onFinish = noOp } = props;
  const [state, , setStateAsync] = useTableState<ServiceOrder>();
  const [, { error }] = useAsyncActionState(fetchServiceOrders);
  const handleTableChange = useTableChangeHandler(
    state,
    setStateAsync,
    convertTableParamsToQueryParams,
    fetchServiceOrders
  );

  const columns = getSearchTableColumns(state);

  useComponentDidMountEffect(() => {
    handleTableChange(state.pagination, state.filters, state.sorter, {
      currentDataSource: state.data,
      action: "paginate",
    });
  });

  const rowSelection: TableRowSelection<ServiceOrder> = React.useMemo(
    () => ({
      type: "radio",
      onChange: (selectedRowKeys, selectedRows) => {
        form?.setFields([{ name: "serviceOrder", value: selectedRows[0] }]);
        form?.validateFields();
      },
      getCheckboxProps: (record) => ({
        disabled: serviceOrderIdsToIgnore.includes(record.id),
        name: record.number,
      }),
    }),
    [serviceOrderIdsToIgnore, form]
  );

  if (error) {
    return <HttpErrorResult error={error} />;
  }

  return (
    <React.Fragment>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={state.loading}
        onChange={handleTableChange}
        rowSelection={rowSelection}
        tableLayout="auto"
        scroll={{ x: true }}
      />
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="serviceOrder"
          rules={[{ required: true, type: "object", message: "Выберите заказ-наряд" }]}
        >
          <Input hidden={true} />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default ServiceOrderFormSearch;
