import React from "react";
import { Form, Descriptions, Button, Space } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import { RoleAbility } from "services/role-ability/models/role-ability";
import { RoleAbilityUpsertDto } from "services/role-ability/dtos/role-ability-upsert-dto.type";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";
import RoleSelect from "components/selects/RoleSelect";
import SubjectSelect from "components/selects/SubjectSelect";
import SubjectActionSelect from "components/selects/SubjectActionSelect";

export type RoleAbilityFormDto = RoleAbilityUpsertDto;

export interface RoleAbilityFormProps {
  form?: FormInstance<RoleAbilityFormDto>;
  onFinish?: (dto: RoleAbilityUpsertDto, entityToUpdate?: RoleAbility) => void;
  entityToUpdate?: RoleAbility;
  initialValues?: Partial<RoleAbilityFormDto>;
  disabledFields?: { [key in keyof RoleAbilityFormDto]?: boolean };
  title?: string;
}

const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };

const RoleAbilityForm: React.FC<RoleAbilityFormProps> = (props) => {
  const {
    form: formProp,
    onFinish = noOp,
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Права роли",
    disabledFields: disabledFieldsProp,
  } = props;

  const [form] = Form.useForm(formProp);

  const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = {};

    if (entityToUpdate) {
      Object.assign(iV, entityToUpdate);
    }

    if (initialValuesProp) {
      Object.assign(iV, initialValuesProp);
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const [disabledFields, setDisabledFields] = React.useState<{
    action: boolean;
  }>({
    action: !initialValues?.subject,
  });

  const reset = React.useCallback(() => {
    form.resetFields();
    setDisabledFields({
      action: !initialValues?.subject,
    });
  }, [form, setDisabledFields, initialValues]);

  const handleFinish = React.useCallback(
    (dto: RoleAbilityFormDto) => {
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

  const handleSubjectSelectChange: (value: string) => void = React.useCallback(
    (value) => {
      if (value) {
        setDisabledFields({ action: false });
      } else {
        setDisabledFields({ action: true });
      }
      form.setFieldsValue({
        action: undefined,
      });
    },
    [setDisabledFields, form]
  );

  const handleSubjectSelectClear = React.useCallback(
    () => setDisabledFields({ action: true }),
    [setDisabledFields]
  );

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="roleId"
            name="roleId"
            label={<strong>Роль</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <RoleSelect disabled={disabledFieldsProp?.roleId} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="subject"
            name="subject"
            label={<strong>Субъект</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <SubjectSelect
              onChange={handleSubjectSelectChange}
              onClear={handleSubjectSelectClear}
              disabled={disabledFieldsProp?.subject}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="action"
            name="action"
            label={<strong>Действие</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <SubjectActionSelect
              subjectId={form.getFieldValue("subject") || initialValues?.subject}
              disabled={disabledFieldsProp?.action || disabledFields?.action}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата создания</strong>}>
          {displayDateTime(entityToUpdate?.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Сохранить
          </Button>
          <Button type="text" size="large" onClick={reset}>
            Сбросить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default RoleAbilityForm;
