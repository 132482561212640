import React from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "ahooks";
import { PageHeader, Spin } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import IntegrationKeyForm from "components/forms/IntegrationKeyForm";
import { integrationKeyService } from "services";
import { IntegrationKeyService } from "services/integration-key/integration-key.service";
import SettingsPage from "pages/settings/settings.page";
import IntegrationKeySettingsPage from "../integration-key/integration-key.page";

const createIntegrationKey: IntegrationKeyService["createOne"] = (dto) =>
  integrationKeyService.createOne(dto);

const AddIntegrationKeySettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, AddIntegrationKeySettingsPage.label]);

  const goBack = useGoBack();

  const history = useHistory();

  const { run, loading } = useRequest(createIntegrationKey, {
    manual: true,
    onSuccess: (data) => {
      if (data) {
        history.push(`${SettingsPage.path}${IntegrationKeySettingsPage.pathCreator(data.id)}`, {
          canGoBack: true,
        });
      }
    },
  });

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, AddIntegrationKeySettingsPage.label]} />
      <PageHeader title={AddIntegrationKeySettingsPage.label} onBack={goBack}>
        <Spin spinning={loading}>
          <IntegrationKeyForm title="" onFinish={run} />
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

AddIntegrationKeySettingsPage.label = "Добавить интеграционный ключ";
AddIntegrationKeySettingsPage.path = "/add-integration-key";

export default AddIntegrationKeySettingsPage;
