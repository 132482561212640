import { plainToClass } from "class-transformer";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "../interfaces/paginated-response.interface";
import { ReviewSite } from "./models/review-site";
import { ReviewSiteUpsertDto } from "./dtos/review-site-upsert-dto.type";

export class ReviewSiteService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<ReviewSite> | ReviewSite[]>(
      "/review-site",
      {
        params: queryParams,
      }
    );
    if (Array.isArray(response)) {
      response = plainToClass(ReviewSite, response);
    } else {
      response.data = plainToClass(ReviewSite, response.data);
    }
    return response;
  }

  async findOne(id: ReviewSite["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<ReviewSite>(`/review-site/${id}`, {
      params: queryParams,
    });
    return plainToClass(ReviewSite, response);
  }

  async createOne(dto: ReviewSiteUpsertDto) {
    const reviewSite = await this.apiDriver.post<ReviewSite>("/review-site", dto);
    return plainToClass(ReviewSite, reviewSite);
  }

  async updateOne(id: string, dto: ReviewSiteUpsertDto) {
    const updated = await this.apiDriver.patch<ReviewSite>(`/review-site/${id}`, dto);
    return plainToClass(ReviewSite, updated);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<ReviewSite>(`/review-site/${id}`, {
      params: queryParams,
    });
    return plainToClass(ReviewSite, deleted);
  }
}
