import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm, message } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import ReviewTagTypeDescription from "components/descriptions/ReviewTagTypeDescription";
import { reviewTagTypeService } from "services";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";
import ReviewTagTypeForm, { ReviewTagTypeFormDto } from "components/forms/ReviewTagTypeForm";
import { ReviewTagTypeService } from "services/review-tag-type/review-tag-type.service";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import ReviewTagTypesSettingsPage from "../review-tag-types/review-tag-types.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

export interface ReviewTagTypeSettingsPageParams {
  id: ReviewTagType["id"];
}

const fetchReviewTagType = (id: ReviewTagType["id"]) => reviewTagTypeService.findOne(id);

const updateReviewTagType: ReviewTagTypeService["updateOne"] = (id, dto) =>
  reviewTagTypeService.updateOne(id, dto);

const deleteReviewTagType = (id: ReviewTagType["id"]) => reviewTagTypeService.deleteOne(id);

const ReviewTagTypeSettingsPage: PageFCWithPathCreator = () => {
  const { id } = useParams<ReviewTagTypeSettingsPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchReviewTagType,
    data: reviewTagType,
    error: fetchReviewTagTypeError,
    loading: fetchReviewTagTypeLoading,
    refresh: refreshReviewTagType,
    mutate: mutateReviewTagType,
  } = useRequest(fetchReviewTagType, {
    manual: true,
  });

  const { runAsync: runUpdateReviewTagType, loading: updateReviewTagTypeLoading } = useRequest(
    updateReviewTagType,
    {
      manual: true,

      onError: (e, params) => {
        const [, dto] = params;
        const { name } = dto;
        if (e instanceof ApiDriverHttpError) {
          if (e.status === 409) {
            message.error(`Тег отзывов с названием "${name}" уже существует!`);
          }
        }
      },
    }
  );

  const { run: runDeleteReviewTagType, loading: deleteReviewTagTypeLoading } = useRequest(
    deleteReviewTagType,
    {
      manual: true,
      onSuccess: () => {
        history.push(`${SettingsPage.path}${ReviewTagTypesSettingsPage.path}`);
      },
    }
  );

  const [edit, setEdit] = React.useState<boolean>(false);

  useSetTitleEffect([
    SettingsPage.label,
    ReviewTagTypeSettingsPage.label,
    reviewTagType?.name || id,
  ]);

  useMount(() => {
    if (id) {
      runFetchReviewTagType(id);
    }
  });

  const handleUpdate = React.useCallback(
    async (dto: ReviewTagTypeFormDto) => {
      if (!reviewTagType) {
        return;
      }
      const updated = await runUpdateReviewTagType(reviewTagType.id, dto);
      mutateReviewTagType(updated);
      setEdit(false);
    },
    [reviewTagType, runUpdateReviewTagType, mutateReviewTagType, setEdit]
  );

  const handleDelete = React.useCallback(async () => {
    if (!reviewTagType) {
      return;
    }
    await runDeleteReviewTagType(reviewTagType.id);
    setEdit(false);
    await refreshReviewTagType();
  }, [reviewTagType, runDeleteReviewTagType, setEdit, refreshReviewTagType]);

  return (
    <ContentLayout>
      <Breadcrumbs
        items={[SettingsPage.label, ReviewTagTypeSettingsPage.label, reviewTagType?.name || id]}
      />
      <PageHeader
        title={ReviewTagTypeSettingsPage.label}
        subTitle={reviewTagType?.name}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.REVIEW_TAG_TYPE,
                  action: subjectAction.REVIEW_TAG_TYPE.UPDATE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={
                    fetchReviewTagTypeLoading || deleteReviewTagTypeLoading || !reviewTagType
                  }
                  loading={updateReviewTagTypeLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.REVIEW_TAG_TYPE,
                  action: subjectAction.REVIEW_TAG_TYPE.DELETE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={
                      fetchReviewTagTypeLoading || updateReviewTagTypeLoading || !reviewTagType
                    }
                    loading={deleteReviewTagTypeLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateReviewTagTypeLoading || deleteReviewTagTypeLoading}
                loading={fetchReviewTagTypeLoading}
                onClick={refreshReviewTagType}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchReviewTagTypeLoading} delay={500}>
          <HttpErrorResult error={fetchReviewTagTypeError} />
          {edit ? (
            <Spin spinning={updateReviewTagTypeLoading}>
              <ReviewTagTypeForm
                entityToUpdate={reviewTagType}
                onFinish={handleUpdate}
                title={""}
              />
            </Spin>
          ) : (
            reviewTagType && <ReviewTagTypeDescription data={reviewTagType} title={""} />
          )}
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

ReviewTagTypeSettingsPage.path = "/review-tag-type/:id";
ReviewTagTypeSettingsPage.pathCreator = (id) => `/review-tag-type/${id}`;
ReviewTagTypeSettingsPage.label = "Тег отзывов";

export default ReviewTagTypeSettingsPage;
