import axios, { AxiosInstance } from "axios";
import ApiDriverBaseError from "./errors/api-driver-base.error";
import ApiDriverHttpError from "./errors/api-driver-http.error";
import ApiDriverNoResponseError from "./errors/api-driver-no-response.error";
import ApiDriverRequestSetupError from "./errors/api-driver-request-setup.error";
import { noOpAsync } from "libs/helpers/no-op-async";
import { noOp } from "libs/helpers/no-op";

export interface TokenPair {
  accessToken: string;
  refreshToken: string;
}

export interface ApiVersion {
  major: number;
  minor: number;
  patch: number;
}

export interface ApiDriverConstructorParams {
  client?: AxiosInstance;
  baseUrl?: string;
  refreshTokenUrl?: string;
  signInUrl?: string;
  signOutUrl?: string;
  accessToken?: string;
  refreshToken?: string;
  onTokenPairUpdate?: (tokenPair: { accessToken?: string; refreshToken?: string }) => Promise<any>;
  onApiVersionChange?: (apiVersion: ApiVersion) => void;
}

class ApiDriver {
  private client: AxiosInstance;
  public accessToken?: string;
  public refreshToken?: string;
  private refreshTokenUrl = "/auth/refresh-token";
  private signInUrl = "/auth/sign-in";
  private signOutUrl = "/auth/sign-out";
  private refreshRequest?: Promise<any>;
  public onTokenPairUpdate: (tokenPair: {
    accessToken?: string;
    refreshToken?: string;
  }) => Promise<any>;
  private onApiVersionChange: (apiVersion: ApiVersion) => void;
  private apiVersion?: ApiVersion;

  constructor(params: ApiDriverConstructorParams = {}) {
    this.client =
      params.client ||
      axios.create({
        baseURL: params.baseUrl || "/",
        timeout: 30 * 1000, // 30 seconds
      });
    this.accessToken = params.accessToken;
    this.refreshToken = params.refreshToken;
    this.onTokenPairUpdate = params.onTokenPairUpdate || noOpAsync;
    this.onApiVersionChange = params.onApiVersionChange || noOp;
    this.refreshRequest = undefined;

    this.refreshTokenUrl = params.refreshTokenUrl || this.refreshTokenUrl;
    this.signInUrl = params.refreshTokenUrl || this.signInUrl;
    this.signOutUrl = params.refreshTokenUrl || this.signOutUrl;

    this.client.interceptors.request.use(
      (config) => {
        if (!this.accessToken) {
          return config;
        }

        const newConfig = {
          headers: {},
          ...config,
        };

        newConfig.headers.Authorization = `Bearer ${this.accessToken}`;
        return newConfig;
      },
      (e) => Promise.reject(e)
    );

    this.client.interceptors.response.use(
      (r) => r,
      async (error) => {
        if (!this.refreshToken) {
          if (error.response) {
            if (error.response.status === 401) {
              this.onTokenPairUpdate({});
            }
          }
          throw error;
        }

        if (error.config.retry) {
          throw error;
        }

        if (error.response) {
          if (error.response.status !== 401) {
            throw error;
          }
        } else {
          throw error;
        }

        if (!this.refreshRequest) {
          this.refreshRequest = this.client
            .post(this.refreshTokenUrl, {
              refreshToken: this.refreshToken,
            })
            .then((r) => r)
            .catch(
              () => ({ data: {} }) // refreshToken not found
            );
        }
        const { data } = await this.refreshRequest;
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;

        const newRequest = {
          ...error.config,
          retry: true,
        };

        if (error.config.url === this.signOutUrl) {
          newRequest.data = { refreshToken: this.refreshToken };
        }

        this.refreshRequest = undefined;
        await this.onTokenPairUpdate({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        });

        return this.client(newRequest);
      }
    );

    this.client.interceptors.response.use(
      (r) => r,
      async (error) => {
        if (
          error instanceof ApiDriverHttpError ||
          error instanceof ApiDriverNoResponseError ||
          error instanceof ApiDriverRequestSetupError
        ) {
          throw error;
        }

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(JSON.stringify(error.response.data));
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new ApiDriverHttpError(error.response.status, error.message, {
            config: {
              url: error.config.url,
              method: error.config.method,
              headers: error.config.headers,
              baseURL: error.config.baseURL,
            },
            response: {
              data: error.response.data,
              status: error.response.status,
              headers: error.response.headers,
            },
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new ApiDriverNoResponseError(error.request, error.message);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new ApiDriverRequestSetupError(error.message);
        }
      }
    );
  }

  private checkApiVersion(headers: any) {
    const version = headers["X-Version".toLowerCase()];
    if (version) {
      let [major, minor, patch] = String(version)
        .split(".")
        .map((v) => Number(v));
      if (Number.isInteger(major) && Number.isInteger(minor) && Number.isInteger(patch)) {
        if (!this.apiVersion) {
          this.apiVersion = { major, minor, patch };
          return;
        }

        if (
          major !== this.apiVersion.major ||
          minor !== this.apiVersion.minor ||
          patch !== this.apiVersion.patch
        ) {
          this.apiVersion = { major, minor, patch };
          this.onApiVersionChange(this.apiVersion);
        }
      }
    }
  }

  async setTokenPair({ accessToken, refreshToken }: TokenPair) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    await this.onTokenPairUpdate({ accessToken, refreshToken });
  }

  getTokenPair() {
    if (this.accessToken && this.refreshToken) {
      return { token: this.accessToken, refreshToken: this.refreshToken };
    }
    return undefined;
  }

  async clearTokenPair() {
    this.accessToken = undefined;
    this.refreshToken = undefined;
    await this.onTokenPairUpdate({ accessToken: undefined, refreshToken: undefined });
  }

  async get<T = any>(url: string, options = {}) {
    const { data, headers } = await this.client.get(url, options);
    this.checkApiVersion(headers);
    return data as T;
  }

  async post<T = any>(url: string, data = {}, options = {}) {
    const { data: responseData, headers } = await this.client.post(url, data, options);
    this.checkApiVersion(headers);
    return responseData as T;
  }

  async patch<T = any>(url: string, data = {}, options = {}) {
    const { data: responseData, headers } = await this.client.patch(url, data, options);
    this.checkApiVersion(headers);
    return responseData as T;
  }

  async delete<T = any>(url: string, options = {}) {
    const { data, headers } = await this.client.delete(url, options);
    this.checkApiVersion(headers);
    return data as T;
  }

  async signIn(username: string, password: string) {
    const tokenPair = await this.post<TokenPair>(this.signInUrl, {
      username,
      password,
    });
    await this.setTokenPair(tokenPair);
  }

  async signOut() {
    await this.post("/auth/sign-out", { refreshToken: this.refreshToken });
    await this.clearTokenPair();
  }
}

export {
  ApiDriver,
  ApiDriverBaseError,
  ApiDriverHttpError,
  ApiDriverNoResponseError,
  ApiDriverRequestSetupError,
};
export default ApiDriver;
