import React from "react";
import { useHistory } from "react-router-dom";
import { Spin, message, Form, Input, Button, Alert } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import Breadcrumbs from "components/common/breadcrumbs";
import useQuery from "hooks/use-query";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import useAsyncActionState from "hooks/use-async-action-state";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import AuthPage from "pages/auth/auth.page";
import { userService } from "services";

const verifyRestoreTokenHelper = (restoreToken: string) =>
  userService.verifyRestoreToken(restoreToken);
const resetPasswordHelper = (payload: { restoreToken: string; newPassword: string }) =>
  userService.resetPassword(payload.restoreToken, payload.newPassword);

const ResetPasswordPage: PageFunctionComponent = () => {
  useSetTitleEffect([ResetPasswordPage.label]);

  const history = useHistory();
  const query = useQuery();
  const restoreToken = query["restore-token"];

  const [state, setState] = React.useState<{ maskedEmail?: string; finish: boolean }>({
    finish: false,
  });

  const [
    verifyRestoreToken,
    { loading: verifyRestoreTokenLoading, error: verifyRestoreTokenError },
  ] = useAsyncActionState(verifyRestoreTokenHelper);
  const [resetPassword, { loading: resetPasswordLoading, error: resetPasswordError }] =
    useAsyncActionState(resetPasswordHelper);

  React.useEffect(() => {
    const error = resetPasswordError as ApiDriverHttpError;
    if (error) {
      let content = "Ошибка!";
      if (error.status === 404) {
        content = "Срок действия ссылки на восстановление пароля истек.";
      } else if (error.status === 429) {
        content = "Слишком много запросов. Попробуйте позже.";
      } else if (error.status >= 500) {
        content = "Ошибка сервера.";
      }
      message.error(content);
    }
  }, [resetPasswordError]);

  useComponentDidMountEffect(() => {
    verifyRestoreToken(String(restoreToken)).then(({ result }) => {
      setState((currentState) => ({ ...currentState, maskedEmail: result?.maskedEmail }));
    });
  });

  const loading = verifyRestoreTokenLoading || resetPasswordLoading;

  const handleFinish = async (values: any) => {
    const { newPassword } = values;
    const { error } = await resetPassword({ restoreToken: String(restoreToken), newPassword });
    if (!error) {
      setState((currentState) => ({ ...currentState, finish: true }));
    }
  };

  if (verifyRestoreTokenError) {
    const error = verifyRestoreTokenError as ApiDriverHttpError;

    let content = "Ошибка!";
    if (error.status === 404) {
      content = "Срок действия ссылки на восстановление пароля истек.";
    } else if (error.status === 429) {
      content = "Слишком много запросов. Попробуйте позже.";
    } else if (error.status >= 500) {
      content = "Ошибка сервера.";
    }

    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Breadcrumbs items={[ResetPasswordPage.label]} />
        <a
          className="restore-password-form-back"
          href={`${AuthPage.path}`}
          onClick={(e) => {
            e.preventDefault();
            history.push(`${AuthPage.path}`);
          }}
        >
          Назад к форме входа
        </a>
        <br />
        <br />
        <Alert message={content} type="error" showIcon />
      </div>
    );
  }

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Breadcrumbs items={[ResetPasswordPage.label]} />
      <Spin spinning={loading}>
        {state.finish ? (
          <React.Fragment>
            <a
              className="restore-password-form-back"
              href={`${AuthPage.path}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`${AuthPage.path}`);
              }}
            >
              Назад к форме входа
            </a>
            <br />
            <br />
            <Alert
              message={
                <span>
                  Для пользователя с логином <strong>{state.maskedEmail}</strong> задан новый
                  пароль.
                </span>
              }
              type="success"
              showIcon
            />
          </React.Fragment>
        ) : (
          <Form name="reset-password" size="large" onFinish={handleFinish}>
            <Form.Item
              name="newPassword"
              label="Новый пароль"
              rules={[
                { required: true, message: "Это поле должно быть заполнено" },
                {
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/,
                  message: "Пароль должен содержать строчные и заглавные латинские буквы, цифры",
                },
                {
                  type: "string",
                  min: 8,
                  message: "Пароль должен содержать минимум 8 символов",
                },
              ]}
            >
              <Input.Password type="newPassword" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Назначить новый пароль
              </Button>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </div>
  );
};

ResetPasswordPage.path = "/reset-password";
ResetPasswordPage.label = "Задать новый пароль";

export default ResetPasswordPage;
