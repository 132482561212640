import React from "react";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { Redirect } from "react-router-dom";
import ReviewsPage from "pages/reviews/reviews.page";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import ServiceOrdersPage from "pages/service-orders/service-orders.page";
import BranchesPage from "pages/branches/branches.page";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";

const IndexPage: PageFunctionComponent = () => {
  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const pathname = React.useMemo(() => {
    if (pageAccessControlState.loading || pageAccessControlState.abilities.length === 0) {
      return IndexPage.path;
    }

    if (
      checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.REVIEW,
            action: subjectAction.REVIEW.READ_ALL,
          },
          {
            subject: Subject.REVIEW,
            action: subjectAction.REVIEW.READ_OWN,
          },
        ],
      })
    ) {
      return ReviewsPage.path;
    } else if (
      checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.SERVICE_ORDER,
        action: subjectAction.SERVICE_ORDER.READ_ALL,
      })
    ) {
      return ServiceOrdersPage.path;
    }

    return BranchesPage.path;
  }, [pageAccessControlState]);

  if (pathname === IndexPage.path) {
    return null;
  }

  return <Redirect to={{ pathname }} />;
};

IndexPage.path = "/";
IndexPage.label = "Главная";

export default IndexPage;
