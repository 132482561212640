import { Type } from "class-transformer";
import { Review } from "../review.service";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";
import { PartialBy } from "libs/types/partial-by.type";

export class ReviewTag {
  id: string;
  reviewId: string;
  reviewTagTypeId: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Review)
  review?: Review;

  @Type(() => ReviewTagType)
  reviewTagType?: ReviewTagType;
}

export type ReviewTagPartialById = PartialBy<ReviewTag, "id">;
