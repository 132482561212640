import { Type } from "class-transformer";
import { Review } from "../review.service";
import { PartialBy } from "libs/types/partial-by.type";

export class ReviewClientEmail {
  id: string;
  reviewId: string;
  clientEmail: string;
  clientEmailContent: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Review)
  review?: Review;
}

export type ReviewClientEmailPartialById = PartialBy<ReviewClientEmail, "id">;
