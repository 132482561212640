import React from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "ahooks";
import { message, PageHeader, Spin } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import ReviewTagTypeForm from "components/forms/ReviewTagTypeForm";
import { reviewTagTypeService } from "services";
import { ReviewTagTypeService } from "services/review-tag-type/review-tag-type.service";
import SettingsPage from "pages/settings/settings.page";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import ReviewTagTypeSettingsPage from "../review-tag-type/review-tag-type.page";

const createReviewTagType: ReviewTagTypeService["createOne"] = (dto) =>
  reviewTagTypeService.createOne(dto);

const AddReviewTagTypeSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, AddReviewTagTypeSettingsPage.label]);

  const goBack = useGoBack();

  const history = useHistory();

  const { run, loading } = useRequest(createReviewTagType, {
    manual: true,
    onSuccess: (data) => {
      if (data) {
        history.push(`${SettingsPage.path}${ReviewTagTypeSettingsPage.pathCreator(data.id)}`, {
          canGoBack: true,
        });
      }
    },

    onError: (e, params) => {
      const [dto] = params;
      const { name } = dto;
      if (e instanceof ApiDriverHttpError) {
        if (e.status === 409) {
          message.error(`Тег отзывов с названием "${name}" уже существует!`);
        }
      }
    },
  });

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, AddReviewTagTypeSettingsPage.label]} />
      <PageHeader title={AddReviewTagTypeSettingsPage.label} onBack={goBack}>
        <Spin spinning={loading}>
          <ReviewTagTypeForm title="" onFinish={run} />
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

AddReviewTagTypeSettingsPage.label = "Добавить тег отзыва";
AddReviewTagTypeSettingsPage.path = "/add-review-tag-type";

export default AddReviewTagTypeSettingsPage;
