import { Type } from "class-transformer";
import { Review } from "../review.service";
import { ReviewSite } from "services/review-site/models/review-site";
import { PartialBy } from "libs/types/partial-by.type";

export class ReviewWebLink {
  id: string;
  reviewId: string;
  reviewSiteId: string;
  link?: string;
  clientNickname?: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Review)
  review?: Review;

  @Type(() => ReviewSite)
  reviewSite?: ReviewSite;
}

export type ReviewWebLinkPartialById = PartialBy<ReviewWebLink, "id">;
