import { Type } from "class-transformer";
import { City } from "services/city/models/city";

export class Branch {
  id!: string;
  guid1c?: string | null;
  cityId?: string | null;
  name!: string;
  isActive!: boolean;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  @Type(() => City)
  city?: City;
}
