import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function useQuery() {
  const location = useLocation();
  //return new URLSearchParams(location.search);
  const query = useMemo(() => queryString.parse(location.search), [location.search]);
  return query;
}

export default useQuery;
