import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { StopOutlined, PlusOutlined } from "@ant-design/icons";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { SideNavigationItem } from "libs/interfaces/side-navigation-item.interface";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import usePath from "hooks/use-path";
import SideNavigation from "components/common/side-navigation";
import Breadcrumbs from "components/common/breadcrumbs";
import ContentLayout from "components/layouts/content.layout";
import NotFoundErrorPage from "pages/not-found-error/not-found-error.page";
import BlacklistPage from "./pages/blacklist/blacklist.page";
import AddToBlacklistPage from "./pages/add-to-blacklist/add-to-blacklist.page";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";
import CheckAbilities from "store/components/CheckAbilities";

const ClientsPage: PageFunctionComponent = () => {
  const path = usePath(2);

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);
  const pageAccessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        AddToBlacklistPage: false,
      };
    }

    return {
      AddToBlacklistPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.BLACKLIST_CLIENT,
        action: subjectAction.BLACKLIST_CLIENT.CREATE,
      }),
    };
  }, [pageAccessControlState]);

  const sideNavigationItems = React.useMemo(() => {
    const items: SideNavigationItem[] = [];

    items.push({
      key: `${ClientsPage.path}${BlacklistPage.path}`,
      pathname: `${ClientsPage.path}${BlacklistPage.path}`,
      icon: <StopOutlined />,
      label: BlacklistPage.label,
    });

    if (pageAccessControl.AddToBlacklistPage) {
      items.push({
        key: `${ClientsPage.path}${AddToBlacklistPage.path}`,
        pathname: `${ClientsPage.path}${AddToBlacklistPage.path}`,
        icon: <PlusOutlined />,
        label: AddToBlacklistPage.label,
      });
    }

    return items;
  }, [pageAccessControl]);

  return (
    <React.Fragment>
      <SideNavigation selectedKey={path} items={sideNavigationItems} />
      <Switch>
        <Route exact path={ClientsPage.path}>
          <Redirect to={`${ClientsPage.path}${BlacklistPage.path}`} />
        </Route>
        <Route path={`${ClientsPage.path}${BlacklistPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ClientsPage.label, BlacklistPage.label]} />
            <BlacklistPage />
          </ContentLayout>
        </Route>
        <Route path={`${ClientsPage.path}${AddToBlacklistPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ClientsPage.label, AddToBlacklistPage.label]} />
            <CheckAbilities manual={pageAccessControl.AddToBlacklistPage}>
              <AddToBlacklistPage />
            </CheckAbilities>
          </ContentLayout>
        </Route>
        <Route path={`${ClientsPage.path}${NotFoundErrorPage.path}`}>
          <NotFoundErrorPage />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

ClientsPage.path = "/clients";
ClientsPage.label = "Клиенты";

export default ClientsPage;
