import React from "react";
import { useHistory } from "react-router-dom";
import { useAntdTable } from "ahooks";
import { Form, Button, Row, Col, PageHeader } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import useGoBack from "hooks/use-go-back";
import { getUserTableData } from "./get-user-table-data";
import UserTableSimpleForm, {
  UserTableSimpleFormDto,
} from "components/tables/UserTable/UserTableSimpleForm";
import AddButton from "components/buttons/add-button";
import AddUserSettingsPage from "../add-user/add-user.page";
import RefreshButton from "components/buttons/refresh-button";
import UserTable from "components/tables/UserTable/UserTable";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

const UsersSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, UsersSettingsPage.label]);

  const goBack = useGoBack();
  const history = useHistory();
  const [form] = Form.useForm<UserTableSimpleFormDto>();

  const { tableProps, loading, refresh, search, params } = useAntdTable(getUserTableData, {
    form,
    defaultType: "simple",
    cacheKey: "UsersPageTable",
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [{ current: 1, pageSize: 10, sorter: { field: "fullName", order: "ascend" } }],
  });

  const { type, changeType, submit, reset } = search || {};
  const { sorter = {} } = params[0] || ({} as any);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, UsersSettingsPage.label]} />
      <PageHeader
        title={UsersSettingsPage.label}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[{ subject: Subject.USER, action: subjectAction.USER.CREATE }]}
              placeholder={null}
              loading={loading}
            >
              <Col>
                <AddButton
                  onClick={() =>
                    history.push({
                      pathname: `${SettingsPage.path}${AddUserSettingsPage.path}`,
                      state: { canGoBack: true },
                    })
                  }
                >
                  Добавить
                </AddButton>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton loading={loading} onClick={refresh} />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {type === "advance" ? null : (
            <UserTableSimpleForm form={form} submit={submit} reset={reset} />
          )}
          <Button type="link" onClick={changeType} hidden>
            {type === "simple" ? "Расширенный поиск" : "Простой поиск"}
          </Button>
        </div>

        <UserTable tableProps={tableProps} sorter={sorter} />
      </PageHeader>
    </ContentLayout>
  );
};

UsersSettingsPage.path = "/users";
UsersSettingsPage.label = "Пользователи";

export default UsersSettingsPage;
