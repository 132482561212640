import React from "react";
import { Select } from "antd";
import { useMount, useRequest } from "ahooks";
import { roleService } from "services";
import { Role } from "services/role/models/role";

const fetchRoles = () =>
  roleService.findAll({
    fields: ["id", "name"],
    sort: [
      { field: "name", order: "ASC" },
      { field: "id", order: "ASC" },
    ],
    limit: 1000,
  });

export interface RoleSelectProps {
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  disabled?: boolean;
}

const RoleSelect: React.FC<RoleSelectProps> = React.forwardRef<
  any, // :(
  RoleSelectProps
>((props, ref) => {
  const { run, data, loading } = useRequest(fetchRoles, {
    manual: true,
  });

  useMount(() => {
    run();
  });

  if (!data) {
    return (
      <Select
        ref={ref}
        {...props}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children"
      />
    );
  }

  const mapData = (item: Role) => (
    <Select.Option key={`RoleSelect_${item.id}`} value={item.id}>
      {item.name}
    </Select.Option>
  );

  return (
    <Select
      ref={ref}
      {...props}
      loading={loading}
      allowClear
      showSearch
      optionFilterProp="children"
    >
      {Array.isArray(data) ? data.map(mapData) : data.data.map(mapData)}
    </Select>
  );
});

export default RoleSelect;
