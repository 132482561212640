import React from "react";
import { Form, Input } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { noOp } from "libs/helpers/no-op";

export interface UserTableSimpleFormDto {
  firstNameOrPatronymicOrSurnameOrPhoneOrEmail?: string;
}

export interface UserTableSimpleFormProps {
  form?: FormInstance<UserTableSimpleFormDto>;
  submit?: () => void;
  reset?: () => void;
}

const UserTableSimpleForm: React.FC<UserTableSimpleFormProps> = (props) => {
  const { form: formProp, submit = noOp /*, reset = noOp */ } = props;

  const [form] = Form.useForm(formProp);

  return (
    <Form layout="inline" form={form}>
      <Form.Item
        name="firstNameOrPatronymicOrSurnameOrPhoneOrEmail"
        label="Фамилия или имя или телефон или email"
      >
        <Input.Search onSearch={submit} allowClear />
      </Form.Item>
    </Form>
  );
};

export default UserTableSimpleForm;
