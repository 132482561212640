import { TableState } from "libs/interfaces/table-state.interface";
import { Review } from "services/review/review.service";
import { ServiceOrder } from "services/service-order.service";

export interface LatestTableStatesState {
  allReviewsTableState?: TableState<Review>;
  allServiceOrdersTableState?: TableState<ServiceOrder>;
  creatingReviewsTableState?: TableState<Review>;
  processingReviewsTableState?: TableState<Review>;
  reviewsFromSitesTableState?: TableState<Review>;
  serviceOrdersWithoutReviewTableState?: TableState<ServiceOrder>;
}

export enum LatestTableStatesActionType {
  SET_ALL_REVIEWS_TABLE_STATE = "SET_ALL_REVIEWS_TABLE_STATE",
  SET_ALL_SERVICE_ORDERS_TABLE_STATE = "SET_ALL_SERVICE_ORDERS_TABLE_STATE",
  SET_CREATING_REVIEWS_TABLE_STATE = "SET_CREATING_REVIEWS_TABLE_STATE",
  SET_PROCESSING_REVIEWS_TABLE_STATE = "SET_PROCESSING_REVIEWS_TABLE_STATE",
  SET_REVIEWS_FROM_SITES_TABLE_STATE = "SET_REVIEWS_FROM_SITES_TABLE_STATE",
  SET_SERVICE_ORDERS_WITHOUT_REVIEW = "SET_SERVICE_ORDERS_WITHOUT_REVIEW",
  CLEAR_LATEST_TABLE_STATES = "CLEAR_LATEST_TABLE_STATES",
}

export interface SetAllReviewsTableState {
  type: LatestTableStatesActionType.SET_ALL_REVIEWS_TABLE_STATE;
  payload: TableState<Review>;
}

export interface SetAllServiceOrdersTableState {
  type: LatestTableStatesActionType.SET_ALL_SERVICE_ORDERS_TABLE_STATE;
  payload: TableState<ServiceOrder>;
}

export interface SetCreatingReviewsTableState {
  type: LatestTableStatesActionType.SET_CREATING_REVIEWS_TABLE_STATE;
  payload: TableState<Review>;
}

export interface SetProcessingReviewsTableState {
  type: LatestTableStatesActionType.SET_PROCESSING_REVIEWS_TABLE_STATE;
  payload: TableState<Review>;
}

export interface SetReviewsFromSitesTableState {
  type: LatestTableStatesActionType.SET_REVIEWS_FROM_SITES_TABLE_STATE;
  payload: TableState<Review>;
}

export interface SetServiceOrdersWithoutReviewAction {
  type: LatestTableStatesActionType.SET_SERVICE_ORDERS_WITHOUT_REVIEW;
  payload: TableState<ServiceOrder>;
}

export interface ClearLatestTableStatesAction {
  type: LatestTableStatesActionType.CLEAR_LATEST_TABLE_STATES;
}

export type LatestTableStatesAction =
  | SetAllReviewsTableState
  | SetAllServiceOrdersTableState
  | SetCreatingReviewsTableState
  | SetProcessingReviewsTableState
  | SetReviewsFromSitesTableState
  | SetServiceOrdersWithoutReviewAction
  | ClearLatestTableStatesAction;

const initialState: LatestTableStatesState = {};

export default function latestTableStatesReducer(
  state = initialState,
  action: LatestTableStatesAction
): LatestTableStatesState {
  switch (action.type) {
    case LatestTableStatesActionType.SET_ALL_REVIEWS_TABLE_STATE:
      return { ...state, allReviewsTableState: action.payload };
    case LatestTableStatesActionType.SET_ALL_SERVICE_ORDERS_TABLE_STATE:
      return { ...state, allServiceOrdersTableState: action.payload };
    case LatestTableStatesActionType.SET_CREATING_REVIEWS_TABLE_STATE:
      return { ...state, creatingReviewsTableState: action.payload };
    case LatestTableStatesActionType.SET_PROCESSING_REVIEWS_TABLE_STATE:
      return { ...state, processingReviewsTableState: action.payload };
    case LatestTableStatesActionType.SET_REVIEWS_FROM_SITES_TABLE_STATE:
      return { ...state, reviewsFromSitesTableState: action.payload };
    case LatestTableStatesActionType.SET_SERVICE_ORDERS_WITHOUT_REVIEW:
      return { ...state, serviceOrdersWithoutReviewTableState: action.payload };
    case LatestTableStatesActionType.CLEAR_LATEST_TABLE_STATES:
      return initialState;
    default:
      return state;
  }
}
