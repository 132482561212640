import { reviewService } from "services";

export interface CreateReviewChatMessagePayload {
  reviewId: string;
  message: string;
}

const createReviewChatMessage = (payload: CreateReviewChatMessagePayload) =>
  reviewService.createOneReviewChatMessage(payload.reviewId, payload.message);

export default createReviewChatMessage;
