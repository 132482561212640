import { reviewService } from "services";

const fetchReview = (id: string) =>
  reviewService.findOne(id, {
    join: [
      ["reviewClientCalls"],
      ["reviewClientEmails"],
      ["reviewDestinations"],
      ["reviewDestinations.reviewDestinationType"],
      ["reviewServiceOrders"],
      ["reviewServiceOrders.serviceOrder"],
      ["reviewServiceOrders.serviceOrder.serviceOrderWorks"],
      ["reviewWebLinks"],
      ["reviewWebLinks.reviewSite"],
      ["reviewTags"],
    ],
  });

export default fetchReview;
