import React from "react";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/lib/button";

interface RemoveButtonProps extends ButtonProps {
  children?: string;
}

const RemoveButton = React.forwardRef<any, RemoveButtonProps>((props, ref) => {
  const { children, loading, ...restProps } = props;
  return (
    <span ref={ref}>
      <Button {...restProps} loading={loading}>
        <DeleteOutlined />
        {children ? <span>{children}</span> : null}
      </Button>
    </span>
  );
});

export default RemoveButton;
