import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import IntegrationKeyDescription from "components/descriptions/IntegrationKeyDescription";
import { integrationKeyService } from "services";
import { IntegrationKey } from "services/integration-key/models/integration-key";
import IntegrationKeyForm, { IntegrationKeyFormDto } from "components/forms/IntegrationKeyForm";
import { IntegrationKeyService } from "services/integration-key/integration-key.service";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import IntegrationKeysSettingsPage from "../integration-keys/integration-keys.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

export interface IntegrationKeySettingsPageParams {
  id: IntegrationKey["id"];
}

const fetchIntegrationKey = (id: IntegrationKey["id"]) => integrationKeyService.findOne(id);

const updateIntegrationKey: IntegrationKeyService["updateOne"] = (id, dto) =>
  integrationKeyService.updateOne(id, dto);

const deleteIntegrationKey = (id: IntegrationKey["id"]) => integrationKeyService.deleteOne(id);

const IntegrationKeySettingsPage: PageFCWithPathCreator = () => {
  const { id } = useParams<IntegrationKeySettingsPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchIntegrationKey,
    data: integrationKey,
    error: fetchIntegrationKeyError,
    loading: fetchIntegrationKeyLoading,
    refresh: refreshIntegrationKey,
    mutate: mutateIntegrationKey,
  } = useRequest(fetchIntegrationKey, {
    manual: true,
  });

  const { runAsync: runUpdateIntegrationKey, loading: updateIntegrationKeyLoading } = useRequest(
    updateIntegrationKey,
    {
      manual: true,
    }
  );

  const { run: runDeleteIntegrationKey, loading: deleteIntegrationKeyLoading } = useRequest(
    deleteIntegrationKey,
    {
      manual: true,
      onSuccess: () => {
        history.push(`${SettingsPage.path}${IntegrationKeysSettingsPage.path}`);
      },
    }
  );

  const [edit, setEdit] = React.useState<boolean>(false);

  useSetTitleEffect([SettingsPage.label, IntegrationKeySettingsPage.label, id]);

  useMount(() => {
    if (id) {
      runFetchIntegrationKey(id);
    }
  });

  const handleUpdate = React.useCallback(
    async (dto: IntegrationKeyFormDto) => {
      if (!integrationKey) {
        return;
      }
      const updated = await runUpdateIntegrationKey(integrationKey.id, dto);
      mutateIntegrationKey(updated);
      setEdit(false);
    },
    [integrationKey, runUpdateIntegrationKey, mutateIntegrationKey, setEdit]
  );

  const handleDelete = React.useCallback(async () => {
    if (!integrationKey) {
      return;
    }
    await runDeleteIntegrationKey(integrationKey.id);
    setEdit(false);
    await refreshIntegrationKey();
  }, [integrationKey, runDeleteIntegrationKey, setEdit, refreshIntegrationKey]);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, IntegrationKeySettingsPage.label, id]} />
      <PageHeader
        title={IntegrationKeySettingsPage.label}
        subTitle={id}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.INTEGRATION_KEY,
                  action: subjectAction.INTEGRATION_KEY.UPDATE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={
                    fetchIntegrationKeyLoading || deleteIntegrationKeyLoading || !integrationKey
                  }
                  loading={updateIntegrationKeyLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.INTEGRATION_KEY,
                  action: subjectAction.INTEGRATION_KEY.DELETE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={
                      fetchIntegrationKeyLoading || updateIntegrationKeyLoading || !integrationKey
                    }
                    loading={deleteIntegrationKeyLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateIntegrationKeyLoading || deleteIntegrationKeyLoading}
                loading={fetchIntegrationKeyLoading}
                onClick={refreshIntegrationKey}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchIntegrationKeyLoading} delay={500}>
          <HttpErrorResult error={fetchIntegrationKeyError} />
          {edit ? (
            <Spin spinning={updateIntegrationKeyLoading}>
              <IntegrationKeyForm
                entityToUpdate={integrationKey}
                onFinish={handleUpdate}
                title={""}
              />
            </Spin>
          ) : (
            integrationKey && <IntegrationKeyDescription data={integrationKey} title={""} />
          )}
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

IntegrationKeySettingsPage.path = "/integration-key/:id";
IntegrationKeySettingsPage.pathCreator = (id) => `/integration-key/${id}`;
IntegrationKeySettingsPage.label = "Интеграционный ключ";

export default IntegrationKeySettingsPage;
