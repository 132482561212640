import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import ReminderDescription from "components/descriptions/ReminderDescription";
import { reminderService } from "services";
import { Reminder } from "services/reminder/models/reminder";
import ReminderForm, { ReminderFormDto } from "components/forms/ReminderForm";
import { ReminderService } from "services/reminder/reminder.service";
import { useSetTitleEffect } from "store/hooks";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import AllRemindersPage from "../../pages/all/all.page";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import RemindersPage from "pages/reminders/reminders.page";
import CheckAbilities from "store/components/CheckAbilities";

export interface ReminderCardPageParams {
  id: Reminder["id"];
}

const fetchReminder = (id: Reminder["id"]) => reminderService.findOne(id, { join: ["user"] });

const updateReminder: ReminderService["updateOne"] = (id, dto) =>
  reminderService.updateOne(id, dto);

const deleteReminder = (id: Reminder["id"]) => reminderService.deleteOne(id);

const ReminderCardPage: PageFCWithPathCreator = () => {
  const { id } = useParams<ReminderCardPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchReminder,
    data: reminder,
    error: fetchReminderError,
    loading: fetchReminderLoading,
    refresh: refreshReminder,
    mutate: mutateReminder,
  } = useRequest(fetchReminder, {
    manual: true,
  });

  const { runAsync: runUpdateReminder, loading: updateReminderLoading } = useRequest(
    updateReminder,
    {
      manual: true,
    }
  );

  const { run: runDeleteReminder, loading: deleteReminderLoading } = useRequest(deleteReminder, {
    manual: true,
    onSuccess: () => {
      history.push(`${RemindersPage.path}${AllRemindersPage.path}`);
    },
  });

  const [edit, setEdit] = React.useState<boolean>(false);

  useSetTitleEffect([RemindersPage.label, ReminderCardPage.label, reminder?.title || id]);

  useMount(() => {
    if (id) {
      runFetchReminder(id);
    }
  });

  const handleUpdate = React.useCallback(
    async (dto: ReminderFormDto) => {
      if (!reminder) {
        return;
      }
      const updated = await runUpdateReminder(reminder.id, dto);
      mutateReminder(updated);
      setEdit(false);
    },
    [reminder, runUpdateReminder, mutateReminder, setEdit]
  );

  const handleDelete = React.useCallback(async () => {
    if (!reminder) {
      return;
    }
    await runDeleteReminder(reminder.id);
    setEdit(false);
    await refreshReminder();
  }, [reminder, runDeleteReminder, setEdit, refreshReminder]);

  return (
    <ContentLayout>
      <Breadcrumbs items={[RemindersPage.label, ReminderCardPage.label, reminder?.title || id]} />
      <PageHeader
        title={ReminderCardPage.label}
        subTitle={reminder?.title}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.REMINDER,
                  action: subjectAction.REMINDER.UPDATE_ALL,
                },
                {
                  subject: Subject.REMINDER,
                  action: subjectAction.REMINDER.UPDATE_OWN,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={fetchReminderLoading || deleteReminderLoading || !reminder}
                  loading={updateReminderLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.REMINDER,
                  action: subjectAction.REMINDER.DELETE_ALL,
                },
                {
                  subject: Subject.REMINDER,
                  action: subjectAction.REMINDER.DELETE_OWN,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={fetchReminderLoading || updateReminderLoading || !reminder}
                    loading={deleteReminderLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateReminderLoading || deleteReminderLoading}
                loading={fetchReminderLoading}
                onClick={refreshReminder}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchReminderLoading} delay={500}>
          <HttpErrorResult error={fetchReminderError} />
          {edit ? (
            <Spin spinning={updateReminderLoading}>
              <ReminderForm entityToUpdate={reminder} onFinish={handleUpdate} title={""} />
            </Spin>
          ) : (
            reminder && <ReminderDescription data={reminder} title={""} />
          )}
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

ReminderCardPage.path = "/card/:id";
ReminderCardPage.pathCreator = (id) => `/card/${id}`;
ReminderCardPage.label = "Напоминание";

export default ReminderCardPage;
