import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Spin, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./index.page.css";
import { useAppSelector, useSetTitleEffect } from "store/hooks";
import Breadcrumbs from "components/common/breadcrumbs";
import AuthPage from "pages/auth/auth.page";
import RestorePasswordPage from "../restore-password/restore-password.page";
import { signIn } from "store/actions/user";
import { useAppDispatch } from "store/hooks";

export let signInPromise: Promise<void> | undefined;

const AuthIndexPage: React.FC = () => {
  useSetTitleEffect(["Войти"]);
  const history = useHistory();

  const userState = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (userState.errorMessage) {
      message.error(userState.errorMessage);
    }
  }, [userState.errorMessage]);

  const onFinish = (values: any) => {
    const username = String(values.username).toLocaleLowerCase().trim();
    const password = String(values.password).trim();
    signInPromise = dispatch(signIn(username, password)).then(() => {
      signInPromise = undefined;
    });
  };

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Breadcrumbs items={["Войти"]} />
      <Spin spinning={userState.loading}>
        <Form name="login" onFinish={onFinish} size="large">
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Введите логин или email" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Логин или email"
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: "Введите пароль" }]}>
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Пароль"
            />
          </Form.Item>
          <Form.Item>
            <a
              className="login-form-forgot"
              href={`${AuthPage.path}${RestorePasswordPage.path}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`${AuthPage.path}${RestorePasswordPage.path}`);
              }}
            >
              Забыли пароль?
            </a>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Войти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default AuthIndexPage;
