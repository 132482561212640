import ApiDriverBaseError from "./api-driver-base.error";

class ApiDriverNoResponseError extends ApiDriverBaseError {
  request: any;

  constructor(...args: any[]) {
    super(...args);

    if (ApiDriverBaseError.captureStackTrace) {
      ApiDriverBaseError.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new ApiDriverBaseError().stack;
    }

    const [request, message] = args;
    this.request = request;
    this.message = message || "ApiDriverNoResponseError";
  }
}

export default ApiDriverNoResponseError;
