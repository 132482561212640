import React from "react";
import dayjs from "dayjs";
import { Form, Input, Button, Radio, DatePicker } from "antd";
import { Store } from "antd/lib/form/interface";
import { FormInstance } from "antd/lib/form";
import {
  ReviewServiceOrder,
  ReviewServiceOrderPartialById,
} from "services/models/review-service-order.model";
import { noOp } from "libs/helpers/no-op";
import ServiceOrderFormSearch from "./service-order-search-form";
import { ServiceOrder } from "services/service-order.service";

export interface ReviewServiceOrderFormProps {
  currentReviewServiceOrders?: ReviewServiceOrderPartialById[];
  form?: FormInstance;
  onFinish?: (reviewServiceOrder: ReviewServiceOrder) => void;
}

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const ReviewServiceOrderForm: React.FC<ReviewServiceOrderFormProps> = (props) => {
  const { currentReviewServiceOrders, form, onFinish = noOp } = props;
  const [mode, setMode] = React.useState<"search" | "manual">("search");

  const handleFinish = React.useCallback(
    (values: Store) => {
      const reviewServiceOrder = new ReviewServiceOrder();

      if (mode === "search") {
        reviewServiceOrder.serviceOrder = values.serviceOrder as ServiceOrder;
        reviewServiceOrder.serviceOrderId = reviewServiceOrder.serviceOrder.id;
      } else if (mode === "manual") {
        reviewServiceOrder.serviceOrderNumber = String(values.serviceOrderNumber).trim();
        reviewServiceOrder.serviceOrderOpenedAt = dayjs(values.serviceOrderOpenedAt).toDate();
      }

      onFinish(reviewServiceOrder);
    },
    [mode, onFinish]
  );

  const serviceOrderIdsToIgnore = React.useMemo(
    () =>
      currentReviewServiceOrders
        ?.map((reviewServiceOrder) =>
          reviewServiceOrder.serviceOrder
            ? reviewServiceOrder.serviceOrder.id
            : reviewServiceOrder.serviceOrderId
        )
        .filter((v) => !!v),
    [currentReviewServiceOrders]
  );

  return (
    <React.Fragment>
      <Radio.Group onChange={(event) => setMode(event.target.value)} value={mode}>
        <Radio.Button value="search">Поиск</Radio.Button>
        <Radio.Button value="manual">Ручной ввод</Radio.Button>
      </Radio.Group>
      <div style={{ marginTop: 16 }}>
        {mode === "search" && (
          <ServiceOrderFormSearch
            serviceOrderIdsToIgnore={serviceOrderIdsToIgnore}
            form={form}
            onFinish={handleFinish}
          />
        )}
        {mode === "manual" && (
          <Form form={form} {...layout} onFinish={handleFinish}>
            <Form.Item
              label="Номер заказ-наряда"
              name="serviceOrderNumber"
              rules={[{ required: true, message: "Это поле должно быть заполнено" }]}
              validateTrigger={"onBlur"}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Дата открытия заказ-наряда"
              name="serviceOrderOpenedAt"
              rules={[
                { type: "object", required: true, message: "Это поле должно быть заполнено" },
              ]}
              validateTrigger={"onBlur"}
            >
              <DatePicker showTime />
            </Form.Item>
            {!form && (
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            )}
          </Form>
        )}
      </div>
    </React.Fragment>
  );
};

export default ReviewServiceOrderForm;
