import { Type } from "class-transformer";
import { Role } from "services/role/models/role";

export class RoleMailing {
  id!: string;
  roleId!: string;
  emailType!: string;

  @Type(() => Date)
  createdAt: Date;

  emailTypeLabel?: string;

  @Type(() => Role)
  role?: Role;
}
