import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ConfigProvider, BackTop, Spin } from "antd";
import ruRULocale from "antd/es/locale/ru_RU";
import { ConfigProviderProps } from "antd/lib/config-provider";
// import dayjs from "dayjs";
// import "dayjs/locale/ru";
import "moment/locale/ru";
import Title from "store/components/Title";
import AuthPage from "pages/auth/auth.page";
import SignOutPage from "pages/auth/pages/sign-out/sign-out.page";
import BranchesPage from "pages/branches/branches.page";
import ClientsPage from "pages/clients/clients.page";
import IndexPage from "pages/index/index.page";
import NotFoundErrorPage from "pages/not-found-error/not-found-error.page";
import ProfilePage from "pages/profile/profile.page";
import ReviewsPage from "pages/reviews/reviews.page";
import ServiceOrdersPage from "pages/service-orders/service-orders.page";
import SettingsPage from "pages/settings/settings.page";
import DashboardLayout from "components/layouts/dashboard.layout";
import AuthLayout from "components/layouts/auth.layout";
import RemindersPage from "pages/reminders/reminders.page";
import { loadProfile } from "store/actions/user";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { signInPromise } from "pages/auth/pages/index/index.page";
import { loadUserRoles } from "store/actions/user-roles";
import { loadUserAbilities } from "store/actions/user-abilities";
import { useNotificationsSSE } from "hooks/use-notifications-sse";
import { loadMyUnreadNotificationCount } from "store/actions/notifications";
import NotificationsPage from "pages/notifications/notifications.page";

// dayjs.locale("ru"); // BUG: Не показывается месяц в DatePicker

const configProviderProps: ConfigProviderProps = {
  locale: ruRULocale,
  pageHeader: { ghost: false },
};

function UseNotificationSSE() {
  useNotificationsSSE();
  return null;
}

const App: React.FunctionComponent = () => {
  const userState = useAppSelector((state) => state.user);
  const userRolesState = useAppSelector((state) => state.userRoles);
  const userAbilitiesState = useAppSelector((state) => state.userAbilities);
  const isAuthenticated = useAppSelector((state) => state.tokenPair.accessToken !== null);
  const dispatch = useAppDispatch();

  const loading = userState.loading || userRolesState.loading || userAbilitiesState.loading;

  React.useEffect(() => {
    if (isAuthenticated) {
      if (signInPromise) {
        signInPromise.then(() => dispatch(loadProfile()));
      } else {
        dispatch(loadProfile());
      }
      dispatch(loadUserAbilities());
      dispatch(loadUserRoles());
      dispatch(loadMyUnreadNotificationCount());
    }
  }, [isAuthenticated, dispatch]);

  if (!isAuthenticated) {
    return (
      <ConfigProvider {...configProviderProps}>
        <Spin spinning={loading} size="large" tip="Загрузка...">
          <Title />
          <Router>
            <AuthLayout>
              <Switch>
                <Route path={AuthPage.path}>
                  <AuthPage />
                </Route>
                <Route path="*">
                  <AuthPage />
                </Route>
              </Switch>
            </AuthLayout>
          </Router>
          <BackTop />
        </Spin>
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider {...configProviderProps}>
      <UseNotificationSSE />
      <Spin spinning={loading} size="large" tip="Загрузка...">
        <Title />
        <Router>
          <DashboardLayout>
            <Switch>
              <Route exact path={IndexPage.path}>
                <IndexPage />
              </Route>
              <Route path={`${AuthPage.path}${SignOutPage.path}`}>
                <SignOutPage />
              </Route>
              <Route path={AuthPage.path}>
                <Redirect to={IndexPage.path} />
              </Route>
              <Route path={BranchesPage.path}>
                <BranchesPage />
              </Route>
              <Route path={ClientsPage.path}>
                <ClientsPage />
              </Route>
              <Route path={ProfilePage.path}>
                <ProfilePage />
              </Route>
              <Route path={ReviewsPage.path}>
                <ReviewsPage />
              </Route>
              <Route path={RemindersPage.path}>
                <RemindersPage />
              </Route>
              <Route path={ServiceOrdersPage.path}>
                <ServiceOrdersPage />
              </Route>
              <Route path={SettingsPage.path}>
                <SettingsPage />
              </Route>
              <Route path={NotificationsPage.path}>
                <NotificationsPage />
              </Route>
              <Route path={NotFoundErrorPage.path}>
                <NotFoundErrorPage />
              </Route>
            </Switch>
          </DashboardLayout>
        </Router>
        <BackTop />
      </Spin>
    </ConfigProvider>
  );
};

export default App;
