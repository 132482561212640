import React from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "ahooks";
import { message, PageHeader, Spin } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import UserForm from "components/forms/UserForm";
import { userService } from "services";
import { UserService } from "services/user/user.service";
import UserSettingsPage from "../user/user.page";
import SettingsPage from "pages/settings/settings.page";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";

const createUser: UserService["createOne"] = (dto) => userService.createOne(dto);

const AddUserSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, AddUserSettingsPage.label]);

  const goBack = useGoBack();

  const history = useHistory();

  const { run, loading } = useRequest(createUser, {
    manual: true,
    onSuccess: (data) => {
      if (data) {
        history.push(`${SettingsPage.path}${UserSettingsPage.pathCreator(data.id)}`, {
          canGoBack: true,
        });
      }
    },

    onError: (e, params) => {
      const [dto] = params;
      const { email } = dto;
      if (e instanceof ApiDriverHttpError) {
        if (e.status === 409) {
          message.error(`Пользователь с почтой "${email}" уже существует!`);
        }
      }
    },
  });

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, AddUserSettingsPage.label]} />
      <PageHeader title={AddUserSettingsPage.label} onBack={goBack}>
        <Spin spinning={loading}>
          <UserForm title="" onFinish={run} />
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

AddUserSettingsPage.label = "Добавить пользователя";
AddUserSettingsPage.path = "/add-user";

export default AddUserSettingsPage;
