import React from "react";
import { Form, Input, Button } from "antd";
import { Store } from "antd/lib/form/interface";
import { FormInstance } from "antd/lib/form";
import { ReviewClientEmail } from "services/review/models/review-client-email";
import { noOp } from "libs/helpers/no-op";
import AntdFormReactQuillInput from "components/common/antd-form-react-quill-input";

export interface ReviewClientEmailFormProps {
  form?: FormInstance;
  onFinish?: (reviewClientCall: ReviewClientEmail) => void;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const ReviewClientEmailForm: React.FC<ReviewClientEmailFormProps> = (props) => {
  const { form, onFinish = noOp } = props;

  const handleFinish = React.useCallback(
    (values: Store) => {
      const reviewClientEmail = new ReviewClientEmail();
      reviewClientEmail.clientEmail = values.clientEmail;
      reviewClientEmail.clientEmailContent = values.clientEmailContent;
      onFinish(reviewClientEmail);
    },
    [onFinish]
  );

  return (
    <Form form={form} {...layout} onFinish={handleFinish}>
      <Form.Item
        label="Email клиента"
        name="clientEmail"
        rules={[
          { required: true, message: "Это поле должно быть заполнено" },
          {
            type: "email",
            message: "Это поле должно содержать валидный email адрес",
          },
        ]}
        validateTrigger={"onBlur"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Содержание письма"
        name="clientEmailContent"
        rules={[{ required: true, message: "Это поле должно быть заполнено" }]}
        validateTrigger={"onBlur"}
      >
        <AntdFormReactQuillInput />
      </Form.Item>
      {!form && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default ReviewClientEmailForm;
