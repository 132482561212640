import { branchService } from "services";
import { Branch } from "services/branch/models/branch";

const fetchBranches = async () => {
  const branches = await branchService.findAll({
    search: { guid1c: { $notnull: true } },
    sort: [{ field: "name", order: "ASC" }],
  });
  return branches as Branch[];
};

export default fetchBranches;
