import { Type } from "class-transformer";

export class City {
  id!: string;
  name!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;
}
