import * as Yup from "yup";
import { Rule } from "antd/lib/form";

export const reviewSiteIdSchema = Yup.string().optional();
export const linkSchemaWhenReviewSiteIdIsEmpty = Yup.string()
  .url("Это поле должно содержать валидный URL")
  .required("Это поле должно быть заполнено");
export const linkSchemaWhenReviewSiteIdIsNotEmpty = Yup.string()
  .url("Это поле должно содержать валидный URL")
  .optional();
export const clientNicknameSchema = Yup.string()
  .max(256, "Максимальная длина - 256 символов")
  .optional();

export function getRuleFromSchema(schema: Yup.SchemaOf<any>): Rule {
  return {
    validator: async (rule, value: string) => {
      await schema.validate(value);
    },
  };
}
