import React from "react";
import { PageHeader, Table } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useAppDispatch, useAppSelector, useSetTitleEffect } from "store/hooks";
import ReviewsPage from "pages/reviews/reviews.page";
import RefreshButton from "components/buttons/refresh-button";
import ResetButton from "components/buttons/reset-button";
import { useTableState } from "hooks/use-table-state";
import { useRefreshReviews } from "./hooks/use-refresh-reviews";
import { DEFAULT_TABLE_SORTER } from "pages/reviews/reviews.constants";
import { Review } from "services/review/review.service";
import fetchProcessingReviews from "./helpers/fetch-processing-reviews";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import { useTableStateCleaningMarkers } from "hooks/use-table-state-cleaning-markers";
import getColumns from "./helpers/get-columns";
import useAsyncActionState from "hooks/use-async-action-state";
import fetchBranches from "../../helpers/fetch-branches";
import fetchReviewDestinationTypes from "../../helpers/fetch-review-destination-types";
import fetchOperators from "pages/reviews/helpers/fetch-operators";
import InfoTooltip from "components/common/into-tooltip";
import { setProcessingReviewsTableState } from "store/actions/latest-table-states";

const ProcessingReviewsPage: PageFunctionComponent = () => {
  useSetTitleEffect([ReviewsPage.label, ProcessingReviewsPage.label]);
  const processingReviewsTableState = useAppSelector(
    (state) => state.latestTableStates.processingReviewsTableState
  );
  const dispatch = useAppDispatch();
  const [state, , setStateAsync] = useTableState<Review>(
    processingReviewsTableState ? processingReviewsTableState : { sorter: DEFAULT_TABLE_SORTER }
  );
  const [branchesFetcher, { loading: branchesLoading, result: branches = [] }] =
    useAsyncActionState(fetchBranches);
  const [
    reviewDestinationTypesFetcher,
    { loading: reviewDestinationTypesLoading, result: reviewDestinationTypes = [] },
  ] = useAsyncActionState(fetchReviewDestinationTypes);
  const refreshReviews = useRefreshReviews(
    state.pagination,
    setStateAsync,
    fetchProcessingReviews,
    reviewDestinationTypes
  );
  const [operatorsFetcher, { loading: fetchOperatorsLoading, result: operators = [] }] =
    useAsyncActionState(fetchOperators);

  const columns = React.useMemo(
    () => getColumns(state, branches, reviewDestinationTypes, operators),
    [state, branches, reviewDestinationTypes, operators]
  );
  const { canClearSorter, canClearFilters } = useTableStateCleaningMarkers(
    state,
    DEFAULT_TABLE_SORTER
  );
  const clearSorter = React.useCallback(() => {
    refreshReviews(state.pagination, state.filters, DEFAULT_TABLE_SORTER);
  }, [refreshReviews, state]);

  const clearFilters = React.useCallback(() => {
    refreshReviews(state.pagination, {}, state.sorter);
  }, [refreshReviews, state]);

  const loading =
    state.loading || branchesLoading || reviewDestinationTypesLoading || fetchOperatorsLoading;

  useComponentDidMountEffect(() => {
    refreshReviews(state.pagination, state.filters, state.sorter);
    branchesFetcher();
    reviewDestinationTypesFetcher();
    operatorsFetcher();
  });

  React.useEffect(() => {
    dispatch(setProcessingReviewsTableState(state));
  }, [dispatch, state]);

  return (
    <PageHeader
      title={
        <span>
          Текущие отзывы <InfoTooltip title="Отзывы, которые требуют ответа" />
        </span>
      }
      extra={
        <React.Fragment>
          <ResetButton onClick={clearFilters} disabled={!canClearFilters || loading}>
            Сбросить фильтры
          </ResetButton>
          <ResetButton onClick={clearSorter} disabled={!canClearSorter || loading}>
            Сбросить сортировки
          </ResetButton>
          <RefreshButton
            onClick={() => refreshReviews(state.pagination, state.filters, state.sorter)}
            loading={loading}
          />
        </React.Fragment>
      }
    >
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={{ spinning: loading, delay: 500 }}
        onChange={refreshReviews}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </PageHeader>
  );
};

ProcessingReviewsPage.path = "/processing";
ProcessingReviewsPage.label = "Требуют ответа";

export default ProcessingReviewsPage;
