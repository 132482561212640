import { plainToClass } from "class-transformer";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "../interfaces/paginated-response.interface";
import { ReviewDestinationType } from "./models/review-destination-type";
import { ReviewDestinationTypeUpsertDto } from "./dtos/review-destination-type-upsert-dto.type";

export class ReviewDestinationTypeService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<
      PagiantedResponse<ReviewDestinationType> | ReviewDestinationType[]
    >("/review-destination-type", {
      params: queryParams,
    });
    if (Array.isArray(response)) {
      response = plainToClass(ReviewDestinationType, response);
    } else {
      response.data = plainToClass(ReviewDestinationType, response.data);
    }
    return response;
  }

  async findOne(id: ReviewDestinationType["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<ReviewDestinationType>(
      `/review-destination-type/${id}`,
      {
        params: queryParams,
      }
    );
    return plainToClass(ReviewDestinationType, response);
  }

  async createOne(dto: ReviewDestinationTypeUpsertDto) {
    const reviewDestinationType = await this.apiDriver.post<ReviewDestinationType>(
      "/review-destination-type",
      dto
    );
    return plainToClass(ReviewDestinationType, reviewDestinationType);
  }

  async updateOne(id: string, dto: ReviewDestinationTypeUpsertDto) {
    const updated = await this.apiDriver.patch<ReviewDestinationType>(
      `/review-destination-type/${id}`,
      dto
    );
    return plainToClass(ReviewDestinationType, updated);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<ReviewDestinationType>(
      `/review-destination-type/${id}`,
      {
        params: queryParams,
      }
    );
    return plainToClass(ReviewDestinationType, deleted);
  }
}
