import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm, message } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import CityDescription from "components/descriptions/CityDescription";
import { cityService } from "services";
import { City } from "services/city/models/city";
import CityForm, { CityFormDto } from "components/forms/CityForm";
import { CityService } from "services/city/city.service";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import CitiesSettingsPage from "../cities/cities.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

export interface CitySettingsPageParams {
  id: City["id"];
}

const fetchCity = (id: City["id"]) => cityService.findOne(id);

const updateCity: CityService["updateOne"] = (id, dto) => cityService.updateOne(id, dto);

const deleteCity = (id: City["id"]) => cityService.deleteOne(id);

const CitySettingsPage: PageFCWithPathCreator = () => {
  const { id } = useParams<CitySettingsPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchCity,
    data: city,
    error: fetchCityError,
    loading: fetchCityLoading,
    refresh: refreshCity,
    mutate: mutateCity,
  } = useRequest(fetchCity, {
    manual: true,
  });

  const { runAsync: runUpdateCity, loading: updateCityLoading } = useRequest(updateCity, {
    manual: true,

    onError: (e, params) => {
      const [, dto] = params;
      const { name } = dto;
      if (e instanceof ApiDriverHttpError) {
        if (e.status === 409) {
          message.error(`Город с названием "${name}" уже существует!`);
        }
      }
    },
  });

  const { run: runDeleteCity, loading: deleteCityLoading } = useRequest(deleteCity, {
    manual: true,
    onSuccess: () => {
      history.push(`${SettingsPage.path}${CitiesSettingsPage.path}`);
    },
  });

  const [edit, setEdit] = React.useState<boolean>(false);

  useSetTitleEffect([SettingsPage.label, CitySettingsPage.label, city?.name || id]);

  useMount(() => {
    if (id) {
      runFetchCity(id);
    }
  });

  const handleUpdate = React.useCallback(
    async (dto: CityFormDto) => {
      if (!city) {
        return;
      }
      const updated = await runUpdateCity(city.id, dto);
      mutateCity(updated);
      setEdit(false);
    },
    [city, runUpdateCity, mutateCity, setEdit]
  );

  const handleDelete = React.useCallback(async () => {
    if (!city) {
      return;
    }
    await runDeleteCity(city.id);
    setEdit(false);
    await refreshCity();
  }, [city, runDeleteCity, setEdit, refreshCity]);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, CitySettingsPage.label, city?.name || id]} />
      <PageHeader
        title={CitySettingsPage.label}
        subTitle={city?.name}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.CITY,
                  action: subjectAction.CITY.UPDATE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={fetchCityLoading || deleteCityLoading || !city}
                  loading={updateCityLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.CITY,
                  action: subjectAction.CITY.DELETE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={fetchCityLoading || updateCityLoading || !city}
                    loading={deleteCityLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateCityLoading || deleteCityLoading}
                loading={fetchCityLoading}
                onClick={refreshCity}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchCityLoading} delay={500}>
          <HttpErrorResult error={fetchCityError} />
          {edit ? (
            <Spin spinning={updateCityLoading}>
              <CityForm entityToUpdate={city} onFinish={handleUpdate} title={""} />
            </Spin>
          ) : (
            city && <CityDescription data={city} title={""} />
          )}
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

CitySettingsPage.path = "/city/:id";
CitySettingsPage.pathCreator = (id) => `/city/${id}`;
CitySettingsPage.label = "Город";

export default CitySettingsPage;
