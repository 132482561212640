import { CondOperator, QueryFilter, QueryFilterArr } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { ReminderTableAdvancedFormDto } from "components/tables/ReminderTable/ReminderTableAdvancedForm";
import { ReminderTableSimpleFormDto } from "components/tables/ReminderTable/ReminderTableSimpleForm";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { reminderService } from "services";
import { Reminder } from "services/reminder/models/reminder";

export type GetReminderTableData = Service<Data, Params>;

export const getReminderTableData: GetReminderTableData = async (
  paginatedParams,
  formData: ReminderTableSimpleFormDto & ReminderTableAdvancedFormDto
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<Reminder>(paginatedParams.sorter);

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  if (formData.id) {
    filter.push({
      field: "id",
      operator: CondOperator.EQUALS,
      value: formData.id.trim(),
    });
  }

  if (formData.title) {
    filter.push({
      field: "title",
      operator: CondOperator.CONTAINS_LOW,
      value: formData.title.trim(),
    });
  }

  const reminders = await reminderService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort: tableQuerySorter
      ? [tableQuerySorter, { field: "id", order: "ASC" }]
      : { field: "id", order: "ASC" },
    join: ["user"],
    filter,
  });

  if (Array.isArray(reminders)) {
    return {
      list: reminders,
      total: reminders.length,
    };
  } else {
    return {
      list: reminders.data,
      total: reminders.total,
    };
  }
};
