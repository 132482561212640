import { Type } from "class-transformer";
import { RoleAbility } from "services/role-ability/models/role-ability";
import { RoleMailing } from "services/role-mailing/models/role-mailing";
import { RoleReviewDestination } from "../../role-review-destination/models/role-review-destination";

export class Role {
  id!: string;
  name!: string;
  description?: string;
  isHead!: boolean;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  @Type(() => RoleAbility)
  roleAbilites?: [];

  @Type(() => RoleReviewDestination)
  roleReviewDestinations?: [];

  @Type(() => RoleMailing)
  roleMailings?: [];
}
