import React from "react";
import { noOp } from "libs/helpers/no-op";
import { noOpAsync } from "libs/helpers/no-op-async";

export type SetStateCallback<S> = (currentState: S) => void;

export interface UseStateState<S> {
  state: S;
  callback: SetStateCallback<S>;
}

export type StateUpdate<S> = S | ((prevState: S) => S);

export type SetState<S> = (stateUpdate: StateUpdate<S>, callback?: SetStateCallback<S>) => void;
export type SetStateAsync<S> = (stateUpdate: StateUpdate<S>) => Promise<S>;

function useState<S = any>(initialState: S): [S, SetState<S>, SetStateAsync<S>] {
  const [stateAndCallback, setStateAndCallback] = React.useState<UseStateState<S>>({
    state: initialState,
    callback: noOp,
  });

  const setStateWithCallback: SetState<S> = React.useCallback((stateChange, callback = noOp) => {
    if (stateChange instanceof Function) {
      setStateAndCallback((prev) => ({
        state: stateChange(prev.state),
        callback,
      }));
    } else {
      setStateAndCallback({ state: stateChange, callback });
    }
  }, []);

  React.useEffect(() => {
    return stateAndCallback.callback(stateAndCallback.state);
  }, [stateAndCallback]);

  let setStateAsync: SetStateAsync<S> = React.useCallback(
    async (stateChange) =>
      new Promise((resolve) =>
        setStateWithCallback(stateChange, (updatedState) => {
          resolve(updatedState);
          return () => {
            setStateAsync = noOpAsync;
          };
        })
      ),
    [setStateWithCallback]
  );

  return [stateAndCallback.state, setStateWithCallback, setStateAsync];
}

export default useState;
