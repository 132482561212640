import React from "react";
import { subjectAction } from "abilities/subject-action.constant";
import { Subject } from "abilities/subject.enum";
import checkAbilities from "store/helpers/check-abilities";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";

export function useSettingsPageAccessControl() {
  const pageAccessControlState = useAppSelector(pageAccessControlSelector);
  const pageAccessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        AddBranchSettingsPage: false,
        AddCitySettingsPage: false,
        AddIntegrationKeySettingsPage: false,
        AddReviewDestinationTypeSettingsPage: false,
        AddReviewSiteSettingsPage: false,
        AddReviewTagTypeSettingsPage: false,
        AddRoleSettingsPage: false,
        AddUserSettingsPage: false,
        BackendCacheSettingsPage: false,
        BranchesSettingsPage: false,
        BranchSettingsPage: false,
        CitiesSettingsPage: false,
        CitySettingsPage: false,
        IntegrationKeySettingsPage: false,
        IntegrationKeysSettingsPage: false,
        ReviewDestinationTypeSettingsPage: false,
        ReviewDestinationTypesSettingsPage: false,
        ReviewSiteSettingsPage: false,
        ReviewSitesSettingsPage: false,
        ReviewTagTypeSettingsPage: false,
        ReviewTagTypesSettingsPage: false,
        RoleSettingsPage: false,
        RolesSettingsPage: false,
        UserSettingsPage: false,
        UsersSettingsPage: false,
      };
    }

    return {
      AddBranchSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.BRANCH,
        action: subjectAction.BRANCH.CREATE,
      }),
      AddCitySettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.CITY,
        action: subjectAction.CITY.CREATE,
      }),
      AddIntegrationKeySettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.INTEGRATION_KEY,
        action: subjectAction.INTEGRATION_KEY.CREATE,
      }),
      AddReviewDestinationTypeSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW_DESTINATION_TYPE,
        action: subjectAction.REVIEW_DESTINATION_TYPE.CREATE,
      }),
      AddReviewSiteSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW_SITE,
        action: subjectAction.REVIEW_SITE.CREATE,
      }),
      AddReviewTagTypeSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.REVIEW_TAG_TYPE,
        action: subjectAction.REVIEW_TAG_TYPE.CREATE,
      }),
      AddRoleSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.ROLE,
        action: subjectAction.ROLE.CREATE,
      }),
      AddUserSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.USER,
        action: subjectAction.USER.CREATE,
      }),
      BackendCacheSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.BACKEND_CACHE,
        action: subjectAction.BACKEND_CACHE.CLEAR,
      }),
      BranchesSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.BRANCH,
            action: subjectAction.BRANCH.CREATE,
          },
          {
            subject: Subject.BRANCH,
            action: subjectAction.BRANCH.UPDATE_ALL,
          },
          {
            subject: Subject.BRANCH,
            action: subjectAction.BRANCH.DELETE_ALL,
          },
        ],
      }),
      BranchSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.BRANCH,
            action: subjectAction.BRANCH.CREATE,
          },
          {
            subject: Subject.BRANCH,
            action: subjectAction.BRANCH.UPDATE_ALL,
          },
          {
            subject: Subject.BRANCH,
            action: subjectAction.BRANCH.DELETE_ALL,
          },
        ],
      }),
      CitiesSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.CITY,
            action: subjectAction.CITY.CREATE,
          },
          {
            subject: Subject.CITY,
            action: subjectAction.CITY.UPDATE_ALL,
          },
          {
            subject: Subject.CITY,
            action: subjectAction.CITY.DELETE_ALL,
          },
        ],
      }),
      CitySettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.CITY,
            action: subjectAction.CITY.CREATE,
          },
          {
            subject: Subject.CITY,
            action: subjectAction.CITY.UPDATE_ALL,
          },
          {
            subject: Subject.CITY,
            action: subjectAction.CITY.DELETE_ALL,
          },
        ],
      }),
      IntegrationKeySettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.INTEGRATION_KEY,
        action: subjectAction.INTEGRATION_KEY.READ_ALL,
      }),
      IntegrationKeysSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.INTEGRATION_KEY,
        action: subjectAction.INTEGRATION_KEY.READ_ALL,
      }),
      ReviewDestinationTypeSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.REVIEW_DESTINATION_TYPE,
            action: subjectAction.REVIEW_DESTINATION_TYPE.CREATE,
          },
          {
            subject: Subject.REVIEW_DESTINATION_TYPE,
            action: subjectAction.REVIEW_DESTINATION_TYPE.UPDATE_ALL,
          },
          {
            subject: Subject.REVIEW_DESTINATION_TYPE,
            action: subjectAction.REVIEW_DESTINATION_TYPE.DELETE_ALL,
          },
        ],
      }),
      ReviewDestinationTypesSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.REVIEW_DESTINATION_TYPE,
            action: subjectAction.REVIEW_DESTINATION_TYPE.CREATE,
          },
          {
            subject: Subject.REVIEW_DESTINATION_TYPE,
            action: subjectAction.REVIEW_DESTINATION_TYPE.UPDATE_ALL,
          },
          {
            subject: Subject.REVIEW_DESTINATION_TYPE,
            action: subjectAction.REVIEW_DESTINATION_TYPE.DELETE_ALL,
          },
        ],
      }),
      ReviewSiteSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.REVIEW_SITE,
            action: subjectAction.REVIEW_SITE.CREATE,
          },
          {
            subject: Subject.REVIEW_SITE,
            action: subjectAction.REVIEW_SITE.UPDATE_ALL,
          },
          {
            subject: Subject.REVIEW_SITE,
            action: subjectAction.REVIEW_SITE.DELETE_ALL,
          },
        ],
      }),
      ReviewSitesSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.REVIEW_SITE,
            action: subjectAction.REVIEW_SITE.CREATE,
          },
          {
            subject: Subject.REVIEW_SITE,
            action: subjectAction.REVIEW_SITE.UPDATE_ALL,
          },
          {
            subject: Subject.REVIEW_SITE,
            action: subjectAction.REVIEW_SITE.DELETE_ALL,
          },
        ],
      }),
      ReviewTagTypeSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.REVIEW_TAG_TYPE,
            action: subjectAction.REVIEW_TAG_TYPE.CREATE,
          },
          {
            subject: Subject.REVIEW_TAG_TYPE,
            action: subjectAction.REVIEW_TAG_TYPE.UPDATE_ALL,
          },
          {
            subject: Subject.REVIEW_TAG_TYPE,
            action: subjectAction.REVIEW_TAG_TYPE.DELETE_ALL,
          },
        ],
      }),
      ReviewTagTypesSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.REVIEW_TAG_TYPE,
            action: subjectAction.REVIEW_TAG_TYPE.CREATE,
          },
          {
            subject: Subject.REVIEW_TAG_TYPE,
            action: subjectAction.REVIEW_TAG_TYPE.UPDATE_ALL,
          },
          {
            subject: Subject.REVIEW_TAG_TYPE,
            action: subjectAction.REVIEW_TAG_TYPE.DELETE_ALL,
          },
        ],
      }),
      RoleSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.ROLE,
            action: subjectAction.ROLE.CREATE,
          },
          {
            subject: Subject.ROLE,
            action: subjectAction.ROLE.UPDATE_ALL,
          },
          {
            subject: Subject.ROLE,
            action: subjectAction.ROLE.DELETE_ALL,
          },
        ],
      }),
      RolesSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        $or: [
          {
            subject: Subject.ROLE,
            action: subjectAction.ROLE.CREATE,
          },
          {
            subject: Subject.ROLE,
            action: subjectAction.ROLE.UPDATE_ALL,
          },
          {
            subject: Subject.ROLE,
            action: subjectAction.ROLE.DELETE_ALL,
          },
        ],
      }),
      UserSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.USER,
        action: subjectAction.USER.READ_ALL,
      }),
      UsersSettingsPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.USER,
        action: subjectAction.USER.READ_ALL,
      }),
    };
  }, [pageAccessControlState]);

  return pageAccessControl;
}
