import { QuerySort, QuerySortArr, SCondition } from "@nestjsx/crud-request";
import { ServiceOrder } from "services/service-order.service";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { serviceOrderService } from "services";

const fetchServiceOrders = async (
  page: number,
  limit: number,
  sort?: QuerySort | Array<QuerySort>,
  search: SCondition = {}
) => {
  const validSort: Array<QuerySort | QuerySortArr> = [];

  if (Array.isArray(sort)) {
    validSort.push(...sort);
  } else if (sort) {
    validSort.push(sort);
  }

  validSort.push({ field: "id", order: "DESC" });

  const serviceOrders = await serviceOrderService.findAll({
    fields: [
      "id",
      "number",
      "branchName",
      "clientDisplayName",
      "clientPhone",
      "carManufacturer",
      "carModel",
      "carManufactureYear",
      "carVin",
      "closedAt",
      "createdAt",
    ],
    page,
    limit,
    sort: validSort,
    search,
  });

  return serviceOrders as PagiantedResponse<ServiceOrder>;
};

export default fetchServiceOrders;
