import React from "react";
import { usePagination, useRequest } from "ahooks";
import { Button, Col, List, PageHeader, Pagination, Row, Spin } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useAppDispatch, useSetTitleEffect } from "store/hooks";
import NotificationsPage from "pages/notifications/notifications.page";
import useGoBack from "hooks/use-go-back";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import RefreshButton from "components/buttons/refresh-button";
import { Notification } from "services/notification/models/notification";
import { notificationService } from "services";
import { LOADING_DELAY } from "configs/configs";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import NotificationCard from "components/cards/NotificationCard";
import { loadMyUnreadNotificationCount } from "store/actions/notifications";

async function getMyUnreadNotificationList(params: { current: number; pageSize: number }): Promise<{
  total: number;
  list: Notification[];
}> {
  const { current: page, pageSize } = params;
  const notifications = await notificationService.findMyAll({ page, pageSize });
  return { total: notifications.count, list: notifications.items };
}

async function markNotificationAsRead(notificationId: Notification["id"]) {
  return notificationService.readMyOne(notificationId);
}

const PAGE_SIZE = 10;

const AllNotificationsPage: PageFunctionComponent = () => {
  useSetTitleEffect([NotificationsPage.label, AllNotificationsPage.label]);

  const goBack = useGoBack();
  const dispatch = useAppDispatch();

  const {
    data: notificationsData,
    loading: notificationsLoading,
    pagination: notificationsPagination,
    refresh: refreshNotifications,
    mutate: mutateNotificationsData,
  } = usePagination(getMyUnreadNotificationList, {
    manual: false,
    loadingDelay: LOADING_DELAY,
    defaultPageSize: PAGE_SIZE,
    onSuccess: () => dispatch(loadMyUnreadNotificationCount()),
  });

  const { run: markOneAsRead, loading: markOneAsReadLoading } = useRequest(markNotificationAsRead, {
    manual: true,
    onSuccess: (success) => {
      if (success) {
        dispatch(loadMyUnreadNotificationCount());
        refreshNotifications();
      }
    },
  });

  function handlePaginationChange(page: number, pageSize?: number) {
    notificationsPagination.onChange(page, pageSize || PAGE_SIZE);
  }

  function handleMarkOneAsReadButtonClick(notification: Notification) {
    return () => {
      notification.isDelivered = true;
      mutateNotificationsData(notificationsData);
      markOneAsRead(notification.id);
    };
  }

  const refreshButtonDisabled = notificationsLoading;
  const refreshButtonLoading = notificationsLoading;
  const paginationDisabled = markOneAsReadLoading;

  return (
    <ContentLayout>
      <Breadcrumbs items={[NotificationsPage.label, AllNotificationsPage.label]} />
      <PageHeader
        title={AllNotificationsPage.label}
        extra={
          <Row gutter={[8, 8]}>
            <Col>
              <RefreshButton
                loading={refreshButtonLoading}
                disabled={refreshButtonDisabled}
                onClick={refreshNotifications}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={notificationsLoading}>
          {notificationsData ? (
            <React.Fragment>
              <Pagination
                current={notificationsPagination.current}
                total={notificationsPagination.total}
                pageSize={notificationsPagination.pageSize}
                onChange={handlePaginationChange}
                showTotal={displayTotalRows}
                disabled={paginationDisabled}
                hideOnSinglePage
              />
              <List
                dataSource={notificationsData.list}
                renderItem={(item) => (
                  <List.Item>
                    <NotificationCard
                      data={item}
                      extra={
                        <Button
                          type="link"
                          onClick={handleMarkOneAsReadButtonClick(item)}
                          disabled={item.isDelivered}
                        >
                          Отметить прочитанным
                        </Button>
                      }
                    />
                  </List.Item>
                )}
              />
              <Pagination
                current={notificationsPagination.current}
                total={notificationsPagination.total}
                pageSize={notificationsPagination.pageSize}
                onChange={handlePaginationChange}
                showTotal={displayTotalRows}
                disabled={paginationDisabled}
                hideOnSinglePage
              />
            </React.Fragment>
          ) : null}
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

AllNotificationsPage.path = "/my-all";
AllNotificationsPage.label = "Все уведомления";

export default AllNotificationsPage;
