import React from "react";
import { CreateQueryParams } from "@nestjsx/crud-request";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { TABLE_DEFAULT_PAGE_SIZE } from "configs/configs";
import { SetStateAsync } from "./use-state";
import { TableState } from "libs/interfaces/table-state.interface";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";

const DEFAULT_TABLE_SORTER: SorterResult<any>[] = [];

export type HandleTableChange<RecordType> = (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
  extra: TableCurrentDataSource<RecordType>
) => void;

export type ConvertTableParamsToQueryParams<RecordType> = (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<RecordType> | SorterResult<RecordType>[]
) => CreateQueryParams;

export type FetchData<RecordType> = (
  params: CreateQueryParams
) => Promise<PagiantedResponse<RecordType>>;

export function useTableChangeHandler<RecordType>(
  currentState: TableState<RecordType>,
  setStateAsync: SetStateAsync<TableState<RecordType>>,
  convertTableParamsToQueryParams: ConvertTableParamsToQueryParams<RecordType>,
  fetchData: FetchData<RecordType>,
  defaultTableSorter: SorterResult<RecordType> | SorterResult<RecordType>[] = DEFAULT_TABLE_SORTER
): HandleTableChange<RecordType> {
  const handleTableChange = React.useCallback(
    async (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
      extra: TableCurrentDataSource<RecordType>
    ) => {
      await setStateAsync((s) => ({ ...s, loading: true }));

      let validSorter = sorter;
      if (!Array.isArray(sorter)) {
        validSorter = sorter.order ? sorter : defaultTableSorter;
      }

      const fetchDataParams = convertTableParamsToQueryParams(pagination, filters, validSorter);
      const response = await fetchData(fetchDataParams);

      const nextPagination = { ...currentState.pagination };
      nextPagination.current = response.page;
      nextPagination.total = response.total;
      nextPagination.pageSize = pagination.pageSize || TABLE_DEFAULT_PAGE_SIZE;

      await setStateAsync({
        data: response.data,
        loading: false,
        pagination: nextPagination,
        filters,
        sorter: validSorter,
      });
    },
    [
      currentState.pagination,
      setStateAsync,
      convertTableParamsToQueryParams,
      fetchData,
      defaultTableSorter,
    ]
  );

  return handleTableChange;
}
