import { reviewService } from "services";
import { ReviewChatMessage } from "services/review/models/review-chat-message.model";

const updateReviewChatMessage = (reviewChatMessage: ReviewChatMessage) =>
  reviewService.updateOneReviewChatMessage(
    reviewChatMessage.reviewId,
    reviewChatMessage.id,
    reviewChatMessage.message
  );

export default updateReviewChatMessage;
