import { plainToClass } from "class-transformer";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "../interfaces/paginated-response.interface";
import { ReviewTagType } from "./models/review-tag-type";
import { ReviewTagTypeUpsertDto } from "./dtos/review-tag-type-upsert-dto.type";

export class ReviewTagTypeService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<ReviewTagType> | ReviewTagType[]>(
      "/review-tag-type",
      {
        params: queryParams,
      }
    );
    if (Array.isArray(response)) {
      response = plainToClass(ReviewTagType, response);
    } else {
      response.data = plainToClass(ReviewTagType, response.data);
    }
    return response;
  }

  async findOne(id: ReviewTagType["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<ReviewTagType>(`/review-tag-type/${id}`, {
      params: queryParams,
    });
    return plainToClass(ReviewTagType, response);
  }

  async createOne(dto: ReviewTagTypeUpsertDto) {
    const reviewTagType = await this.apiDriver.post<ReviewTagType>("/review-tag-type", dto);
    return plainToClass(ReviewTagType, reviewTagType);
  }

  async updateOne(id: string, dto: ReviewTagTypeUpsertDto) {
    const updated = await this.apiDriver.patch<ReviewTagType>(`/review-tag-type/${id}`, dto);
    return plainToClass(ReviewTagType, updated);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<ReviewTagType>(`/review-tag-type/${id}`, {
      params: queryParams,
    });
    return plainToClass(ReviewTagType, deleted);
  }
}
