import { MyAbility } from "services/user/models/my-ability";

export interface UserAbilitiesState {
  userAbilities: MyAbility[];
  loading: boolean;
  error?: Error;
}

export enum UserAbilitiesActionType {
  LOAD_USER_ABILITIES = "LOAD_USER_ABILITIES",
  LOADED_USER_ABILITIES = "LOADED_USER_ABILITIES",
  ERRORED_USER_ABILITIES = "ERRORED_USER_ABILITIES",
  CLEAR_USER_ABILITIES = "CLEAR_USER_ABILITIES",
}

export interface UserAbilitiesAction {
  type: UserAbilitiesActionType;
  payload?: Partial<UserAbilitiesState>;
}

const initialState: UserAbilitiesState = {
  loading: false,
  userAbilities: [],
};

export default function userAbilitiesReducer(
  state = initialState,
  action: UserAbilitiesAction
): UserAbilitiesState {
  switch (action.type) {
    case UserAbilitiesActionType.LOAD_USER_ABILITIES:
      return { ...state, loading: true, error: undefined };
    case UserAbilitiesActionType.LOADED_USER_ABILITIES:
      return {
        ...state,
        userAbilities: action?.payload?.userAbilities || [],
        loading: false,
        error: undefined,
      };
    case UserAbilitiesActionType.ERRORED_USER_ABILITIES:
      return {
        ...state,
        loading: false,
        error: action?.payload?.error,
      };
    case UserAbilitiesActionType.CLEAR_USER_ABILITIES:
      return initialState;
    default:
      return state;
  }
}
