type ReviewAttachmentLabel = string;

export class ReviewAttachmentService {
  findAll(): { [reviewJoinColumn: string]: ReviewAttachmentLabel } {
    return {
      reviewSite: "Отзыв на сайте",
      reviewServiceOrder: "Заказ-наряд",
      reviewClientEmail: "Email клиента",
      reviewClientCall: "Звонок Клиента",
    };
  }
}
