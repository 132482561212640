import { Review } from "services/review/review.service";
import { ServiceOrder } from "services/service-order.service";
import { Type } from "class-transformer";
import { PartialBy } from "libs/types/partial-by.type";

export class ReviewServiceOrder {
  id: string;
  reviewId: string;
  serviceOrderId: string;
  serviceOrderGuid1c?: string;
  serviceOrderNumber?: string;

  @Type(() => Date)
  serviceOrderOpenedAt?: Date;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => Review)
  review?: Review;

  @Type(() => ServiceOrder)
  serviceOrder?: ServiceOrder;
}

export type ReviewServiceOrderPartialById = PartialBy<ReviewServiceOrder, "id">;
