import { CondOperator, QueryFilter, QueryFilterArr } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { ReviewDestinationTypeTableSimpleFormDto } from "components/tables/ReviewDestinationTypeTable/ReviewDestinationTypeTableSimpleForm";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { reviewDestinationTypeService } from "services";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";

export type GetReviewDestinationTypeTableData = Service<Data, Params>;

export const getReviewDestinationTypeTableData: GetReviewDestinationTypeTableData = async (
  paginatedParams,
  formData: ReviewDestinationTypeTableSimpleFormDto
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<ReviewDestinationType>(
    paginatedParams.sorter
  );

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  if (formData.name) {
    filter.push({
      field: "name",
      operator: CondOperator.CONTAINS_LOW,
      value: formData.name.trim(),
    });
  }

  const reviewDestinationTypes = await reviewDestinationTypeService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort: tableQuerySorter
      ? [tableQuerySorter, { field: "id", order: "ASC" }]
      : { field: "id", order: "ASC" },
    filter,
  });

  if (Array.isArray(reviewDestinationTypes)) {
    return {
      list: reviewDestinationTypes,
      total: reviewDestinationTypes.length,
    };
  } else {
    return {
      list: reviewDestinationTypes.data,
      total: reviewDestinationTypes.total,
    };
  }
};
