import { Type } from "class-transformer";
import { Review } from "../review.service";
import { User } from "services/user/models/user";

export class ReviewChatMessage {
  id: string;
  reviewId: string;
  userId?: string;
  message: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => Review)
  review?: Review;

  @Type(() => User)
  user?: User;
}
