import React from "react";
import { TableState } from "libs/interfaces/table-state.interface";
import { Review } from "services/review/review.service";
import { SortOrder, ColumnsType } from "antd/es/table/interface";
import { displayDateTime } from "libs/helpers/display-date-time";
import ReviewsPage from "pages/reviews/reviews.page";
import ReviewCardPage from "../../card/card.page";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Rate } from "antd";
import { Branch } from "services/branch/models/branch";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import { User } from "services/user/models/user";
import ReviewStatus from "components/common/review-status";

function getColumns(
  tableState: TableState<Review>,
  branches: Branch[],
  reviewDestinationTypes: ReviewDestinationType[],
  operators: User[]
): ColumnsType<Review> {
  let createdAtSortorder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;

  if (Array.isArray(tableState.sorter)) {
    //...
  } else {
    createdAtSortorder =
      tableState.sorter.field === "createdAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
    updatedAtSortOrder =
      tableState.sorter.field === "updatedAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
  }

  return [
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (_: any, record) => <ReviewStatus status={record.status} />,
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
      render: (_: any, record) => record?.branch?.city?.name,
    },
    {
      title: "Филиал",
      dataIndex: "branchId",
      key: "branchId",
      render: (_: any, record) => record?.branch?.name,
      filters: branches.map((branch) => ({
        text: branch.name,
        value: branch.id,
      })),
      filteredValue: tableState.filters.branchId || null,
    },
    {
      title: "Направление",
      dataIndex: "reviewDestination",
      key: "reviewDestination",
      render: (_: any, record) => (
        <div>
          {record?.reviewDestinations?.map((reviewDestination) => (
            <div key={`review_${record.id}_reviewDestination_${reviewDestination.id}`}>
              {reviewDestination.reviewDestinationType?.name}
            </div>
          ))}
        </div>
      ),
      filters: reviewDestinationTypes.map((reviewDestinationType) => ({
        text: reviewDestinationType.name,
        value: reviewDestinationType.id,
      })),
      filteredValue: tableState.filters.reviewDestination || null,
    },
    {
      title: "Оценка",
      dataIndex: "rate",
      key: "rate",
      render: (rate: number, record) =>
        rate || rate === 0 ? <Rate disabled value={rate} /> : "Без оценки",
      filters: [0, 1, 2, 3, 4, 5].map((rate) => {
        return {
          text: rate === 0 ? "Без оценки" : <Rate disabled value={rate} />,
          value: rate,
        };
      }),
      filteredValue: tableState.filters.rate || null,
    },
    {
      title: "Оператор",
      dataIndex: "operator",
      key: "operator",
      render: (_: any, record) => (
        <React.Fragment>
          {record.operatorUser?.surname} {record.operatorUser?.firstName}
        </React.Fragment>
      ),
      filters: operators.map((operator) => ({
        text: `${operator.surname} ${operator.firstName}`,
        value: operator.id,
      })),
      filteredValue: tableState.filters.operator || null,
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: Date) => displayDateTime(createdAt),
      sorter: true,
      sortOrder: createdAtSortorder,
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt: Date) => displayDateTime(updatedAt),
      sorter: true,
      sortOrder: updatedAtSortOrder,
      sortDirections: ["descend"],
    },
    {
      title: "",
      key: "action",
      render: (text: any, record) => (
        <Link
          to={{
            pathname: `${ReviewsPage.path}${ReviewCardPage.pathCreator(record.id)}`,
            state: {
              canGoBack: true,
            },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      fixed: "right",
      align: "center",
    },
  ];
}

export default getColumns;
