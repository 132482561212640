import React from "react";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { TableState } from "libs/interfaces/table-state.interface";
import { SetStateAsync } from "hooks/use-state";
import { QuerySort } from "@nestjsx/crud-request";
import { TABLE_DEFAULT_PAGE, TABLE_DEFAULT_PAGE_SIZE } from "configs/configs";
import { Review } from "services/review/review.service";
import { DEFAULT_TABLE_SORTER } from "pages/reviews/reviews.constants";
import fetchProcessingReviews from "../helpers/fetch-processing-reviews";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";

export function useRefreshReviews(
  currentPagination: TablePaginationConfig,
  setStateAsync: SetStateAsync<TableState<Review>>,
  fetchReviews: typeof fetchProcessingReviews,
  reviewDestinationTypes: ReviewDestinationType[]
) {
  return React.useCallback(
    async (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null> = {},
      sorter: SorterResult<Review> | SorterResult<Review>[]
    ) => {
      let validSorter = sorter;
      if (!Array.isArray(sorter)) {
        validSorter = sorter.order ? sorter : DEFAULT_TABLE_SORTER;
      }

      const search: { $and: any[] } = { $and: [] };

      if (Array.isArray(filters.branchId)) {
        search.$and.push({ branchId: { $in: filters.branchId } });
      }

      if (Array.isArray(filters.operator)) {
        if (filters.operator.length > 0) {
          search.$and.push({
            operatorUserId: { $in: filters.operator },
          });
        }
      }

      if (Array.isArray(filters.reviewDestination)) {
        if (filters.reviewDestination.length !== reviewDestinationTypes.length) {
          search.$and.push({
            "reviewDestinations.reviewDestinationTypeId": { $in: filters.reviewDestination },
          });
        }
      }

      if (Array.isArray(filters.rate)) {
        if (filters.rate.includes("0")) {
          search.$and.push({
            $or: [
              { rate: { $in: filters.rate.filter((r) => r !== "0") } },
              { rate: { $isnull: true } },
            ],
          });
        } else {
          search.$and.push({
            rate: { $in: filters.rate },
          });
        }
      }

      const fetchSorter = Array.isArray(validSorter)
        ? validSorter
            .map((s) => convertTableSorterToQuerySorter<Review>(s))
            .reduce((acc, curr) => {
              if (curr) {
                acc.push(curr);
              }
              return acc;
            }, [] as QuerySort[])
        : convertTableSorterToQuerySorter<Review>(validSorter);

      await setStateAsync((s) => ({ ...s, loading: true }));

      const serviceOrders = await fetchReviews(
        pagination.current || TABLE_DEFAULT_PAGE,
        pagination.pageSize || TABLE_DEFAULT_PAGE_SIZE,
        fetchSorter,
        search
      );

      const nextPagination = { ...currentPagination };

      nextPagination.current = serviceOrders.page;
      nextPagination.total = serviceOrders.total;
      nextPagination.pageSize = pagination.pageSize || TABLE_DEFAULT_PAGE_SIZE;

      await setStateAsync({
        data: serviceOrders.data,
        loading: false,
        pagination: nextPagination,
        filters,
        sorter: validSorter,
      });
    },
    [currentPagination, setStateAsync, fetchReviews, reviewDestinationTypes]
  );
}
