import React from "react";
import { Descriptions } from "antd";
import { ReviewClientCallPartialById } from "services/review/models/review-client-call.model";

export interface ReviewClientCallDescriptionsProps {
  reviewClientCall: ReviewClientCallPartialById;
}

function ReviewClientCallDescriptions(props: ReviewClientCallDescriptionsProps) {
  const { reviewClientCall } = props;

  return (
    <Descriptions bordered>
      <Descriptions.Item label="Номер телефона">{reviewClientCall.clientPhone}</Descriptions.Item>
    </Descriptions>
  );
}

export default ReviewClientCallDescriptions;
