import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { setTitle } from "./actions/title";
import type { RootState, AppDispatch } from "./store";

export const useAppDispatch = () =>
  useDispatch<AppDispatch & ThunkDispatch<RootState, unknown, AnyAction>>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useSetTitleEffect(title: string[]) {
  const dispatch = useAppDispatch();

  useComponentDidMountEffect(() => {
    dispatch(setTitle(title));
  });
}
