import { CondOperator, QueryFilter, QueryFilterArr } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { RoleTableSimpleFormDto } from "components/tables/RoleTable/RoleTableSimpleForm";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { roleService } from "services";
import { Role } from "services/role/models/role";

export type GetRoleTableData = Service<Data, Params>;

export const getRoleTableData: GetRoleTableData = async (
  paginatedParams,
  formData: RoleTableSimpleFormDto
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<Role>(paginatedParams.sorter);

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  if (formData.name) {
    filter.push({
      field: "name",
      operator: CondOperator.CONTAINS_LOW,
      value: formData.name.trim(),
    });
  }

  const vehicleBrands = await roleService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort: tableQuerySorter
      ? [tableQuerySorter, { field: "id", order: "ASC" }]
      : { field: "id", order: "ASC" },
    filter,
  });

  if (Array.isArray(vehicleBrands)) {
    return {
      list: vehicleBrands,
      total: vehicleBrands.length,
    };
  } else {
    return {
      list: vehicleBrands.data,
      total: vehicleBrands.total,
    };
  }
};
