import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import AuthIndexPage from "./pages/index/index.page";
import SignOutPage from "./pages/sign-out/sign-out.page";
import ResetPasswordPage from "./pages/reset-password/reset-password.page";
import RestorePasswordPage from "./pages/restore-password/restore-password.page";

const AuthPage: PageFunctionComponent = () => {
  return (
    <Switch>
      <Route path={`${AuthPage.path}${SignOutPage.path}`}>
        <Redirect to={AuthPage.path} />
      </Route>
      <Route path={`${AuthPage.path}${ResetPasswordPage.path}`}>
        <ResetPasswordPage />
      </Route>
      <Route path={`${AuthPage.path}${RestorePasswordPage.path}`}>
        <RestorePasswordPage />
      </Route>
      <Route path={"*"}>
        <AuthIndexPage />
      </Route>
    </Switch>
  );
};

AuthPage.path = "/auth";
AuthPage.label = "Аутентификация";

export default AuthPage;
