import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm, message, Tabs } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import RoleDescription from "components/descriptions/RoleDescription";
import { roleService } from "services";
import { Role } from "services/role/models/role";
import RoleForm, { RoleFormDto } from "components/forms/RoleForm";
import { RoleService } from "services/role/role.service";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import RoleAbilityRelation from "./components/RoleAbilityRelation";
import RoleMailingRelation from "./components/RoleMailingRelation";
import RoleReviewDestinationRelation from "./components/RoleReviewDestinationRelation";
import RolesSettingsPage from "../roles/roles.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

const { TabPane } = Tabs;

export interface RoleSettingsPageParams {
  id: Role["id"];
}

const fetchRole = (id: Role["id"]) => roleService.findOne(id);

const updateRole: RoleService["updateOne"] = (id, dto) => roleService.updateOne(id, dto);

const deleteRole = (id: Role["id"]) => roleService.deleteOne(id);

const RoleSettingsPage: PageFCWithPathCreator = () => {
  const { id } = useParams<RoleSettingsPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchRole,
    data: role,
    error: fetchRoleError,
    loading: fetchRoleLoading,
    refresh: refreshRole,
    mutate: mutateRole,
  } = useRequest(fetchRole, {
    manual: true,
  });

  const { runAsync: runUpdateRole, loading: updateRoleLoading } = useRequest(updateRole, {
    manual: true,

    onError: (e, params) => {
      const [, dto] = params;
      const { name } = dto;
      if (e instanceof ApiDriverHttpError) {
        if (e.status === 409) {
          message.error(`Роль с названием "${name}" уже существует!`);
        }
      }
    },
  });

  const { run: runDeleteRole, loading: deleteRoleLoading } = useRequest(deleteRole, {
    manual: true,
    onSuccess: () => {
      history.push(`${SettingsPage.path}${RolesSettingsPage.path}`);
    },
  });

  const [edit, setEdit] = React.useState<boolean>(false);

  useSetTitleEffect([SettingsPage.label, RoleSettingsPage.label, role?.name || id]);

  useMount(() => {
    if (id) {
      runFetchRole(id);
    }
  });

  const handleUpdate = React.useCallback(
    async (dto: RoleFormDto) => {
      if (!role) {
        return;
      }
      const updated = await runUpdateRole(role.id, dto);
      mutateRole(updated);
      setEdit(false);
    },
    [role, runUpdateRole, mutateRole, setEdit]
  );

  const handleDelete = React.useCallback(async () => {
    if (!role) {
      return;
    }
    await runDeleteRole(role.id);
    setEdit(false);
    await refreshRole();
  }, [role, runDeleteRole, setEdit, refreshRole]);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, RoleSettingsPage.label, role?.name || id]} />
      <PageHeader
        title={RoleSettingsPage.label}
        subTitle={role?.name}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[{ subject: Subject.ROLE, action: subjectAction.ROLE.UPDATE_ALL }]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={fetchRoleLoading || deleteRoleLoading || !role}
                  loading={updateRoleLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[{ subject: Subject.ROLE, action: subjectAction.ROLE.DELETE_ALL }]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={fetchRoleLoading || updateRoleLoading || !role}
                    loading={deleteRoleLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateRoleLoading || deleteRoleLoading}
                loading={fetchRoleLoading}
                onClick={refreshRole}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchRoleLoading} delay={500}>
          <HttpErrorResult error={fetchRoleError} />
          {edit ? (
            <Spin spinning={updateRoleLoading}>
              <RoleForm entityToUpdate={role} onFinish={handleUpdate} title={""} />
            </Spin>
          ) : (
            role && <RoleDescription data={role} title={""} />
          )}
        </Spin>
      </PageHeader>

      {role && (
        <Tabs type="card" style={{ marginTop: "16px" }} tabBarStyle={{ marginBottom: "0" }}>
          <TabPane tab="Права" key="1">
            <RoleAbilityRelation roleId={role.id} />
          </TabPane>
          <TabPane tab="Рассылки" key="2">
            <RoleMailingRelation roleId={role.id} />
          </TabPane>
          <TabPane tab="Назначения отзывов" key="3">
            <RoleReviewDestinationRelation roleId={role.id} />
          </TabPane>
        </Tabs>
      )}
    </ContentLayout>
  );
};

RoleSettingsPage.path = "/role/:id";
RoleSettingsPage.pathCreator = (id) => `/role/${id}`;
RoleSettingsPage.label = "Роль";

export default RoleSettingsPage;
