import React from "react";
import { Breadcrumb } from "antd";
import "./breadcrumbs.css";
import { SITE_LABEL } from "configs/configs";

interface BreadcrumbsProps {
  items: string[];
}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = (props) => {
  const { items: itemsProp } = props;
  const items = React.useMemo(() => [SITE_LABEL, ...itemsProp], [itemsProp]);
  return (
    <div className={"breadcrumbs_container"}>
      <Breadcrumb>
        {items.map((item) => (
          <Breadcrumb.Item key={`breadcrumb_item_${item}`}>{item}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
