import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { Type } from "class-transformer";
import { Review } from "../review.service";
import { User } from "services/user/models/user";

export class ReviewStatus {
  id: string;
  reviewId: string;
  status: ReviewStatusEnum;
  userId?: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => User)
  user?: User;

  @Type(() => Review)
  review?: Review;
}
