import React from "react";
import { Tag } from "antd";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { reviewStatusViewService } from "services";

interface ReviewStatusProps {
  status?: keyof typeof ReviewStatusEnum;
}

const ReviewStatus: React.FunctionComponent<ReviewStatusProps> = (props) => {
  const { status } = props;

  const statusData = React.useMemo(() => {
    if (!status) {
      return { color: undefined, name: "Статус не определен" };
    }
    return reviewStatusViewService.findOne(status);
  }, [status]);

  if (!statusData) {
    return <Tag>Статус не определен</Tag>;
  }

  return <Tag color={statusData.color}>{statusData.name}</Tag>;
};

export default ReviewStatus;
