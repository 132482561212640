import React from "react";
import { Descriptions } from "antd";
import { ReviewClientEmailPartialById } from "services/review/models/review-client-email";

export interface ReviewClientEmailDescriptionsProps {
  reviewClientEmail: ReviewClientEmailPartialById;
}

function ReviewClientEmailDescriptions(props: ReviewClientEmailDescriptionsProps) {
  const { reviewClientEmail } = props;

  return (
    <Descriptions bordered style={{ wordBreak: "normal" }} column={1}>
      <Descriptions.Item label="Email клиента">{reviewClientEmail.clientEmail}</Descriptions.Item>
      <Descriptions.Item label="Контент" style={{ wordBreak: "unset", whiteSpace: "pre-wrap" }}>
        <div dangerouslySetInnerHTML={{ __html: reviewClientEmail.clientEmailContent }} />
      </Descriptions.Item>
    </Descriptions>
  );
}

export default ReviewClientEmailDescriptions;
