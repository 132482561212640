import { ThunkAction } from "redux-thunk";
import { userService } from "services";
import { LatestTableStatesAction } from "store/reducers/latest-table-states";
import { NotificationsAction } from "store/reducers/notifications";
import { UserAction, UserActionType } from "store/reducers/user";
import { UserAbilitiesAction } from "store/reducers/user-abilities";
import { UserRolesAction } from "store/reducers/user-roles";
import { RootState } from "store/store";
import { clearLatestTableStates } from "./latest-table-states";
import { clearNotifications } from "./notifications";
import { clearUserAbilities } from "./user-abilities";
import { clearUserRoles } from "./user-roles";

export function signIn(
  username: string,
  password: string
): ThunkAction<Promise<void>, RootState, unknown, UserAction> {
  return async function signInThunk(dispatch) {
    try {
      dispatch({ type: UserActionType.SIGN_IN });
      await userService.signIn(username, password);
      dispatch({ type: UserActionType.SIGNED_IN });
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: UserActionType.ERRORED_SIGN_IN, payload: { error } });
      }
    }
  };
}

export function signOut(): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  UserAction | UserAbilitiesAction | UserRolesAction | LatestTableStatesAction | NotificationsAction
> {
  return async function signOutThunk(dispatch) {
    try {
      dispatch({ type: UserActionType.SIGN_OUT });
      await userService.signOut();
      dispatch({ type: UserActionType.SIGNED_OUT });
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: UserActionType.ERRORED_SIGN_OUT, payload: { error } });
      }
    } finally {
      dispatch(clearUserAbilities());
      dispatch(clearUserRoles());
      dispatch(clearLatestTableStates());
      dispatch(clearNotifications());
    }
  };
}

export function loadProfile(): ThunkAction<Promise<void>, RootState, unknown, UserAction> {
  return async function loadProfileThunk(dispatch) {
    try {
      dispatch({ type: UserActionType.LOAD_USER });
      const profile = await userService.getMyProfile();
      dispatch({ type: UserActionType.LOADED_USER, payload: { user: profile } });
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ type: UserActionType.ERRORED_USER, payload: { error } });
      }
    }
  };
}
