import React from "react";
import { useHistory } from "react-router-dom";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { Result, Button } from "antd";
import { useSetTitleEffect } from "store/hooks";
import ResultContainer from "components/common/result-container";
import IndexPage from "pages/index/index.page";

const NotFoundErrorPage: PageFunctionComponent = () => {
  const history = useHistory();
  useSetTitleEffect([NotFoundErrorPage.label]);

  return (
    <ResultContainer>
      <Result
        status={404}
        title="404"
        subTitle="Страница не найдена."
        extra={
          <Button type="primary" onClick={() => history.push({ pathname: IndexPage.path })}>
            Перейти на главную
          </Button>
        }
      />
    </ResultContainer>
  );
};

NotFoundErrorPage.path = "/*";
NotFoundErrorPage.label = "Не найдено";

export default NotFoundErrorPage;
