import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";
import { plainToClass } from "class-transformer";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { ReminderUpsertDto } from "./dtos/reminder-upsert-dto.type";
import { Reminder } from "./models/reminder";

export class ReminderService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<Reminder> | Reminder[]>("/reminder", {
      params: queryParams,
    });
    if (Array.isArray(response)) {
      response = plainToClass(Reminder, response);
    } else {
      response.data = plainToClass(Reminder, response.data);
    }
    return response;
  }

  async findOne(id: Reminder["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<Reminder>(`/reminder/${id}`, {
      params: queryParams,
    });
    return plainToClass(Reminder, response);
  }

  async createOne(dto: ReminderUpsertDto) {
    const response = await this.apiDriver.post<Reminder>("/reminder", dto);
    return plainToClass(Reminder, response);
  }

  async updateOne(id: string, dto: ReminderUpsertDto) {
    const response = await this.apiDriver.patch<Reminder>(`/reminder/${id}`, dto);
    return plainToClass(Reminder, response);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<Reminder>(`/reminder/${id}`, {
      params: queryParams,
    });
    return plainToClass(Reminder, deleted);
  }
}
