import { Type } from "class-transformer";
import { User } from "services/user/models/user";

export class Reminder {
  id: string; // https://github.com/typeorm/typeorm/issues/2400

  userId?: string | null;

  title: string;

  description?: string;

  @Type(() => Date)
  remindAt: Date;

  notificationId?: string | null;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => User)
  user?: User;
}
