import React from "react";
import { Descriptions } from "antd";
import { Branch } from "services/branch/models/branch";
import { displayDateTime } from "libs/helpers/display-date-time";

export interface BranchDescriptionProps {
  data: Branch;
  title?: string;
}

const COLUMN = { xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 };

const BranchDescription: React.FC<BranchDescriptionProps> = (props) => {
  const { data, title = "Филиал" } = props;

  return (
    <Descriptions title={title} column={COLUMN}>
      <Descriptions.Item label={<strong>Id</strong>}>{data.id}</Descriptions.Item>
      <Descriptions.Item label={<strong>ГУИД (1С)</strong>}>
        {data.guid1c || "Нет"}
      </Descriptions.Item>
      {data.city ? (
        <Descriptions.Item label={<strong>Город</strong>}>{data.city.name}</Descriptions.Item>
      ) : null}
      <Descriptions.Item label={<strong>Название</strong>}>{data.name}</Descriptions.Item>
      <Descriptions.Item label={<strong>Активен</strong>}>
        {data.isActive ? "Да" : "Нет"}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Дата обновления</strong>}>
        {displayDateTime(data.updatedAt)}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Дата создания</strong>}>
        {displayDateTime(data.createdAt)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default BranchDescription;
