import React from "react";
import { Helmet } from "react-helmet";
import { useAppSelector } from "store/hooks";

const Title: React.FC = () => {
  const title = useAppSelector((state) => state.title.title);

  return (
    <Helmet>
      <title>{title.join(" / ")}</title>
    </Helmet>
  );
};

export default Title;
