import { QuerySort, QuerySortArr, CreateQueryParams } from "@nestjsx/crud-request";
import { Review } from "services/review/review.service";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { reviewService } from "services";

const fetchReviews = async (params: CreateQueryParams) => {
  const validSort: Array<QuerySort | QuerySortArr> = [];

  if (Array.isArray(params.sort)) {
    for (const sortUnit of params.sort) {
      if (Array.isArray(sortUnit) || typeof sortUnit !== "string") {
        validSort.push(sortUnit);
      } else {
        break;
      }
    }

    if (validSort.length === 0) {
      validSort.push(params.sort as QuerySortArr);
    }
  } else if (params.sort) {
    validSort.push(params.sort);
  }

  validSort.push({ field: "id", order: "DESC" });

  if (params.search) {
    if (params.search.$and) {
      params.search.$and.push({
        status: { $in: [ReviewStatusEnum.DRAFT, ReviewStatusEnum.REQUIRE_CHECK] },
      });
    } else {
      params.search = {
        $and: [
          params.search,
          {
            status: { $in: [ReviewStatusEnum.DRAFT, ReviewStatusEnum.REQUIRE_CHECK] },
          },
        ],
      };
    }
  }

  const reviews = await reviewService.findAll({
    fields: [
      "id",
      "branchId",
      "rate",
      "status",
      "statusUpdatedAt",
      "clientDisplayName",
      "clientPhone",
      "clientEmail",
      "operatorUserId",
      "createdAt",
      "updatedAt",
    ],
    join: [
      ["branch", ["id", "name", "cityId"]],
      ["branch.city", ["id", "name"]],
      ["operatorUser", ["id", "firstName", "surname"]],
    ],
    page: params.page,
    limit: params.limit,
    sort: validSort,
    search: params.search,
  });

  return reviews as PagiantedResponse<Review>;
};

export default fetchReviews;
