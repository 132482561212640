import { QueryFilter, QueryFilterArr, QuerySort, SCondition } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { UserTableSimpleFormDto } from "components/tables/UserTable/UserTableSimpleForm";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { userService } from "services";
import { User } from "services/user/models/user";

export const getUserTableData: Service<Data, Params> = async (
  paginatedParams,
  formData: UserTableSimpleFormDto
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<User>(paginatedParams.sorter);

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  let search: SCondition | undefined = undefined;

  if (formData.firstNameOrPatronymicOrSurnameOrPhoneOrEmail) {
    search = {
      $or: [
        {
          firstName: {
            $contL: formData.firstNameOrPatronymicOrSurnameOrPhoneOrEmail.trim(),
          },
        },
        {
          patronymic: {
            $contL: formData.firstNameOrPatronymicOrSurnameOrPhoneOrEmail.trim(),
          },
        },
        {
          surname: {
            $contL: formData.firstNameOrPatronymicOrSurnameOrPhoneOrEmail.trim(),
          },
        },
        {
          email: {
            $contL: formData.firstNameOrPatronymicOrSurnameOrPhoneOrEmail.trim(),
          },
        },
        {
          phone: {
            $contL: formData.firstNameOrPatronymicOrSurnameOrPhoneOrEmail.trim(),
          },
        },
      ],
    };
  }

  let sort: QuerySort[] = [];
  if (tableQuerySorter) {
    if (tableQuerySorter.field === "fullName") {
      sort = [
        { field: "surname", order: tableQuerySorter.order },
        { field: "firstName", order: tableQuerySorter.order },
        { field: "patronymic", order: tableQuerySorter.order },
      ];
    } else {
      sort = [tableQuerySorter, { field: "id", order: "ASC" }];
    }
  } else {
    sort = [{ field: "id", order: "ASC" }];
  }

  const actions = await userService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort,
    filter,
    search,
  });

  if (Array.isArray(actions)) {
    return {
      list: actions,
      total: actions.length,
    };
  } else {
    return {
      list: actions.data,
      total: actions.total,
    };
  }
};
