import { Type } from "class-transformer";
import { Role } from "services/role/models/role";

export class RoleAbility {
  id!: string;
  roleId!: string;
  subject!: string;
  action!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Role)
  role?: Role;

  subjectLabel?: string;
  actionLabel?: string;
}
