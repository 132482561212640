import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";
import { plainToClass } from "class-transformer";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "../interfaces/paginated-response.interface";
import { RoleUpsertDto } from "./dtos/role-upsert-dto.type";
import { Role } from "./models/role";

export class RoleService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<Role> | Role[]>("/role", {
      params: queryParams,
    });
    if (Array.isArray(response)) {
      response = plainToClass(Role, response);
    } else {
      response.data = plainToClass(Role, response.data);
    }
    return response;
  }

  async findOne(id: Role["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<Role>(`/role/${id}`, {
      params: queryParams,
    });
    return plainToClass(Role, response);
  }

  async createOne(dto: RoleUpsertDto) {
    const role = await this.apiDriver.post<Role>("/role", dto);
    return plainToClass(Role, role);
  }

  async updateOne(id: string, dto: RoleUpsertDto) {
    const updated = await this.apiDriver.patch<Role>(`/role/${id}`, dto);
    return plainToClass(Role, updated);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<Role>(`/role/${id}`, {
      params: queryParams,
    });
    return plainToClass(Role, deleted);
  }
}
