import React from "react";
import { Result } from "antd";
import ResultContainer from "./result-container";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";

const FORBIDDEN_DEFAULT_SUBTITLE = "Доступ запрещен";
const NOT_FOUND_DEFAULT_SUBTITLE = "Не найдено";

export interface HttpErrorResultProps {
  error?: Error;
  subtitlesMap?: { "403"?: string; "404"?: string };
}

const HttpErrorResult: React.FunctionComponent<HttpErrorResultProps> = (props) => {
  const { error, subtitlesMap: subtitlesMapProp = {} } = props;

  const subtitlesMap = React.useMemo(
    () =>
      Object.assign(
        {},
        {
          "403": FORBIDDEN_DEFAULT_SUBTITLE,
          "404": NOT_FOUND_DEFAULT_SUBTITLE,
        },
        subtitlesMapProp
      ),
    [subtitlesMapProp]
  );

  if (!error) {
    return null;
  }

  if (error instanceof ApiDriverHttpError) {
    if (error.status === 403) {
      return (
        <ResultContainer>
          <Result status={403} title="403" subTitle={subtitlesMap["403"]} />
        </ResultContainer>
      );
    } else if (error.status === 404) {
      return (
        <ResultContainer>
          <Result status={404} title="404" subTitle={subtitlesMap["404"]} />
        </ResultContainer>
      );
    }
  }

  return (
    <ResultContainer>
      <Result status="error" title="Неизвестная ошибка" subTitle={error.message} />
    </ResultContainer>
  );
};

export default HttpErrorResult;
