import React from "react";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { gold } from "@ant-design/colors";
import { ArrowRightOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { SortOrder } from "antd/es/table/interface";
import { Branch } from "services/branch/models/branch";
import { ServiceOrder } from "services/service-order.service";
import { displayDateTime } from "libs/helpers/display-date-time";
import ServiceOrdersPage from "pages/service-orders/service-orders.page";
import { TableState } from "libs/interfaces/table-state.interface";
import { getColumnSearchProps } from "components/common/table-column-search-filter/helpers/get-column-search-props";
import ServiceOrderCardPage from "../../card/card.page";
import { Button } from "antd";

export default function getColumns(
  branches: Branch[],
  tableState: TableState<ServiceOrder>,
  handleAddReview: (serviceOrderIs: string) => void
): ColumnsType<ServiceOrder> {
  let closedAtSortOrder: SortOrder = null;
  let createdAtSortorder: SortOrder = null;

  if (Array.isArray(tableState.sorter)) {
    //...
  } else {
    closedAtSortOrder =
      tableState.sorter.field === "closedAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
    createdAtSortorder =
      tableState.sorter.field === "createdAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
  }

  return [
    {
      title: "Номер",
      dataIndex: "number",
      key: "number",
      ...getColumnSearchProps("number", "Номер заказ-наряда"),
      filteredValue: tableState.filters.number || null,
      render: (number: string) =>
        tableState.filters.number ? (
          <Highlighter
            highlightStyle={{ backgroundColor: gold[2], padding: 0 }}
            searchWords={tableState.filters.number.map((rStr) => rStr.toString().trim())}
            autoEscape
            textToHighlight={number}
          />
        ) : (
          number
        ),
      ellipsis: true,
    },
    {
      title: "Филиал",
      dataIndex: "branchGuid1c",
      key: "branchGuid1c",
      render: (branchGuid1c: any, record) => record.branchName,
      filters: branches.map((branch) => ({
        text: branch.name,
        value: branch.guid1c as string,
      })),
      filteredValue: tableState.filters.branchGuid1c || null,
      ellipsis: true,
    },
    {
      title: "Клиент",
      dataIndex: "clientDisplayNameOrClientPhone",
      key: "clientDisplayNameOrClientPhone",
      ...getColumnSearchProps("clientDisplayNameOrClientPhone", "Имя или телефон"),
      filteredValue: tableState.filters.clientDisplayName || null,
      render: (_: any, record) => {
        return (
          <React.Fragment>
            <div>
              {tableState.filters.clientDisplayNameOrClientPhone ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: gold[3], padding: 0 }}
                  searchWords={tableState.filters.clientDisplayNameOrClientPhone.map((rStr) =>
                    rStr.toString().trim()
                  )}
                  autoEscape
                  textToHighlight={record.clientDisplayName}
                />
              ) : (
                record.clientDisplayName
              )}
            </div>
            <div>
              {tableState.filters.clientDisplayNameOrClientPhone ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: gold[4], padding: 0 }}
                  searchWords={tableState.filters.clientDisplayNameOrClientPhone.map((rStr) =>
                    rStr.toString().trim()
                  )}
                  autoEscape
                  textToHighlight={record.clientPhone}
                />
              ) : (
                record.clientPhone
              )}
            </div>
          </React.Fragment>
        );
      },
      ellipsis: true,
    },
    {
      title: "Автомобиль",
      dataIndex: "car",
      key: "car",
      render: (car: any, record) => (
        <span>
          {[record.carManufacturer, record.carModel, record.carManufactureYear]
            .filter((el) => !!el)
            .join(" ")}
        </span>
      ),
      ellipsis: true,
    },
    {
      title: "Дата закрытия",
      dataIndex: "closedAt",
      key: "closedAt",
      render: (closedAt: Date) => displayDateTime(closedAt),
      sorter: true,
      sortOrder: closedAtSortOrder,
      ellipsis: true,
    },
    {
      title: "Дата выгрузки",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: Date) => displayDateTime(createdAt),
      sorter: true,
      sortOrder: createdAtSortorder,
      sortDirections: ["descend"],
      ellipsis: true,
    },
    {
      title: "",
      key: "addReviewAction",
      render: (_: any, record) => (
        <Button type="link" onClick={() => handleAddReview(record.id)}>
          Добавить отзыв
        </Button>
      ),
      fixed: "right",
      align: "center",
    },
    {
      title: "",
      key: "action",
      render: (text: any, record) => (
        <Link
          to={{
            pathname: `${ServiceOrdersPage.path}${ServiceOrderCardPage.pathCreator(record.id)}`,
            state: {
              canGoBack: true,
            },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      fixed: "right",
      align: "center",
    },
  ];
}
