import React from "react";
import { TableState } from "libs/interfaces/table-state.interface";
import { SorterResult } from "antd/es/table/interface";
import { HandleTableChange } from "./use-table-change-handler";

const DEFAULT_TABLE_SORTER: SorterResult<any>[] = [];

export function useTableSorterCleaner<RecordType>(
  currentState: TableState<RecordType>,
  handleTableChange: HandleTableChange<RecordType>,
  defaultTableSorter: SorterResult<RecordType> | SorterResult<RecordType>[] = DEFAULT_TABLE_SORTER
): [boolean, () => void] {
  const canClearSorter = React.useMemo(() => {
    if (Array.isArray(currentState.sorter)) {
      if (Array.isArray(defaultTableSorter)) {
        if (currentState.sorter.length !== defaultTableSorter.length) {
          return true;
        }

        let canClear = true;
        for (let i = 0; i < currentState.sorter.length; i++) {
          if (
            !(
              currentState.sorter[i].field === defaultTableSorter[i].field &&
              currentState.sorter[i].order === defaultTableSorter[i].order
            )
          ) {
            canClear = false;
            break;
          }
        }

        return canClear;
      } else {
        return true;
      }
    } else {
      if (Array.isArray(defaultTableSorter)) {
        return true;
      } else {
        return !(
          currentState.sorter.field === defaultTableSorter.field &&
          currentState.sorter.order === defaultTableSorter.order
        );
      }
    }
  }, [currentState.sorter, defaultTableSorter]);

  const clearSorter = React.useCallback(() => {
    handleTableChange(currentState.pagination, currentState.filters, defaultTableSorter, {
      currentDataSource: currentState.data,
      action: "sort",
    });
  }, [handleTableChange, currentState, defaultTableSorter]);

  return [canClearSorter, clearSorter];
}
