import React from "react";
import { useAntdTable, useRequest } from "ahooks";
import { Row, Col, PageHeader, Spin, Tag, Popconfirm, message } from "antd";
import { RoleMailing } from "services/role-mailing/models/role-mailing";
import { RoleMailingUpsertDto } from "services/role-mailing/dtos/role-mailing-upsert-dto.type";
import { RoleMailingService } from "services/role-mailing/role-mailing.service";
import { roleMailingService } from "services";
import RoleMailingTable from "./RoleMailingTable";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import RoleMailingForm, { RoleMailingFormProps } from "./RoleMailingForm";
import { ColumnsType } from "antd/es/table";
import RemoveButton from "components/buttons/remove-button";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";

const createRoleMailing: RoleMailingService["createOne"] = (roleId, dto) => {
  const dtoCopy: RoleMailingUpsertDto = { ...dto, roleId };
  return roleMailingService.createOne(roleId, dtoCopy);
};

const deleteRoleMailing: RoleMailingService["deleteOne"] = (roleId, id) =>
  roleMailingService.deleteOne(roleId, id);

export interface RoleMailingRelationProps {
  roleId?: RoleMailing["roleId"];
  loading?: boolean;
  buttonsDisabled?: boolean;
  title?: string;
}

const RoleMailingRelation: React.FC<RoleMailingRelationProps> = (props) => {
  const {
    roleId,
    loading: loadingProp = false,
    buttonsDisabled = false,
    title = "Рассылка роли",
  } = props;

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const accessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        update: false,
        delete: false,
      };
    }
    return {
      update: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.ROLE,
        action: subjectAction.ROLE.UPDATE_ALL,
      }),
      delete: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.ROLE,
        action: subjectAction.ROLE.DELETE_ALL,
      }),
    };
  }, [pageAccessControlState]);

  const [addRoleMailing, setAddRoleMailing] = React.useState<boolean>(false);

  const getTableData = React.useMemo(() => {
    if (!roleId) {
      return async () => {
        return {
          list: [],
          total: 0,
        };
      };
    }

    return roleMailingService.getRoleMailingTableDataGetter(roleId);
  }, [roleId]);

  const {
    tableProps,
    loading: getTableDataLoading,
    refresh,
    params,
  } = useAntdTable(getTableData, {
    defaultType: "simple",
    cacheKey: `RoleMailingTable-${roleId}`,
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: {},
      },
    ],
    refreshDeps: [roleId],
  });

  const { sorter = {} } = params[0] || ({} as any);

  const { run: runCreateRoleMailing, loading: createRoleMailingLoading } = useRequest(
    createRoleMailing,
    {
      manual: true,
      onSuccess: () => {
        setAddRoleMailing(false);
        refresh();
      },
      onError: (e) => {
        if (e instanceof ApiDriverHttpError) {
          if (e.status === 409) {
            message.error("Такая рассылка роли уже существует!");
          }
        }
      },
    }
  );

  const { run: runDeleteRoleMailing, loading: deleteRoleMailingLoading } = useRequest(
    deleteRoleMailing,
    {
      manual: true,
      onSuccess: () => {
        setAddRoleMailing(false);
        refresh();
      },
      onError: () => refresh(),
    }
  );

  const handleRoleMailingFormFinish: Required<RoleMailingFormProps>["onFinish"] = React.useCallback(
    (dto) => {
      if (roleId) {
        runCreateRoleMailing(roleId, dto);
      }
    },
    [roleId, runCreateRoleMailing]
  );

  const handleDelete = React.useCallback(
    async (roleMailingId: RoleMailing["id"]) => {
      if (!roleMailingId || !roleId) {
        return;
      }
      await runDeleteRoleMailing(roleId, roleMailingId);
    },
    [runDeleteRoleMailing, roleId]
  );

  const roleMailingTableRightExtra: ColumnsType<RoleMailing> = React.useMemo(() => {
    if (!accessControl.delete) {
      return [];
    }

    return [
      {
        title: "",
        dataIndex: "_",
        key: "_",
        ellipsis: true,
        align: "center",
        fixed: "right",
        render: (_: any, record) => (
          <Popconfirm
            title="Вы уверены? Это действие будет нельзя отменить."
            okText="Удалить"
            okButtonProps={{ danger: true }}
            onConfirm={() => handleDelete(record.id)}
          >
            <RemoveButton
              disabled={getTableDataLoading || createRoleMailingLoading}
              loading={deleteRoleMailingLoading}
            />
          </Popconfirm>
        ),
      },
    ];
  }, [
    getTableDataLoading,
    createRoleMailingLoading,
    deleteRoleMailingLoading,
    handleDelete,
    accessControl,
  ]);

  if (!roleId) {
    return null;
  }

  return (
    <PageHeader
      title={title}
      subTitle={<Tag color="warning">Вносимые изменения вступят в силу в течение 10 минут</Tag>}
      extra={
        <Row gutter={[8, 8]}>
          {accessControl.update ? (
            <Col>
              <AddButton
                disabled={buttonsDisabled}
                danger={addRoleMailing}
                onClick={() => setAddRoleMailing(!addRoleMailing)}
              >
                {addRoleMailing ? "Отмена" : "Добавить"}
              </AddButton>
            </Col>
          ) : null}
          <Col>
            <RefreshButton
              disabled={buttonsDisabled}
              loading={getTableDataLoading}
              onClick={refresh}
            />
          </Col>
        </Row>
      }
    >
      <Spin spinning={loadingProp} delay={500}>
        {addRoleMailing && (
          <div style={{ marginBottom: "24px" }}>
            <Spin spinning={createRoleMailingLoading || deleteRoleMailingLoading}>
              <RoleMailingForm
                title="Добавить рассылку роли"
                initialValues={{ roleId }}
                disabledFields={{ roleId: true }}
                onFinish={handleRoleMailingFormFinish}
              />
            </Spin>
          </div>
        )}

        <RoleMailingTable
          tableProps={tableProps}
          sorter={sorter}
          size="small"
          rightExtra={roleMailingTableRightExtra}
        />
      </Spin>
    </PageHeader>
  );
};

export default RoleMailingRelation;
