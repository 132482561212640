import React from "react";
import { gold } from "@ant-design/colors";
import Highlighter from "react-highlight-words";
import { ColumnsType } from "antd/es/table";
import { ServiceOrder } from "services/service-order.service";
import { displayDateTime } from "libs/helpers/display-date-time";
import { getColumnSearchProps } from "components/common/table-column-search-filter/table-column-search-filter";
import { TableState } from "libs/interfaces/table-state.interface";
function getSearchTableColumns(tableState: TableState<ServiceOrder>): ColumnsType<ServiceOrder> {
  return [
    {
      title: "Номер",
      dataIndex: "number",
      key: "number",
      ...getColumnSearchProps("number"),
      filteredValue: tableState.filters.number || null,
      render: (number: string) =>
        tableState.filters.number ? (
          <Highlighter
            highlightStyle={{ backgroundColor: gold[2], padding: 0 }}
            searchWords={tableState.filters.number.map((rStr) => rStr.toString().trim())}
            autoEscape
            textToHighlight={number}
          />
        ) : (
          number
        ),
      ellipsis: true,
    },
    {
      title: "Филиал",
      dataIndex: "branchGuid1c",
      key: "branchGuid1c",
      render: (_: any, record) => record.branchName,
      ellipsis: true,
    },
    {
      title: "Клиент",
      dataIndex: "clientDisplayName",
      key: "clientDisplayName",
      ...getColumnSearchProps("clientDisplayName"),
      filteredValue: tableState.filters.clientDisplayName || null,
      render: (clientDisplayName: string) =>
        tableState.filters.clientDisplayName ? (
          <Highlighter
            highlightStyle={{ backgroundColor: gold[2], padding: 0 }}
            searchWords={tableState.filters.clientDisplayName.map((rStr) => rStr.toString().trim())}
            autoEscape
            textToHighlight={clientDisplayName}
          />
        ) : (
          clientDisplayName
        ),
      ellipsis: true,
    },
    {
      title: "Телефон клиента",
      dataIndex: "clientPhone",
      key: "clientPhone",
      ...getColumnSearchProps("clientPhone"),
      filteredValue: tableState.filters.clientPhone || null,
      render: (clientPhone: string) =>
        tableState.filters.clientPhone ? (
          <Highlighter
            highlightStyle={{ backgroundColor: gold[2], padding: 0 }}
            searchWords={tableState.filters.clientPhone.map((rStr) => rStr.toString().trim())}
            autoEscape
            textToHighlight={clientPhone}
          />
        ) : (
          clientPhone
        ),
      ellipsis: true,
    },
    {
      title: "Дата закрытия",
      dataIndex: "closedAt",
      key: "closedAt",
      render: (_: any, record) => displayDateTime(record.closedAt),
      sorter: true,
      sortOrder: "descend",
      sortDirections: ["descend"],
      ellipsis: true,
    },
  ];
}

export default getSearchTableColumns;
