import React from "react";
import { Descriptions } from "antd";
import { Reminder } from "services/reminder/models/reminder";
import { displayDateTime } from "libs/helpers/display-date-time";

export interface ReminderDescriptionProps {
  data: Reminder;
  title?: string;
}

const COLUMN = { xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };

const ReminderDescription: React.FC<ReminderDescriptionProps> = (props) => {
  const { data, title = "Напоминание" } = props;

  return (
    <Descriptions title={title} column={COLUMN} bordered style={{ wordBreak: "normal" }}>
      <Descriptions.Item label={<strong>Id</strong>}>{data.id}</Descriptions.Item>
      {data.userId ? (
        <Descriptions.Item label={<strong>Пользователь</strong>}>
          {data.user ? data.user.fullName : data.userId}
        </Descriptions.Item>
      ) : null}
      <Descriptions.Item label={<strong>Дата напоминания</strong>}>
        {displayDateTime(data.remindAt)}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Заголовок</strong>} span={2}>
        {data.title}
      </Descriptions.Item>
      <Descriptions.Item
        label={<strong>Описание</strong>}
        span={2}
        style={{ wordBreak: "unset", whiteSpace: "pre-wrap" }}
      >
        {data.description ? <div dangerouslySetInnerHTML={{ __html: data.description }} /> : null}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Дата обновления</strong>}>
        {displayDateTime(data.updatedAt)}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Дата создания</strong>}>
        {displayDateTime(data.createdAt)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ReminderDescription;
