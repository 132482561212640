import { QuerySort } from "@nestjsx/crud-request";
import { SorterResult } from "antd/es/table/interface";

enum TableOrderToQueryOrderMap {
  ascend = "ASC",
  descend = "DESC",
}

export function convertTableSorterToQuerySorter<RecordType>(
  sorter: SorterResult<RecordType>
): QuerySort | undefined {
  if (!sorter.order) {
    return undefined;
  }

  return {
    field: String(sorter.field),
    order: TableOrderToQueryOrderMap[sorter.order],
  };
}
