import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { FolderOutlined } from "@ant-design/icons";
import { useAppSelector } from "store/hooks";

const { Sider } = Layout;

interface SideNavigationItem {
  key: string;
  pathname: string;
  icon?: React.ReactNode;
  label: React.ReactNode;
}

interface SideNavigationProps {
  items: SideNavigationItem[];
  selectedKey?: string;
}

const SideNavigation: React.FunctionComponent<SideNavigationProps> = (props) => {
  const { items, selectedKey } = props;
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);
  const sideBarCollapsed = useAppSelector((state) => state.appSettings.sideBarCollapsed);

  return (
    <Sider width={250} trigger={null} collapsible collapsed={sideBarCollapsed}>
      <Menu style={{ height: "100%", borderRight: 0 }} mode="inline" selectedKeys={selectedKeys}>
        {items.map(({ key, icon = <FolderOutlined />, pathname, label }) => (
          <Menu.Item key={key}>
            <Link to={{ pathname }}>
              {icon}
              <span>{label}</span>
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default SideNavigation;
