import React from "react";
import { useHistory } from "react-router-dom";
import { useAntdTable } from "ahooks";
import { Form, PageHeader, Row, Col, Button } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import useGoBack from "hooks/use-go-back";
import CityTableSimpleForm, {
  CityTableSimpleFormDto,
} from "components/tables/CityTable/CityTableSimpleForm";
import { getCityTableData } from "./get-city-table-data";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import CityTable from "components/tables/CityTable/CityTable";
import AddCitySettingsPage from "../add-city/add-city.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

const CitiesSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, CitiesSettingsPage.label]);

  const goBack = useGoBack();
  const history = useHistory();
  const [form] = Form.useForm<CityTableSimpleFormDto>();

  const { tableProps, loading, refresh, search, params } = useAntdTable(getCityTableData, {
    form,
    defaultType: "simple",
    cacheKey: "CitiesPageTable",
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: { field: "name", order: "ascend" },
      },
    ],
  });

  const { type, changeType, submit, reset } = search || {};
  const { sorter = {} } = params[0] || ({} as any);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, CitiesSettingsPage.label]} />
      <PageHeader
        title={CitiesSettingsPage.label}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[{ subject: Subject.CITY, action: subjectAction.CITY.CREATE }]}
              placeholder={null}
              loading={loading}
            >
              <Col>
                <AddButton
                  onClick={() =>
                    history.push({
                      pathname: `${SettingsPage.path}${AddCitySettingsPage.path}`,
                      state: { canGoBack: true },
                    })
                  }
                >
                  Добавить
                </AddButton>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton loading={loading} onClick={refresh} />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {type === "advance" ? null : (
            <CityTableSimpleForm form={form} submit={submit} reset={reset} />
          )}
          <Button type="link" onClick={changeType} hidden>
            {type === "simple" ? "Расширенный поиск" : "Простой поиск"}
          </Button>
        </div>

        <CityTable tableProps={tableProps} sorter={sorter} />
      </PageHeader>
    </ContentLayout>
  );
};

CitiesSettingsPage.path = "/cities";
CitiesSettingsPage.label = "Города";

export default CitiesSettingsPage;
