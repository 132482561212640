import { Review } from "services/review/review.service";
import { ConvertTableParamsToQueryParams } from "hooks/use-table-change-handler";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { QuerySort } from "@nestjsx/crud-request";
import { TABLE_DEFAULT_PAGE, TABLE_DEFAULT_PAGE_SIZE } from "configs/configs";

const convertTableParamsToQueryParams: ConvertTableParamsToQueryParams<Review> = (
  pagination,
  filters,
  sorter
) => {
  const search: { $and: any[] } = { $and: [] };

  if (Array.isArray(filters.status)) {
    search.$and.push({
      status: { $in: filters.status },
    });
  }

  if (Array.isArray(filters.clientDisplayName)) {
    if (filters.clientDisplayName.length > 0) {
      search.$and.push({
        clientDisplayName: { $contL: filters.clientDisplayName[0].toString().trim() },
      });
    }
  }

  if (Array.isArray(filters.clientContacts)) {
    if (filters.clientContacts.length > 0) {
      search.$and.push({
        $or: [
          { clientPhone: { $contL: filters.clientContacts[0].toString().trim() } },
          { clientEmail: { $contL: filters.clientContacts[0].toString().trim() } },
        ],
      });
    }
  }

  if (Array.isArray(filters.operator)) {
    if (filters.operator.length > 0) {
      search.$and.push({
        operatorUserId: { $in: filters.operator },
      });
    }
  }

  const fetchSorter = Array.isArray(sorter)
    ? sorter
        .map((s) => convertTableSorterToQuerySorter<Review>(s))
        .reduce((acc, curr) => {
          if (curr) {
            acc.push(curr);
          }
          return acc;
        }, [] as QuerySort[])
    : convertTableSorterToQuerySorter<Review>(sorter);

  return {
    page: pagination.current || TABLE_DEFAULT_PAGE,
    limit: pagination.pageSize || TABLE_DEFAULT_PAGE_SIZE,
    sort: fetchSorter,
    search,
  };
};

export default convertTableParamsToQueryParams;
