import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";

export interface SearchFilterIconProps {
  filtered?: boolean;
}

const SearchFilterIcon: React.FunctionComponent<SearchFilterIconProps> = (props) => {
  const { filtered = false } = props;
  return <SearchOutlined style={{ color: filtered ? blue.primary : undefined }} />;
};

export default SearchFilterIcon;
