import React from "react";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { gold } from "@ant-design/colors";
import { ArrowRightOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { SortOrder } from "antd/es/table/interface";
import { Branch } from "services/branch/models/branch";
import { ServiceOrder } from "services/service-order.service";
import { displayDateTime } from "libs/helpers/display-date-time";
import ServiceOrdersPage from "pages/service-orders/service-orders.page";
import { TableState } from "libs/interfaces/table-state.interface";
import { getColumnSearchProps } from "components/common/table-column-search-filter/helpers/get-column-search-props";
import ServiceOrderCardPage from "../../card/card.page";

export default function getColumns(
  branches: Branch[],
  tableState: TableState<ServiceOrder>
): ColumnsType<ServiceOrder> {
  let closedAtSortOrder: SortOrder = null;
  let createdAtSortorder: SortOrder = null;

  if (Array.isArray(tableState.sorter)) {
    //...
  } else {
    closedAtSortOrder =
      tableState.sorter.field === "closedAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
    createdAtSortorder =
      tableState.sorter.field === "createdAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
  }

  return [
    {
      title: "Номер",
      dataIndex: "number",
      key: "number",
      ...getColumnSearchProps("number", "Номер заказ-наряда"),
      filteredValue: tableState.filters.number || null,
      render: (number: string) =>
        tableState.filters.number ? (
          <Highlighter
            highlightStyle={{ backgroundColor: gold[2], padding: 0 }}
            searchWords={tableState.filters.number.map((rStr) => rStr.toString().trim())}
            autoEscape
            textToHighlight={number}
          />
        ) : (
          number
        ),
    },
    {
      title: "Филиал",
      dataIndex: "branchGuid1c",
      key: "branchGuid1c",
      render: (branchGuid1c: any, record) => record.branchName,
      filters: branches.map((branch) => ({
        text: branch.name,
        value: branch.guid1c as string,
      })),
      filteredValue: tableState.filters.branchGuid1c || null,
    },
    {
      title: "Клиент",
      dataIndex: "clientDisplayName",
      key: "clientDisplayName",
      ...getColumnSearchProps("clientDisplayName"),
      filteredValue: tableState.filters.clientDisplayName || null,
      render: (_: any, record) =>
        tableState.filters.clientDisplayName ? (
          <Highlighter
            highlightStyle={{ backgroundColor: gold[3], padding: 0 }}
            searchWords={tableState.filters.clientDisplayName.map((rStr) => rStr.toString().trim())}
            autoEscape
            textToHighlight={record.clientDisplayName}
          />
        ) : (
          record.clientDisplayName
        ),
    },
    {
      title: "Телефон клиента",
      dataIndex: "clientPhone",
      key: "clientPhone",
      ...getColumnSearchProps("clientPhone", "Телефон клиента"),
      filteredValue: tableState.filters.clientPhone || null,
      render: (clientPhone: string) =>
        tableState.filters.clientPhone ? (
          <Highlighter
            highlightStyle={{ backgroundColor: gold[4], padding: 0 }}
            searchWords={tableState.filters.clientPhone.map((rStr) => rStr.toString().trim())}
            autoEscape
            textToHighlight={clientPhone}
          />
        ) : (
          clientPhone
        ),
    },
    {
      title: "Автомобиль",
      dataIndex: "car",
      key: "car",
      render: (car: any, record) => (
        <span>
          {record.carManufacturer} {record.carModel} {record.carManufactureYear}
        </span>
      ),
    },
    {
      title: "VIN автомобиля",
      dataIndex: "carVin",
      key: "carVin",
    },
    {
      title: "Дата закрытия",
      dataIndex: "closedAt",
      key: "closedAt",
      render: (closedAt: Date) => displayDateTime(closedAt),
      sorter: true,
      sortOrder: closedAtSortOrder,
    },
    {
      title: "Дата выгрузки",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: Date) => displayDateTime(createdAt),
      sorter: true,
      sortOrder: createdAtSortorder,
      sortDirections: ["descend"],
    },
    {
      title: "",
      key: "action",
      render: (text: any, record) => (
        <Link
          to={{
            pathname: `${ServiceOrdersPage.path}${ServiceOrderCardPage.pathCreator(record.id)}`,
            state: {
              canGoBack: true,
            },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      fixed: "right",
      align: "center",
    },
  ];
}
