import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { ReviewServiceOrder } from "services/models/review-service-order.model";
import { displayDateTime } from "libs/helpers/display-date-time";
import ReviewsPage from "pages/reviews/reviews.page";
import ReviewCardPage from "pages/reviews/pages/card/card.page";
import ReviewStatus from "components/common/review-status";

function getReviewTableColumns(): ColumnsType<ReviewServiceOrder> {
  return [
    {
      title: "Дата создания",
      dataIndex: "reviewCreatedAt",
      key: "reviewCreatedAt",
      render: (_: any, record) => (
        <React.Fragment>
          {record.review ? displayDateTime(record.review?.createdAt) : ""}
        </React.Fragment>
      ),
    },
    {
      title: "Текущий статус",
      dataIndex: "reviewStatus",
      key: "reviewStatus",
      render: (_: any, record) => <ReviewStatus status={record.review?.status} />,
    },
    {
      title: "Оператор",
      dataIndex: "operator",
      key: "operator",
      render: (_: any, record) =>
        record.review && (
          <React.Fragment>
            {record.review.operatorUser?.surname} {record.review.operatorUser?.firstName}
          </React.Fragment>
        ),
    },
    {
      title: "",
      key: "action",
      render: (_: any, record) => (
        <Link
          to={{
            pathname: `${ReviewsPage.path}${ReviewCardPage.pathCreator(record.review?.id || "")}`,
            state: {
              canGoBack: true,
            },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      fixed: "right",
      align: "center",
    },
  ];
}

export default getReviewTableColumns;
