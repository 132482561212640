import React from "react";
import { useLocation, useHistory } from "react-router-dom";

function useGoBack(locationStateKey = "canGoBack") {
  const { state: locationState } = useLocation();
  const history = useHistory();

  const goBack = React.useMemo(() => {
    const canGoBack = locationState ? locationState[locationStateKey] : false;
    return canGoBack ? () => history.goBack() : undefined;
  }, [history, locationState, locationStateKey]);

  return goBack;
}

export default useGoBack;
