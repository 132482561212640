import React, { useMemo, useReducer } from "react";
import { PromiseValue } from "type-fest";
import { Parameters } from "libs/types/parameters.type";
import { ReturnType } from "libs/types/return-type.type";

export interface AsyncActionState<T> {
  result?: T;
  loading: boolean;
  error?: Error;
}

export enum AsyncActionActionType {
  INIT = "INIT",
  RESULT = "RESULT",
  ERROR = "ERROR",
  RESET = "RESET",
}

export interface AsyncActionInitAction {
  type: AsyncActionActionType.INIT;
}

export interface AsyncActionResultAction<T> {
  type: AsyncActionActionType.RESULT;
  payload: T;
}

export interface AsyncActionErrorAction {
  type: AsyncActionActionType.ERROR;
  payload: Error;
}

export interface AsyncActionResetAction {
  type: AsyncActionActionType.RESET;
}

export type AsyncActionAction<T> =
  | AsyncActionInitAction
  | AsyncActionResultAction<T>
  | AsyncActionErrorAction
  | AsyncActionResetAction;

const createReducer: <T>() => React.Reducer<AsyncActionState<T>, AsyncActionAction<T>> =
  () => (prevState, action) => {
    switch (action.type) {
      case AsyncActionActionType.INIT:
        return {
          loading: true,
        };
      case AsyncActionActionType.RESULT:
        return {
          loading: false,
          result: action.payload,
        };
      case AsyncActionActionType.ERROR:
        return {
          loading: false,
          error: action.payload,
        };
      case AsyncActionActionType.RESET: {
        return { loading: false };
      }
    }
  };

function useAsyncActionState<T extends (...args: any[]) => Promise<any>>(
  asyncAction: T
): [
  (
    ...params: Parameters<typeof asyncAction>
  ) => Promise<AsyncActionState<PromiseValue<ReturnType<T>>>>,
  AsyncActionState<PromiseValue<ReturnType<T>>>,
  () => void
] {
  const reducer = useMemo(() => createReducer<PromiseValue<ReturnType<T>>>(), []);

  const [state, dispatch] = useReducer(reducer, { loading: false });

  let wrappedAsyncAction = React.useCallback(
    async function wrappedAsyncAction(...params: Parameters<typeof asyncAction>) {
      let result;
      let error;
      try {
        await dispatch({ type: AsyncActionActionType.INIT });
        const res = await asyncAction(...params);
        await dispatch({ type: AsyncActionActionType.RESULT, payload: res });
        result = res;
      } catch (err) {
        if (err instanceof Error) {
          error = err;
          await dispatch({ type: AsyncActionActionType.ERROR, payload: err });
        }
      }
      return { result, error, loading: false };
    },
    [asyncAction]
  );

  const resetState = () => dispatch({ type: AsyncActionActionType.RESET });

  return [wrappedAsyncAction, state, resetState];
}

export default useAsyncActionState;
