import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import { ENV } from "configs/configs";
import rootReducer from "./reducers";
import { TokenPairActionType, TokenPairState } from "./reducers/token-pair";
import { apiDriver } from "api-driver.instance";
import { setTokenPair } from "./actions/token-pair";
import { NotificationsActionType } from "./reducers/notifications";

const persistConfig = {
  key: "store",
  storage,
  whitelist: ["appSettings", "tokenPair"],
};

const syncConfig = {
  whitelist: [
    TokenPairActionType.SET_TOKEN_PAIR,
    TokenPairActionType.CLEAR_TOKEN_PAIR,
    NotificationsActionType.SET_MY_UNREAD_NOTIFICATION_COUNT,
    NotificationsActionType.LOADED_MY_UNREAD_NOTIFICATION_COUNT,
  ],
};

const persistedRootReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [createStateSyncMiddleware(syncConfig), thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancer =
  ENV === "development" ? composeWithDevTools(middlewareEnhancer) : middlewareEnhancer;
const store = createStore(persistedRootReducer, enhancer);

export const persistor = persistStore(store);
initMessageListener(store);

let currentTokenPair: TokenPairState | null = null;
export const unsubscribeTokenPairRefresh = store.subscribe(() => {
  const state = store.getState();

  let previousTokenPair = currentTokenPair;
  currentTokenPair = state.tokenPair;

  if (
    previousTokenPair?.accessToken !== currentTokenPair.accessToken &&
    previousTokenPair?.refreshToken !== currentTokenPair.refreshToken
  ) {
    apiDriver.accessToken = currentTokenPair.accessToken || undefined;
    apiDriver.refreshToken = currentTokenPair.refreshToken || undefined;
  }
});

apiDriver.onTokenPairUpdate = async (tokenPair) => {
  store.dispatch(
    setTokenPair({
      accessToken: tokenPair.accessToken || null,
      refreshToken: tokenPair.refreshToken || null,
    })
  );
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
