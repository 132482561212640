import React from "react";
import { Form, Input, Button, Spin, Select, Alert } from "antd";
import { Store } from "antd/lib/form/interface";
import { FormInstance, Rule } from "antd/lib/form";
import { SelectValue } from "antd/lib/select";
import { noOp } from "libs/helpers/no-op";
import { ReviewSite } from "services/review-site/models/review-site";
import useAsyncActionState from "hooks/use-async-action-state";
import { ReviewWebLink } from "services/review/models/review-web-link.model";
import {
  getRuleFromSchema,
  reviewSiteIdSchema,
  linkSchemaWhenReviewSiteIdIsNotEmpty,
  linkSchemaWhenReviewSiteIdIsEmpty,
  clientNicknameSchema,
} from "./review-web-link-validation";
import useState from "hooks/use-state";
import { reviewSiteService } from "services";

const reviewSitesFetcher = () => {
  return reviewSiteService.findAll() as Promise<ReviewSite[]>;
};

export interface ReviewWebLinkFormProps {
  form?: FormInstance;
  onFinish?: (reviewWebLink: ReviewWebLink) => void;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const ReviewWebLinkForm: React.FC<ReviewWebLinkFormProps> = (props) => {
  const { form, onFinish = noOp } = props;

  const [linkRules, setLinkRules] = useState<Rule[]>([
    getRuleFromSchema(linkSchemaWhenReviewSiteIdIsEmpty),
  ]);

  const [fetchReviewSites, { loading: reviewSitesLoading, result: reviewSites = [] }] =
    useAsyncActionState(reviewSitesFetcher);

  const handleFinish = React.useCallback(
    (values: Store) => {
      const reviewWebLink = new ReviewWebLink();
      reviewWebLink.reviewSiteId = values.reviewSiteId;
      reviewWebLink.link = values.link;
      reviewWebLink.clientNickname = values.clientNickname;
      if (values.reviewSiteId) {
        reviewWebLink.reviewSite = reviewSites.find(
          (reviewSite) => reviewSite.id === values.reviewSiteId
        );
      }
      onFinish(reviewWebLink);
    },
    [onFinish, reviewSites]
  );

  const handleReviewSiteIdSelectChange = React.useCallback(
    (value: SelectValue) => {
      if (value) {
        setLinkRules([getRuleFromSchema(linkSchemaWhenReviewSiteIdIsNotEmpty)], () => {
          form?.validateFields();
        });
      } else {
        setLinkRules([getRuleFromSchema(linkSchemaWhenReviewSiteIdIsEmpty)], () => {
          form?.validateFields();
        });
      }
    },
    [setLinkRules, form]
  );

  React.useEffect(() => {
    fetchReviewSites();
  }, [fetchReviewSites]);

  return (
    <Spin spinning={reviewSitesLoading}>
      <Alert
        message="Если сайт выбран, то ссылка - необязательное поле."
        type="info"
        showIcon
        style={{ marginBottom: 32 }}
      />
      <Form form={form} {...layout} onFinish={handleFinish}>
        <Form.Item
          label="Сайт"
          name="reviewSiteId"
          rules={[getRuleFromSchema(reviewSiteIdSchema)]}
          validateTrigger={"onBlur"}
        >
          <Select placeholder="Выбрать" allowClear onChange={handleReviewSiteIdSelectChange}>
            {reviewSites.map((reviewSite) => (
              <Select.Option key={`reviewSite_${reviewSite.id}`} value={reviewSite.id}>
                {reviewSite.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Ссылка" name="link" rules={linkRules} validateTrigger={"onBlur"}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Никнейм клиента"
          name="clientNickname"
          rules={[getRuleFromSchema(clientNicknameSchema)]}
          validateTrigger={"onBlur"}
        >
          <Input />
        </Form.Item>
        {!form && (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
    </Spin>
  );
};

export default ReviewWebLinkForm;
