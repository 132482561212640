import React from "react";
import { PageHeader, Table, Row, Col } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import ReviewsPage from "pages/reviews/reviews.page";
import { useAppDispatch, useAppSelector, useSetTitleEffect } from "store/hooks";
import RefreshButton from "components/buttons/refresh-button";
import ResetButton from "components/buttons/reset-button";
import { useTableState } from "hooks/use-table-state";
import { DEFAULT_TABLE_SORTER } from "pages/reviews/reviews.constants";
import { Review } from "services/review/review.service";
import { useTableChangeHandler } from "hooks/use-table-change-handler";
import fetchReviews from "./helpers/fetch-reviews";
import convertTableParamsToQueryParams from "./helpers/convert-table-params-to-query-params";
import getColumns from "./helpers/get-columns";
import fetchBranches from "../../helpers/fetch-branches";
import fetchReviewDestinationTypes from "../../helpers/fetch-review-destination-types";
import fetchReviewTagTypes from "../../helpers/fetch-review-tag-types";
import useAsyncActionState from "hooks/use-async-action-state";
import { Branch } from "services/branch/models/branch";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";
import getReviewAttachments from "./helpers/get-review-attachments";
import getReviewStatusViews from "./helpers/get-review-status-views";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import { useTableSorterCleaner } from "hooks/use-table-sorter-cleaner";
import { useTableFilterCleaner } from "hooks/use-table-filter-cleaner";
import fetchOperators from "pages/reviews/helpers/fetch-operators";
import { User } from "services/user/models/user";
import { setAllReviewsTableState } from "store/actions/latest-table-states";

const filterDataFetcher = () =>
  Promise.all([
    fetchBranches(),
    fetchReviewDestinationTypes(),
    fetchReviewTagTypes(),
    fetchOperators(),
  ]) as Promise<[Branch[], ReviewDestinationType[], ReviewTagType[], User[]]>;
const reviewAttachments = getReviewAttachments();
const reviewStatusViews = getReviewStatusViews();

const AllReviewsPage: PageFunctionComponent = () => {
  useSetTitleEffect([ReviewsPage.label, AllReviewsPage.label]);
  const allReviewsTableState = useAppSelector(
    (state) => state.latestTableStates.allReviewsTableState
  );
  const dispatch = useAppDispatch();
  const [state, , setStateAsync] = useTableState<Review>(
    allReviewsTableState ? allReviewsTableState : { sorter: DEFAULT_TABLE_SORTER }
  );
  const handleTableChange = useTableChangeHandler(
    state,
    setStateAsync,
    convertTableParamsToQueryParams,
    fetchReviews,
    DEFAULT_TABLE_SORTER
  );
  const [fetchFilterData, { loading: filterDataLoading, result: filterData = [[], [], [], []] }] =
    useAsyncActionState(filterDataFetcher);
  const columns = React.useMemo(
    () =>
      getColumns(
        state,
        filterData[0],
        filterData[1],
        reviewAttachments,
        filterData[2],
        reviewStatusViews,
        filterData[3]
      ),
    [state, filterData]
  );

  const [canClearSorter, clearSorter] = useTableSorterCleaner(
    state,
    handleTableChange,
    DEFAULT_TABLE_SORTER
  );
  const [canClearFilter, clearFilter] = useTableFilterCleaner(state, handleTableChange, {});

  useComponentDidMountEffect(() => {
    handleTableChange(state.pagination, state.filters, state.sorter, {
      currentDataSource: state.data,
      action: "paginate",
    });
    fetchFilterData();
  });

  React.useEffect(() => {
    dispatch(setAllReviewsTableState(state));
  }, [dispatch, state]);

  const loading = state.loading || filterDataLoading;

  return (
    <PageHeader
      title={AllReviewsPage.label}
      extra={
        <Row gutter={[8, 8]}>
          <Col>
            <ResetButton onClick={() => clearFilter()} disabled={!canClearFilter || loading}>
              Сбросить фильтры
            </ResetButton>
          </Col>
          <Col>
            <ResetButton onClick={() => clearSorter()} disabled={!canClearSorter || loading}>
              Сбросить сортировки
            </ResetButton>
          </Col>
          <Col>
            <RefreshButton
              onClick={() =>
                handleTableChange(state.pagination, state.filters, state.sorter, {
                  currentDataSource: state.data,
                  action: "paginate",
                })
              }
              loading={loading}
            />
          </Col>
        </Row>
      }
    >
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={state.data}
        pagination={state.pagination}
        loading={{ spinning: loading, delay: 500 }}
        onChange={handleTableChange}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </PageHeader>
  );
};

AllReviewsPage.path = "/all";
AllReviewsPage.label = "Все отзывы";

export default AllReviewsPage;
