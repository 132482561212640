import React from "react";
import { useHistory } from "react-router-dom";
import { useAntdTable } from "ahooks";
import { Form, PageHeader, Row, Col, Button } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import useGoBack from "hooks/use-go-back";
import ReviewTagTypeTableSimpleForm, {
  ReviewTagTypeTableSimpleFormDto,
} from "components/tables/ReviewTagTypeTable/ReviewTagTypeTableSimpleForm";
import { getReviewTagTypeTableData } from "./get-review-tag-type-table-data";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import ReviewTagTypeTable from "components/tables/ReviewTagTypeTable/ReviewTagTypeTable";
import AddReviewTagTypeSettingsPage from "../add-review-tag-type/add-review-tag-type.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

const ReviewTagTypesSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, ReviewTagTypesSettingsPage.label]);

  const goBack = useGoBack();
  const history = useHistory();
  const [form] = Form.useForm<ReviewTagTypeTableSimpleFormDto>();

  const { tableProps, loading, refresh, search, params } = useAntdTable(getReviewTagTypeTableData, {
    form,
    defaultType: "simple",
    cacheKey: "ReviewTagTypesPageTable",
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: { field: "name", order: "ascend" },
      },
    ],
  });

  const { type, changeType, submit, reset } = search || {};
  const { sorter = {} } = params[0] || ({} as any);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, ReviewTagTypesSettingsPage.label]} />
      <PageHeader
        title={ReviewTagTypesSettingsPage.label}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                { subject: Subject.REVIEW_TAG_TYPE, action: subjectAction.REVIEW_TAG_TYPE.CREATE },
              ]}
              placeholder={null}
              loading={loading}
            >
              <Col>
                <AddButton
                  onClick={() =>
                    history.push({
                      pathname: `${SettingsPage.path}${AddReviewTagTypeSettingsPage.path}`,
                      state: { canGoBack: true },
                    })
                  }
                >
                  Добавить
                </AddButton>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton loading={loading} onClick={refresh} />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {type === "advance" ? null : (
            <ReviewTagTypeTableSimpleForm form={form} submit={submit} reset={reset} />
          )}
          <Button type="link" onClick={changeType} hidden>
            {type === "simple" ? "Расширенный поиск" : "Простой поиск"}
          </Button>
        </div>

        <ReviewTagTypeTable tableProps={tableProps} sorter={sorter} />
      </PageHeader>
    </ContentLayout>
  );
};

ReviewTagTypesSettingsPage.path = "/review-tag-types";
ReviewTagTypesSettingsPage.label = "Теги отзывов";

export default ReviewTagTypesSettingsPage;
