import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { volcano, green, grey, orange, cyan } from "@ant-design/colors";

export interface ReviewStatusView {
  id: keyof typeof ReviewStatusEnum;
  name: string;
  color: string;
}

export class ReviewStatusViewService {
  private data: ReviewStatusView[] = [
    { id: ReviewStatusEnum.DRAFT, name: "Черновик", color: grey[5] },
    { id: ReviewStatusEnum.PROCESSING, name: "В обработке", color: volcano[5] },
    { id: ReviewStatusEnum.CLOSED, name: "Закрыт", color: green[5] },
    { id: ReviewStatusEnum.REQUIRE_CHECK, name: "Требуется проверка", color: orange[5] },
    { id: ReviewStatusEnum.READY_TO_CLOSE, name: "Готов к закрытию", color: cyan[5] },
  ];

  findAll() {
    return this.data;
  }

  findOne(id: keyof typeof ReviewStatusEnum | string) {
    return this.data.find((status) => status.id === id);
  }
}
