import React from "react";
import { Descriptions } from "antd";
import { ReviewWebLinkPartialById } from "services/review/models/review-web-link.model";
import ExternalLink from "components/common/external-link";

export interface ReviewWebLinkDescriptionsProps {
  reviewWebLink: ReviewWebLinkPartialById;
}

function ReviewWebLinkDescriptions(props: ReviewWebLinkDescriptionsProps) {
  const { reviewWebLink } = props;

  return (
    <Descriptions
      bordered
      style={{ wordBreak: "normal" }}
      column={{ xxl: 3, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
    >
      {reviewWebLink.reviewSite && (
        <Descriptions.Item label="Сайт">
          {reviewWebLink.reviewSite.link ? (
            <ExternalLink href={reviewWebLink.reviewSite.link}>
              {reviewWebLink.reviewSite.name}
            </ExternalLink>
          ) : null}
        </Descriptions.Item>
      )}
      {reviewWebLink.clientNickname && (
        <Descriptions.Item label="Никнейм клиента">
          {reviewWebLink.clientNickname}
        </Descriptions.Item>
      )}
      {reviewWebLink.link && (
        <Descriptions.Item label="Ссылка">
          <ExternalLink href={reviewWebLink.link} />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}

export default ReviewWebLinkDescriptions;
