import React from "react";
import { Card, Menu, Dropdown, Button } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { geekblue, blue } from "@ant-design/colors";
import { ReviewChatMessage } from "services/review/models/review-chat-message.model";
import { ReviewStatus as ReviewStatusModel } from "services/review/models/review-status.model";
import ReviewStatusComponent from "components/common/review-status";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";
import ReviewChatMessageForm from "../review-chat-message-form/review-chat-message-form";
import InfoTooltip from "components/common/into-tooltip";

export interface ReviewChatProps {
  reviewId: string;
  items?: (ReviewChatMessage | ReviewStatusModel)[];
  userId?: string;
  onReviewChatMessageUpdate?: (reviewChatMessage: ReviewChatMessage) => Promise<void>;
  loading?: boolean;
  disableReviewChatMessageUpdate?: boolean;
}

const ReviewChat: React.FC<ReviewChatProps> = (props) => {
  const {
    reviewId,
    items = [],
    userId,
    onReviewChatMessageUpdate = noOp,
    loading = false,
    disableReviewChatMessageUpdate = false,
  } = props;
  const [editorItemId, setEditorItemId] = React.useState<string | null>(null);

  const handleSubmit = React.useCallback(
    async (reviewChatMessage: ReviewChatMessage) => {
      await onReviewChatMessageUpdate(reviewChatMessage);
      setEditorItemId(null);
    },
    [onReviewChatMessageUpdate, setEditorItemId]
  );

  return (
    <div>
      {items.map((item) => {
        if (item instanceof ReviewChatMessage) {
          if (item.id === editorItemId) {
            return (
              <div id={`review-chat-message-${item.id}`} key={`reviewChatMessage_${item.id}`}>
                <Card
                  bordered={false}
                  size="small"
                  style={{ marginBottom: 8 }}
                  headStyle={{ backgroundColor: userId === item.userId ? blue[2] : blue[0] }}
                  bodyStyle={{ padding: 0 }}
                  title={
                    <React.Fragment>
                      {item.user ? (
                        <React.Fragment>
                          <strong>
                            {[item.user?.surname, item.user?.firstName, item.user?.patronymic]
                              .filter((v) => !!v)
                              .join(" ")}
                          </strong>
                          <InfoTooltip
                            title={
                              <span>
                                тел. {item.user.phone}{" "}
                                {item.user.phoneDescription && `(${item.user.phoneDescription})`}
                              </span>
                            }
                            style={{ marginLeft: 4 }}
                          />
                        </React.Fragment>
                      ) : (
                        <i>Пользователь удален</i>
                      )}{" "}
                      » {displayDateTime(item.createdAt)}
                    </React.Fragment>
                  }
                >
                  <ReviewChatMessageForm
                    key={`reviewChatMessageForm_${item.id}`}
                    reviewId={reviewId}
                    reviewChatMessageId={item.id}
                    initialValues={{ message: item.message }}
                    onSubmit={handleSubmit}
                    extra={
                      <Button size="large" onClick={() => setEditorItemId(null)} disabled={loading}>
                        Отмена
                      </Button>
                    }
                    loading={loading}
                  />
                </Card>
              </div>
            );
          }

          return (
            <div id={`review-chat-message-${item.id}`} key={`reviewChatMessage_${item.id}`}>
              <Card
                size="small"
                style={{ marginBottom: 8 }}
                headStyle={{ backgroundColor: userId === item.userId ? blue[2] : blue[0] }}
                bodyStyle={{ padding: 0 }}
                title={
                  <React.Fragment>
                    {item.user ? (
                      <React.Fragment>
                        <strong>
                          {[item.user?.surname, item.user?.firstName, item.user?.patronymic]
                            .filter((v) => !!v)
                            .join(" ")}
                        </strong>
                        <InfoTooltip
                          title={
                            <span>
                              тел. {item.user.phone}{" "}
                              {item.user.phoneDescription && `(${item.user.phoneDescription})`}
                            </span>
                          }
                          style={{ marginLeft: 4 }}
                        />
                      </React.Fragment>
                    ) : (
                      <i>Пользователь удален</i>
                    )}{" "}
                    » {displayDateTime(item.createdAt)}
                  </React.Fragment>
                }
                extra={
                  disableReviewChatMessageUpdate ||
                  (userId === item.userId && (
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu
                          onClick={(params) => {
                            if (params.key === "1") {
                              setEditorItemId(item.id);
                            }
                          }}
                        >
                          <Menu.Item key="1">Редактировать</Menu.Item>
                        </Menu>
                      }
                      placement="bottomRight"
                    >
                      <MoreOutlined />
                    </Dropdown>
                  ))
                }
              >
                <div
                  style={{ marginBottom: 0 }}
                  className="ql-editor"
                  dangerouslySetInnerHTML={{ __html: item.message }}
                />
                {Number(item.createdAt) !== Number(item.updatedAt) && (
                  <div style={{ marginLeft: 15 }}>
                    <i>Изменено: {displayDateTime(item.updatedAt)}</i>
                  </div>
                )}
              </Card>
            </div>
          );
        }
        if (item instanceof ReviewStatusModel) {
          return (
            <div id={`review-status-${item.id}`} key={`reviewStatus_${item.id}`}>
              <Card
                size="small"
                style={{ marginBottom: 8 }}
                headStyle={{ backgroundColor: geekblue[0] }}
                bodyStyle={{ padding: 0 }}
                title={
                  <React.Fragment>
                    Установлен статус <ReviewStatusComponent status={item.status} />
                    {item.user ? (
                      <React.Fragment>
                        <strong>
                          {[item.user?.surname, item.user?.firstName, item.user?.patronymic]
                            .filter((v) => !!v)
                            .join(" ")}
                        </strong>
                        <InfoTooltip
                          title={
                            <span>
                              тел. {item.user.phone}{" "}
                              {item.user.phoneDescription && `(${item.user.phoneDescription})`}
                            </span>
                          }
                          style={{ marginLeft: 4 }}
                        />
                      </React.Fragment>
                    ) : (
                      <i>Пользователь удален</i>
                    )}{" "}
                    » {displayDateTime(item.createdAt)}
                  </React.Fragment>
                }
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ReviewChat;
