import { SITE_LABEL } from "configs/configs";

export interface TitleState {
  title: string[];
}

export enum TitleActionType {
  SET_TITLE = "SET_TITLE",
  RESET_TITLE_TO_DEFAULT = "RESET_TITLE_TO_DEFAULT",
}

export interface TitleAction {
  type: TitleActionType;
  payload: Partial<TitleState>;
}

const initialState: TitleState = {
  title: [SITE_LABEL],
};

export default function titleReducer(state = initialState, action: TitleAction): TitleState {
  switch (action.type) {
    case TitleActionType.SET_TITLE:
      return { title: [SITE_LABEL, ...(action.payload.title || [])] };
    case TitleActionType.RESET_TITLE_TO_DEFAULT:
      return initialState;
    default:
      return state;
  }
}
