import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm, message, Tag } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import UserDescription from "components/descriptions/UserDescription";
import { userService } from "services";
import { User } from "services/user/models/user";
import UserForm, { UserFormDto } from "components/forms/UserForm";
import { UserService } from "services/user/user.service";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import UserRoleRelation from "./components/UserRoleRelation";
import UsersSettingsPage from "../users/users.page";
import CheckAbilities from "store/components/CheckAbilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";

export interface UserSettingsPageParams {
  id: User["id"];
}

const fetchUser = (id: User["id"]) => userService.findOne(id);

const updateUser: UserService["updateOne"] = (id, dto) => userService.updateOne(id, dto);

const deleteUser = (id: User["id"]) => userService.deleteOne(id);

const UserSettingsPage: PageFCWithPathCreator = () => {
  const { id } = useParams<UserSettingsPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchUser,
    data: user,
    error: fetchUserError,
    loading: fetchUserLoading,
    refresh: refreshUser,
    mutate: mutateUser,
  } = useRequest(fetchUser, {
    manual: true,
  });

  const { runAsync: runUpdateUser, loading: updateUserLoading } = useRequest(updateUser, {
    manual: true,

    onError: (e, params) => {
      const [, dto] = params;
      const { email } = dto;
      if (e instanceof ApiDriverHttpError) {
        if (e.status === 409) {
          message.error(`Пользователь с почтой "${email}" уже существует!`);
        }
      }
    },
  });

  const { run: runDeleteUser, loading: deleteUserLoading } = useRequest(deleteUser, {
    manual: true,
    onSuccess: () => {
      history.push(`${SettingsPage.path}${UsersSettingsPage.path}`);
    },
  });

  const [edit, setEdit] = React.useState<boolean>(false);

  useSetTitleEffect([SettingsPage.label, UserSettingsPage.label, user?.fullName || id]);

  useMount(() => {
    if (id) {
      runFetchUser(id);
    }
  });

  const handleUpdate = React.useCallback(
    async (dto: UserFormDto) => {
      if (!user) {
        return;
      }
      const updated = await runUpdateUser(user.id, dto);
      mutateUser(updated);
      setEdit(false);
    },
    [user, runUpdateUser, mutateUser, setEdit]
  );

  const handleDelete = React.useCallback(async () => {
    if (!user) {
      return;
    }
    await runDeleteUser(user.id);
    setEdit(false);
    await refreshUser();
  }, [user, runDeleteUser, setEdit, refreshUser]);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, UserSettingsPage.label, user?.fullName || id]} />
      <PageHeader
        title={UserSettingsPage.label}
        subTitle={
          <span>
            <span>{user?.fullName}</span>
            {user && !user.isActive ? (
              <React.Fragment>
                <span style={{ marginLeft: "12px" }}>
                  <Tag color="grey">Не активен</Tag>
                </span>
              </React.Fragment>
            ) : null}
          </span>
        }
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[{ subject: Subject.USER, action: subjectAction.USER.UPDATE_ALL }]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={fetchUserLoading || deleteUserLoading || !user}
                  loading={updateUserLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[{ subject: Subject.USER, action: subjectAction.USER.DELETE_ALL }]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={fetchUserLoading || updateUserLoading || !user}
                    loading={deleteUserLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateUserLoading || deleteUserLoading}
                loading={fetchUserLoading}
                onClick={refreshUser}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchUserLoading} delay={500}>
          <HttpErrorResult error={fetchUserError} />
          {edit ? (
            <Spin spinning={updateUserLoading}>
              <UserForm entityToUpdate={user} onFinish={handleUpdate} title={""} />
            </Spin>
          ) : (
            user && <UserDescription data={user} title={""} />
          )}
        </Spin>
      </PageHeader>
      {user && (
        <UserRoleRelation
          userId={user.id}
          loading={updateUserLoading || deleteUserLoading}
          buttonsDisabled={updateUserLoading || deleteUserLoading}
        />
      )}
    </ContentLayout>
  );
};

UserSettingsPage.path = "/user/:id";
UserSettingsPage.pathCreator = (id) => `/user/${id}`;
UserSettingsPage.label = "Пользователь";

export default UserSettingsPage;
