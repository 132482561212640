import React from "react";
import { Result } from "antd";
import { CheckAbilitiesAbilityToCheckParamItem } from "store/helpers/check-abilities";
import { useAppSelector } from "store/hooks";

export interface CheckAbilitiesProps {
  abilities?: CheckAbilitiesAbilityToCheckParamItem[];
  manual?: boolean; // if true - visible, else if false - placeholder
  placeholder?: React.ReactNode;
  loading?: boolean;
}

const DEFAULT_ABILITIES: CheckAbilitiesAbilityToCheckParamItem[] = [];

const DEFAULT_PLACEHOLDER: React.ReactNode = (
  <div style={{ marginLeft: "auto", marginRight: "auto" }}>
    <Result status={403} title={403} subTitle="Доступ запрещен" />
  </div>
);

const CheckAbilities: React.FC<CheckAbilitiesProps> = (props) => {
  const {
    children,
    abilities = DEFAULT_ABILITIES,
    manual,
    placeholder = DEFAULT_PLACEHOLDER,
    loading = false,
  } = props;

  const userAbilities = useAppSelector((state) => state.userAbilities);

  const checkAbilities = React.useMemo(() => {
    if (manual !== undefined) {
      return manual;
    }

    const yesICan = abilities.reduce((result, ability) => {
      const myAbility = userAbilities.userAbilities.find((a) => {
        let predicate = a.subject === ability.subject && a.action === ability.action;
        if (ability.reviewDestinationTypeIds) {
          predicate =
            predicate &&
            a.reviewDestinationTypeIds.some((e) => ability.reviewDestinationTypeIds?.includes(e));
        }
        if (ability.branchId) {
          predicate = predicate && a.branchIds.includes(ability.branchId);
        }
        return predicate;
      });

      if (myAbility) {
        result = result || true;
      }
      return result;
    }, false);

    return yesICan;
  }, [userAbilities, abilities, manual]);

  if (userAbilities.loading || loading) {
    return null;
  }

  if (!checkAbilities) {
    return <React.Fragment>{placeholder}</React.Fragment>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default CheckAbilities;
