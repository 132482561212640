import { BlacklistClient } from "services/blacklist-client.service";
import { uniqBy } from "lodash";
import { blacklistClientService } from "services";

const addToBlacklist = (blacklistClients: BlacklistClient[]) => {
  const blacklistClientsToCreate = uniqBy(
    blacklistClients.filter((item) => !!item.clientPhone),
    "clientPhone"
  );
  return blacklistClientService.createMany(blacklistClientsToCreate);
};

export default addToBlacklist;
