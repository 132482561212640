import { Type } from "class-transformer";

export class Notification {
  id: string;
  userId: string;
  title: string;
  description?: string;

  @Type(() => Date)
  notifyAt: Date;

  isDelivered: boolean;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;
}
