import React from "react";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import { RoleReviewDestination } from "services/role-review-destination/models/role-review-destination";

export interface RoleReviewDestinationTableProps {
  tableProps: TableProps<RoleReviewDestination>;
  sorter: SorterResult<RoleReviewDestination> | SorterResult<RoleReviewDestination>[];
  size?: SizeType;
  rightExtra?: ColumnsType<RoleReviewDestination>;
}

export interface RoleReviewDestinationTableGetColumnsParams {
  sorter: RoleReviewDestinationTableProps["sorter"];
  rightExtra?: RoleReviewDestinationTableProps["rightExtra"];
}

const getColumns: (
  params: RoleReviewDestinationTableGetColumnsParams
) => ColumnsType<RoleReviewDestination> = (params) => {
  const { sorter, rightExtra } = params;

  let createdAtSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    }
  }

  const columns: ColumnsType<RoleReviewDestination> = [
    {
      title: "Назначение отзывов",
      dataIndex: "reviewDestinationTypeId",
      key: "reviewDestinationTypeId",
      ellipsis: true,
      render: (_: any, record) => {
        if (record.reviewDestinationType) {
          return record.reviewDestinationType.name;
        }
        return record.reviewDestinationTypeId;
      },
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
  ];

  if (rightExtra) {
    columns.push(...rightExtra);
  }

  return columns;
};

const RoleReviewDestinationTable: React.FC<RoleReviewDestinationTableProps> = (props) => {
  const { tableProps, sorter, size, rightExtra } = props;

  const columns = React.useMemo(() => getColumns({ sorter, rightExtra }), [sorter, rightExtra]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default RoleReviewDestinationTable;
