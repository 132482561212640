import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";
import { plainToClass } from "class-transformer";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { UserRoleUpsertDto } from "./dtos/user-role-upsert-dto.type";
import { UserRole } from "./models/user-role";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";

export class UserRoleService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(userId: UserRole["userId"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<UserRole> | UserRole[]>(
      `/user/${userId}/user-role`,
      { params: queryParams }
    );
    if (Array.isArray(response)) {
      response = plainToClass(UserRole, response);
    } else {
      response.data = plainToClass(UserRole, response.data);
    }
    return response;
  }

  async findOne(userId: UserRole["userId"], id: UserRole["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<UserRole>(`/user/${userId}/user-role/${id}`, {
      params: queryParams,
    });
    return plainToClass(UserRole, response);
  }

  async createOne(userId: UserRole["userId"], dto: UserRoleUpsertDto) {
    const role = await this.apiDriver.post<UserRole>(`/user/${userId}/user-role`, dto);
    return plainToClass(UserRole, role);
  }

  async updateOne(userId: UserRole["userId"], id: UserRole["id"], dto: UserRoleUpsertDto) {
    const updated = await this.apiDriver.patch<UserRole>(`/user/${userId}/user-role/${id}`, dto);
    return plainToClass(UserRole, updated);
  }

  async deleteOne(userId: UserRole["userId"], id: UserRole["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<UserRole>(`/user/${userId}/user-role/${id}`, {
      params: queryParams,
    });
    return plainToClass(UserRole, deleted);
  }

  getUserRoleTableDataGetter(userId: UserRole["userId"]): Service<Data, Params> {
    return async (paginatedParams, formData: Record<string, unknown>) => {
      const tableQuerySorter = convertTableSorterToQuerySorter<UserRole>(paginatedParams.sorter);

      const userRoles = await this.findAll(userId, {
        page: paginatedParams.current,
        limit: paginatedParams.pageSize,
        sort: tableQuerySorter
          ? [tableQuerySorter, { field: "id", order: "ASC" }]
          : { field: "id", order: "ASC" },
        join: [["user"], ["role"], ["branch"]],
      });

      if (Array.isArray(userRoles)) {
        return {
          list: userRoles,
          total: userRoles.length,
        };
      } else {
        return {
          list: userRoles.data,
          total: userRoles.total,
        };
      }
    };
  }
}
