import { TokenPairAction, TokenPairActionType, TokenPairState } from "store/reducers/token-pair";

export function setTokenPair(tokenPair: TokenPairState): TokenPairAction {
  return {
    type: TokenPairActionType.SET_TOKEN_PAIR,
    payload: { accessToken: tokenPair.accessToken, refreshToken: tokenPair.refreshToken },
  };
}

export function clearTokenPair(): TokenPairAction {
  return { type: TokenPairActionType.CLEAR_TOKEN_PAIR };
}
