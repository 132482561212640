import React from "react";
import { SorterResult } from "antd/lib/table/interface";
import { TableState } from "libs/interfaces/table-state.interface";

export interface StateMarkers {
  canClearSorter: boolean;
  canClearFilters: boolean;
}

export function useTableStateCleaningMarkers<RecordType>(
  state: TableState<RecordType>,
  defaultTableSorter: SorterResult<RecordType> = {}
): StateMarkers {
  const canClearSorter = React.useMemo(() => {
    if (Array.isArray(state.sorter)) {
      const sorter = state.sorter.filter((s) => !!s.order);
      if (sorter.length === 0) {
        return false;
      } else if (sorter.length === 1) {
        return !(
          state.sorter[0].field === defaultTableSorter.field &&
          state.sorter[0].order === defaultTableSorter.order
        );
      } else {
        return true;
      }
    } else {
      return !(
        state.sorter.field === defaultTableSorter.field &&
        state.sorter.order === defaultTableSorter.order
      );
    }
  }, [state.sorter, defaultTableSorter]);

  const canClearFilters = React.useMemo(
    () =>
      Object.keys(state.filters).reduce((prev, curr) => {
        if (state.filters[curr] !== null) {
          prev.push(curr);
        }
        return prev;
      }, [] as string[]).length > 0,
    [state.filters]
  );

  return { canClearSorter, canClearFilters };
}
