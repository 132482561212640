import { Type } from "class-transformer";
import { User } from "../../user/models/user";

export class IntegrationKey {
  id: string;
  belongsToUserId: string;
  createdByUserId?: string;
  description?: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => User)
  belongsToUser?: User;

  @Type(() => User)
  createdByUser?: User;
}
