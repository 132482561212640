import React from "react";
import { Link } from "react-router-dom";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import ReviewSiteSettingsPage from "pages/settings/pages/review-site/review-site.page";
import { ArrowRightOutlined } from "@ant-design/icons";
import SettingsPage from "pages/settings/settings.page";
import { ReviewSite } from "services/review-site/models/review-site";
import ExternalLink from "components/common/external-link";

export interface ReviewSiteTableProps {
  tableProps: TableProps<ReviewSite>;
  sorter: SorterResult<ReviewSite> | SorterResult<ReviewSite>[];
  size?: SizeType;
}

const getColumns: (
  sorter: SorterResult<ReviewSite> | SorterResult<ReviewSite>[]
) => ColumnsType<ReviewSite> = (sorter) => {
  let nameSorter: SortOrder = null;
  let createdAtSortOrder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "name" && sorter.order) {
      nameSorter = sorter.order;
    } else if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    } else if (sorter.field === "updatedAt" && sorter.order) {
      updatedAtSortOrder = sorter.order;
    }
  }

  return [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: nameSorter,
      ellipsis: true,
      render: (_: any, record) => record.name,
    },
    {
      title: "Ссылка",
      dataIndex: "link",
      key: "link",
      ellipsis: true,
      render: (_: any, record) =>
        record.link ? <ExternalLink href={record.link}>{record.link}</ExternalLink> : null,
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortOrder: updatedAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "",
      dataIndex: "reviewSite",
      key: "reviewSite",
      render: (_: any, record) => (
        <Link
          to={{
            pathname: `${SettingsPage.path}${ReviewSiteSettingsPage.pathCreator(record.id)}`,
            state: { canGoBack: true },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      align: "center",
      fixed: "right",
      ellipsis: true,
    },
  ];
};

const ReviewSiteTable: React.FC<ReviewSiteTableProps> = (props) => {
  const { tableProps, sorter, size } = props;

  const columns = React.useMemo(() => getColumns(sorter), [sorter]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default ReviewSiteTable;
