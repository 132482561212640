import React from "react";
import { Form, Descriptions, Button, Space } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import { UserRole } from "services/user-role/models/user-role";
import { UserRoleUpsertDto } from "services/user-role/dtos/user-role-upsert-dto.type";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";
import UserSelect from "components/selects/UserSelect";
import RoleSelect from "components/selects/RoleSelect";
import BranchSelect, {
  BRANCH_SELECT_ALL_BRANCHES_OPTION_VALUE,
} from "components/selects/BranchSelect";

export type UserRoleFormDto = UserRoleUpsertDto;

export interface UserRoleFormProps {
  form?: FormInstance<UserRoleFormDto>;
  onFinish?: (dto: UserRoleUpsertDto, entityToUpdate?: UserRole) => void;
  entityToUpdate?: UserRole;
  initialValues?: Partial<UserRoleFormDto>;
  disabledFields?: { [key in keyof UserRoleFormDto]?: boolean };
  title?: string;
}

const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };

const UserRoleForm: React.FC<UserRoleFormProps> = (props) => {
  const {
    form: formProp,
    onFinish = noOp,
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Роль пользователя",
    disabledFields,
  } = props;

  const [form] = Form.useForm(formProp);

  const reset = React.useCallback(() => {
    form.resetFields();
  }, [form]);

  const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = {
      branchId: BRANCH_SELECT_ALL_BRANCHES_OPTION_VALUE,
    };

    if (entityToUpdate) {
      Object.assign(iV, entityToUpdate);
    }

    if (initialValuesProp) {
      Object.assign(iV, initialValuesProp);
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const handleFinish = React.useCallback(
    (dto: UserRoleFormDto) => {
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="userId"
            name="userId"
            label={<strong>Пользователь</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <UserSelect disabled={disabledFields?.userId} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="roleId"
            name="roleId"
            label={<strong>Роль</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <RoleSelect disabled={disabledFields?.roleId} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="branchId"
            name="branchId"
            label={<strong>Филиал</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <BranchSelect disabled={disabledFields?.branchId} allBranchesOption />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата обновления</strong>}>
          {displayDateTime(entityToUpdate?.updatedAt)}
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата создания</strong>}>
          {displayDateTime(entityToUpdate?.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Сохранить
          </Button>
          <Button type="text" size="large" onClick={reset}>
            Сбросить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UserRoleForm;
