import { blacklistClientService } from "services";

export interface FetchBlacklistClientParams {
  clientGuid1c: string;
  clientPhone: string;
}

const fetchBlacklistClient = async (params: FetchBlacklistClientParams) => {
  const blacklistClients = await blacklistClientService.findAll({
    search: {
      $or: [
        { clientGuid1c: { $eq: params.clientGuid1c } },
        { clientPhone: { $eq: params.clientPhone } },
      ],
    },
    limit: 1,
  });

  if (Array.isArray(blacklistClients)) {
    if (blacklistClients.length === 0) {
      return undefined;
    }
    return blacklistClients[0];
  } else {
    if (blacklistClients.data.length === 0) {
      return undefined;
    }
    return blacklistClients.data[0];
  }
};

export default fetchBlacklistClient;
