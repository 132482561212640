import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { UnorderedListOutlined } from "@ant-design/icons";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import usePath from "hooks/use-path";
import SideNavigation from "components/common/side-navigation";
import Breadcrumbs from "components/common/breadcrumbs";
import ContentLayout from "components/layouts/content.layout";
import NotFoundErrorPage from "pages/not-found-error/not-found-error.page";
import AllServiceOrdersPage from "./pages/all/all.page";
import ServiceOrersWithoutReview from "./pages/without-review/without-review.page";
import ServiceOrderCardPage from "./pages/card/card.page";
import { SideNavigationItem } from "libs/interfaces/side-navigation-item.interface";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";
import CheckAbilities from "store/components/CheckAbilities";

const ServiceOrdersPage: PageFunctionComponent = () => {
  const path = usePath(2);

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);
  const pageAccessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        AllServiceOrdersPage: false,
        ServiceOrderCardPage: false,
        ServiceOrersWithoutReview: false,
      };
    }

    return {
      AllServiceOrdersPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.SERVICE_ORDER,
        action: subjectAction.SERVICE_ORDER.READ_ALL,
      }),
      ServiceOrderCardPage: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.SERVICE_ORDER,
        action: subjectAction.SERVICE_ORDER.READ_ALL,
      }),
      ServiceOrersWithoutReview: checkAbilities(pageAccessControlState.abilities, {
        $and: [
          {
            subject: Subject.SERVICE_ORDER,
            action: subjectAction.SERVICE_ORDER.READ_ALL,
          },
          { subject: Subject.REVIEW, action: subjectAction.REVIEW.CREATE },
        ],
      }),
    };
  }, [pageAccessControlState]);

  const sideNavigationItems = React.useMemo(() => {
    const items: SideNavigationItem[] = [];

    if (pageAccessControl.AllServiceOrdersPage) {
      items.push({
        key: `${ServiceOrdersPage.path}${AllServiceOrdersPage.path}`,
        pathname: `${ServiceOrdersPage.path}${AllServiceOrdersPage.path}`,
        icon: <UnorderedListOutlined />,
        label: AllServiceOrdersPage.label,
      });
    }

    if (pageAccessControl.ServiceOrersWithoutReview) {
      items.push({
        key: `${ServiceOrdersPage.path}${ServiceOrersWithoutReview.path}`,
        pathname: `${ServiceOrdersPage.path}${ServiceOrersWithoutReview.path}`,
        label: ServiceOrersWithoutReview.label,
      });
    }

    return items;
  }, [pageAccessControl]);

  return (
    <React.Fragment>
      <SideNavigation selectedKey={path} items={sideNavigationItems} />
      <Switch>
        <Route exact path={ServiceOrdersPage.path}>
          <Redirect to={`${ServiceOrdersPage.path}${AllServiceOrdersPage.path}`} />
        </Route>
        <Route path={`${ServiceOrdersPage.path}${ServiceOrderCardPage.path}`}>
          <CheckAbilities manual={pageAccessControl.ServiceOrderCardPage}>
            <ServiceOrderCardPage />
          </CheckAbilities>
        </Route>
        <Route path={`${ServiceOrdersPage.path}${AllServiceOrdersPage.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ServiceOrdersPage.label, AllServiceOrdersPage.label]} />
            <CheckAbilities manual={pageAccessControl.AllServiceOrdersPage}>
              <AllServiceOrdersPage />
            </CheckAbilities>
          </ContentLayout>
        </Route>
        <Route path={`${ServiceOrdersPage.path}${ServiceOrersWithoutReview.path}`}>
          <ContentLayout>
            <Breadcrumbs items={[ServiceOrdersPage.label, ServiceOrersWithoutReview.label]} />
            <CheckAbilities manual={pageAccessControl.ServiceOrersWithoutReview}>
              <ServiceOrersWithoutReview />
            </CheckAbilities>
          </ContentLayout>
        </Route>
        <Route path={`${ServiceOrdersPage.path}${NotFoundErrorPage.path}`}>
          <NotFoundErrorPage />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

ServiceOrdersPage.path = "/service-orders";
ServiceOrdersPage.label = "Заказ-наряды";

export default ServiceOrdersPage;
