import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Spin, Alert, message } from "antd";
import "./restore-password.page.css";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import Breadcrumbs from "components/common/breadcrumbs";
import AuthPage from "pages/auth/auth.page";
import useAsyncActionState from "hooks/use-async-action-state";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { userService } from "services";

const restorePasswordHelper = (email: string) => userService.restorePassword(email);

const RestorePasswordPage: PageFunctionComponent = () => {
  useSetTitleEffect([RestorePasswordPage.label]);
  const history = useHistory();
  const [state, setState] = React.useState({ email: "", finish: false });

  const [restorePassword, { error: restorePasswordError, loading }] =
    useAsyncActionState(restorePasswordHelper);

  React.useEffect(() => {
    const error = restorePasswordError as ApiDriverHttpError;
    if (error) {
      let content = "Ошибка!";
      if (error.status === 404) {
        content = "Пользователя с тамим email'ом не существует.";
      } else if (error.status === 429) {
        content = "Слишком много запросов. Попробуйте позже.";
      } else if (error.status >= 500) {
        content = "Ошибка сервера.";
      }
      message.error(content);
    }
  }, [restorePasswordError]);

  const handleFinish = async (values: any) => {
    const { email } = values;
    const { error } = await restorePassword(email);
    if (!error) {
      setState({ email, finish: true });
    }
  };

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Breadcrumbs items={[RestorePasswordPage.label]} />
      <Spin spinning={loading}>
        {state.finish ? (
          <React.Fragment>
            <a
              className="restore-password-form-back"
              href={`${AuthPage.path}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`${AuthPage.path}`);
              }}
            >
              Назад к форме входа
            </a>
            <br />
            <br />
            <Alert
              message="Проверьте электронную почту."
              description={
                <span>
                  По адресу, указанному вами <strong>({state.email})</strong>, будет немедленно
                  отправлено сообщение.
                </span>
              }
              type="success"
              showIcon
            />
          </React.Fragment>
        ) : (
          <Form name="restore-password" size="large" onFinish={handleFinish}>
            <Form.Item>
              <a
                className="restore-password-form-back"
                href={`${AuthPage.path}`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`${AuthPage.path}`);
                }}
              >
                Назад к форме входа
              </a>
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, type: "email", message: "Введите email" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Восстановить пароль
              </Button>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </div>
  );
};

RestorePasswordPage.path = "/restore-password";
RestorePasswordPage.label = "Восстановление пароля";

export default RestorePasswordPage;
