import React from "react";
import { Collapse } from "antd";
import { ReviewServiceOrderPartialById } from "services/models/review-service-order.model";
import { ReviewWebLinkPartialById } from "services/review/models/review-web-link.model";
import { ReviewClientCallPartialById } from "services/review/models/review-client-call.model";
import { ReviewClientEmailPartialById } from "services/review/models/review-client-email";
import ServiceOrderDescriptions from "components/descriptions/service-order-descriptions";
import ReviewServiceOrderDescriptions from "components/descriptions/review-service-order-desriptions";
import ReviewWebLinkDescriptions from "components/descriptions/review-web-link-descriptions";
import ReviewClientEmailDescriptions from "components/descriptions/review-client-email-descriptions";
import ReviewClientCallDescriptions from "components/descriptions/review-client-call-descriptions";
import { ReviewAttachment } from "services/review/review.service";
import { displayDateTime } from "libs/helpers/display-date-time";

export interface ReviewAttachmentsCollapseExtraPropProps {
  attachment: ReviewAttachment;
}

export interface ReviewAttachmentsCollapseProps {
  reviewServiceOrders?: ReviewServiceOrderPartialById[];
  reviewWebLinks?: ReviewWebLinkPartialById[];
  reviewClientCalls?: ReviewClientCallPartialById[];
  reviewClientEmails?: ReviewClientEmailPartialById[];
  extra?: (props: ReviewAttachmentsCollapseExtraPropProps) => React.ReactNode;
}

const extraFiller: React.FC<ReviewAttachmentsCollapseExtraPropProps> = (props) => null;

function ReviewAttachmentCollapse(props: ReviewAttachmentsCollapseProps) {
  const {
    reviewServiceOrders = [],
    reviewWebLinks = [],
    reviewClientCalls = [],
    reviewClientEmails = [],
    extra = extraFiller,
  } = props;

  return (
    <Collapse style={{ borderRadius: 0 }}>
      {reviewServiceOrders?.map((reviewServiceOrder, index) => {
        if (reviewServiceOrder.serviceOrder) {
          const car = (
            <span>
              {[
                reviewServiceOrder.serviceOrder.carManufacturer,
                reviewServiceOrder.serviceOrder.carModel,
                reviewServiceOrder.serviceOrder.carManufactureYear,
              ]
                .filter((el) => !!el)
                .join(" ")}
            </span>
          );
          const closedAt = displayDateTime(reviewServiceOrder.serviceOrder.closedAt);

          return (
            <Collapse.Panel
              key={`reviewServiceOrder_${index}`}
              header={
                <React.Fragment>
                  <span>
                    Заказ наряд № <b>{reviewServiceOrder.serviceOrder.number}</b>
                  </span>
                  <span style={{ marginLeft: 32, wordBreak: "break-word" }}>{car}</span>
                  <span style={{ marginLeft: 32, wordBreak: "break-word" }}>{closedAt}</span>
                </React.Fragment>
              }
              extra={extra({ attachment: reviewServiceOrder })}
            >
              <ServiceOrderDescriptions serviceOrder={reviewServiceOrder.serviceOrder} />
            </Collapse.Panel>
          );
        } else {
          return (
            <Collapse.Panel
              key={`reviewServiceOrder_${index}`}
              header={`Заказ наряд № ${reviewServiceOrder.serviceOrderNumber}`}
              extra={extra({ attachment: reviewServiceOrder })}
            >
              <ReviewServiceOrderDescriptions reviewServiceOrder={reviewServiceOrder} />
            </Collapse.Panel>
          );
        }
      })}
      {reviewWebLinks?.map((reviewWebLink, index) => (
        <Collapse.Panel
          key={`reviewWebLink_${index}`}
          header={
            reviewWebLink.reviewSite
              ? `Отзыв на сайте ${reviewWebLink.reviewSite?.name}`
              : "Отзыв на сайте"
          }
          extra={extra({ attachment: reviewWebLink })}
        >
          <ReviewWebLinkDescriptions reviewWebLink={reviewWebLink} />
        </Collapse.Panel>
      ))}
      {reviewClientEmails?.map((reviewClientEmail, index) => (
        <Collapse.Panel
          key={`reviewClientEmail_${index}`}
          header={`Электронное письмо клиента (${reviewClientEmail.clientEmail})`}
          extra={extra({ attachment: reviewClientEmail })}
        >
          <ReviewClientEmailDescriptions reviewClientEmail={reviewClientEmail} />
        </Collapse.Panel>
      ))}
      {reviewClientCalls?.map((reviewClientCall, index) => (
        <Collapse.Panel
          key={`reviewClientCall_${index}`}
          header={`Звонок клиента (${reviewClientCall.clientPhone})`}
          extra={extra({ attachment: reviewClientCall })}
        >
          <ReviewClientCallDescriptions reviewClientCall={reviewClientCall} />
        </Collapse.Panel>
      ))}
    </Collapse>
  );
}

export default ReviewAttachmentCollapse;
