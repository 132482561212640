import React from "react";
import { useAntdTable, useRequest } from "ahooks";
import { Row, Col, PageHeader, Spin, Tag, Popconfirm } from "antd";
import { UserRole } from "services/user-role/models/user-role";
import { UserRoleUpsertDto } from "services/user-role/dtos/user-role-upsert-dto.type";
import { UserRoleService } from "services/user-role/user-role.service";
import { userRoleService } from "services";
import UserRoleTable from "./UserRoleTable";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import UserRoleForm, { UserRoleFormProps } from "./UserRoleForm";
import { BRANCH_SELECT_ALL_BRANCHES_OPTION_VALUE } from "components/selects/BranchSelect";
import SettingsPage from "pages/settings/settings.page";
import BranchSettingsPage from "../../branch/branch.page";
import RoleSettingsPage from "../../role/role.page";
import { ColumnsType } from "antd/es/table";
import RemoveButton from "components/buttons/remove-button";
import { subjectAction } from "abilities/subject-action.constant";
import { Subject } from "abilities/subject.enum";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";

const createUserRole: UserRoleService["createOne"] = (userId, dto) => {
  const dtoCopy: UserRoleUpsertDto = {
    userId: dto.userId,
    roleId: dto.roleId,
  };
  if (dto.branchId !== BRANCH_SELECT_ALL_BRANCHES_OPTION_VALUE) {
    dtoCopy.branchId = dto.branchId;
  }
  return userRoleService.createOne(userId, dtoCopy);
};

const deleteUserRole: UserRoleService["deleteOne"] = (userId, id) =>
  userRoleService.deleteOne(userId, id);

export interface UserRoleRelationProps {
  userId?: UserRole["userId"];
  loading?: boolean;
  buttonsDisabled?: boolean;
  title?: string;
}

const UserRoleRelation: React.FC<UserRoleRelationProps> = (props) => {
  const {
    userId,
    loading: loadingProp = false,
    buttonsDisabled = false,
    title = "Роли пользователя",
  } = props;

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const accessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        update: false,
        delete: false,
      };
    }
    return {
      update: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.USER,
        action: subjectAction.USER.UPDATE_ALL,
      }),
      delete: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.USER,
        action: subjectAction.USER.DELETE_ALL,
      }),
    };
  }, [pageAccessControlState]);

  const [addUserRole, setAddUserRole] = React.useState<boolean>(false);

  const getTableData = React.useMemo(() => {
    if (!userId) {
      return async () => {
        return {
          list: [],
          total: 0,
        };
      };
    }

    return userRoleService.getUserRoleTableDataGetter(userId);
  }, [userId]);

  const {
    tableProps,
    loading: getTableDataLoading,
    refresh,
    params,
  } = useAntdTable(getTableData, {
    defaultType: "simple",
    cacheKey: `UserRoleTable-${userId}`,
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: {},
      },
    ],
    refreshDeps: [userId],
  });

  const { sorter = {} } = params[0] || ({} as any);

  const { run: runCreateUserRole, loading: createUserRoleLoading } = useRequest(createUserRole, {
    manual: true,
    onSuccess: () => {
      setAddUserRole(false);
      refresh();
    },
  });

  const { run: runDeleteUserRole, loading: deleteUserRoleLoading } = useRequest(deleteUserRole, {
    manual: true,
    onSuccess: () => {
      setAddUserRole(false);
      refresh();
    },
  });

  const handleUserRoleFormFinish: Required<UserRoleFormProps>["onFinish"] = React.useCallback(
    (dto) => {
      if (userId) {
        runCreateUserRole(userId, dto);
      }
    },
    [userId, runCreateUserRole]
  );

  const handleDelete = React.useCallback(
    async (userRoleId: UserRole["id"]) => {
      if (!userRoleId || !userId) {
        return;
      }
      await runDeleteUserRole(userId, userRoleId);
    },
    [runDeleteUserRole, userId]
  );

  const userRoleTableRightExtra: ColumnsType<UserRole> = React.useMemo(() => {
    if (!accessControl.delete) {
      return [];
    }

    return [
      {
        title: "",
        dataIndex: "_",
        key: "_",
        ellipsis: true,
        align: "center",
        fixed: "right",
        render: (_: any, record) => (
          <Popconfirm
            title="Вы уверены? Это действие будет нельзя отменить."
            okText="Удалить"
            okButtonProps={{ danger: true }}
            onConfirm={() => handleDelete(record.id)}
          >
            <RemoveButton
              disabled={getTableDataLoading || createUserRoleLoading}
              loading={deleteUserRoleLoading}
            />
          </Popconfirm>
        ),
      },
    ];
  }, [
    getTableDataLoading,
    createUserRoleLoading,
    deleteUserRoleLoading,
    handleDelete,
    accessControl,
  ]);

  if (!userId) {
    return null;
  }

  return (
    <PageHeader
      title={title}
      subTitle={<Tag color="warning">Вносимые изменения вступят в силу в течение 10 минут</Tag>}
      extra={
        <Row gutter={[8, 8]}>
          {accessControl.update ? (
            <Col>
              <AddButton
                disabled={buttonsDisabled}
                danger={addUserRole}
                onClick={() => setAddUserRole(!addUserRole)}
              >
                {addUserRole ? "Отмена" : "Добавить"}
              </AddButton>
            </Col>
          ) : null}
          <Col>
            <RefreshButton
              disabled={buttonsDisabled}
              loading={getTableDataLoading}
              onClick={refresh}
            />
          </Col>
        </Row>
      }
      style={{ marginTop: "16px" }}
    >
      <Spin spinning={loadingProp} delay={500}>
        {addUserRole && (
          <div style={{ marginBottom: "24px" }}>
            <Spin spinning={createUserRoleLoading || deleteUserRoleLoading}>
              <UserRoleForm
                title="Добавить роль пользователя"
                initialValues={{ userId }}
                disabledFields={{ userId: true }}
                onFinish={handleUserRoleFormFinish}
              />
            </Spin>
          </div>
        )}

        <UserRoleTable
          tableProps={tableProps}
          sorter={sorter}
          size="small"
          getBranchLink={(branchId) =>
            `${SettingsPage.path}${BranchSettingsPage.pathCreator(branchId)}`
          }
          getRoleLink={(roleId) => `${SettingsPage.path}${RoleSettingsPage.pathCreator(roleId)}`}
          rightExtra={userRoleTableRightExtra}
        />
      </Spin>
    </PageHeader>
  );
};

export default UserRoleRelation;
