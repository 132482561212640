import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { User } from "services/user/models/user";

export interface UserState {
  user?: User;
  loading: boolean;
  error?: Error;
  errorMessage?: string;
}

export enum UserActionType {
  SIGN_IN = "SIGN_IN",
  SIGNED_IN = "SIGNED_IN",
  ERRORED_SIGN_IN = "ERRORED_SIGN_IN",
  LOAD_USER = "LOAD_USER",
  LOADED_USER = "LOADED_USER",
  ERRORED_USER = "ERRORED_USER",
  SIGN_OUT = "SIGN_OUT",
  SIGNED_OUT = "SIGNED_OUT",
  ERRORED_SIGN_OUT = "ERRORED_SIGN_OUT",
}

export interface UserAction {
  type: UserActionType;
  payload?: Partial<Omit<UserState, "errorMessage">>;
}

const initialState: UserState = {
  loading: false,
};

export default function userReducer(state = initialState, action: UserAction): UserState {
  switch (action.type) {
    case UserActionType.SIGN_IN:
      return { ...state, loading: true, error: undefined, errorMessage: undefined };
    case UserActionType.SIGNED_IN:
      return { ...state, loading: false, error: undefined, errorMessage: undefined };
    case UserActionType.ERRORED_SIGN_IN:
      const error = action?.payload?.error;
      let errorMessage = "Ошибка!";
      if (error) {
        if (error instanceof ApiDriverHttpError) {
          if (error.status === 401) {
            errorMessage = "Введен неверный логин или пароль.";
          } else if (error.status === 429) {
            errorMessage = "Слишком много запросов. Попробуйте позже.";
          } else if (error.status >= 500) {
            errorMessage = "Ошибка сервера.";
          }
        }
      }
      return {
        ...state,
        loading: false,
        error,
        errorMessage,
      };
    case UserActionType.LOAD_USER:
      return { ...state, loading: true, error: undefined, errorMessage: undefined };
    case UserActionType.LOADED_USER:
      return {
        ...state,
        user: action?.payload?.user,
        loading: false,
        error: undefined,
        errorMessage: undefined,
      };
    case UserActionType.ERRORED_USER:
      return {
        ...state,
        loading: false,
        error: action?.payload?.error,
        errorMessage: undefined,
      };
    case UserActionType.SIGN_OUT:
      return { ...state, loading: true, error: undefined, errorMessage: undefined };
    case UserActionType.SIGNED_OUT:
      return { loading: false };
    case UserActionType.ERRORED_SIGN_OUT:
      return {
        ...state,
        loading: false,
        error: action?.payload?.error,
        errorMessage: undefined,
      };
    default:
      return state;
  }
}
