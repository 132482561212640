import HttpStatus from "http-status-codes";
import ApiDriverBaseError from "./api-driver-base.error";

class ApiDriverHttpError extends ApiDriverBaseError {
  status: number;
  details: any;

  constructor(...args: any) {
    super(...args);

    if (ApiDriverBaseError.captureStackTrace) {
      ApiDriverBaseError.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new ApiDriverBaseError().stack;
    }

    const [status, message, details] = args;
    this.status = status;
    this.message = message || HttpStatus.getStatusText(status) || "ApiDriverHttpError";
    this.details = details;
  }
}

export default ApiDriverHttpError;
