import { CreateQueryParams } from "@nestjsx/crud-request";
import { ServiceOrder } from "services/service-order.service";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { serviceOrderService } from "services";

const fetchServiceOrders = async (params: CreateQueryParams) => {
  const serviceOrders = await serviceOrderService.findAll({
    join: [["serviceOrderWorks"], ["reviewServiceOrders", ["id", "reviewId"]]],
    page: params.page,
    limit: params.limit,
    sort: [
      { field: "closedAt", order: "DESC" },
      { field: "id", order: "DESC" },
    ],
    search: params.search,
  });

  return serviceOrders as PagiantedResponse<ServiceOrder>;
};

export default fetchServiceOrders;
