import React from "react";
import { Layout } from "antd";
import "./content.layout.css";

interface ContentLayoutProps {
  content?: React.ReactNode;
}

const ContentLayout: React.FunctionComponent<ContentLayoutProps> = (props) => {
  const { children, content } = props;
  return <Layout className="content-layout--container">{content ? content : children}</Layout>;
};

export default ContentLayout;
