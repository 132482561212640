import { MyRole } from "services/user/models/my-role";

export interface UserRolesState {
  userRoles?: MyRole[];
  loading: boolean;
  error?: Error;
}

export enum UserRolesActionType {
  LOAD_USER_ROLES = "LOAD_USER_ROLES",
  LOADED_USER_ROLES = "LOADED_USER_ROLES",
  ERRORED_USER_ROLES = "ERRORED_USER_ROLES",
  CLEAR_USER_ROLES = "CLEAR_USER_ROLES",
}

export interface UserRolesAction {
  type: UserRolesActionType;
  payload?: Partial<UserRolesState>;
}

const initialState: UserRolesState = {
  loading: false,
};

export default function userRolesReducer(
  state = initialState,
  action: UserRolesAction
): UserRolesState {
  switch (action.type) {
    case UserRolesActionType.LOAD_USER_ROLES:
      return { ...state, loading: true, error: undefined };
    case UserRolesActionType.LOADED_USER_ROLES:
      return {
        ...state,
        userRoles: action?.payload?.userRoles,
        loading: false,
        error: undefined,
      };
    case UserRolesActionType.ERRORED_USER_ROLES:
      return {
        ...state,
        loading: false,
        error: action?.payload?.error,
      };
    case UserRolesActionType.CLEAR_USER_ROLES:
      return initialState;
    default:
      return state;
  }
}
