import React from "react";
import { Form, Input, Descriptions, Button, Space, Switch } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import CitySelect from "components/selects/CitySelect";
import { Branch } from "services/branch/models/branch";
import { BranchUpsertDto } from "services/branch/dtos/branch-upsert-dto.type";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";

export type BranchFormDto = BranchUpsertDto;

export interface BranchFormProps {
  form?: FormInstance<BranchFormDto>;
  onFinish?: (dto: BranchUpsertDto, entityToUpdate?: Branch) => void;
  entityToUpdate?: Branch;
  initialValues?: Partial<BranchFormDto>;
  disabledFields?: { [key in keyof BranchFormDto]?: boolean };
  title?: string;
}

const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };

const BranchForm: React.FC<BranchFormProps> = (props) => {
  const {
    form: formProp,
    onFinish = noOp,
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Филиал",
    disabledFields,
  } = props;

  const [form] = Form.useForm(formProp);

  const reset = React.useCallback(() => {
    form.resetFields();
  }, [form]);

  const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = {
      isActive: true,
    };

    if (entityToUpdate) {
      Object.assign(iV, entityToUpdate);
    }

    if (initialValuesProp) {
      Object.assign(iV, initialValuesProp);
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const handleFinish = React.useCallback(
    (dto: BranchFormDto) => {
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="guid1c"
            name="guid1c"
            label={<strong>ГУИД (1С)</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              {
                pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
                message: "Введите валидный ГУИД (пример - 264529ec-1dad-44bf-9be5-a3d7f5b51249).",
              },
            ]}
          >
            <Input disabled={disabledFields?.guid1c} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="cityId"
            name="cityId"
            label={<strong>Город</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <CitySelect disabled={disabledFields?.cityId} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="name"
            name="name"
            label={<strong>Название</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              { required: true, message: "Это поле должно быть заполнено." },
              {
                type: "string",
                max: 1024,
                message: "Максимальный размер строки - 1024 символа.",
              },
            ]}
          >
            <Input maxLength={1024} disabled={disabledFields?.name} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="isActive"
            name="isActive"
            label={<strong>Активен</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Да"
              unCheckedChildren="Нет"
              disabled={disabledFields?.isActive}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата обновления</strong>}>
          {displayDateTime(entityToUpdate?.updatedAt)}
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата создания</strong>}>
          {displayDateTime(entityToUpdate?.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Сохранить
          </Button>
          <Button type="text" size="large" onClick={reset}>
            Сбросить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default BranchForm;
