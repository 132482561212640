import { reviewSiteService } from "services";
import { ReviewSite } from "services/review-site/models/review-site";

const fetchReviewSites = async () => {
  const reviewSites = await reviewSiteService.findAll({
    sort: [{ field: "name", order: "ASC" }],
  });
  return reviewSites as ReviewSite[];
};

export default fetchReviewSites;
