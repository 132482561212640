import React from "react";
import { Link } from "react-router-dom";
import { Checkbox, Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import BranchSettingsPage from "pages/settings/pages/branch/branch.page";
import { ArrowRightOutlined } from "@ant-design/icons";
import SettingsPage from "pages/settings/settings.page";
import { Branch } from "services/branch/models/branch";

export interface BranchTableProps {
  tableProps: TableProps<Branch>;
  sorter: SorterResult<Branch> | SorterResult<Branch>[];
  size?: SizeType;
}

const getColumns: (sorter: SorterResult<Branch> | SorterResult<Branch>[]) => ColumnsType<Branch> = (
  sorter
) => {
  let citySorter: SortOrder = null;
  let nameSorter: SortOrder = null;
  let guid1cSorter: SortOrder = null;
  let createdAtSortOrder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;
  let isActiveSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "city.name" && sorter.order) {
      citySorter = sorter.order;
    } else if (sorter.field === "name" && sorter.order) {
      nameSorter = sorter.order;
    } else if (sorter.field === "guid1c" && sorter.order) {
      guid1cSorter = sorter.order;
    } else if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    } else if (sorter.field === "updatedAt" && sorter.order) {
      updatedAtSortOrder = sorter.order;
    } else if (sorter.field === "isActive" && sorter.order) {
      isActiveSortOrder = sorter.order;
    }
  }

  return [
    {
      title: "Город",
      dataIndex: "city.name",
      key: "city.name",
      sorter: true,
      sortOrder: citySorter,
      ellipsis: true,
      render: (_: any, record) => record.city?.name,
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: nameSorter,
      ellipsis: true,
      render: (_: any, record) => record.name,
    },
    {
      title: "Есть ГУИД из 1С",
      dataIndex: "guid1c",
      key: "guid1c",
      sorter: true,
      sortOrder: guid1cSorter,
      ellipsis: true,
      render: (_: any, record) => <Checkbox checked={record.guid1c ? true : false} disabled />,
    },
    {
      title: "Филиал активен",
      dataIndex: "isActive",
      key: "isActive",
      sorter: true,
      sortOrder: isActiveSortOrder,
      ellipsis: true,
      render: (_: any, record) => (record.isActive ? "Да" : "Нет"),
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortOrder: updatedAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "",
      dataIndex: "branch",
      key: "branch",
      render: (_: any, record) => (
        <Link
          to={{
            pathname: `${SettingsPage.path}${BranchSettingsPage.pathCreator(record.id)}`,
            state: { canGoBack: true },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      align: "center",
      fixed: "right",
      ellipsis: true,
    },
  ];
};

const BranchTable: React.FC<BranchTableProps> = (props) => {
  const { tableProps, sorter, size } = props;

  const columns = React.useMemo(() => getColumns(sorter), [sorter]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default BranchTable;
