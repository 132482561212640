import React from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "ahooks";
import { PageHeader, Spin } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import ReminderForm from "components/forms/ReminderForm";
import { reminderService } from "services";
import { ReminderService } from "services/reminder/reminder.service";
import ReminderCardPage from "../card/card.page";
import RemindersPage from "pages/reminders/reminders.page";

const createReminder: ReminderService["createOne"] = (dto) => reminderService.createOne(dto);

const AddReminderCardPage: PageFunctionComponent = () => {
  useSetTitleEffect([RemindersPage.label, AddReminderCardPage.label]);

  const goBack = useGoBack();

  const history = useHistory();

  const { run, loading } = useRequest(createReminder, {
    manual: true,
    onSuccess: (data) => {
      if (data) {
        history.push(`${RemindersPage.path}${ReminderCardPage.pathCreator(data.id)}`, {
          canGoBack: true,
        });
      }
    },
  });

  return (
    <ContentLayout>
      <Breadcrumbs items={[RemindersPage.label, AddReminderCardPage.label]} />
      <PageHeader title={AddReminderCardPage.label} onBack={goBack}>
        <Spin spinning={loading}>
          <ReminderForm title="" onFinish={run} />
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

AddReminderCardPage.label = "Добавить напоминание";
AddReminderCardPage.path = "/add";

export default AddReminderCardPage;
